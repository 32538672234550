<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">盘点产品库存
          <span v-if="Form.status == 6">(已作废)</span>
        </div>
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-body">
        <div class="search-item">
          <div class="item-label bold">盘点仓库：</div>
          <div class="item-value">
            {{ Form.warehouse.name }}
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">仓库地址：</div>
          <div class="item-value">
            {{ Form.warehouse.address }}
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">计划开始时间：</div>
          <div class="item-value">
            {{ Form.planStartDate | dateformat('yyyy-MM-DD') }}
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">计划结束时间：</div>
          <div class="item-value">
            {{ Form.planStartDate | dateformat('yyyy-MM-DD') }}
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold"><span class="star">*</span>盘点人：</div>
          <div class="item-value">
            <inputSelect v-if="Form.status == 1" apiMethod="pageUser" v-model="Form.checkerId"
              :label="Form.checkerInfo.name" ref="checkerId" />
            <span v-else>
              {{ Form.checkerInfo.name }}
            </span>
          </div>
        </div>
        <!-- 开始盘点后才有的表单 -->
        <template v-if="Form.status > 1">
          <div class="search-item">
            <div class="item-label bold">实际开始时间：</div>
            <div class="item-value">
              {{ Form.realStartDate | dateformat('yyyy-MM-DD') }}
            </div>
          </div>
        </template>
        <!-- 结束盘点后才有的表单 -->
        <template v-if="Form.status > 2">
          <div class="search-item">
            <div class="item-label bold">实际结束时间：</div>
            <div class="item-value">
              {{ Form.realEndDate | dateformat('yyyy-MM-DD') }}
            </div>
          </div>
          <div class="search-item">
            <div class="item-label bold"><span class="star">*</span>复核人：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageUser" v-model="Form.reviewerUserId" :label="Form.reviewerName"
                ref="reviewerUserId" :disabled="Form.status != 3" />
            </div>
          </div>
          <div class="search-item">
            <div class="item-label bold"><span class="star">*</span>复核结论：</div>
            <div class="item-value">
              <el-radio v-model="Form.opinion" :label="1" :disabled="Form.status != 3">退回</el-radio>
              <el-radio v-model="Form.opinion" :label="2" :disabled="Form.status != 3">确认通过</el-radio>
            </div>
          </div>
          <div class="search-item" style="width: 100%;margin-bottom: 80px;">
            <div class="item-label bold"><span class="star">*</span>复核意见：</div>
            <div class="item-value">
              <el-input v-model="Form.comment" clearable maxlength="200" type="textarea" :rows="3" placeholder="限200字"
                show-word-limit :disabled="Form.status != 3" ref="comment"></el-input>
            </div>
          </div>
        </template>
      </div>
      <!-- 盘点清单 -->
      <template>
        <div class="form-title" style="margin-top: 20px;">
          <span class="fa fa-border-style"></span>
          产品清单信息
        </div>
        <el-table :data="Form.stockStorageInfo" border style="width: 100%;margin-top: 5px;" height="calc(100% - 280px)"
          ref="list" destroy-on-close>
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column prop="name" label="产品名称" min-width="150" align="center"></el-table-column>
          <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
          <el-table-column label="批准文号" prop="drugApprovalNo" min-width="100" align="center"></el-table-column>
          <el-table-column label="供应商" prop="supplier.name" min-width="120" align="center"></el-table-column>
          <el-table-column label="有效期" width="120" align="center">
            <template slot-scope="scope">{{ scope.row.expiryDate | dateformat('yyyy-MM-DD') }}</template>
          </el-table-column>
          <el-table-column label="存放位置" min-width="160" align="center">
            <template slot-scope="scope">
              <el-select v-if="Form.status == 2" v-model="scope.row.warehouseLocationId" filterable placeholder="请选择">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <span v-else>
                {{ scope.row.warehouseLocation.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="账面库存量" prop="currentQuantity" min-width="100" align="center">
            <template slot-scope="scope">
              {{ Form.status != 1 ? scope.row.currentQuantity : scope.row.availableQuantity }}
            </template>
          </el-table-column>
          <el-table-column v-if="Form.status != 1" label="稽核库存量" prop="auditQuantity" min-width="100" align="center">
            <template slot-scope="scope">
              <inputNumber v-if="Form.status == 2" :min="0" :max="99999999" :precision="4"
                v-model="scope.row.auditQuantity" :id="'auditQuantity' + scope.$index" />
              <span v-else>
                {{ scope.row.auditQuantity }}
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="Form.status != 1" label="差异数量" min-width="100" align="center">
            <template slot-scope="scope">
              <span :class="{ star: Number(scope.row.auditQuantity) - Number(scope.row.currentQuantity) }">
                {{ Number(scope.row.auditQuantity) - Number(scope.row.currentQuantity) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="Form.status != 1" label="差异原因" min-width="120" align="center">
            <template slot-scope="scope">
              <el-input v-if="Form.status == 2" v-model="scope.row.reason" maxlength="64" :id="'reason' + scope.$index"
                clearable></el-input>
              <span v-else>
                {{ scope.row.reason }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 盘盈入库清单 -->
      <template v-if="Form.status > 2">
        <div class="form-title" style="margin-top: 20px;">
          <span class="fa fa-border-style"></span>
          盘盈入库
        </div>
        <el-table :data="Form.surplusResults" border style="width: 100%;margin-top: 5px;" ref="list" destroy-on-close>
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column prop="name" label="产品名称" min-width="150" align="center"></el-table-column>
          <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
          <el-table-column label="批准文号" prop="drugApprovalNo" min-width="100" align="center"></el-table-column>
          <el-table-column label="供应商" prop="supplier.name" min-width="120" align="center"></el-table-column>
          <el-table-column label="有效期" width="120" align="center">
            <template slot-scope="scope">{{ scope.row.expiryDate | dateformat('yyyy-MM-DD') }}</template>
          </el-table-column>
          <el-table-column label="存放位置" prop="warehouseLocationName" min-width="160" align="center">
          </el-table-column>
          <el-table-column label="当前库存" prop="currentQuantity" min-width="100" align="center"></el-table-column>
          <el-table-column label="入库数量" prop="differenceQuantity" min-width="100" align="center"></el-table-column>
          <el-table-column label="期后数量" prop="auditQuantity" min-width="100" align="center"></el-table-column>
          <el-table-column label="稽核库存量" prop="auditQuantity" min-width="100" align="center"></el-table-column>
        </el-table>
      </template>

      <!-- 盘亏出库清单 -->
      <template v-if="Form.status > 2">
        <div class="form-title" style="margin-top: 20px;">
          <span class="fa fa-border-style"></span>
          盘亏出库
        </div>
        <el-table :data="Form.lossResults" border style="width: 100%;margin-top: 5px;" ref="list" destroy-on-close>
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column prop="name" label="产品名称" min-width="150" align="center"></el-table-column>
          <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
          <el-table-column label="批准文号" prop="drugApprovalNo" min-width="100" align="center"></el-table-column>
          <el-table-column label="供应商" prop="supplier.name" min-width="120" align="center"></el-table-column>
          <el-table-column label="有效期" width="120" align="center">
            <template slot-scope="scope">{{ scope.row.expiryDate | dateformat('yyyy-MM-DD') }}</template>
          </el-table-column>
          <el-table-column label="存放位置" prop="warehouseLocationName" min-width="160" align="center">
          </el-table-column>
          <el-table-column label="当前库存" prop="currentQuantity" min-width="100" align="center"></el-table-column>
          <el-table-column label="入库数量" prop="differenceQuantity" min-width="100" align="center"></el-table-column>
          <el-table-column label="期后数量" prop="auditQuantity" min-width="100" align="center"></el-table-column>
          <el-table-column label="稽核库存量" prop="auditQuantity" min-width="100" align="center"></el-table-column>
        </el-table>
      </template>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push('/Stock/Inventory')" />
        <imsButton typeClass="secondary" style="margin-left: 10px;" text="打印清单" @click="printPD()" :loading="loading" />
        <imsButton style="margin-left: 10px;" v-if="Form.status == 1" text="开始盘点" @click="startPD()" :loading="loading" />
        <imsButton typeClass="secondary" style="margin-left: 10px;" v-if="Form.status == 2" text="保存" @click="tempSave()"
          :loading="loading" />
        <imsButton style="margin-left: 10px;" v-if="Form.status == 2" text="结束盘点" @click="endPD()" :loading="loading" />
        <imsButton style="margin-left: 10px;" v-if="Form.status == 3" text="提交复核" @click="approvalPD()"
          :loading="loading" />
      </div>
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber,
  },
  watch: {},
  data() {
    return {
      loading: false,
      Form: {
        status: 1,
        warehouse: {},
        stockStorageInfo: [],
        surplusResults: [],
        lossResults: [],
        checkerInfo: {}
      },
      options: []
    };
  },
  methods: {
    getDetail() {
      this.loading = true
      this.$apis
      [Number(this.Form.status) < 3 ? 'getPDPlan' : 'getPD'](this.Form.id)
        .then(res => {
          this.loading = false
          this.Form = {
            ...res.body,
            checkerId: res.body.creator.id,
            checkedName: res.body.creator.name,
            stockStorageInfo: res.body.stockStorageInfo ? res.body.stockStorageInfo.map(item => {
              return {
                ...item,
                currentQuantity: item.quantity
              }
            }) : res.body.listings
          };
          this.$router.push(`/Stock/Inventory/formPage?id=${res.body.id}&status=${res.body.status}`)
          // 获取仓库-库房信息
          this.$apis.getWarehouse(res.body.warehouse.id).then(res2 => {
            this.options = res2.body.locations.map(item => {
              return {
                id: item.id + '',
                name: item.name
              }
            })
          })
        })
        .catch(() => { this.loading = false });
    },
    // 开始盘点
    startPD() {
      if (!this.Form.checkerId) {
        this.$message.warning('请选择“盘点人”！')
        this.$refs.checkerId.focus()
        return
      }
      this.loading = true
      this.$apis
        .startPD(this.Form)
        .then(res => {
          this.loading = false
          if (res.body) {
            this.$message.success('已开始本次盘点，请填写产品清单信息！')
            this.getDetail()
          }
        })
        .catch(() => { this.loading = false });
    },
    // 打印盘点清单
    printPD() {
      var notify = this.$notify.info({
        title: '消息',
        message: '正在加载打印窗口，请耐心等待...',
        duration: 0
      });
      this.loading = true
      this.$apis.printPD(this.Form.id).then(res => {

      })
        .catch(res => {
          notify.close()
          this.loading = false
          const content = res;
          this.pdfUrl = window.URL.createObjectURL(
            new Blob([content], { type: "application/pdf" })
          );
          var ifr = document.createElement("iframe");
          ifr.style.frameborder = "no";
          ifr.style.display = "none";
          ifr.style.pageBreakBefore = "always";
          ifr.setAttribute("id", "printPdf");
          ifr.setAttribute("name", "printPdf");
          ifr.src = this.pdfUrl;
          document.body.appendChild(ifr);
          this.doPrint("printPdf");
          window.URL.revokeObjectURL(ifr.src); // 释放URL 对象
        })
    },
    // 打印方法
    doPrint(val) {
      var ordonnance = document.getElementById(val).contentWindow;
      setTimeout(() => {
        ordonnance.print();
        this.pdfLoading = false;
      }, 100);
    },
    // 暂存盘点清单
    tempSave() {
      this.loading = true
      this.$apis
        .savePDList(this.Form)
        .then(res => {
          this.loading = false
          if (res.body) {
            this.$message.success('已保存本次盘点信息！')
            this.getDetail()
          }
        })
        .catch(() => { this.loading = false });
    },
    // 结束盘点
    endPD() {
      var checkBool = true
      this.Form.stockStorageInfo.forEach((item, index) => {
        if (checkBool && !item.warehouseLocationId) {
          this.$message.warning(`序号为[${index + 1}]的数据未选择“存放位置”！`)
          document.getElementById(`warehouseLocationId${index}`).focus()
          checkBool = false
        }
        if (checkBool && !item.auditQuantity && item.auditQuantity != 0) {
          this.$message.warning(`序号为[${index + 1}]的数据未填写“稽核库存量”！`)
          document.getElementById(`auditQuantity${index}`).focus()
          checkBool = false
        }
        if (checkBool && !item.reason && (item.auditQuantity != item.currentQuantity)) {
          this.$message.warning(`序号为[${index + 1}]的数据未填写“差异原因”！`)
          document.getElementById(`reason${index}`).focus()
          checkBool = false
        }
      })
      if (!checkBool) return

      this.loading = true
      this.$apis
        .endPD(this.Form)
        .then(res => {
          this.loading = false
          if (res.body) {
            this.$message.success('已结束本次盘点！')
            this.getDetail()
          }
        })
        .catch(() => { this.loading = false });
    },
    // 复核盘点
    approvalPD() {
      if (!this.Form.reviewerUserId) {
        this.$message.warning('请选择“复核人”！')
        this.$refs.reviewerUserId.focus()
        return
      }
      if (!this.Form.opinion) {
        this.$message.warning('请选择“复核结论”！')
        return
      }
      if (!this.Form.comment) {
        this.$message.warning('请选择“复核意见”！')
        this.$refs.comment.focus()
        return
      }
      this.loading = true
      this.$apis
        .approvalPD(this.Form)
        .then(res => {
          this.loading = false
          if (res.body) {
            this.$message.success('已复核本次盘点！')
            this.getDetail()
          }
        })
        .catch(() => { this.loading = false });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.Form.id = this.$route.query.id;
        this.Form.status = this.$route.query.status;
        this.getDetail();
      }
    });
  }
};
</script>