<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    报废出库
                </div>
            </div>
        </div>
        <div class="page-body" style=" height: calc(100% - 170px);">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item">
                    <div class="item-label">出库单号：</div>
                    <div class="item-value">
                        <el-input v-model="Form.no" maxlength="99" placeholder="系统自动生成" disabled></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label"><span class="star" v-show="Form.status != 2 && Form.status != 1">*</span>出库日期：
                    </div>
                    <div class="item-value">
                        <el-date-picker v-model="Form.outboundDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                            ref="outboundDate" :disabled="readonly">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                出库清单
                <span class="link" style="float: right;" @click="openDialogVisible" v-if="!readonly">选择产品</span>
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 15px;">
                <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="产品名称" min-width="160" align="center"></el-table-column>
                <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
                <el-table-column prop="drugApprovalNo" label="批准文号" min-width="100" align="center"></el-table-column>
                <el-table-column prop="batchNo" label="生产批号" min-width="100" align="center"></el-table-column>
                <el-table-column label="有效期" width="100" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.expiryDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="unitName" label="计量单位" width="80" align="center"></el-table-column>
                <el-table-column prop="attributeTypeName" label="产品属性" width="120" align="center">
                </el-table-column>
                <el-table-column prop="supplier.name" label="供应商" min-width="120" align="center">
                </el-table-column>
                <el-table-column prop="availableQuantity" label="可用库存数量" min-width="100" align="center"></el-table-column>
                <el-table-column min-width="100" align="center">
                    <template slot="header">
                        <span class="star" v-show="Form.status != 2 && Form.status != 1">*</span>出库数量
                    </template>
                    <template slot-scope="scope">
                        <inputNumber v-if="!readonly" v-model="scope.row.quantity" :id="'quantity' + scope.$index" :min="0"
                            :max="scope.row.availableQuantity" />
                        <div class="cell-div" v-else>{{
                            scope.row.quantity }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" fixed="right" align="center" v-if="!readonly">
                    <template slot-scope="scope">
                        <el-button type="text" @click="delData(scope.$index)"
                            style="color:rgba(217, 0, 17, 1)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="bottom-btn" style="z-index:99">
            <imsButton typeClass="secondary" text="返回" @click="$router.push('/Stock/Scrap')" style="margin-right:10px" />
            <imsButton style="margin-right:10px" typeClass="secondary" text="保存" :loading="loading" @click="tempSaveForm()"
                v-if="!readonly" />
            <imsButton typeClass="primary" text="提交" :loading="loading" @click="saveForm()" v-if="!readonly" />
            <imsButton typeClass="primary" text="确认记账" :loading="loading" @click="confirmForm(2)" v-if="Form.status == 1"
                style="margin-right:10px" />
            <imsButton typeClass="secondary" text="返回修改" :loading="loading" @click="confirmForm(0)"
                v-if="Form.status == 1" />
        </div>
        <publishDialogVisible :dialogVisible="dialogVisible" :Form="Form" @change="change" :type="2" :stockKeepingUnit="true"></publishDialogVisible>
    </div>
</template>
  
<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";
import publishDialogVisible from "@/components/publishDialogVisible.vue";
export default {
    components: {
        imsButton,
        inputSelect,
        inputNumber,
        publishDialogVisible
    },
    mixins: [mixins],
    data() {
        return {
            Form: {
                id: null,
                listingCollection: []
            },
            dialogVisible: false,
            tableData: [],
            loading: false,
            Query: {
                nameLike: null,
                drugApprovalNoLike: null,
                supplierId: null
            },
            readonly: false,
            supplierOptions: [],
            optionsKW: []
        };
    },
    methods: {
        change(val) {
            this.dialogVisible = false
        },
        getAttributeTypes(value) {
            var arr = this.$store.state.attributeTypes.filter(item => item.code == value)
            return arr.length >= 0 ? arr[0].name : '-'
        },
        // 打开选择产品窗口
        openDialogVisible() {
            this.dialogVisible = true;
        },
        // 删除子表数据
        delData(index) {
            this.Form.listingCollection.splice(index, 1);
        },
        // 记账通过or不通过
        confirmForm(status) {
            this.loading = true;
            this.$apis
                .confirmScrap({ status: status }, this.Form.id)
                .then(res => {
                    if (res.body) {
                        this.$message.success("操作成功！自动返回列表页");
                    }
                    this.loading = false;
                    this.$router.push("/Stock/Scrap");
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 获取详情数据
        getDetail() {
            this.loading = true;
            this.$apis
                .getScrap(this.Form.id)
                .then(res => {
                    if (res.body) {
                        this.Form = {
                            ...this.Form,
                            ...res.body,
                            listingCollection: res.body.stockOutboundListingCollection.map(item => {
                                return {
                                    ...item,
                                }
                            })
                        }
                        if (this.Form.status == 1 || this.Form.status == 2) {
                            this.readonly = true;
                        }
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 提交
        saveForm() {
            var bool = true
            if (!this.Form.outboundDate) {
                this.$message.warning('请选择“出库日期”')
                this.$refs.outboundDate.focus()
                return
            }
            if (this.Form.listingCollection.length == 0) {
                this.$message.warning('请选择“产品”')
                return
            } else {
                this.Form.listingCollection.forEach((item, index) => {
                    if (bool && !item.quantity) {
                        this.$message.warning(`第${index + 1}行未填写“出库数量”`)
                        document.getElementById('quantity' + index).focus()
                        bool = false
                    }
                })
            }
            if (!bool) return
            this.loading = true;
            this.$apis
                .submitScrap({ ...this.Form, stockOutboundListingOtherCollection: this.Form.listingCollection })
                .then(res => {
                    if (res.body) {
                        this.$message.success("保存成功！自动返回列表页");
                    }
                    this.loading = false;
                    this.$router.push("/Stock/Scrap");
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 暂存
        tempSaveForm() {
            this.loading = true;
            this.$apis
                .saveScrap({ ...this.Form, stockOutboundListingOtherCollection: this.Form.listingCollection })
                .then(res => {
                    if (res.body) {
                        this.$message.success("保存成功！");
                        this.Form = {
                            ...this.Form,
                            ...res.body,
                            listingCollection: res.body.stockOutboundListingCollection.map(item => {
                                return {
                                    ...item,
                                }
                            })
                        }
                        this.$router.push(`/Stock/Scrap/formPage?id=${res.body.id}`);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.Form.id = this.$route.query.id;
            this.getDetail()
        }
        this.tableData = [];
    }
};
</script>
  
<style lang="less" scoped>
.search-item {
    width: calc(33.33% - 40px);
}
</style>