<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ Form.status ? '查看销售退货单' : '记账销售退货单' }}
                </div>
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item">
                    <div class="item-label bold">销售退货单号：</div>
                    <div class="item-value">
                        {{ Form.no }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">门店：</div>
                    <div class="item-value">
                        {{ Form.customerName }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">编制人：</div>
                    <div class="item-value">
                        {{ Form.orderMaker }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">发货人：</div>
                    <div class="item-value">
                        {{ Form.consignor }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">复核人：</div>
                    <div class="item-value">
                        {{ Form.reviewingOfficer }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">收货人：</div>
                    <div class="item-value">
                        {{ Form.receiver }}
                    </div>
                </div>
            </div>
            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                产品信息（合计：
                <span class="star">{{ Form.totalAmount }}</span>
                元）
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 15px;"
                height="calc(100% - 240px)" ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="140" align="center">
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
                </el-table-column>
                <el-table-column label="生产批号" prop="batchNo" width="120" align="center">
                </el-table-column>
                <el-table-column label="规格" prop="specification" width="120" align="center">
                </el-table-column>
                <el-table-column label="产品属性" prop="attributeTypeName" min-width="200" align="center">
                </el-table-column>
                <el-table-column label="单价" prop="price" width="80" align="center">
                </el-table-column>
                <el-table-column label="退货数量" prop="quantity" width="100" align="center">
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Sale/Reject')" />
                <imsButton v-if="Form.status == 1" style="margin-left: 10px;" text="复核记账" :loading="loading"
                    @click="approval()" />
                <imsButton v-if="Form.status == 1" style="margin-left: 10px;" text="作废" :loading="loading"
                    @click="invalidSaleReject" />
                <imsButton style="margin-left: 10px;" text="打印" :loading="loading" @click="printReview()" />
            </div>
        </div>

    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
import npmnDialog from '@/components/npmnDialog.vue';
export default {
    components: {
        inputSelect,
        inputNumber,
        npmnDialog
    },
    computed: {
        totalMoney() {
            var total = 0
            this.Form.products.forEach(item => {
                var money = Number(item.quantity) * Number(item.price)
                total += money
            })
            total = total.toFixed(2)
            return total
        }
    },
    data() {
        return {
            loading: false,
            Form: {
                id: null,
                name: null,
                no: '',
                createUserName: '',
                listingCollection: [],
            },
            tableData: [],
            pdfUrl: ''
        }
    },
    methods: {
        getDetail() {
            this.$apis.getSaleReject(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                    customerId: res.body.customer.id,
                    customerName: res.body.customer.name,
                    listingCollection: res.body.listingCollection.map(item => {
                        return {
                            ...item,
                            saleUnit: {
                                name: item.unitName
                            },
                            batchNumber: item.batchNo,
                            validDateEnd: item.expiryDate
                        }
                    })
                }
            }).catch(() => {

            })
        },
        approval() {
            this.$apis.approvalSaleReject(this.Form).then(res => {
                if (res.body) {
                    this.$message.success('复核成功！')
                    this.getDetail()
                }
            }).catch(()=>{})
        },
        invalidSaleReject() {
            this.$confirm(
                `您确定要作废该条数据吗？作废后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.$apis.invalidSaleReject(this.Form.id).then(res => {
                        if (res.body) {
                            this.$message.success('作废成功')
                            this.getDetail()
                        }
                    })
                })
                .catch(() => {

                })
        },
        printReview() {
            this.loading = true
            this.$apis.printSaleReject(this.Form.id).then(res => {
                // console.log(1, res);
            })
                .catch(res => {
                    console.log(res);
                    this.loading = false
                    const content = res;
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([content], { type: "application/pdf" })
                    );
                    // window.open(this.pdfUrl);
                    // var date = new Date().getTime();
                    var ifr = document.createElement("iframe");
                    ifr.style.frameborder = "no";
                    ifr.style.display = "none";
                    ifr.style.pageBreakBefore = "always";
                    ifr.setAttribute("id", "printPdf");
                    ifr.setAttribute("name", "printPdf");
                    ifr.src = this.pdfUrl;
                    document.body.appendChild(ifr);
                    this.doPrint("printPdf");
                    window.URL.revokeObjectURL(ifr.src); // 释放URL 对象
                })
        },
        doPrint(val) {
            var ordonnance = document.getElementById(val).contentWindow;
            setTimeout(() => {
                ordonnance.print();
                // this.pdfLoading = false;
            }, 100);
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>