<template>
    <div class="npmn-dialog">
        <div class="btn-box">
            <!-- <span class="link right" @click="active = !active">
                {{ active ? '查看流程图' : '查看流程信息' }}
            </span> -->
        </div>
        <div class="body-box">
            <imsNpmn v-if="!active" />
            <el-table v-else :data="tableData" border style="width: 100%;height: 100%;" ref="list">
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="流程节点" prop="joints" width="150" align="center">
                </el-table-column>
                <el-table-column label="结论" prop="conclusionDesc" min-width="100" align="center">
                </el-table-column>
                <el-table-column label="意见" prop="reason" min-width="300" align="center">
                </el-table-column>
                <el-table-column label="操作人" prop="createUserName" width="100" align="center">
                </el-table-column>
                <el-table-column label="操作时间" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | dateformat() }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import imsNpmn from '@/components/imsNpmn.vue';
export default {
    components: {
        imsNpmn
    },
    props: ['npmnData'],
    watch: {
        npmnData: {
            handler(val) {
                if (val) {
                    this.tableData = val
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            active: true,
            tableData: [],
            npms: []
        }
    }
}
</script>
<style lang="less" scoped>
.npmn-dialog {
    .btn-box {
        height: 40px;
        line-height: 40px;
    }
    .body-box {
        height: calc(100% - 40px);
    }
}
</style>