import request_artisan from '@/utils/request_artisan'

// 充值卡分页列表
export function getRechargeCardPageList(data) {
  return request_artisan({
    url: '/v1/RechargeCard/page',
    method: 'get',
    params: data
  })
}

// 充值卡列表
export function getRechargeCardList(data) {
  return request_artisan({
    url: '/v1/RechargeCard/List',
    method: 'get',
    params: data
  })
}

// 添加充值卡
export function addRechargeCard(data) {
  return request_artisan({
    url: '/v1/RechargeCard',
    method: 'post',
    data
  })
}

// 修改充值卡
export function updateRechargeCard(data) {
  return request_artisan({
    url: '/v1/RechargeCard',
    method: 'put',
    data
  })
}

// 修改充值卡是否使用
export function updateRechargeCardUse(data) {
  return request_artisan({
    url: '/v1/RechargeCard/use',
    method: 'put',
    data
  })
}

// 删除充值卡
export function deleteRechargeCard(data) {
  return request_artisan({
    url: '/v1/RechargeCard/' + data.rechargeCardId,
    method: 'delete'
  })
}
