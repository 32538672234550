import request from '../request.js'

export default {
  // 门店财务月结统计-分页列表
  pageFinancialCustomer(data) {
    return request.Post({
      url: '/v1/statistics/financial/customer/page',
      data: data
    })
  },
  // 供应商财务月结统计-分页列表
  pageFinancialSupplier(data) {
    return request.Post({
      url: '/v1/statistics/financial/supplier/page',
      data: data
    })
  },
  // 采购统计-分页列表
  pageFinancialPurchase(data) {
    return request.Post({
      url: '/v1/statistics/product/purchase/page',
      data: data
    })
  },
  // 销售统计-分页列表
  pageFinancialSales(data) {
    return request.Post({
      url: '/v1/statistics/product/sales/page',
      data: data
    })
  },
}


import request_artisan from '@/utils/request_artisan'
// 统计订单
export function getStatisticsOrderList(data) {
  return request_artisan({
    url: '/v1/StatisticsOrder/List',
    method: 'get',
    params: data
  })
}
