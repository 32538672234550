<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="70%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form
      ref="dialogForm"
      :model="productForm"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item>
        <el-col :span="8">
          <el-form-item label="产品货号:" prop="productNo">
            <el-input
              v-model="productForm.productNo"
              placeholder="请输入产品货号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品名称:" prop="name">
            <el-input v-model="productForm.name" placeholder="请输入产品名称" />
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="8">
          <el-form-item label="分类类型:" prop="categoryId">
            <el-select
              v-model="productForm.categoryType"
              @change="getCategorysListApi('change')"
            >
              <el-option :value="1" label="销售"></el-option>
              <el-option :value="2" label="预订"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品类型:" prop="categoryId">
            <el-select
              v-model="productForm.categoryId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in categorysList"
                :label="item.name"
                :value="item.categoryId"
                :key="item.prop"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-col :span="8">
          <el-form-item label="产品价格:" prop="markPrice">
            <el-input
              v-model="productForm.markPrice"
              placeholder="请选择产品价格"
            >
              <template slot="prepend">¥</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="副标题:" prop="introduction">
            <el-input
              v-model="productForm.introduction"
              maxlength="30"
              placeholder="请输入副标题"
            />
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="产品属性:" prop="attrs">
        <div v-for="item in attrList" :key="item.attrId">
          <template v-if="item.valueList && item.valueList.length">
            <div class="attr__name">{{ item.name }}</div>
            <el-checkbox-group v-model="item.checkList">
              <el-checkbox
                v-for="it in item.valueList"
                :key="it.valueId"
                :label="it.valueId"
                >{{ it.name }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </div>
      </el-form-item>

      <el-form-item label="上传封面:" prop="fileList">
        <Upload
          :file-list.sync="productForm.fileList"
          upload-url="product"
          multiple
          :limit="9"
        />
      </el-form-item>
      <tinymce v-model="productForm.detial" ref="content" :height="300" />
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import {addProduct, getCategorysList, getProductInfo, updateProduct,} from "@/api/Product";
import {getAttrList} from "@/api/Attr";
import Upload from "@/components/Upload";
import Tinymce from "@/components/Tinymce";

export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    categoryInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Upload,
    Tinymce,
  },
  data() {
    return {
      visible: false,
      loading: false,
      attrList: [],
      categorysList: [],
      productForm: {
        categoryType: null,
        categoryId: null,
        fileList: [],
      },
      productId: "",
      rules: {
        productNo: [
          {
            required: true,
            message: "请输入产品货号",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur",
          },
        ],
        categoryType: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        categoryId: [
          {
            required: true,
            message: "请选择产品类型",
            trigger: "blur",
          },
        ],
        fileList: [
          {
            required: true,
            message: "请至少上传一张图片",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.reset();
    },
    // 重置表单
    reset() {
      this.productForm = {
        fileList: [],
      };
      this.attrList = [];
      this.resetForm("dialogForm");
    },
    // 获取编辑信息
    getEditInfo(categoryInfo) {
      this.productForm = categoryInfo;
      this.visible = true;
    },
    async getData(productId) {
      this.productId = productId;
      if (!this.attrList.length) {
        this.attrList = await getAttrList();
        this.attrList.map((item) => {
          this.$set(item, "checkList", []);
        });
      }
      // 编辑
      if (productId) {
        const res = await getProductInfo({ productId });
        this.productForm = {
          productNo: res.productNo,
          name: res.name,
          categoryType: res.categoryType,
          categoryId: res.categoryId,
          introduction: res.introduction,
          markPrice: res.markPrice,
          detial: res.detial,
          status: res.status,
          params: [],
          fileList: [],
        };
        if (this.$refs.content != undefined) {
          this.$refs.content.setContent(res.detial);
        }
        await this.getCategorysListApi();
        res.attrs &&
          res.attrs.map((item) => {
            this.attrList.map((it) => {
              if (item.attrId === it.attrId) {
                it.checkList = [];
                item.values.forEach((val) => {
                  it.checkList.push(val.valueId);
                });
              }
            });
          });
        res.images.forEach((item) => {
          this.productForm.fileList.push({
            url: item.url,
            path: item.path,
          });
        });
      }
      this.visible = true;
    },
    async getCategorysListApi(type) {
      let query = {};
      if (
        this.productForm.categoryType != null &&
        this.productForm.categoryType != undefined
      ) {
        query.categoryType = this.productForm.categoryType;
      }
      if (type) {
        this.productForm.categoryId = null;
      }
      this.categorysList = await getCategorysList(query);
    },
    handleSubmit() {
      let list = JSON.parse(JSON.stringify(this.attrList));
      list = list.filter((item) => {
        return item.checkList.length;
      });
      list.forEach((item) => {
        item.list = [];
        item.valueList.map((it) => {
          if (item.checkList.includes(it.valueId)) {
            item.list.push(it);
          }
        });
      });
      list.forEach((item) => {
        delete item.valueList;
        item.values = item.list;
        delete item.list;
        delete item.checkList;
      });

      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            // this.productForm.icon = this.productForm.fileList[0].url
            const params = JSON.parse(JSON.stringify(this.productForm));
            params.attrs = list;
            params.images = [];
            params.fileList.forEach((item, index) => {
              params.images.push({
                sort: index,
                url: item.path,
                path: item.url,
              });
            });
            delete params.fileList;
            if (this.productId) {
              params.productId = this.productId;
              await updateProduct(params);
            } else {
              params.status = 1;
              await addProduct(params);
            }
            // if (this.dialogTitle === '添加分类') {
            //   params.categoryId = null
            //   await saveCategory(params)
            // } else {
            //   await updateCategory(params)
            // }
            this.loading = false;
            this.close();
            this.$emit("updateList");
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.attr__name {
  font-size: 15px;
  font-weight: bold;
}
</style>
