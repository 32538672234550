import request_artisan from "@/utils/request_artisan";

// 上传图片
export function uploadImage(data, onUploadProgress) {
  return request_artisan({
    url: "/v1/File/public/banner",
    method: "post",
    onUploadProgress,
    data,
  });
}

// 上传图片(productCover)
export function uploadProductImage(data, onUploadProgress) {
  return request_artisan({
    url: "/v1/File/public/productCover",
    method: "post",
    onUploadProgress,
    data,
  });
}

// 上传视频
export function uploadVideo(data, onUploadProgress) {
  return request_artisan({
    url: "/v1/File/public/Video",
    method: "post",
    onUploadProgress,
    data,
  });
}
