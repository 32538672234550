import { render, staticRenderFns } from "./productlist.vue?vue&type=template&id=47191be8&scoped=true"
import script from "./productlist.vue?vue&type=script&lang=js"
export * from "./productlist.vue?vue&type=script&lang=js"
import style0 from "./productlist.vue?vue&type=style&index=0&id=47191be8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47191be8",
  null
  
)

export default component.exports