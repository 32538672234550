<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          主题新增/编辑
        </div>
      </div>
    </div>
    <div v-loading="pageLoading" class="page-body product">
      <el-form
        ref="dialogForm"
        :model="themeForm"
        :rules="rules"
      >
        <el-form-item> 
          <el-col :span="6">
            <el-form-item label="主题名称:" prop="name">
              <el-input v-model="themeForm.name" placeholder="请输入主题名称" />
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="每行显示商品数量:" prop="rowShowNum">
          <el-radio-group v-model="themeForm.rowShowNum">
            <el-radio :label="1">1个</el-radio>
            <el-radio :label="2">2个</el-radio>
            <el-radio :label="3">3个</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-col :span="8">
            <el-form-item label="备注（选填）:" prop="description">
              <el-input
                type="textarea"
                v-model="themeForm.description"
                :rows="5"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="顶部图片:" prop="fileList">
          <Upload
            :file-list.sync="themeForm.fileList"
            upload-url="product"
            multiple
            :limit="1"
          />
        </el-form-item>

        <el-form-item>
          <div class="product__head">
            <span>商品列表</span>
            <span class="link" style="float: right; margin: 15px;" @click="handleCreate"
              >增加</span>
          </div>
          <el-table :data="productList">
            <el-table-column label="排序" type="index" width="100" align="center">
            </el-table-column>
            <el-table-column
              v-for="item in columns"
              :key="item.prop"
              :prop="item.prop"
              :formatter="item.formatter"
              :label="item.label"
              :width="item.width"
              align="center"
                          >
              <template
                v-if="item.prop === 'coverImage'"
                #default="scope"
              >
                <img
                  class="list-image m-6"
                  :src="scope.row.coverImage"
                  alt=""
                  width="100"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="260">
              <template #default="scope">
                <template>
                  <el-popconfirm
                    title="是否确认删除？"
                    style="margin-left: 10px"
                    @confirm="handleDelete(scope.$index)"
                  >
                    <template #reference>
                      <el-button
                        type="danger"
                        size="mini"
                        icon="el-icon-delete-solid"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" style="margin-top: 20px; text-align: center">
        <imsButton
          v-if="!sendEnd"
          text="保 存"
          :loading="loading"
          @click="handleSubmit"
          />
      </div>
      <ProductListDialog
        ref="productListDialog"
        :parentProductList="productList"
        from="theme"
        @addProduct="updateThemeList"
      />
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import {
  getThemeDetail,
  updateThemeDetail,
  addThemeDetail,
} from "@/api/Marketings/theme";
import Upload from "@/components/Upload";
import ProductListDialog from "@/views/Marketing/recommend/components/ProductListDialog";
import router from "@/router";
export default {
  name: "themeDetail",
  components: {
    Upload,
    ProductListDialog,
  },
  data() {
    return {
      pageLoading: false,
      loading: false,
      sendEnd: false,
      themeId: null,
      productList: [],
      productId: "",
      themeForm: {
        name: "",
        rowShowNum: 1,
        description: "",
        fileList: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入主题名称",
            trigger: "blur",
          },
        ],
        rowShowNum: [
          {
            required: true,
            message: "请至少勾选一项",
            trigger: "change",
          },
        ],
        fileList: [
          {
            required: true,
            message: "请至少上传一张图片",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          label: "商品名称",
          prop: "name",
        },
        {
          label: "商品图片",
          prop: "coverImage",
        },
        {
          label: "分类名称",
          prop: "category",
        },
      ],
    };
  },
  mounted() {
    this.getData();
    // 拖拽
    document.body.ondrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    this.$nextTick(() => {
      this.rowDrop();
    });
  },
  methods: {
    async getData() {
      this.pageLoading = true;
      this.themeId = this.$route.query.themeId;
      // 编辑
      if ( this.themeId ) {
        const { products, name, description, topImg, rowShowNum } =
          await getThemeDetail({
            themeId: this.themeId ,
          });
          this.productList = products.map((item)=>{
            return item.product
          })
        // this.productList = products;
        this.themeForm.name = name;
        this.themeForm.description = description;
        this.themeForm.rowShowNum = rowShowNum;
        this.themeForm.fileList = [
          {
            url: topImg,
            path: topImg,
          },
        ];
      }
      this.pageLoading = false;
    },
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      Sortable.create(tbody, {
        onEnd: async ({ newIndex, oldIndex }) => {
          const list = JSON.parse(JSON.stringify(this.themeForm.productList));
          console.log(list,'list');
          const currRow = list.splice(oldIndex, 1)[0];
          list.splice(newIndex, 0, currRow);
          // this.themeForm.productList = []
          this.$set(this.productList, list);
        },
      });
    },
    handleCreate() {
      this.$refs.productListDialog.getData();
    },
    updateThemeList(list) {
      // const newList = []
      // list.forEach((item, index) => {
      //   item["product"] = item;
      // });
      this.productList = [...this.productList,{...list}];
    },
    handleDelete(index) {
      this.productList.splice(index, 1);
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const params = JSON.parse(JSON.stringify(this.themeForm));
            params.topImg = params.fileList[0].url;
            params.productIds = [];
            this.productList.forEach((item) => {
              params.productIds.push(item.productId);
            });
            delete params.fileList;
            if (this.themeId) {
              params.themeId = this.themeId;
              await updateThemeDetail(params);
            } else {
              await addThemeDetail(params);
            }
            this.loading = false;
            this.sendEnd = true;
            this.$notify({
              title: "成功",
              message: this.themeId ? "编辑成功" : "新增成功",
              type: "success",
              duration: 2000,
            });
            // this.$emit('updateList')
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
  
    // unloadEvent(){
    //   this.themeForm = {
    //     name: "",
    //     rowShowNum: 1,
    //     description: "",
    //     fileList: [],
    //   }
    //   this.productList = []
    //   this.pageLoading = false
    //   this.loading = false
    //   this.sendEnd = false
    //   this.themeId = null
    //   this.productId = ""
    // }

  },
  // watch:{
  //   $route: {
  //     handler() {
  //     this.unloadEvent()
  //     }
  //   }
  // },
  // beforeDestroy(){
  //   this.unloadEvent()
  // }
};
</script>

<style lang="less" scoped>
.product {
  background: #fff !important;
  /deep/ .el-table__body-wrapper {
    cursor: move;
  }
  .product__head {
    span {
      margin-right: 20px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .svg-drag .i {
    width: 30px;
    height: 40px;
  }
}
</style>
