<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    采购退货
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div>
                    <div class="search-item">
                        <div class="item-label">退货单号：</div>
                        <div class="item-value">
                            <el-input v-model="Query.noLike" clearable="" maxlength="99"></el-input>
                        </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">退货日期：</div>
                        <div class="item-value">
                            <el-date-picker v-model="Query.returnsDate" type="date" value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="page-search-right">
                    <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
                    <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
                </div>
            </div>
            <div class="table-btns">
                <span class="link" style="float: right;margin-left: 10px;"
                    @click="$router.push('/Purchase/Reject/formPage')">创建退货单</span>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="no" label="退货单号" width="160" align="center"></el-table-column>
                <el-table-column prop="supplier.name" label="供应商" width="160" align="center"></el-table-column>
                <el-table-column label="产品" min-width="160" align="center">
                    <template slot-scope="scope">
                        <span v-for="(item, index) in scope.row.listingCollection" :key="index">
                            {{ item.name }}
                            <span v-if="(index < scope.row.listingCollection.length - 1)">，</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="退货日期" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.returnsDate | dateformat() }}
                    </template>
                </el-table-column>
                <el-table-column label="复核日期" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.auditTime | dateformat('yyyy-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column prop="approvalStatus" label="复核状态" width="160" align="center">
                    <template slot-scope="scope">
                        {{ dict2[scope.row.status] }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                    <template slot-scope="scope">
                        <div class="link"
                            @click="$router.push(`/Purchase/Reject/${scope.row.status == 0 ? 
                                'formPage' : 'detailPage'}?status=${scope.row.status}&id=${scope.row.id}`)">
                            {{ scope.row.status == 0 ? '编辑' :
                                    scope.row.status == 1 ? '审核' : '查看'
                            }}
                        </div>
                        <div class="link star" v-if="scope.row.status == 0" @click="delData(scope.row)">
                            删除
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination
            v-show="pageQuery.total > 0"
            :total="pageQuery.total"
            :page.sync="pageQuery.pageIndex"
            :limit.sync="pageQuery.pageSize"
            @pagination="getData()"
          />
        </div>

    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import ImsNpmn from '@/components/imsNpmn.vue';
export default {
    components: {
        inputSelect,
        ImsNpmn
    },
    data() {
        return {
            loading: false,
            Query: {
                noLike: null,
                returnsDate: null,
            },
            pageQuery:{
                pageIndex: 1,
                pageSize: 10,
            },
            tableData: [],
        }
    },
    methods: {
        getData(item) {
            this.loading = true
            if(item)
            this.pageQuery={
                ...this.pageQuery,
                pageIndex:1
            }
            this.$apis.pagePurchaseReject({ ...this.Query, ...this.pageQuery, }).then(res => {
                this.tableData = res.body.records;
                this.pageQuery.total = Number(res.body.recordCount);
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        delData(item) {
            this.$confirm(
                `您确定要删除单号为【${item.no}】的数据吗？删除后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.$apis.delPurchaseReject(item.id).then(res => {
                        if (res.body) {
                            this.$message.success('删除成功')
                            this.getData(true)
                        }
                    })
                })
                .catch(() => {

                })
        },  
        handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData()
        })
    }
}
</script>