<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          预订生成汇总
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">店铺列表：</div>
            <div class="item-value">
              <el-select v-model="queryForm.shopId" clearable>
                <el-option
                  v-for="item in shopList"
                  :label="item.name"
                  :value="item.shopId"
                  :key="item.shopUid"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">时间：</div>
            <div class="item-value">
              <el-date-picker
                v-model="queryForm.reserveTime"
                size="small"
                style="width: 340px;"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="handleQuery" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="base-table">
        <el-table :data="itemList" v-loading="loading" border>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.productName">
                <el-table-column
                  prop="shopProduct.name"
                  label="商品名称"
                  align="center"
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column prop="skuAttrValue" label="规格" align="center">
                </el-table-column>

                <el-table-column
                  label="商品图片"
                  prop="shopProduct.coverImage"
                  align="center"
                >
                  <template #default="scope">
                    <img
                      class="list-image"
                      :src="scope.row.shopProduct.coverImage"
                      alt=""
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="totalSaleNumber"
                  label="销售数量"
                  align="center"
                >
                </el-table-column>

                <el-table-column
                  prop="orderNumber"
                  label="订单数量"
                  align="center"
                >
                </el-table-column>

                <el-table-column prop="shop.name" label="店铺" align="center" show-overflow-tooltip>
                </el-table-column>

                <el-table-column label="操作" align="center">
                  <template #default="scope">
                    <template>
                      <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-view"
                        @click="handleView(scope.row, props.row.reserveTime)"
                        >查看</el-button
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <!-- <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="商品名称">
                  <span>{{ props.row }}</span>
                </el-form-item>
              </el-form>
            </template> -->
          </el-table-column>
          <el-table-column label="日期" prop="reserveTime"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {getShopList2} from '@/api/Shop';
import {getStatisticsOrderList} from '@/api/Statistics';

export default {
  name: 'dayStatistics',
  data() {
    return {
      loading: false,
      queryForm: {
        shopId: null,
        reserveTime: []
      },
      shopList: [],
      itemList: []
    }
  },
  mounted() {
    this.getShopList2Api()
    this.getStatisticsOrderListApi()
  },
  methods: {
    async getShopList2Api() {
      this.shopList = await getShopList2({})
    },
    async getStatisticsOrderListApi() {
      this.loading = true
      if (this.queryForm.reserveTime != null) {
        this.queryForm.reserveTimeStart = this.queryForm.reserveTime[0]
        this.queryForm.reserveTimeEnd = this.queryForm.reserveTime[1]
      } else {
        this.queryForm.reserveTimeStart = null
        this.queryForm.reserveTimeEnd = null
      }
      const params = JSON.parse(JSON.stringify(this.queryForm))
      delete params.reserveTime
      const list = await getStatisticsOrderList(params)
      this.itemList = list.map(item => {
        return {
          reserveTime: this.formatDay(item.reserveTime, {
            format: 'YYYY-MM-DD'
          }),
          productName: item.products
        }
      })
      this.loading = false
    },
    handleQuery() {
      this.getStatisticsOrderListApi()
    },
    async handleView(row, reserveTime) {
      // 跳转到订单详情页面， 参入
      // 订单状态=2，订单类型=2，shopId，sku，reserveTimeStart，reserveTimeEnd
      // 两个时间相同
      this.$router.push({
        path:
          `/order/transactionList/dayStatistics/` +
          new Date() +
          '?orderType=2&orderStatus=2' +
          '&shopId=' +
          row.shopId +
          '&sku=' +
          row.sku +
          '&reserveTimeStart=' +
          reserveTime +
          '&reserveTimeEnd=' +
          reserveTime +
          '&newDate=' +
          new Date()
      })
    }      ,
     handleReset() {
            this.initListData(this.queryForm)
            this.$nextTick(() => {
            this.getStatisticsOrderListApi()
            })
        }
  }
}
</script>

<style lang="less" scoped>
.productName {
  margin-bottom: 12px;
  font-weight: bold;
}
</style>
