export default {
  data() {
    return {
      currentRoute: '',
      currentIndex: '',
      wxRouteList: [
        {
          url: '/pages/menu/index',
          name: '销售'
        },
        {
          url: '/pages/reserve/index',
          name: '预订'
        },
        {
          url: '/packageGoods/detail', // shopProductUid=80e514e0-fab6-4def-a841-42bc34ffcc45
          name: '商品'
        },
        {
          url: '/packageTopic/detail',
          name: '文章'
        },
        {
          url: '/packageTopic/theme',
          name: '主题'
        },
        {
          url: '/packageMember/rechargeCenter',
          name: '充值中心'
        }
      ]
    }
  },
  methods: {
    handleJumpTypeChange(url, index) {
      this.currentRoute = url
      this.currentIndex = index
      if (url === '/packageGoods/detail') {
        this.$refs.productDialog.getData()
      } else if (url === '/packageTopic/detail') {
        this.$refs.communityDialog.getData()
      } else if (url === '/packageTopic/theme') {
        this.$refs.themeDialog.getData()
      } else {
        this.form.contentList.forEach((item, index) => {
          if (index === this.currentIndex) {
            this.$set(item, 'linkUrl', this.currentRoute)
          }
        })
      }
    },
    // 选择商品
    addProduct(productUid) {
      this.currentRoute = `${this.currentRoute}?productUid=${productUid}`
      this.form.contentList.forEach((item, index) => {
        if (index === this.currentIndex) {
          this.$set(item, 'linkUrl', this.currentRoute)
        }
      })
    },
    // 选择文章
    addCommunity(topicUId) {
      this.currentRoute = `${this.currentRoute}?topicUId=${topicUId}`
      this.form.contentList.forEach((item, index) => {
        if (index === this.currentIndex) {
          this.$set(item, 'linkUrl', this.currentRoute)
        }
      })
    },
    // 选择主题
    addTheme(themeId) {
      this.currentRoute = `${this.currentRoute}?themeId=${themeId}`
      this.form.contentList.forEach((item, index) => {
        if (index === this.currentIndex) {
          this.$set(item, 'linkUrl', this.currentRoute)
        }
      })
    },
    handleJumpTypeChangeForBanner(url) {
      this.currentRoute = url
      if (url === '/packageGoods/detail') {
        this.$refs.productDialog.getData()
      } else if (url === '/packageTopic/detail') {
        this.$refs.communityDialog.getData()
      } else if (url === '/packageTopic/theme') {
        this.$refs.themeDialog.getData()
      } else {
        this.form.redirectUrl = this.currentRoute
      }
    },
    // 选择商品(banner)
    addProductForBanner(productUid) {
      this.currentRoute = `${this.currentRoute}?productUid=${productUid}`
      this.form.redirectUrl = this.currentRoute
    },
    // 选择文章(banner)
    addCommunityForBanner(topicUId) {
      this.currentRoute = `${this.currentRoute}?topicUId=${topicUId}`
      this.form.redirectUrl = this.currentRoute
    },
    // 选择主题(banner)
    addThemeForBanner(themeId) {
      this.currentRoute = `${this.currentRoute}?themeId=${themeId}`
      this.form.redirectUrl = this.currentRoute
    }
  }
}
