<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="100px">
      <!-- <el-form-item label="广告状态:">
        <span>未发布</span>
      </el-form-item> -->
      <el-form-item label="名称:" prop="name">
        <el-input v-model="form.name" placeholder="请输入广告名称" />
      </el-form-item>
      <el-form-item label="广告位置:" prop="type">
        <el-select v-model="form.type">
          <el-option :value="1" label="顶部banner"></el-option>
          <el-option :value="2" label="中部会员卡"></el-option>
          <el-option :value="3" label="商品详情广告"></el-option>
          <el-option :value="4" label="续租页广告"></el-option>
          <el-option :value="5" label="我的足迹页广告"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input-number
          v-model="form.sort"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="显示权限:" prop="showType">
        <el-select v-model="form.showType">
          <el-option :value="1" label="全部可见"></el-option>
          <el-option :value="2" label="仅会员可见"></el-option>
          <el-option :value="3" label="仅非会员可见"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="有效日期:" prop="createDate">
        <el-date-picker
          v-model="form.createDate"
          size="small"
          style="width: 300px;"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="跳转类型:" prop="scenes">
        <el-select v-model="form.scenes">
          <el-option value="web" label="Web"></el-option>
          <el-option value="app" label="App"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跳转链接:" prop="redirectUrl">
        <el-select
          v-model="selectUrl"
          style="margin-bottom: 12px;"
          @change="handleJumpTypeChangeForBanner($event)"
        >
          <el-option
            v-for="(route, idx) in wxRouteList"
            :key="idx"
            :value="route.url"
            :label="route.name"
          ></el-option>
        </el-select>
        <el-input
          v-model="form.redirectUrl"
          disabled
          placeholder="请输入跳转链接"
        />
      </el-form-item>
      <el-form-item label="广告描述:" prop="remark">
        <el-input
          v-model="form.remark"
          :rows="2"
          type="textarea"
          maxlength="200"
          placeholder="请输入广告描述"
        />
      </el-form-item>
      <el-form-item label="上传封面:" prop="fileList">
        <Upload :file-list.sync="form.fileList" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
    <ProductListDialog ref="productDialog" @addProduct="addProductForBanner" />
    <CommunityDialog
      ref="communityDialog"
      @addCommunity="addCommunityForBanner"
    />
    <ThemeDialog ref="themeDialog" @addTheme="addThemeForBanner" />
  </el-dialog>
</template>

<script>
import jumpType from '@/mixins/jumpType'
import { createBanner, getBanner, updateBanner } from '@/api/Decorate'
import Upload from '@/components/Upload'
import ProductListDialog from './ProductListDialog'
import CommunityDialog from './CommunityDialog'
import ThemeDialog from './ThemeDialog'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    categoryInfo: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [jumpType],
  components: {
    Upload,
    ProductListDialog,
    CommunityDialog,
    ThemeDialog
  },
  data() {
    return {
      visible: false,
      loading: false,
      bannerId: '',
      selectUrl: '',
      form: {
        fileList: []
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择广告位置',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          }
        ],
        showType: [
          {
            required: true,
            message: '请选择广告显示权限',
            trigger: 'blur'
          }
        ],
        createDate: [
          {
            required: true,
            message: '请选择有效期日期',
            trigger: 'blur'
          }
        ],
        fileList: [
          {
            required: true,
            message: '请至少上传一张图片',
            trigger: 'change'
          }
        ],
        scenes: [
          {
            required: true,
            message: '跳转类型必填',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.selectUrl = ''
      this.form = {
        fileList: [],
        createDate: []
      }
      this.resetForm('dialogForm')
    },
    // 获取编辑信息
    async getData(id) {
      this.reset()
      this.bannerId = id
      if (id) {
        const res = await getBanner({
          id
        })
        this.form = Object.assign({}, this.form, res)
        this.form.createDate = [res.effectiveStartTime, res.effectiveEndTime]
        this.form.fileList = [
          {
            path: res.imageUrl,
            url: res.imagePath
          }
        ]
      }
      this.visible = true
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            this.form.ImageUrl = this.form.fileList[0].path
            this.form.effectiveStartTime = this.form.createDate[0]
            this.form.effectiveEndTime = this.form.createDate[1]
            const params = JSON.parse(JSON.stringify(this.form))
            delete params.fileList
            delete params.createDate
            if (this.bannerId) {
              await updateBanner({
                id: this.bannerId,
                info: params
              })
            } else {
              await createBanner(params)
            }
            // if (this.dialogTitle === '添加分类') {
            //   params.categoryId = null
            //   await saveCategory(params)
            // } else {
            //   await createBanner(params)
            // }
            this.loading = false
            this.close()
            this.$emit('updateList')
          } catch (error) {
            console.log(error)
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
