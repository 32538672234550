<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="30%"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="属性:" prop="name">
        <el-input v-model="form.name" placeholder="请输入属性" />
      </el-form-item>
      <el-form-item label="备注:" prop="description">
        <el-input
          v-model="form.description"
          :rows="2"
          type="textarea"
          placeholder="说点什么..."
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { addAttr, updateAttr } from "@/api/Attr";
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      type: "", // 编辑还是创建
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入属性值",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.reset();
      this.visible = false;
    },
    // 重置表单
    reset() {
      this.resetForm("dialogForm");
      this.form = {};
    },
    // 获取编辑信息
    getData(row, type) {
      this.type = type;
      if (type === "edit") {
        // 属性编辑
        this.form = {
          attrId: row.attrId,
          name: row.name,
          description: row.description,
        };
      } else {
        this.parent = row;
      }

      this.visible = true;
    },
    openDialog(row) {
      if (row == null || row == undefined) {
        this.type = "add";
      } else {
        this.type = "edit";
        // 属性编辑
        this.form = {
          attrId: row.attrId,
          name: row.name,
          description: row.description,
        };
      }
      this.visible = true;
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            this.loading = false;
            const params = JSON.parse(JSON.stringify(this.form));
            if (this.type === "add") {
              await addAttr(params);
            } else {
              await updateAttr(params);
            }
            this.close();
            this.$emit("updateList");
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
