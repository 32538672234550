import request_artisan from '@/utils/request_artisan'

// 主题分页列表
export function getThemeList(data) {
  return request_artisan({
    url: '/v1/Theme/Page',
    method: 'get',
    params: data
  })
}

// 获取主题
export function getThemeDetail(data) {
  return request_artisan({
    url: '/v1/Theme/' + data.themeId,
    method: 'get'
  })
}

// 修改主题
export function updateThemeDetail(data) {
  return request_artisan({
    url: '/v1/Theme/' + data.themeId,
    method: 'put',
    data
  })
}

// 修改主题
export function addThemeDetail(data) {
  return request_artisan({
    url: '/v1/Theme/',
    method: 'post',
    data
  })
}

// 修改主题使用
export function updateThemeIsUse(data) {
  return request_artisan({
    url: '/v1/Theme/IsUsed/' + data.themeId,
    method: 'put'
  })
}

// 主题排序
export function sortTheme(data) {
  return request_artisan({
    url: '/v1/Theme/Sort',
    method: 'put',
    data
  })
}

// 删除主题使用
export function deleteTheme(data) {
  return request_artisan({
    url: '/v1/Theme/' + data.themeId,
    method: 'delete'
  })
}
