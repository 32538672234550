import request from '../request.js'

export default {
  // 分页-采购计划
  pagePurchasePlan(data) {
    return request.Post({
      url: '/v1/purchase/plan/page',
      data: data
    })
  },
  // 详情-采购计划
  getPurchasePlan(id) {
    return request.Get({
      url: `/v1/purchase/plan/${id}`,
      data: {}
    })
  },
  // 新增暂存-采购计划
  addPurchasePlan(data, id) {
    return request.Put({
      url: `/v1/purchase/plan?id=${id ? id : ''}`,
      data: data
    })
  },
  // 提交锁定-采购计划
  submitPurchasePlan(data, id) {
    return request.Put({
      url: `/v1/purchase/plan/commit?id=${id ? id : ''}`,
      data: data
    })
  },
  // 解锁-采购计划
  unlockPurchasePlan(id) {
    return request.Put({
      url: `/v1/purchase/plan/${id}/unlock`,
      data: {}
    })
  },
  // 审核-采购计划
  approvalPurchasePlan(data, id) {
    return request.Put({
      url: `/v1/purchase/plan/${id}/approval`,
      data: data
    })
  },
  // 导出指定计划-采购计划
  exportPurchasePlan(id) {
    return request.Download({
      url: `/v1/purchase/plan/${id}/export`,
      data: {}
    })
  },
  // 导入excel-采购计划
  importPurchasePlan(data) {
    return request.Get({
      url: `/v1/purchase/plan/import`,
      data: data
    })
  },
  // 打印指定计划-采购计划
  printPurchasePlan(id) {
    return request.Put({
      url: `/v1/purchase/plan/${id}/print`,
      data: {}
    })
  },
  // 删除-采购计划
  delPurchasePlan(id) {
    return request.Delete({
      url: `/v1/purchase/plan/${id}`,
      data: {}
    })
  },
  // 作废-采购计划
  invalidPurchasePlan(id) {
    return request.Put({
      url: `/v1/purchase/plan/${id}/invalid`,
      data: {}
    })
  },
  // 查看历史-采购计划
  historyPurchasePlan(id) {
    return request.Get({
      url: `/v1/purchase/plan/${id}/history`,
      data: {}
    })
  },

  // 分页-采购订单
  pagePurchaseOrder(data) {
    return request.Post({
      url: '/v1/purchase/order/page',
      data: data
    })
  },
  // 详情-采购订单
  getPurchaseOrder(id) {
    return request.Get({
      url: `/v1/purchase/order/${id ? id : ''}`,
      data: {}
    })
  },
  // 保存-采购订单
  addPurchaseOrder(data, id) {
    return request.Put({
      url: `/v1/purchase/order/${id ? id : ''}`,
      data: data
    })
  },
  // 提交-采购订单
  submitPurchaseOrder(data, id) {
    return request.Put({
      url: `/v1/purchase/order/commit/${id ? id : ''}`,
      data: data
    })
  },

  // 分页-采购退货
  pagePurchaseReject(data) {
    return request.Post({
      url: '/v1/purchase/returns/page',
      data: data
    })
  },
  // 详情-采购退货
  getPurchaseReject(id) {
    return request.Get({
      url: `/v1/purchase/returns/${id}`,
      data: {}
    })
  },
  // 提交-采购退货
  submitPurchaseReject(data) {
    return request.Put({
      url: `/v1/purchase/returns/commit`,
      data: data
    })
  },
  // 暂存-采购退货
  savePurchaseReject(data) {
    return request.Put({
      url: '/v1/purchase/returns/stage',
      data: data
    })
  },
  // 删除-采购退货
  delPurchaseReject(id) {
    return request.Delete({
      url: `/v1/purchase/returns/${id}`,
      data: {}
    })
  },
  // 可选的退货产品列表-采购退货
  pageCanReject(data) {
    return request.Post({
      url: `/v1/purchase/returns/listPage`,
      data: data
    })
  },
  // 审核-采购退货
  approvalPurchaseReject(data) {
    return request.Put({
      url: `/v1/purchase/returns/approval`,
      data: data
    })
  },
  // 作废-采购退货
  invalidPurchaseReject(id) {
    return request.Put({
      url: `/v1/purchase/returns/${id}/invalid`,
      data: {}
    })
  },
  // 解锁-采购退货
  unlockPurchaseReject(id) {
    return request.Put({
      url: `/v1/purchase/returns/${id}/unlock`,
      data: {}
    })
  },
}