<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">实时库存</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
            <div class="search-item">
            <div class="item-label">产品名称：</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
            </div>
            <div class="search-item">
              <div class="item-label">有效期：</div>
              <div class="item-value">
                <el-date-picker v-model="Query.expiryDateArr" type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
              </div>
            </div>
            <div class="search-item">
              <div class="item-label">供应商：</div>
              <div class="item-value">
                <inputSelect apiMethod="pageSupplier" v-model="Query.supplierId" />
              </div>
            </div>
            <div class="search-item">
              <div class="item-label">生产批号：</div>
              <div class="item-value">
                <el-input v-model="Query.batchNoLike" clearable maxlength="99"></el-input>
              </div>
            </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column label="产品名称" prop="name" min-width="140" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="供应商" prop="supplier.name" width="120" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="生产批号" prop="batchNo" width="120" align="center">
        </el-table-column>
        <el-table-column label="规格" prop="specification" width="120" align="center">
        </el-table-column>
        <el-table-column label="产品属性" prop="attributeTypeName" min-width="200" align="center">
        </el-table-column>
        <el-table-column label="有效期" prop="expiryDate" width="100" align="center">
        </el-table-column>
        <el-table-column label="单价" prop="price" width="80" align="center">
        </el-table-column>
        <el-table-column label="库存量" prop="quantity" width="100" align="center">
        </el-table-column>
        <el-table-column label="可用库存量" prop="availableQuantity" width="100" align="center">
        </el-table-column>
        <!-- <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <div class="link" @click="$router.push(`/Stock/RealTimeStock/detail?id=${scope.row.id}`)">查看库存流水</div>
          </template>
        </el-table-column> -->
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";

export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber
  },
  watch: {
    'Query.expiryDateArr'(val) {
      if (val && val.length > 0) {
        this.Query.startExpiryDate = this.$options.filters['dateformat'](val[0], 'yyyy-MM-DD')
        this.Query.endExpiryDate = this.$options.filters['dateformat'](val[1], 'yyyy-MM-DD')
      } else {
        this.Query.startExpiryDate = null
        this.Query.endExpiryDate = null
      }
    },
  },
  data() {
    return {
      loading: false,
      Query: {
        nameLike: null,
        expiryDateArr: [],
      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageStorage({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>