import request from '../request.js'

export default {

  // 库存采购入库-分页
  pageStockPurchaseInbound(data) {
    return request.Post({
      url: `/v1/stock/purchase/inbound/page`,
      data: data
    })
  },
  // 库存采购入库-详情
  getStockPurchaseInbound(uid) {
    return request.Get({
      url: `/v1/stock/purchase/inbound/${uid}`,
      data: {}
    })
  },
  // 库存采购入库-提交
  submitStockPurchaseInbound(data, id) {
    return request.Put({
      url: `/v1/stock/purchase/inbound/commit?uid=${id ? id : ''}`,
      data: data
    })
  },
  // 库存采购入库-暂存
  saveStockPurchaseInbound(data, id) {
    return request.Put({
      url: `/v1/stock/purchase/inbound?uid=${id ? id : ''}`,
      data: data
    })
  },
  // 库存采购入库-审核
  approvalStockPurchaseInbound(uid, status) {
    return request.Put({
      url: `/v1/stock/purchase/inbound/review/${uid}/${status}`,
      data: {}
    })
  },
  // 库存采购入库-打印
  printStockPurchaseInbound(uid) {
    return request.DownloadPost({
      url: `/v1/stock/purchase/inbound/generate/${uid}`,
      data: {}
    })
  },

  // 分页-采购计划
  pageStockSalesOutbound(data) {
    return request.Post({
      url: '/v1/stock/sales/outbound/page',
      data: data
    })
  },
  // 详情-采购计划
  getStockSalesOutbound(id) {
    return request.Get({
      url: `/v1/stock/sales/outbound/${id}`,
      data: {}
    })
  },
  // 新增暂存-采购计划
  saveStockSalesOutbound(data, id) {
    return request.Put({
      url: `/v1/stock/sales/outbound?id=${id ? id : ''}`,
      data: data
    })
  },

  // 实时库存-分页
  pageStorage(data) {
    return request.Post({
      url: `/v1/stock/storage/page`,
      data: data
    })
  },

  // 分页-销售出库
  pageOrderFlowing(data) {
    return request.Post({
      url: '/v1/stock/outbound/sales/page',
      data: data
    })
  },

  // 详情-销售出库
  getOrderFlowing(id) {
    return request.Get({
      url: `/v1/stock/outbound/sales/${id}`,
      data: {}
    })
  },

  // 记账-销售出库
  confirmOrderFlowing(data, id) {
    return request.Put({
      url: `/v1/stock/outbound/sales/out/${id}`,
      data: data
    })
  },

  // 分页-销售退货入库
  pageOrderFlowingReturn(data) {
    return request.Post({
      url: '/v1/stock/inbound/sale/returns/page',
      data: data
    })
  },

  // 详情-销售退货入库
  getOrderFlowingReturn(id) {
    return request.Get({
      url: `/v1/stock/inbound/sale/returns/${id}`,
      data: {}
    })
  },

  // 记账-销售退货入库
  confirmOrderFlowingReturn(data, id) {
    return request.Put({
      url: `/v1/stock/inbound/sale/returns/${id}`,
      data: data
    })
  },

  // 分页-采购退货出库
  pageOrderRejectOut(data) {
    return request.Post({
      url: '/v1/stock/outbound/purchase/returns/page',
      data: data
    })
  },

  // 详情-采购退货出库
  getOrderRejectOut(id) {
    return request.Get({
      url: `/v1/stock/outbound/purchase/returns/${id}`,
      data: {}
    })
  },

  // 记账-采购退货出库
  confirmOrderRejectOut(data, id) {
    return request.Put({
      url: `/v1/stock/outbound/purchase/returns/out/${id}`,
      data: data
    })
  },

  // 分页-库存盘点
  pagePD(data) {
    return request.Post({
      url: `/v1/stock/storage/inventory/page`,
      data: data
    })
  },
  // 删除-库存盘点
  delPD(id) {
    return request.Delete({
      url: `/v1/stock/storage/inventory/${id}`,
      data: {}
    })
  },
  // 暂存-库存盘点
  savePD(data) {
    return request.Put({
      url: `/v1/stock/storage/inventory/save`,
      data: data
    })
  },
  // 提交-库存盘点
  submitPD(data) {
    return request.Put({
      url: `/v1/stock/storage/inventory/commit`,
      data: data
    })
  },
  // 作废-库存盘点
  cancelPD(id) {
    return request.Put({
      url: `/v1/stock/storage/inventory/cancel/${id}`,
      data: {}
    })
  },
  // 盘点计划详情-库存盘点
  getPDPlan(id) {
    return request.Get({
      url: `/v1/stock/storage/inventory/Info/${id}`,
      data: {}
    })
  },
  // 详情-库存盘点
  getPD(id) {
    return request.Get({
      url: `/v1/stock/storage/inventory/${id}`,
      data: {}
    })
  },
  // 开始-库存盘点
  startPD(data) {
    return request.Put({
      url: `/v1/stock/storage/inventory/start`,
      data: data
    })
  },
  // 打印-库存盘点
  printPD(id) {
    return request.DownloadPost({
      url: `/v1/common/export/stockStorageInventory/${id}`,
      data: {}
    })
  },
  // 暂存清单-库存盘点
  savePDList(data) {
    return request.Put({
      url: `/v1/stock/storage/inventory/Info/stage`,
      data: data
    })
  },
  // 结束-库存盘点
  endPD(data) {
    return request.Put({
      url: `/v1/stock/storage/inventory/end`,
      data: data
    })
  },
  // 结束-库存盘点
  approvalPD(data) {
    return request.Put({
      url: `/v1/stock/storage/inventory/approval`,
      data: data
    })
  },
}