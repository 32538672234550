<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          店铺商品列表
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">商品名称：</div>
            <div class="item-value">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入商品名称"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">分类类别：</div>
            <div class="item-value">
              <el-select
                v-model="queryForm.categoryType"
                @change="getCategorysListApi"
                clearable
              >
                <el-option :value="1" label="销售"></el-option>
                <el-option :value="2" label="预订"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">产品分类：</div>
            <div class="item-value">
              <el-select
                v-model="queryForm.categoryId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in categorysList"
                  :label="item.name"
                  :value="item.categoryId"
                  :key="item.prop"
                  :prop="item.prop"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">状态：</div>
            <div class="item-value">
              <el-select v-model="queryForm.status" clearable>
                <el-option :value="1" label="未发布"></el-option>
                <el-option :value="2" label="已发布"></el-option>
                <el-option :value="3" label="下架"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">选择店铺：</div>
            <div class="item-value">
              <el-select
                v-model="queryForm.shopId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in shopsList"
                  :label="item.name"
                  :value="item.shopId"
                  :key="item.prop"
                  :prop="item.prop"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin: 4px;" text="查询" :loading="loading" @click="handleQuery" />
          <imsButton typeClass="secondary" text="重置"  style="float: right;margin: 4px" @click="handleReset()" />
        </div>
      </div>
      <div class="base-table">
        <div class="action">
          <span class="link" style="float: right; margin: 15px;" @click="handleCreate"
            >添加店铺商品</span>
        </div>
        <el-table :data="productList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
            <template v-if="item.prop === 'coverImage'" #default="scope">
              <img class="m-6" :src="scope.row.coverImage" alt="" width="100" />
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="400">
            <template #default="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleStore(scope.row.shopProductId)"
                >添加库存</el-button
              >

              <el-popconfirm
                title="是否确认删除？"
                style="margin: 0 10px;"
                @confirm="handleDelete(scope.row.shopProductId)"
              >
                <template #reference>
                  <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getShopProductListApi"
        />
      </div>
      <ProductListDialog
        ref="productListDialog"
        :shopIdF="queryForm.shopId"
        :shopsList="shopsList"
        @updateList="getShopProductListApi"
      />
      <SkuListDialog ref="SkuListDialog" />
    </div>
  </div>
</template>

<script>
import {getCategorysList} from '@/api/Product'
import {deleteShopProduct, getShopList, getShopProduct, getShopProductList} from '@/api/Shop'
import ProductListDialog from './components/ProductListDialog'
import SkuListDialog from './components/SkuListDialog'

export default {
  name: 'shopProductList',
  components: {
    ProductListDialog,
    SkuListDialog
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        createDate: [],
        name: '',
        categoryId: null,
        shopId:null,
        status: null
      },
      total: 0,
      productList: [],
      shopsList:[],
      categorysList: [],
      dialogTitle: '店铺新增',
      columns: [
        {
          label: '店铺商品ID',
          prop: 'shopProductId'
        },
        {
          label: '商品名称',
          prop: 'name'
        },
        {
          label: '分类类型',
          prop: 'categoryType',
          formatter: (row, column, value) => {
            return value === 1 ? '销售' : value === 2 ? '预订' : ''
          }
        },
        {
          label: '商品类型',
          prop: 'category'
        },
        {
          label: '商品图片',
          prop: 'coverImage'
        },
        {
          label: '创建时间',
          prop: 'createDateTime',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD'
            })
          }
        },
        {
          label: '状态',
          prop: 'status',
          formatter: (row, column, value) => {
            return {
              1: '未发布',
              2: '已发布',
              3: '下架'
            }[value]
          }
        }
      ]
    }
  },
  mounted() {
    this.queryForm.shopId = this.$route.query.id
    this.getCategorysListApi()
    this.getShopProductListApi()
    this.getShopListApi()
  },
  methods: {
    async getCategorysListApi() {
      let query = {}
      if (
        this.queryForm.categoryType != null &&
        this.queryForm.categoryType != undefined
      ) {
        query.categoryType = this.queryForm.categoryType
      }
      this.queryForm.categoryId = null
      this.categorysList = await getCategorysList(query)
    },
    async getShopListApi(){
      this.loading = true
      const { records, recordCount } = await getShopList({
        ...this.queryForm
      })
      this.shopsList = records
      this.loading = false
    },
    async getShopProductListApi() {
      this.loading = true
      const { records, recordCount } = await getShopProductList({
        ...this.queryForm,
        // shopId: this.$route.params.shopId
      })
      this.productList = records
      this.total = recordCount
      this.loading = false
    },
    async handleStatus(row) {
      await updateShopStatus({
        shopId: row.shopId,
        status: row.status == -1 ? 1 : -1
      })
      this.getShopProductListApi()
    },
    async handleDelete(shopProductId) {
      await deleteShopProduct({ shopProductId })
      this.getShopProductListApi()
    },
    handleCreate() {
      this.$refs.productListDialog.getData()
      // this.$refs.OperateDialog.reset()
    },
    async handleStore(shopProductId) {
      let data = await getShopProduct({
        shopProductId,
        shopId: this.queryForm.shopId
      })
      // console.log(data)
      this.$refs.SkuListDialog.setData(data)
    },
    handleQuery() {
      this.queryForm.pageIndex = 1
      this.getShopProductListApi()
    },
    handleReset() {
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
      this.$nextTick(() => {
      this.getShopProductListApi()
      })
        }
    // handleReset() {
    //   this.resetForm('queryForm')
    //   this.getShopProductListApi()
    // }
  }
}
</script>

<style lang="less" scoped></style>
