<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ Form.id ? '编辑销售订单' : '新增销售订单' }}
                </div>
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item">
                    <div class="item-label bold">销售单号：</div>
                    <div class="item-value">
                        <el-input v-model="Form.no" disabled></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold"><span class="star">*</span>门店：</div>
                    <div class="item-value">
                        <inputSelect apiMethod="pageBtype" v-model="Form.customerId" :label="Form.customerName"
                            ref="customerId" />
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">编制人：</div>
                    <div class="item-value">
                        <el-input v-model="Form.orderMaker"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">发货人：</div>
                    <div class="item-value">
                        <el-input v-model="Form.consignor"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">复核人：</div>
                    <div class="item-value">
                        <el-input v-model="Form.reviewingOfficer"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">收货人：</div>
                    <div class="item-value">
                        <el-input v-model="Form.receiver"></el-input>
                    </div>
                </div>
            </div>
            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                产品信息（合计：
                <span class="star">{{ totalMoney }}</span>
                元）
                <span class="link" style="float: right;" @click="openDialogVisible">选择产品</span>
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 15px;"
                height="calc(100% - 240px)" ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="140" align="center">
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
                </el-table-column>
                <el-table-column label="生产批号" prop="batchNo" width="120" align="center">
                </el-table-column>
                <el-table-column label="规格" prop="specification" width="120" align="center">
                </el-table-column>
                <el-table-column label="产品属性" prop="attributeTypeName" min-width="200" align="center">
                </el-table-column>
                <el-table-column label="单价" prop="price" width="80" align="center">
                </el-table-column>
                <el-table-column label="库存量" prop="stockStorageQuantity" width="100" align="center">
                </el-table-column>
                <el-table-column label="可用库存量" prop="availableQuantity" width="100" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="销售数量" width="200" align="center">
                    <template slot-scope="scope">
                        <inputNumber v-model="scope.row.quantity" :max="scope.row.availableQuantity" :min="0"
                            :ref="'quantity' + scope.$index">
                        </inputNumber>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <div class="link star" @click="delData(scope)">删除
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Sale/Order')" />
                <imsButton style="margin-left: 10px;" text="提交" :loading="loading" @click="saveForm('submitSaleOrder')" />
                <imsButton style="margin-left: 10px;" typeClass="secondary" text="保存" @click="saveForm('saveSaleOrder')"
                    :loading="loading" />
            </div>
        </div>
        <publishDialogVisible :dialogVisible="dialogVisible" :Form="Form" @change="change" :type="2" :stockKeepingUnit="true"></publishDialogVisible>
    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
import npmnDialog from '@/components/npmnDialog.vue';
import publishDialogVisible from "@/components/publishDialogVisible.vue";

export default {
    components: {
        inputSelect,
        inputNumber,
        npmnDialog,
        publishDialogVisible
    },
    computed: {
        totalMoney() {
            var total = 0
            this.Form.listingCollection.forEach(item => {
                var money = Number(item.quantity) * Number(item.price)
                total += money
            })
            total = total.toFixed(2)
            return total
        }
    },
    watch: {
    },
    data() {
        return {
            loading: false,
            Form: {
                no: '系统自动生成',
                listingCollection: [],
            },
            dialogVisible: false,
            Query: {
                nameLike: null,
                enabled: true
            },
            vaccineList: [],
            options: [],
            multipleSelection: [],
        }
    },
    methods: {
        // 更新listingCollection数据
        updateList() {
            this.Form.listingCollection = this.Form.listingCollection.concat(this.multipleSelection)
            this.dialogVisible = false
            this.multipleSelection = []
        },
        delData(scope) {
            this.$confirm(
                `您确定要删除名称为【${scope.row.name}】的数据吗？删除后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.Form.listingCollection.splice(scope.$index, 1)
                })
                .catch(() => {

                })
        },
        saveForm(apiMethod) {
            let bool = true
            if (apiMethod == 'submitSaleOrder') {
                if (!this.Form.customerId) {
                    this.$message.warning('请选择“门店”')
                    this.$refs['customerId'].focus()
                    return
                }
                if (this.Form.listingCollection.length == 0) {
                    this.$message.warning('请添加“产品信息”')
                    return
                } else {
                    this.Form.listingCollection.forEach((item, index) => {
                        if (bool && (!item.quantity)) {
                            this.$message.warning(`第${index + 1}行数据未填写“数量”`)
                            this.$refs['quantity' + index].focus()
                            bool = false
                        }
                    })
                }
            }
            if (!bool) return
            this.loading = true
            this.$apis[apiMethod](this.Form).then(res => {
                this.loading = false
                if (apiMethod == 'saveSaleOrder') {
                    this.$message.success('保存成功')
                    this.Form.id = res.body.id
                    this.$router.push({
                        name: this.$route.name,
                        query: {
                            id: res.body.id
                        }
                    })
                    this.getDetail()
                } else {
                    this.$message.success('提交成功，即将返回列表页')
                    setTimeout(() => {
                        this.$router.push('/Sale/Order')
                    }, 1000);
                }
            }).catch(() => {
                this.loading = false
            })
        },
        getDetail() {
            this.$apis.getSaleOrder(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                    customerId: res.body.customer.id,
                    customerName: res.body.customer.name,
                    listingCollection: res.body.listingCollection.map(item => {
                        return {
                            ...item,
                            saleUnit: {
                                name: item.unitName
                            },
                            batchNumber: item.batchNo,
                            validDateEnd: item.expiryDate
                        }
                    })
                }
            }).catch(() => {

            })
        },
        openDialogVisible() {
            this.dialogVisible = true;
        },
        change(val) {
            this.dialogVisible = false
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>