<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          推荐促销
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="base-table">
        <div class="table-btns">
          <span class="link" style="float: right;" @click="handleCreate"
            >添加推荐</span>
        </div>
        <el-table :data="recommendList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
            <template v-if="item.prop === 'product.coverImage'" #default="scope">
              <img
                class="list-image m-6"
                :src="scope.row.product.coverImage"
                alt=""
                width="100"
              />
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="370">
            <template #default="scope">
              <template>
                <template>
                  <el-popconfirm
                    title="是否确认删除？"
                    @confirm="handleDelete(scope.row)"
                  >
                    <template #reference>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <ProductListDialog ref="productDialog" :parentProductList="recommendList" @addProduct="addProduct" />
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import {
  getRecommendList,
  deleteRecommendById,
  addRecommend,
  dragRecommend,
} from "@/api/Marketings";
import ProductListDialog from "./components/ProductListDialog";
export default {
  name: "recommendProductList",
  components: {
    ProductListDialog,
  },
  data() {
    return {
      loading: false,
      queryForm: {},
      recommendList: [],
      columns: [
        {
          label: "名称",
          prop: "product.name",
        },
        {
          label: "图片",
          prop: "product.coverImage",
          width: 120
        },
        {
          label: "展示价格",
          prop: "product.markPrice",
          formatter: (row, column, value) => {
            return "￥" + value;
          },
        },
      ],
    };
  },
  mounted() {
    this.getTopicPageListApi();
    this.rowDrop();
  },
  methods: {
    async getTopicPageListApi() {
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.queryForm));
      this.recommendList = await getRecommendList(params);
      this.loading = false;
    },

    // 删除
    async handleDelete(row) {
      await deleteRecommendById(row);
      this.$message.success("操作成功");
      this.getTopicPageListApi();
    },
    // 创建
    handleCreate() {
      // this.$refs.productDialog.getData()
      // this.$refs.productDialog.visible = true
      this.$refs.productDialog.getData();
    },
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      Sortable.create(tbody, {
        onEnd: async ({ newIndex, oldIndex }) => {
          const list = JSON.parse(JSON.stringify(this.recommendList));
          const currRow = list.splice(oldIndex, 1)[0];
          list.splice(newIndex, 0, currRow);
          const oldList = [];
          const newList = [];
          this.recommendList.map((item) => {
            oldList.push(item.recommendId);
          });
          list.map((item) => {
            newList.push(item.recommendId);
          });
          if (oldList.toString() === newList.toString()) {
            return;
          }
          await dragRecommend(newList);
          this.$set(this.recommendList, list);
          this.$message.success("排序成功");
        },
      });
    },
    async addProduct(item) {
      // this.$refs.productDialog.getData()
      await addRecommend({
        recommendType: 1,
        relationId: item.productId,
        relationUid: item.productUid,
        sort: this.recommendList.length + 1,
        remark: "",
      });
      this.getTopicPageListApi();
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
// .app-container {
//   /deep/ .el-table__body-wrapper {
//     cursor: move;
//   }
// }
</style>
