<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="80%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="名称:" prop="name">
            <el-input v-model="form.name" placeholder="请输入广告名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="显示权限:" prop="showType">
            <el-select v-model="form.showType">
              <el-option value="1" label="全部"></el-option>
              <el-option value="2" label="会员"></el-option>
              <el-option value="3" label="非会员"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="样式选择">
        <el-row>
          <el-radio-group v-model="form.typeConfig">
            <el-radio-button label="100">单图样式</el-radio-button>
            <el-radio-button label="101">单行二排</el-radio-button>
            <el-radio-button label="102">主副三排</el-radio-button>
            <el-radio-button label="103">副主三排</el-radio-button>
            <el-radio-button label="104">单行四排</el-radio-button>
            <el-radio-button label="105">单行五排</el-radio-button>
            <el-radio-button label="106">九宫格</el-radio-button>
          </el-radio-group>
        </el-row>
      </el-form-item>
      <el-form-item label="内容操作:">
        <imsButton text="添加图片" @click="handleCreate"/>
        <el-table
          :data="form.contentList"
          stripe
          border
          style="margin-top: 20px;"
          class="image__table"
        >
          <el-table-column label="排序" align="center">
            <template>
              <span class="svg-drag">
                <i icon-class="drag" />
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="resourcesUrl" label="图片" width="180">
            <template #default="scope">
              <Upload
                :file-list.sync="scope.row.fileList"
                upload-url="product"
              />
            </template>
          </el-table-column>
          <el-table-column label="跳转小程序页面">
            <template #default="scope">
              <el-select
                v-model="scope.row.linkRoute"
                @change="handleJumpTypeChange($event, scope.$index)"
              >
                <el-option
                  v-for="(route, idx) in wxRouteList"
                  :key="idx"
                  :value="route.url"
                  :label="route.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- <el-table-column label="跳转链接">
            <template #default="scope">
              <el-input disabled v-model="scope.row.linkUrl" />
            </template>
          </el-table-column> -->
          <el-table-column label="标题">
            <template #default="scope">
              <el-input v-model="scope.row.title" placeholder="请输入标题" />
            </template>
          </el-table-column>
          <el-table-column label="副标题">
            <template #default="scope">
              <el-input v-model="scope.row.content" placeholder="请输入标题" />
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete-solid"
                @click="handleDelete(scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
    <ProductListDialog ref="productDialog" @addProduct="addProduct" />
    <CommunityDialog ref="communityDialog" @addCommunity="addCommunity" />
    <ThemeDialog ref="themeDialog" @addTheme="addTheme" />
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs'
import jumpType from '@/mixins/jumpType'
import { getConfig, addConfig, updateConfig } from '@/api/Decorate'
import Upload from '@/components/Upload'
import ProductListDialog from './ProductListDialog'
import CommunityDialog from './CommunityDialog'
import ThemeDialog from './ThemeDialog'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    categoryInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Upload,
    ProductListDialog,
    CommunityDialog,
    ThemeDialog
  },
  mixins: [jumpType],
  data() {
    return {
      configId: null,
      visible: false,
      loading: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    // 拖拽
    document.body.ondrop = event => {
      event.preventDefault()
      event.stopPropagation()
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.form = {
        name: '',
        typeConfig: '100',
        type: '1',
        contentList: [],
        showType: '1'
      }
      this.resetForm('dialogForm')
    },
    // 获取编辑信息
    async getData(id) {
      this.reset()
      this.configId = id
      if (id) {
        const { contentList, name, showType, typeConfig } = await getConfig({
          id
        })
        this.form.contentList = contentList
        this.form.name = name
        this.form.showType = showType
        this.form.typeConfig = typeConfig
        this.form.contentList.forEach(item => {
          this.$set(item, 'fileList', [
            {
              url: item.resourcesUrl
            }
          ])
          if (item.linkUrl.indexOf('?') > -1) {
            this.$set(item, 'linkRoute', item.linkUrl.split('?')[0])
          } else {
            this.$set(item, 'linkRoute', item.linkUrl)
          }
        })
      }
      this.visible = true
      this.$nextTick(() => {
        this.rowDrop()
      })
    },
    rowDrop() {
      const tbody = document.querySelector(
        '.image__table .el-table__body-wrapper tbody'
      )
      Sortable.create(tbody, {
        onEnd: async ({ newIndex, oldIndex }) => {
          const list = JSON.parse(JSON.stringify(this.form.contentList))
          const currRow = list.splice(oldIndex, 1)[0]
          list.splice(newIndex, 0, currRow)
          list.forEach((item, index) => {
            item.sort = list.length - index
          })
          this.form.contentList = []
          this.$nextTick(() => {
            this.$set(this.form, 'contentList', list)
          })
        }
      })
    },
    // 删除
    handleDelete(index) {
      this.form.contentList.splice(index, 1)
    },
    // 创建
    handleCreate() {
      const params = {
        fileList: [],
        linkType: '2',
        linkUrl: '',
        linkRoute: '',
        title: '',
        content: ''
      }
      if (this.form.typeConfig === '100') {
        if (this.form.contentList.length === 1) {
          this.$message.warning('【单图样式】只能添加一张图片')
          return
        }
      }
      if (this.form.typeConfig === '101') {
        if (this.form.contentList.length === 2) {
          this.$message.warning('【单行两排样式】只能添加两张图片')
          return
        }
      }
      if (this.form.typeConfig === '102') {
        if (this.form.contentList.length === 3) {
          this.$message.warning('【主副三排样式】只能添加三张图片')
          return
        }
      }
      if (this.form.typeConfig === '103') {
        if (this.form.contentList.length === 3) {
          this.$message.warning('【副主三排样式】只能添加三张图片')
          return
        }
      }
      if (this.form.typeConfig === '104') {
        if (this.form.contentList.length === 4) {
          this.$message.warning('【单行四排样式】只能添加四张图片')
          return
        }
      }
      if (this.form.typeConfig === '105') {
        if (this.form.contentList.length === 5) {
          this.$message.warning('【单行五排样式】只能添加五张图片')
          return
        }
      }
      if (this.form.typeConfig === '106') {
        if (this.form.contentList.length === 9) {
          this.$message.warning('【九宫格样式】只能添加九张图片')
          return
        }
      }
      this.form.contentList.push(params)
      // this.$set(this.form, 'imageList', [params])
    },
    // 提交
    handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          if (this.form.typeConfig === '100') {
            if (this.form.contentList.length > 1) {
              this.$message.warning('【单图样式】只能添加一张图片')
              return
            }
          }
          const params = JSON.parse(JSON.stringify(this.form))
          params.contentList.forEach(item => {
            if (item.fileList[0]) {
              item.resourcesUrl = item.fileList[0].url
              delete item.fileList
            }
          })
          params.typeConfigNum = params.contentList.length
          this.loading = true
          try {
            if (this.configId) {
              params.configId = this.configId
              await updateConfig(params)
              this.$message.success('更新成功')
            } else {
              await addConfig(params)
              this.$message.success('添加成功')
            }
            this.loading = false
            this.$emit('updateList')
            this.close()
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.svg-drag > .i {
  width: 30px;
  height: 40px;
}
</style>
