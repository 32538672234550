<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title" style="display:inline-block">库存流水</div>
        <imsButton style="float: right;margin-top: 8px;" text="导出" :loading="loading" />
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-body">
        <div class="search-item" data-required="no">
          <div class="item-label bold">产品名称：</div>
          <div class="item-value">{{ Form.name }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">供应商：</div>
          <div class="item-value">{{ Form.number }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">生产批号：</div>
          <div class="item-value">{{ Form.no }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">产品属性：</div>
          <div class="item-value">{{ Form.attributeTypeName }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">规格：</div>
          <div class="item-value">{{ Form.printCount }}</div>
        </div>
        <!-- <div class="search-item">
          <div class="item-label bold">剂型：</div>
          <div class="item-value">{{ Form.approvalDate }}</div>
        </div> -->
        <div class="search-item">
          <div class="item-label bold">批准文号：</div>
          <div class="item-value">{{ Form.approvalDate }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">有效期：</div>
          <div class="item-value">{{ Form.createTime | dateformat() }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">进口通关编号：</div>
          <div class="item-value">{{ Form.createTime }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">批签发合格证明编号：</div>
          <div class="item-value">{{ Form.createTime }}</div>
        </div>
      </div>
      <el-table
        :data="Form.listingCollection"
        border
        style="width: 100%;margin-top: 5px;"
        height="calc(100% - 280px)"
        ref="list"
        destroy-on-close
      >
        <el-table-column label="日期" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="出入库类型" prop="x1" min-width="140" align="center"></el-table-column>
        <el-table-column label="来源/去向单位" prop="x3" minwidth="120" align="center"></el-table-column>
        <el-table-column label="出库数量" prop="x4" minwidth="80" align="center"></el-table-column>
        <el-table-column label="入库数量" prop="x5" minwidth="80" align="center"></el-table-column>
        <el-table-column label="库存数" prop="x6" minwidth="80" align="center"></el-table-column>
        <el-table-column label="计量单位" prop="x7" minwidth="120" align="center"></el-table-column>
        <el-table-column label="对方单位经手人" prop="x2" minwidth="120" align="center"></el-table-column>
        <el-table-column label="本单位经手人" prop="x8" minwidth="120" align="center"></el-table-column>
        <el-table-column label="备注" prop="remark" minwidth="140" align="center"></el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next,jumper"
        :current-page="pageQuery.pageIndex"
        :total="pageQuery.total"
        @current-change="val => pageQuery.pageIndex = val"
      ></el-pagination>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push('/Stock/RealTimeStock')" />
      </div>
    </div>
  </div>
</template>
        <script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect
  },
  data() {
    return {
      loading: false,
      Form: {
        id: null,
        name: null,
        no: "",
        createUserName: "",
        allListData1: [],
        allListData2: []
      },
      tableData: []
    };
  },
  methods: {
    // 查询产品列表
    getData() {
      this.loading = true;
      this.$apis
        .pagePurchasePlan({ ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records.map(item => {
            return {
              ...item,
              materialId: item.id,
              id: null
            };
          });
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetail() {
      this.$apis
        .getPurchasePlan(this.Form.id)
        .then(res => {
          this.Form = {
            ...this.Form,
            ...res.body
          };
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.Form.id = this.$route.query.id;
        this.getDetail();
      }
    });
  }
};
</script>