<template>
  <el-dialog
    title="Sku列表"
    :visible.sync="visible"
    width="70%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-table :data="shopProduct.skus" v-loading="loading">
      <el-table-column
        v-for="item in columns"
        :key="item.prop"
        :prop="item.prop"
        :formatter="item.formatter"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="scope">
          <el-input
            v-if="item.prop === 'salePrice'"
            type="number"
            maxlength="9"
            v-model="scope.row.salePrice"
            style="width: 100px;"
          ></el-input>
          <el-input
            v-else-if="item.prop === 'addStockCount'"
            type="number"
            maxlength="5"
            v-model="scope.row.addStockCount"
            style="width: 100px;"
          ></el-input>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { addShopProductStore } from '@/api/Shop'
export default {
  data() {
    return {
      visible: false,
      loading: false,
      shopProduct: {},
      columns: [
        {
          label: 'SKU',
          prop: 'sku',
          width: '180'
        },
        {
          label: '属性',
          prop: 'skuAttrValue'
        },
        {
          label: '展示价格',
          prop: 'markPrice'
        },
        {
          label: '销售价格',
          prop: 'salePrice'
        },
        {
          label: '现有库存',
          prop: 'stockCount'
        },
        {
          label: '新增/减少库存',
          prop: 'addStockCount'
        }
      ]
    }
  },
  methods: {
    close() {
      this.visible = false
    },
    setData(data) {
      this.visible = true
      this.loading = true
      data.skus.forEach(element => {
        element.addStockCount = 0
      })
      this.shopProduct = data
      this.loading = false
    },
    async handleSubmit() {
      try {
        this.loading = true
        console.log(this.shopProduct.shopProductId)
        let addStockList = this.shopProduct.skus

        addStockList.forEach(element => {
          element.stockCount = element.addStockCount
        })

        await addShopProductStore([
          {
            shopProductId: this.shopProduct.shopProductId,
            skus: addStockList
          }
        ])
        this.loading = false
        this.close()
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.map-link {
  line-height: 30px;
  color: #337ab7;
  margin-left: 30px;
  cursor: pointer;
}
</style>
