<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">采购退货出库</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">采购退货单号：</div>
            <div class="item-value">
              <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="no" label="采购退货单号" width="180" align="center">
          <template slot-scope="scope">
            <div class="link"
              @click="$router.push(`/Purchase/Reject/detailPage?status=${scope.row.status}&id=${scope.row.sourceId}`)">{{
                scope.row.no }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="supplier.name" label="供应商" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="creator.name" label="操作人" width="120" align="center"></el-table-column>
        <el-table-column label="操作时间" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.createTime | dateformat() }}</template>
        </el-table-column>
        <el-table-column label="状态" width="80" align="center">
          <template slot-scope="scope">{{ scope.row.status == 1 ? '待记账' : '已记账' }}</template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <div class="link" @click="$router.push(`/Stock/RejectOut/detail?&id=${scope.row.id}`)">
              {{ scope.row.status == 1 ? '记账' : '查看' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
      v-show="pageQuery.total > 0"
      :total="pageQuery.total"
      :page.sync="pageQuery.pageIndex"
      :limit.sync="pageQuery.pageSize"
      @pagination="getData()"
      />   
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
export default {
  components: {
    imsButton,
  },
  data() {
    return {
      loading: false,
      Query: {
        noLike: null,
      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      this.$apis
        .pageOrderRejectOut({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>