<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          优惠券
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">优惠券类型：</div>
          <div class="item-value">
            <el-select v-model="param.couponType" placeholder="请选择" clearable>
              <el-option label="满减券" value="1"></el-option>
            </el-select>
          </div>
          </div>
          <div class="search-item">
            <div class="item-label">优惠券名称：</div>
            <div class="item-value">
              <el-input placeholder="优惠券名称" v-model="param.name" clearable>
              </el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="onSelect"/>
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset('queryForm')" />
        </div>
            <!-- <imsButton typeClass="secondary" text="重置"  style="margin-right: 4px" @click="handleReset('queryForm')" /> -->
      </div>
      <div class="base-table">
        <div class="action">
          <span class="link" style="float: right; margin: 15px;" @click="handleCreate"
            >添加优惠券</span>
        </div>
        <el-table :data="couponList" v-loading="loading" border>
          <!-- <el-table-column type="index" label="序号" width="100" align="center" /> -->
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
            <template v-if="item.prop === 'receiveTotal'" #default="scope">
              <el-link
                icon="el-icon-view"
                :underline="false"
                @click="openReceiveList(scope.row)"
              >
                {{ scope.row.receiveTotal }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="260">
            <template #default="scope">
              <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="handleView(scope.row)"
                  >查看</el-button
                >
              </template>
              <template v-if="scope.row.enable">
                <el-popconfirm
                  v-if="scope.row.isOverdue"
                  title="是否确认删除？"
                  @confirm="handleDelete(scope.row)"
                >
                  <template #reference>
                    <el-button
                      v-if="scope.row.isOverdue"
                      style="margin: 0 10px;"
                      type="danger"
                      size="mini"
                      icon="el-icon-error"
                      >过期
                    </el-button>
                  </template>
                </el-popconfirm>
                <el-button
                  v-if="!scope.row.isOverdue"
                  type="success"
                  size="mini"
                  icon="el-icon-upload2"
                  @click="openDelivery(scope.row)"
                  >派送
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-error"
                  @click="handleStatus(scope.row)"
                  >停用
                </el-button>
              </template>
              <template v-else>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-error"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-success"
                  @click="handleStatus(scope.row)"
                  >启用</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
        <template>
          <Pagination
            v-show="page.total > 0"
            :total="page.total"
            :page.sync="param.PageIndex"
            :limit.sync="param.PageSize"
            @pagination="onSelect"
          />
        </template>
      </div>

      <ReceiveList
        ref="ReceiveList"
        :dialog-title="dialogTitle"
        :coupon-info="couponInfo"
      />

      <Delivery
        ref="Delivery"
        :dialog-title="dialogTitle"
        :coupon-info="couponInfo"
      />

      <Detail
        ref="Detail"
        :dialog-title="dialogTitle"
        @updateList="getCouponListApi"
      />
    </div>
  </div>
</template>

<script>
import {
  getCouponList,
  updateConponStatus,
  deleteConpon,
} from "@/api/Marketings/coupons";
import { formatDate } from "@/utils/day";
import imsButton from "@/components/imsButton.vue";
import ReceiveList from "./components/ReceiveList.vue";
import Delivery from "./components/Delivery.vue";
import Detail from "./components/Detail.vue";
export default {
  name: "couponList",
  components: {
    ReceiveList,
    Delivery,
    Detail,
    imsButton
  },
  data() {
    return {
      loading: false,
      couponList: [],
      param: {
        PageIndex: 1,
        PageSize: 10,
        name: null,
        couponType: null,
      },
      page: {
        total: 0,
      },
      couponInfo: {},
      dialogTitle: "添加优惠券",
      columns: [
        {
          label: "优惠券名称",
          prop: "name",
        },
        {
          label: "优惠券类型",
          prop: "couponTypeName",
        },
        {
          label: "面额",
          prop: "denomination",
          formatter: (row, column, value) => {
            return "￥" + value;
          },
        },
        {
          label: "优惠券系统标识ID",
          prop: "identification",
        },
        {
          label: "兑换码",
          prop: "exchangeCode",
          formatter: (row, column, value) => {
            var text = "-";
            if (!row.isExchangeCode) {
              text = "无";
            } else {
              text = value;
            }
            return text;
          },
        },
        {
          label: "使用有效期",
          prop: "validTypeName",
          formatter: (row, column, value) => {
            var text = "-";
            if (row.validType === 1) {
              text =
                formatDate(row.validStartTime, "yyyy-MM-dd") +
                " ~ " +
                formatDate(row.validEndTime, "yyyy-MM-dd");
            }
            if (row.validType === 2) {
              text = value;
            }
            return text;
          },
        },
        {
          label: "领取数",
          prop: "receiveTotal",
        },
        {
          label: "已使用数",
          prop: "useTotal",
        },
      ],
    };
  },
  mounted() {
    this.getCouponListApi();
    this.$refs.Delivery.getMemberListApi();
    this.$refs.Detail.getMemberListApi();
  },
  methods: {
    async getCouponListApi() {
      this.loading = true;
      let data = await getCouponList(this.param);
      this.couponList = data.records;
      this.page.total = data.recordCount;
      this.loading = false;
    },
    onSelect() {
      if (this.param.couponType == "") {
        this.param.couponType = null;
      }
      this.getCouponListApi();
    },
    handleView(row) {
      this.dialogTitle = "查看优惠券";
      if (!row.isExchangeCode) {
        row.exchangeCode = "";
      }
      this.couponInfo = row;
      this.couponInfo.optionType = "view";
      this.$refs.Detail.setConfigInfo(this.couponInfo);
      this.$refs.Detail.visible = true;
    },
    handleCreate() {
      this.dialogTitle = "添加优惠券";
      this.couponInfo = {
        optionType: "add",
        validType: 1,
        validTime: [],
        membershipLevelId: [],
      };
      this.$refs.Detail.setConfigInfo(this.couponInfo);
      this.$refs.Detail.visible = true;
    },
    // 停/启用
    async handleStatus(row) {
      const data = {
        couponId: row.couponId,
      };
      await updateConponStatus(data);
      this.$message.success("操作成功");
      this.onSelect();
    },
    // 删除
    async handleDelete(row) {
      const data = {
        couponId: row.couponId,
      };
      await deleteConpon(data);
      this.$message.success("操作成功");
      this.onSelect();
    },
    // 打开领取列表
    openReceiveList(row) {
      this.dialogTitle = "【" + row.name + "】领取列表";
      this.couponInfo = row;
      this.$refs.ReceiveList.visible = true;
      this.$refs.ReceiveList.getReceiveList(this.couponInfo);
    },
    // 打开派送
    openDelivery(row) {
      this.dialogTitle = "派送【" + row.name + "】";
      this.couponInfo = row;
      this.$refs.Delivery.visible = true;
    },
      handleReset() {
        let data = {}
      for(let key in this.param){
          if(key!= 'PageIndex' && key != 'PageSize'){
            data[key] = this.param[key]
          }
      }
      this.initListData(data)
      this.param = Object.assign(this.param,data)
      this.$nextTick(() => {
      this.getCouponListApi();
            })
        }
  },
};
</script>

<style lang="less" scoped></style>
