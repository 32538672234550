<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="50%"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="100px">
      <el-form-item v-if="parent.attrId != undefined" label="属性:">
        <el-input v-model="parent.name" disabled />
      </el-form-item>
      <el-form-item label="属性值:" prop="name">
        <el-input v-model="form.name" placeholder="请输入属性值" />
      </el-form-item>
      <el-form-item label="备注:" prop="description">
        <el-input
          v-model="form.description"
          :rows="2"
          type="textarea"
          placeholder="说点什么..."
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { addAttrValue, updateAttrValue } from "@/api/Attr";
export default {
  name: "AttrValueDialog",
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    categoryInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      isValue: false, // 是否是编辑属性值
      type: "", // 编辑还是创建
      parent: {},
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入属性值",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.reset();
      this.visible = false;
    },
    // 重置表单
    reset() {
      this.resetForm("dialogForm");
      this.form = {};
      this.parent = {};
    },
    showDialog(attr, row) {
      this.parent = attr;
      if (row == null || row == undefined) {
        this.type = "add";
      } else {
        this.type = "edit";
        this.form = {
          valueId: row.valueId,
          attrId: this.parent.attrId,
          name: row.name,
          description: row.description,
        };
      }
      this.visible = true;
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            this.loading = false;
            const params = JSON.parse(JSON.stringify(this.form));
            params.attrId = this.parent.attrId;
            this.loading = true;
            if (this.type === "add") {
              await addAttrValue(params);
            } else {
              await updateAttrValue(params);
            }

            this.close();
            this.$emit("updateList");
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
