<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          营销文章
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="base-table">
        <div class="table-btns">
          <span class="link" style="float: right;" @click="handleCreate"
            >添加文章</span>
        </div>
        <el-table :data="topicList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
          >
          </el-table-column>

          <el-table-column label="操作" align="center" width="370">
            <template #default="scope">
              <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="handleView(scope.row)"
                  >查看</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  class="m-l-r-10"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <template>
                  <el-popconfirm
                    title="是否确认删除？"
                    @confirm="handleDelete(scope.row)"
                  >
                    <template #reference>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getTopicPageListApi"
        />
      </div>
      <Detail
        ref="Detail"
        :dialog-title="dialogTitle"
        :option-type="optionType"
      />
    </div>
  </div>
</template>

<script>
import { getTopicPageList, deleteTopic } from "@/api/Marketings/communitys";
import Detail from "./components/Detail.vue";
export default {
  name: "communityList",
  components: {
    Detail,
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      topicList: [],
      dialogTitle: "文章详情",
      optionType: "view",
      columns: [
        {
          label: "标题",
          prop: "title",
        },
        {
          label: "发布时间",
          prop: "releaseDate",
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: "YYYY-MM-DD HH:mm:ss",
            });
          },
        },
      ],
    };
  },
  mounted() {
    this.getTopicPageListApi();
  },
  methods: {
    async getTopicPageListApi() {
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.queryForm));
      const { records, recordCount } = await getTopicPageList(params);
      this.topicList = records;
      this.total = recordCount;
      this.loading = false;
    },

    // 删除
    async handleDelete(row) {
      await deleteTopic(row);
      this.$message.success("操作成功");
      this.getTopicPageListApi();
    },
    // 创建
    handleCreate() {
      this.$refs.Detail.reset();
      this.optionType = "add";
      this.dialogTitle = "添加文章";
      this.$refs.Detail.getData();
      this.$refs.Detail.visible = true;
    },
    // 修改
    handleEdit(row) {
      this.$refs.Detail.reset();
      this.optionType = "edit";
      this.dialogTitle = "编辑文章";
      this.$refs.Detail.getData(row);
      this.$refs.Detail.visible = true;
    },
    // 查看
    handleView(row) {
      this.$refs.Detail.reset();
      this.optionType = "view";
      this.dialogTitle = "查看文章";
      this.$refs.Detail.getData(row);
      this.$refs.Detail.visible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
