<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="70%"
    @close="close"
  >
    <el-form
      ref="dialogForm"
      :model="topicForm"
      :rules="rules"
      label-width="100px"
      style="margin-right:20px"
    >
      <el-form-item>
        <el-col :span="24">
          <el-form-item label="标题:" prop="title">
            <el-input
              v-model="topicForm.title"
              placeholder="请输入标题"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="24">
          <el-form-item label="内容:" prop="content"  v-if="visible">
            <tinymce v-model="topicForm.content" ref="content" :height="300" />
          </el-form-item>
        </el-col>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" v-show="optionType != 'view'">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { addTopic, updateTopic } from "@/api/Marketings/communitys";
import Tinymce from "@/components/Tinymce";
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    optionType: {
      type: String,
      default: "view",
    },
  },
  components: {
    Tinymce,
  },
  data() {
    return {
      visible: false,
      loading: false,
      topicForm: {
        fileList: [],
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.reset();
    },
    // 重置表单
    reset() {
      this.topicForm = {
        fileList: [],
        topicId: undefined,
        title: "",
        content: "",
      };
      this.resetForm("dialogForm");
    },
    getData(topicInfo) {
      this.reset();
      if (topicInfo) {
        this.topicForm = {
          fileList: [],
          topicId: topicInfo.topicId,
          title: topicInfo.title,
          content: topicInfo.content,
        };
        if (this.$refs.content != undefined) {
          this.$refs.content.setContent(topicInfo.content);
        }
      }

      this.visible = true;
    },
    async handleSubmit() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            true;
            this.loading = true;
            const params = JSON.parse(JSON.stringify(this.topicForm));
            if (this.optionType == "edit") {
              await updateTopic(params);
            }
            if (this.optionType == "add") {
              await addTopic(params);
            }
            this.$parent.getTopicPageListApi();
            this.loading = false;
            this.close();
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.attr__name {
  font-size: 15px;
  font-weight: bold;
}
</style>
