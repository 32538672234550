<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          客户列表
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">用户名：</div>
          <div class="item-value">
            <el-input
              v-model="queryForm.name"
              placeholder="请输入用户名"
            ></el-input>
          </div>
          </div>
          <div class="search-item">
            <div class="item-label">手机号码：</div>
            <div class="item-value">
              <el-input
                v-model="queryForm.mobile"
                placeholder="请输入手机号码"
              ></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">注册时间：</div>
            <div class="item-value">
              <el-date-picker
                v-model="queryForm.createDate"
                size="small"
                style="width: 340px;"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
          </div>    
        </div>
        <div class="page-search-right">
          <imsButton style="float: right; margin-top: 4px;" text="查询" :loading="loading" @click="handleQuery" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
            <!-- <imsButton typeClass="secondary" text="重置"  style="float: right;" @click="handleReset()" /> -->
        </div>
      <div class="base-table">
        <el-table :data="userList" v-loading="loading" border>
          <!-- <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column> -->
          <el-table-column type="expand" show-overflow-tooltip>
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="用户名:">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="手机号码:">
                  <span>{{ props.row.mobile }}</span>
                </el-form-item>
                <el-form-item label="性别:">
                  <span>{{ props.row.sex == 1 ? "男" : props.row.sex == 2 ? "女" : "" }}</span>
                </el-form-item>
                <el-form-item label="真实姓名:">
                  <span>{{ props.row.realName }}</span>
                </el-form-item>
                <el-form-item label="出生年月:">
                  <span v-if="props.row.birthday">{{
                      formatDay(props.row.birthday, {
                        format: 'YYYY-MM-DD'
                      })
                    }}</span>
                </el-form-item>
                <el-form-item label="所在省市:">
                  <span>{{ props.row.province }} {{ props.row.city }}</span>
                </el-form-item>
                <el-form-item label="所属店铺:">
                  <span>{{ getShopName(props.row.shopId) }}</span>
                </el-form-item>
                <el-form-item label="会员卡号:">
                  <span>{{ props.row.memberCardId }}</span>
                </el-form-item>
                <el-form-item label="注册时间:">
                  <span>{{
                    formatDay(props.row.createDate, {
                      format: 'YYYY-MM-DD HH:mm:ss'
                    })
                  }}</span>
                </el-form-item>
                <el-form-item label="最后登录时间:">
                  <span>{{
                    formatDay(props.row.lastLoginDate, {
                      format: 'YYYY-MM-DD HH:mm:ss'
                    })
                  }}</span>
                </el-form-item>
                <el-form-item label="会员等级名称:">
                  <span>{{ props.row.membershipLevel.name }}</span>
                </el-form-item>
                <el-form-item label="会员到期时间:">
                  <span>{{
                    formatDay(props.row.membershipLevel.deadlineDate, {
                      format: 'YYYY-MM-DD HH:mm:ss'
                    })
                  }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
          >
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template #default="scope">
              <!-- <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleShow(scope.row.userId)"
                  >查看</el-button
                >
              </template> -->
              <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleRecharge(scope.row)"
                  >充值</el-button
                >
                <el-button
                    type="success"
                    size="mini"
                    icon="el-icon-edit-outline"
                    @click="handleEditPage(scope.row)"
                >编辑
                </el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getUserListApi"
        />
      </div>
      <RechargeOperateDialog ref="RechargeOperateDialog" />
    </div>
    <el-dialog
        title="编辑客户信息"
        :visible.sync="dialogVisible"
        width="60%">
      <el-form
          ref="dialogForm"
          :model="userForm"
          label-width="100px"
          :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名:" prop="name">
              <el-input
                  type="text"
                  v-model="userForm.name"
                  placeholder="请输入用户名"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号:" prop="mobile">
              <el-input
                  type="text"
                  v-model="userForm.mobile"
                  placeholder="请输入手机号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别:">
              <el-radio-group v-model="userForm.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名:">
              <el-input
                  type="text"
                  v-model="userForm.realName"
                  placeholder="请输入真实姓名"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="出生年月:">
              <el-date-picker
                  v-model="userForm.birthday"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在省市:">
              <el-cascader
                  size="large"
                  :options="options"
                  v-model="nowRegion"
                  @change="handleRegionChange">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属店铺:">
              <el-select v-model="userForm.shopId" placeholder="选择店铺">
                <el-option
                    v-for="item in shopsList"
                    :label="item.name"
                    :value="item.shopId"
                    :key="item.prop"
                    :prop="item.prop"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员卡号:">
              <el-input
                  type="text"
                  v-model="userForm.memberCardId"
                  placeholder="请输入会员卡号"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="handleEdit()">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {getUsersDetail, getUsersList, userEditor} from '@/api/User'
import RechargeOperateDialog from './components/RechargeOperateDialog.vue'
import {codeToText, provinceAndCityData} from 'element-china-area-data'
import {getShopList} from "@/api/Shop";


export default {
  name: 'userList',
  components: {
    RechargeOperateDialog
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      options: provinceAndCityData,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        createDate: [],
        name: '',
        mobile: ''
      },
      shopsList: [],
      userForm: {},
      nowRegion: [],//当前选择用户地区，
      total: 0,
      userList: [],
      categoryInfo: {},
      dialogTitle: '添加分类',
      columns: [
        {
          label: '用户名',
          prop: 'name'
        },
        {
          label: '手机号码',
          prop: 'mobile'
        },
        {
          label: '账户余额',
          prop: 'accountBalance'
        },
        {
          label: '注册时间',
          prop: 'createDate',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD'
            })
          }
        }
      ],
      rules: {
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {required: true, pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getshopsList()
    this.getUserListApi()
  },
  methods: {
    async getUserListApi() {
      this.loading = true
      this.queryForm.createDateStart = this.queryForm.createDate[0]
      this.queryForm.createDateEnd = this.queryForm.createDate[1]
      const params = JSON.parse(JSON.stringify(this.queryForm))
      delete params.createDate
      const { records, recordCount } = await getUsersList(params)
      this.userList = records
      this.total = recordCount
      this.loading = false
    },
    //获取店铺信息
    async getshopsList() {
      const {records} = await getShopList({
        ...this.queryForm
      })
      this.shopsList = records;
    },
    // 充值
    handleRecharge(row) {
      this.$refs.RechargeOperateDialog.reset()
      this.$refs.RechargeOperateDialog.getData(row)
      this.$refs.RechargeOperateDialog.visible = true
    },
    //唤醒编辑页面弹窗
    async handleEditPage(row) {
      this.nowRegion = [];
      this.dialogVisible = true;
      this.userForm = await getUsersDetail({
        userId: row.userId
      })
      // 将查询到的城市编码赋值给地区选择器中
      let arr = [];
      arr.push(this.userForm.provinceId + "");
      arr.push(this.userForm.cityId + "")
      this.nowRegion = arr;
    },
    //编辑
    handleEdit() {
      this.$refs['dialogForm'].validate((valid) => {
        if (valid) {
          const res = userEditor(this.userForm);
          if (res) {
            this.$message.success('用户信息编辑成功')
            this.dialogVisible = false;
            this.userForm = {};
            this.getUserListApi();
          } else {
            this.$message.error('用户信息编辑失败')
          }
        } else {
          this.$message.error('表单信息验证失败')
        }
      });

    },
    //地区选择器Change
    handleRegionChange(value) {
      // 将选择的城市编码赋值给userForm中
      this.userForm.provinceId = this.nowRegion[0];
      this.userForm.cityId = this.nowRegion[1];
      const codeText = this.getCodeToText(value);
      this.userForm.province = codeText.substring(0, codeText.indexOf('/'));
      this.userForm.city = codeText.substring(codeText.indexOf('/') + 1);
    },
    handleCreate() {
      this.dialogTitle = '添加分类'
      this.$refs.OperateDialog.visible = true
      this.$refs.OperateDialog.reset()
    },
    handleQuery() {
      this.queryForm.pageIndex = 1
      this.getUserListApi()
    },
    handleReset() {
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
      this.getUserListApi()
    },
    async handleShow(userId) {
      await getUsersDetail({
        userId
      })
    },
    //根据shopId获取店铺名字
    getShopName(shopId) {
      for (let i = 0; i < this.shopsList.length; i++) {
        if (this.shopsList[i].shopId == shopId) {
          return this.shopsList[i].name
        }
      }
      return "暂无店铺";
    },
    //区域码转成汉字
    getCodeToText(codeArray) {
      let area = "";
      switch (codeArray.length) {
        case 1:
          area += codeToText[codeArray[0]];
          break;
        case 2:
          area += codeToText[codeArray[0]] + "/" + codeToText[codeArray[1]];
          break;
        case 3:
          area +=
              codeToText[codeArray[0]] +
              "/" +
              codeToText[codeArray[1]] +
              "/" +
              codeToText[codeArray[2]];
          break;
        default:
          break;
      }
      return area;
    }
  }
}
</script>

<style lang="less" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
