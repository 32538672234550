import request_artisan from '@/utils/request_artisan'

// 交易订单列表
export function getTransactionList(data) {
  return request_artisan({
    url: '/v1/Orders/Page',
    method: 'get',
    params: data
  })
}

// 交易订单列表每一项详情
export function getTransactionDetail(data) {
  return request_artisan({
    url: '/v1/orders/Deatil/' + data.orderUid,
    method: 'get'
  })
}

// 待发货列表
export function getDeliveredList(data) {
  return request_artisan({
    url: '/v1/Orders/PageGroup',
    method: 'get',
    params: data
  })
}

// 发货
export function confirmDeliveryByOrderUid(data) {
  return request_artisan({
    url: '/v1/orders/ConfirmDeliveryPost/' + data.orderUid,
    method: 'post'
  })
}

// 收货
export function signForByOrderUid(data) {
  return request_artisan({
    url: '/v1/orders/SignFor/' + data.orderUid,
    method: 'get'
  })
}

// 退款
export function refundByOrderUid(data) {
  return request_artisan({
    url: '/v1/orders/Refund/' + data.orderUid,
    method: 'post',
    data: data
  })
}

// 修改打印次数
export function changePrintNum(data) {
  return request_artisan({
    url: '/v1/orders/Print/' + data.orderUid,
    method: 'put'
  })
}
