<template>
  <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
    <div class="dialog-header">选择产品</div>
    <div class="dialog-main" style="padding-right: 20px;">
      <div class="search-item">
        <div class="item-label">产品名称：</div>
        <div class="item-value">
          <el-input v-model="Query.nameLike" clearable maxlength="32"></el-input>
        </div>
      </div>
      <div class="search-item" v-show="type == 2">
        <div class="item-label">生产批号：</div>
        <div class="item-value">
          <el-input v-model="Query.batchNoLike" clearable maxlength="32"></el-input>
        </div>
      </div>
      <div class="search-item">
        <div class="item-label">供应商：</div>
        <div class="item-value">
          <el-select v-model="Query.supplierId" placeholder="请选择" clearable>
            <el-option v-for="item in supplierOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      
      <imsButton typeClass="primary" text="查询" :loading="loading" style="float: right;margin-right: 0;margin: 4px;"
        @click="getData(1)" />
        <imsButton typeClass="secondary" text="重置"  :loading="loading" style="float: right;margin-right: 0;margin: 4px;"
        @click="handleReset()" />
      <div class="vaccine-box">
        <el-table :data="vaccineList" border style="width: 100%;margin-top: 15px;"
          @selection-change="val => multipleSelection = val" ref="multipleTable" :row-key="getRowKey">
          <el-table-column prop="name" label="产品名称" min-width="160" align="center"></el-table-column>
          <el-table-column v-if="type == 3" prop="salesOrderListingId" label="销售订单id" width="120" align="center"></el-table-column>
          <el-table-column prop="supplier.name" label="供应商" min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
          <el-table-column prop="batchNo" label="生产批号" width="120" align="center"
            v-if="type == '2' ? true : false"></el-table-column>
          <el-table-column prop="attributeTypeName" label="产品属性" width="120" align="center"></el-table-column>
          <el-table-column prop="drugApprovalNo" label="批准文号" width="100" align="center"></el-table-column>
          <el-table-column prop="unit.name" v-if="type != '2' ? true : false" label="单位" align="center"></el-table-column>
          <el-table-column prop="expiryDate" label="有效日期" width="120" align="center" v-if="type == '2' ? true : false">
            <template slot-scope="scope">
              {{ scope.row.expiryDate | dateformat() }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="单价" width="80" align="center"
            v-if="type == '2' ? true : false"></el-table-column>
          <el-table-column prop="warehouseLocation.name" label="存储位置" width="120" align="center"
            v-if="type == '2' ? true : false"></el-table-column>

          <el-table-column fixed="right" prop="quantity" width="90" align="center">
            <template slot="header">
              {{ type == 3 ? '销售数量' : '当前库存' }}
            </template>
            <template slot-scope="scope">
              {{ scope.row.quantity ? scope.row.quantity : scope.row.storageQuantity }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="availableQuantity" label="可用库存数量" width="120" align="center">
            <template slot-scope="scope">
              {{ scope.row.availableQuantity ? scope.row.availableQuantity : scope.row.availableStorageQuantity }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" type="selection" width="50" align="center"
            :reserve-selection="true"></el-table-column>
        </el-table>
      </div>
      <el-pagination  layout="total, prev, pager, next,jumper"
        :current-page="pageQuery.pageIndex" :total="pageQuery.total" :page-size="100"
        @current-change="val => pageQuery.pageIndex = val"></el-pagination>
      <div class="tag-box">
        <span class="tag" v-for="(item, index) in multipleSelection" :key="index">
          {{ item.name + '(' + item.supplier.name + ',' + item.specification + ')'
          }}
          <i class="far fa-times-circle" style="padding-left: 40px;color: red;" @click="delTag(index, item)"></i>
        </span>
      </div>
    </div>
    <div class="dialog-footer">
      <imsButton typeClass="secondary" text="取消" @click="change" style="margin-right:10px" />
      <imsButton text="确定" @click="updateData()" />
    </div>
  </el-dialog>
</template>
<script>
import imsButton from "@/components/imsButton.vue";

export default {
  components: {
    imsButton,
  },
  props: ["dialogVisible", "Form", "loading", "type", "other", "stockKeepingUnit", "query"],
  data() {
    return {
      supplierOptions: [],
      Query: {
        nameLike: null,
        batchNoLike: null,
        supplierId: null,
      },
      vaccineList: [],
      multipleSelection: [],
    };
  },
  watch: {
    dialogVisible(val) {
      if(this.other == 1)return
      if (val) {
        // 回填选择框
        var keyId = this.$props.stockKeepingUnit ? "stockKeepingUnit" : this.type == 3 ? 'salesOrderListingId' : 'productId'
        var ids = this.Form.listingCollection.map((item) => item[keyId]);
        this.multipleSelection = this.multipleSelection.filter(item => ids.indexOf(item[keyId]) > -1)
        this.vaccineList.forEach((item) => {
          if (ids.indexOf(item[keyId]) > -1) {
            setTimeout(() => {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }, 100); // 渲染bug，延迟切换
          } else {
            setTimeout(() => {
              this.$refs.multipleTable.toggleRowSelection(item, false);
            }, 100);
          }
        });
      } else {
        this.$emit('change', false)
      }
    },
  },
  methods: {
    // 获取产品列表数据
    getData(item) {
      // type：1为产品列表，2为库存列表，3为可销售退货的产品列表
      var apiMethod = this.type == 1 ? "pageVaccine" : this.type == 2 ? "pageStorage" : "pageSaleGoods"
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1,
        }
      this.$apis
      [apiMethod]({ ...this.Query, ...this.pageQuery, pageSize: 100, ...this.$props.query })
        .then((res) => {
          this.vaccineList = res.body.records.map(item => {
            return {
              ...item,
              stockStorageQuantity: item.stockStorageQuantity ? item.stockStorageQuantity : item.quantity,
              productId: item.productId ? item.productId : item.id,
              id: item.id ? item.id : null,
              salesOrderListingId: item.id,
              supplierId: item.supplier && item.supplier.id ? item.supplier.id : item.supplierId
            }
          })
          this.pageQuery.total = Number(res.body.recordCount);
        }).catch(()=>{})
    },
    // 点确定更新数据
    updateData() {
      var productIds = this.Form.listingCollection.map(
        (item) => item.productId
      );
      this.multipleSelection.forEach((item) => {
        // 没有的id，加进去
        if (this.other) {
         // 其他入库-重复添加
         this.Form.listingCollection.push({
            ...item,
            productId: item.productId ? item.productId : item.id,
            id: null,
          });
        } else {
          if (productIds.indexOf(item.productId) < 0) {
              this.Form.listingCollection.push({
              ...item,
              productId: item.productId ? item.productId : item.id,
              id: null,
            });
          }
        }
      });
      var ids = this.multipleSelection.map((item) => item.productId);
      // 未选择的即已删除，则过滤掉
      this.Form.listingCollection =
        this.Form.listingCollection.filter(
          (item) => ids.indexOf(item.productId) > -1
        );
      this.$emit('change', false)
      // 其他入库-清除回填
      if(this.other){
        this.multipleSelection.forEach(row => {
              this.$refs.multipleTable.toggleRowSelection(row);
            });
        this.multipleSelection = []
      }
    },
    // 移除tag
    delTag(index, item) {
      this.multipleSelection.splice(index, 1);
      this.$refs.multipleTable.toggleRowSelection(item, false);
    },
    change() {
      this.$emit('change', false)
    },
    // shiqi.he 解决报row.id错误
    getRowKey(row) {
      return row.id;
    },
    handleReset(){
      let data = {}
      for(let key in this.Query){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.Query[key]
          }
      }
      this.initListData(data)
      this.Query = Object.assign(this.Query,data)
      this.$nextTick(()=>{
        this.getData();
      })
    }
  },
  mounted() {
    this.getSupplierOptions();
    this.getData();
  },
}
</script>

<style lang="less" scoped>
.search-item {
  width: calc(33.33% - 40px);
}

.tag-box {
  height: 120px;
  margin-left: 20px;
  margin-top: 40px;

  .tag {
    display: inline-block;
    min-width: 287px;
    height: 23px;
    line-height: 23px;
    font-size: 14px;
    border-radius: 5px;
    color: @basicColor;
    background: #eaf6ff;
    border: 1px solid @basicColor;
    padding: 5px 20px 5px 20px;
    margin: 10px 40px;

    >i {
      cursor: pointer;
    }
  }
}
</style>