<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">供应商管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">供应商名称：</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="addData()">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="供应商名称" min-width="80" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="abbreviation" label="简称" min-width="80" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unifiedSocialCode" label="统一社会信用代码" min-width="60" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="legal" label="法定代表人" min-width="60" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="editData(scope.row)" type="text" class="edit">编辑</el-button>
            <el-button type="text" @click="deleData(scope.row)" style="color:rgba(217, 0, 17, 1)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <el-dialog :visible.sync="dialogVisible" width="80%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.id ? '编辑' : '新增' }}供应商</div>
      <div class="dialog-main">
        <div class="search-item">
          <div class="item-label w-130">
            <span class="star">*</span>供应商名称：
          </div>
          <div class="item-value w--138">
            <el-input v-model="Form.name" clearable maxlength="30" ref="name"></el-input>
          </div>
        </div>
        <div class="search-item" >
          <div class="item-label w-130">简称：</div>
          <div class="item-value w--138">
            <el-input v-model="Form.abbreviation" clearable maxlength="100"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label w-130">统一社会信用代码：</div>
          <div class="item-value w--138">
            <el-input v-model="Form.unifiedSocialCode" clearable maxlength="10" ref="roleText"></el-input>
          </div>
        </div>
        <div class="search-item" >
          <div class="item-label w-130">法定代表人：</div>
          <div class="item-value w--138">
            <el-input v-model="Form.legal" clearable maxlength="11" ref="roleText"></el-input>
          </div>
        </div>
        <div class="table-btns">
          <span class="link" style="float: right;" @click="addContact()">新增</span>
        </div>
        <div class="contact-box" v-for="(item, index) in contactList" :key="index">
          <span class="contact">联系人{{ index + 1 }}</span>
          <div class="close" v-show="index + 1 > 1" @click="delContact(index)">x</div>
          <div class="search-item">
            <div class="item-label">
              联系人：
            </div>
            <div class="item-value">
              <el-input v-model="item.name" clearable maxlength="30" ref="name"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">
              移动电话：
            </div>
            <div class="item-value">
              <el-input v-model="item.mobile" clearable maxlength="11" ref="mobile"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">
              固定电话：
            </div>
            <div class="item-value">
              <el-input v-model="item.telephone" clearable maxlength="30" ref="telephone"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">地址：</div>
            <div class="item-value">
              <el-input v-model="item.address" clearable maxlength="30"></el-input>
            </div>
          </div>
          <div class="search-item" style="width: 66.66%;">
            <div class="item-label">备注：</div>
            <div class="item-value">
              <el-input v-model="item.remark" clearable maxlength="200" type="textarea" :rows="3" placeholder="限200字"
                show-word-limit ref="roleText"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="保存" @click="saveForm" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
export default {
  components: {
    imsButton,
  },
  mixins: [mixins],
  data() {
    return {
      options: [],
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      loading: false,
      dialogVisible: false,
      Query: {
        nameLike: ""
      },
      Form: {
        name: "",
        abbreviation: "",
        unifiedSocialCode: "",
        legal: ""
      },
      contactList: [
        {
          contactName: "",
          phone: "",
          wetchat: "",
          qq: "",
          remark: ""
        }
      ]
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageSupplier({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editData(item) {
      this.contactList = item.contactList;
      this.Form = {
        ...item
      };
      this.dialogVisible = true;
    },
    addData() {
      this.contactList = [
        {
          contactName: "",
          phone: "",
          wetchat: "",
          qq: "",
          remark: ""
        }
      ];
      this.Form = {
        name: "",
        abbreviation: "",
        unifiedSocialCode: "",
        legal: ""
      };
      this.dialogVisible = true;
    },
    addContact() {
      let obj = {};
      this.contactList.push(obj);
    },
    delContact(i) {
      if (i != 0) {
        this.contactList.splice(i, 1);
      }
    },
    deleData(item) {
      item = {
        ...item,
        id: Number(item.id)
      }
      this.$confirm("您确定要删除" + item.name + "吗?", "删除提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning"
      }).then(() => {
        this.$apis.delSupplier(item.id).then(res => {
          if (res.body) {
            this.$message.success("删除成功！");
          }
        });
        this.getData();
      }).catch(()=>{})
    },
    saveForm() {
      if (this.typeList.includes(this.Form.name)) {
        this.$message.warning("请输入供应商名称！");
        this.$refs.name.focus();
        return;
      } else if (this.contactList.length > 0) {
        for (var i in this.contactList) {
          if (this.contactList[i].mobile && !this.regPhone.test(this.contactList[i].mobile)) {
            this.$message.warning("'移动电话'格式错误，示例：1380013800！");
            this.$refs.mobile.focus();
            return;
          }
          if (this.contactList[i].telephone && !this.regOfficePhone.test(this.contactList[i].telephone)) {
            this.$message.warning("'固定电话'格式错误，示例：020-12345678！");
            this.$refs.telephone.focus();
            return;
          }
        }
      }
      this.loading = true;
      this.Form = {
        ...this.Form,
        contactList: this.contactList
      };
      if (this.Form.id) {
        // 编辑
        this.$apis
          .editSupplier(this.Form, this.Form.id)
          .then(res => {
            if (res.body) {
              this.$message.success("编辑成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$apis
          .addSupplier(this.Form)
          .then(res => {
            if (res.body) {
              this.$message.success("添加成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="less" scoped>
  // css width
  .w-130{
    width: 130px !important;
  }
  .w--138{
    width: calc(100% - 138px) !important;
  }

.contact-box {
  position: relative;
  // height: 160px;
  padding-top: 20px;
  padding-right: 40px;
  margin-top: 25px;
  margin-left: 40px;
  border: 1px @basicColor solid;

  .contact {
    position: absolute;
    top: -10px;
    left: 10px;
    width: 80px;
    text-align: center;
    align-content: center;
    background-color: #fff;
  }

  .close {
    position: absolute;
    top: -8px;
    right: -6px;
    width: 15px;
    height: 15px;
    line-height: 14px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    background-color: rgba(217, 0, 17, 1);
  }
  .search-item:last-child{
  height: 60px;
  margin-bottom: 50px;
  }
}
</style>