import { render, staticRenderFns } from "./editPage.vue?vue&type=template&id=510bc770&scoped=true"
import script from "./editPage.vue?vue&type=script&lang=js"
export * from "./editPage.vue?vue&type=script&lang=js"
import style0 from "./editPage.vue?vue&type=style&index=0&id=510bc770&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510bc770",
  null
  
)

export default component.exports