<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="70%"
    :close-on-click-modal="false"
    @close="close"
  >
    <!-- <div id="printMeForDetail">
      <Print :order-info="orderInfo" />
    </div> -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span @click="handlePrint">订单状态</span>
        <!-- v-print="printObj" -->
        <el-button
          v-if="orderInfo.orderStatus == 2"
          @click="handlePrint(orderInfo.orderUid)"
          type="primary"
          style="float: right"
          >打印小票</el-button
        >
      </div>
      <div>
        <p>
          当前状态:
          {{ orderInfo.orderStatus | filterOrderStatus }}
          <el-button
            type="primary"
            v-if="orderInfo.orderStatus == 13 || orderInfo.orderStatus == 16"
            @click="refund"
          >
            确认退款
          </el-button>
          <el-button
            type="primary"
            v-if="orderInfo.orderStatus == 3"
            @click="confirmAlert('signFor')"
          >
            确认收货
          </el-button>
          <el-button
            type="primary"
            v-if="orderInfo.orderStatus == 2"
            @click="confirmAlert('confirmDelivery')"
          >
            确认发货
          </el-button>
        </p>
        <h3 v-for="(item, index) in orderInfo.operateRecords" :key="index">
          {{
            item.createTime +
            " " +
            item.operate +
            " " +
            (item.remark != null ? "备注:" + item.remark : "")
          }}
        </h3>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>费用信息</span>
      </div>
      <div>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">订单金额:</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              ￥
              {{
                orderInfo.amount &&
                orderInfo.amount.orderAmount + orderInfo.amount.freightFee
              }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">支付金额:</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              ￥
              {{ orderInfo.totalPrice }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">优惠金额:</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              ￥
              {{ orderInfo.discountAmount }}
            </div>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">运费:</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              ￥
              {{ orderInfo.amount && orderInfo.amount.freightFee }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">支付方式:</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{ paymentTypeList[orderInfo.paymentType - 1].text }}
            </div>
          </el-col>
        </el-form-item>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>订单信息</span>
      </div>
      <div>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">创建时间：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">{{ orderInfo.createDate }}</div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">订单号：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">{{ orderInfo.orderNo }}</div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">订单类型：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple" v-if="orderInfo.orderType == 1">
              会员卡
            </div>
            <div class="grid-content bg-purple" v-if="orderInfo.orderType == 2">
              商品
            </div>
            <div class="grid-content bg-purple" v-if="orderInfo.orderType == 3">
              充值卡
            </div>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">下单类型：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.placeOrderType == 1"
            >
              销售
            </div>
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.placeOrderType == 2"
            >
              预订
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">收货方式：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.receivingType == 11"
            >
              自提外带
            </div>
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.receivingType == 12"
            >
              自提
            </div>
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.receivingType == 21"
            >
              配送
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">取餐时间：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.placeOrderType == 1"
            >
              {{ orderInfo.reserveTime }}
            </div>
            <div
              class="grid-content bg-purple"
              v-if="orderInfo.placeOrderType == 2 && orderInfo.timeSlot > 0"
            >
              {{ orderInfo.reserveTime }} /
              {{ timeList[orderInfo.timeSlot - 1].text }}
            </div>
          </el-col>
        </el-form-item>
        <el-form-item
          v-if="orderInfo.placeOrderType == 2 && orderInfo.receivingType == 21"
        >
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">收货人：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{ orderInfo.receiving.name }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">电话：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{ orderInfo.receiving.mobile }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">地址：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{
                orderInfo.receiving.province +
                orderInfo.receiving.city +
                orderInfo.receiving.county +
                orderInfo.receiving.street +
                orderInfo.receiving.detail
              }}
            </div>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">买家留言：</div>
          </el-col>
          <el-col :span="20" align="left">
            <div class="grid-content bg-purple">
              {{ orderInfo.remark || "--" }}
            </div>
          </el-col>
        </el-form-item>
      </div>
    </el-card>
    <el-card class="box-card" v-if="orderInfo.orderType == 2">
      <div slot="header" class="clearfix">
        <span>商品信息</span>
      </div>
      <div>
        <el-table :data="orderInfo.products" v-loading="loading">
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template v-if="item.prop === 'coverImage'" #default="scope">
              <img class="list-image" :src="scope.row.coverImage" alt="" />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-card class="box-card" v-if="orderInfo.orderType == 3">
      <div slot="header" class="clearfix">
        <span>充值卡信息</span>
      </div>
      <div>
        <el-table :data="orderInfo.rechargeCardInfos" v-loading="loading">
          <el-table-column
            v-for="item in rechargeCardColumns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template v-if="item.prop === 'icon'" #default="scope">
              <img class="list-image" :src="scope.row.icon" alt="" />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>用户信息</span>
      </div>
      <div>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">用户名：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{ orderInfo.user != null ? orderInfo.user.name : "" }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">手机号码：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{ orderInfo.user != null ? orderInfo.user.mobile : "" }}
            </div>
          </el-col>
          <!-- <el-col :span="4" align="right">
            <div class="grid-content bg-purple">用户等级：</div>
          </el-col> -->
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{
                orderInfo.user != null && orderInfo.user.membershipLevel != null
                  ? orderInfo.user.membershipLevel.name
                  : ""
              }}
            </div>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">认证结果：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{
                orderInfo.user != null &&
                orderInfo.user.memberAuthenticationStatus != null &&
                orderInfo.user.memberAuthenticationStatusId == 0
                  ? "未认证"
                  : "已认证"
              }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">是否黑名单：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{
                orderInfo.user != null && orderInfo.user.blacklist ? "是" : "否"
              }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-form-item>
      </div>
    </el-card>
    <el-card class="box-card" v-if="orderInfo.receivingType == 21">
      <div slot="header" class="clearfix">
        <span>物流信息</span>
      </div>
      <div>
        <el-form-item>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">收货方式：</div>
          </el-col>
          <el-col :span="4" align="left">
            <div class="grid-content bg-purple">
              {{
                orderInfo.receivingType == 21
                  ? "配送"
                  : orderInfo.receivingType == 11
                  ? "自提"
                  : "自提"
              }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">
              收货人：{{ orderInfo.receiving && orderInfo.receiving.name }}
            </div>
          </el-col>
          <el-col :span="4" align="right">
            <div class="grid-content bg-purple">收货地址：</div>
          </el-col>
          <el-col :span="8" align="left">
            <div class="grid-content bg-purple">
              {{
                orderInfo.receiving == null
                  ? ""
                  : orderInfo.receiving.province +
                    orderInfo.receiving.city +
                    orderInfo.receiving.county +
                    orderInfo.receiving.street +
                    orderInfo.receiving.detail
              }}
            </div>
          </el-col>
        </el-form-item>
      </div>
    </el-card>

    <Refund ref="Refund" :father-method="setConfigInfo" />

    <el-dialog
      :title="alertMsg.title"
      :visible.sync="alertMsg.dialogVisible"
      width="30%"
      :modal="false"
    >
      <span>{{ alertMsg.msg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alertMsg.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="option"> 确 定 </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {formatDate} from "@/utils/day";
import {printOrder} from "@/utils";
import {confirmDeliveryByOrderUid, getTransactionDetail, signForByOrderUid,} from "@/api/Order";
import {BuyOrderStatus} from "@/constant/status";
import Refund from "./Refund.vue";
// import Print from './Print.vue'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
  },
  components: {
    Refund,
    // Print
  },
  data() {
    return {
      visible: false,
      loading: false,
      memberList: [],
      orderInfo: { paymentType: 1 },
      // printObj: {
      //   id: 'printMe',
      //   popTitle: '漫迪西饼',
      //   beforeOpenCallback(vue) {
      //     vue.printLoading = true
      //     console.log('打开之前')
      //   },
      //   openCallback(vue) {
      //     vue.printLoading = false
      //     console.log('执行了打印')
      //   },
      //   closeCallback(vue) {
      //     console.log('关闭了打印工具')
      //   }
      // },
      paymentTypeList: [
        {
          value: 1,
          text: "微信",
        },
        {
          value: 2,
          text: "支付宝",
        },
        {
          value: 3,
          text: "余额",
        },
      ],
      timeList: [
        {
          value: 1,
          text: "9:00-11:00",
        },
        {
          value: 2,
          text: "11:00-13:00",
        },
        {
          value: 3,
          text: "13:00-15:00",
        },
        {
          value: 4,
          text: "15:00-16:00",
        },
        {
          value: 5,
          text: "16:00-17:00",
        },
        {
          value: 6,
          text: "17:00-18:00",
        },
      ],
      columns: [
        {
          label: "产品货号",
          prop: "productNo",
        },
        {
          label: "产品名称",
          prop: "name",
        },
        {
          label: "分类",
          prop: "category",
        },
        {
          label: "产品参数",
          prop: "skuName",
        },
        {
          label: "数量",
          prop: "count",
        },
        {
          label: "图片",
          prop: "coverImage",
        },
        {
          label: "价格",
          prop: "salePrice",
        },
      ],
      rechargeCardColumns: [
        {
          label: "名称",
          prop: "name",
        },
        {
          label: "展示金额",
          prop: "markPrice",
        },
        {
          label: "赠送金额",
          prop: "givePrice",
        },
      ],
      alertMsg: {},
    };
  },
  filters: {
    filterOrderStatus: (value) => {
      return {
        1: "待付款",
        2: "待发货",
        3: "待收货",
        5: "待评价",
        7: "已取消",
        9: "退款中",
        10: "已退款",
        11: "已完成",
        13: "申请退款中",
        14: "申请退款不通过",
        15: "拒收",
        16: "退款失败",
      }[value];
    },
  },
  methods: {
    handlePrint(orderUid) {
      printOrder(orderUid);
    },
    close() {
      this.visible = false;
      this.orderInfo = { paymentType: 1 };
    },
    async setConfigInfo(orderUid) {
      const orderDetail = await getTransactionDetail({ orderUid: orderUid });
      orderDetail.paymentType = 1;
      if (orderDetail.finance != null) {
        orderDetail.paymentType = orderDetail.finance.paymentType;
      }
      this.orderInfo = orderDetail;
      this.$store.dispatch("order/setOrderInfo", this.orderInfo);
      this.orderInfo.orderStatusName =
        BuyOrderStatus[this.orderInfo.orderStatus];
      this.orderInfo.createDate = formatDate(
        this.orderInfo.createDate,
        "yyyy-MM-dd HH:mm:ss"
      );
      if (this.orderInfo.placeOrderType == 1) {
        this.orderInfo.reserveTime = formatDate(
          this.orderInfo.reserveTime,
          "yyyy-MM-dd HH:mm:ss"
        );
      }

      if (this.orderInfo.placeOrderType == 2) {
        this.orderInfo.reserveTime = formatDate(
          this.orderInfo.reserveTime,
          "yyyy-MM-dd"
        );
      }

      this.orderInfo.operateRecords.forEach((element) => {
        element.createTime = formatDate(
          element.createTime,
          "yyyy-MM-dd HH:mm:ss"
        );
      });
      this.visible = true;
    },
    confirmAlert(option) {
      if (option === "confirmDelivery") {
        // 确认收货
        this.alertMsg = {
          title: "确认发货",
          dialogVisible: true,
          option: "confirmDelivery",
          msg: "确认发货吗?",
        };
      } else if (option === "signFor") {
        // 确认收货
        this.alertMsg = {
          title: "确认收货",
          dialogVisible: true,
          option: "signFor",
          msg: "确认收货吗?",
        };
      }
    },
    async option() {
      let msgData = this.alertMsg;
      this.alertMsg.dialogVisible = false;
      this.alertMsg = {};
      if (msgData.option === "confirmDelivery") {
        await confirmDeliveryByOrderUid(this.orderInfo);
        this.$message.success("已发货");
        this.$emit("handleRefresh");
        this.close();
      } else if (msgData.option === "signFor") {
        await signForByOrderUid(this.orderInfo);
        this.$message.success("已收货");
        this.$emit("handleRefresh");
        this.close();
      } else {
        this.$message.error("错误操作");
      }
    },
    refund() {
      this.$refs.Refund.setConfigInfo(this.orderInfo);
      this.$refs.Refund.visible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
