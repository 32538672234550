<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ headline }}
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div class="search-item item">
                    <div class="item-label">采购计划名称：</div>
                    <div class="item-value ellipsis">
                        <el-tooltip v-if="Form.name?.length>20"  effect="dark" :content="Form.name" placement="bottom-start">
                            <p class="ellipsis"> {{ Form.name }}</p>
                        </el-tooltip>
                        <p v-else>{{ Form.name }}</p>
                    </div>
                </div>
                <div class="search-item item">
                    <div class="item-label">制单人：</div>
                    <div class="item-value">
                        {{ Form.makeUser.name }}
                    </div>
                </div>
                <div class="search-item item">
                    <div class="item-label">采购计划单号：</div>
                    <div class="item-value">
                        {{ Form.planNo }}
                    </div>
                </div>
                <div class="search-item item">
                    <div class="item-label">历史单号：</div>
                    <div class="item-value">
                        {{ Form.no }}
                    </div>
                </div>
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%" max-height="550"
                :row-class-name="tableRowClassName">
                <el-table-column type="index" label="序号" width="80" align="center">
                </el-table-column>
                <!-- <el-table-column prop="symbol" width="80" align="center">
                </el-table-column> -->
                <el-table-column prop="modifier.name" label="编辑用户" min-width="150" align="center">
                    <template>
                        {{ Form.modifier?.name || null}}
                    </template>
                </el-table-column>
                <el-table-column prop="modifyTime" label="编辑时间" min-width="150" align="center">
                    <template>
                        {{Form.modifyTime || null}}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="产品名称" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="supplier.name" label="供应商" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="specification" label="规格" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="unit.name" label="单位" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="storageQuantity" label="可用库存" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="planQuantity" label="收集计划数" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="quantity" label="本次采购数量" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="90" align="center">
                </el-table-column>
            </el-table>
        </div>
        <div class="bottom-btn">
            <imsButton typeClass="secondary" text="返回" @click="$router.go(-1)" />
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import imsButton from '@/components/imsButton.vue'
export default {
    components: {
        imsButton,
    },
    computed: {
        ...mapMutations['getHeadline'],
    },
    data() {
        return {
            headline: '',
            Form: {
                planName: '',
                makeName: '',
                planID: '',
                orderID: '',
                listingCollection: [{ symbol: '-' }, { symbol: '+' }, {}],
            }
        }
    },
    methods: {
        getData() {
            this.loading = true
            this.$apis.historyPurchasePlan(this.$route.query.id).then((res) => {
                this.Form = {
                    ...this.form,
                    ...res.body,
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        tableRowClassName({ row }) {
            if (row.symbol === '+') {
                return 'success-row';
            }
            if (row.symbol == '-') {
                return 'warning-row';
            }
            return '';
        }
    },
    mounted() {
        this.headline = '查看历史'
        this.$store.commit('getHeadline', this.headline)
        this.getData()
    },
}
</script>

<style lang="less">
// .item {
//     margin: 5px 130px;
// }
.page-search{
    flex-wrap: wrap;
}
</style>
