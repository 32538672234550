<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">实付账款</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">货单编号：</div>
            <div class="item-value">
              <el-input v-model="Query.no" clearable maxlength="99"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">供应商：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageBtype" v-model="Query.supplierId" />
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">付款日期：</div>
            <div class="item-value">
              <el-date-picker v-model="Query.paymentDate" type="date" placeholder="选择日期" ref="date"></el-date-picker>
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="no" label="货单编号" width="160" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplier.name" label="供应商" min-width="160" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="voucherNo" label="发票号" min-width="160" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="paymentDate" label="付款日期" min-width="160" align="center">
          <template slot-scope="scope">{{ scope.row.paymentDate | dateformat() }}</template>
        </el-table-column>
        <el-table-column prop="totalAmount" label="订单总金额（元）" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="payableAmount" label="应付金额（元）" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amount" label="已付金额（元）" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <div class="link" @click="$router.push(`/Finance/RealPay/detail?id=${scope.row.id}`)">查看明细</div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 


    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";

export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber
  },
  data() {
    return {
      loading: false,
      Query: {
        no: null,
        supplierId: null,
        paymentDate: null,
      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      Form: {}
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      this.$apis
        .pagePayment({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>