<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">库存盘点</div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div>
                    <div class="search-item">
                        <div class="item-label">盘点单号：</div>
                        <div class="item-value">
                            <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
                        </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">盘点月份：</div>
                        <div class="item-value">
                            <el-date-picker v-model="Query.month" type="month" placeholder="选择月" value-format="M">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div>
                    <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
                    <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
                </div>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="no" label="盘点单号" width="160" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span class="link" @click="$router.push(`/Stock/Inventory/formPage?id=${scope.row.id}`)">
                            {{ scope.row.no }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastAmountSum" label="上月结存金额汇总" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="purchaseAmountSum" label="本月购进金额汇总" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="returnAmountSum" label="本月退货金额汇总" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="backAmountSum" label="本月退回金额汇总" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outboundAmountSum" label="本月出库金额汇总" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="balanceAmountSum" label="本月结存金额汇总" min-width="120" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="80" align="center">
                    <template slot-scope="scope">
                        <div class="link" @click="$router.push(`/Statistics/Inventory/formPage?id=${scope.row.id}`)">查看</div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination  layout="total, prev, pager, next,jumper"
                :current-page="pageQuery.pageIndex" :total="pageQuery.total" :page-size="pageQuery.pageSize"
                @current-change="val => pageQuery.pageIndex = val"></el-pagination>
        </div>
    </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
export default {
    components: {
        imsButton,
    },
    data() {
        return {
            loading: false,
            Query: {
            },
            tableData: [],
        };
    },
    methods: {
        getData(item) {
            this.loading = true;
            if (item) {
                this.pageQuery = {
                    ...this.pageQuery,
                    pageIndex: 1
                }
            }

            this.$apis
                .pageStatisticsInventory({ ...this.Query, ...this.pageQuery })
                .then(res => {
                    this.tableData = res.body.records;
                    this.pageQuery.total = Number(res.body.recordCount);
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
        });
    }
};
</script>