export function addDateRange(params, dateRange) {
  let search = params
  search.beginTime = ''
  search.endTime = ''
  if (null != dateRange && '' != dateRange) {
    search.beginTime = dateRange[0]
    search.endTime = dateRange[1]
  }
  return search
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str == 'undefined' || str == 'null') {
    return ''
  }
  return str
}

// 页面数据重置
export function initListData(data) {
  if (data) {
      if(data.constructor===Object){
          for (let key in data) {
                if(data[key]?.constructor===Array){
                  data[key]=[]
              }else if(data[key]?.constructor===Object){
                  data[key]={} 
              }else {
                  data[key] = null
              }
          }
      }
  }
}