<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">计量单位管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">名称：</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="addData()">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="启/禁用" width="80" align="center">
          <template slot-scope="scope">
            <el-switch active-color="#BB1BB5" v-model="scope.row.enabled" :disabled="scope.row.disabled"
              @change="val => toggleEnabled(val, scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="editData(scope.row)" type="text" class="edit">编辑</el-button>
            <el-button type="text" @click="deleData(scope.row)" v-show="!scope.row.enabled"
              style="color:rgba(217, 0, 17, 1)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.id ? '编辑' : '新增' }}计量单位</div>
      <div class="dialog-main">
        <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>名称：
          </div>
          <div class="item-value">
            <el-input v-model="Form.name" clearable maxlength="30" ref="name"></el-input>
          </div>
        </div>
        <div class="search-item" style="width: 100%;">
          <div class="item-label">备注：</div>
          <div class="item-value">
            <el-input v-model="Form.remark" clearable maxlength="200" type="textarea" :rows="3" placeholder="限200字"
              show-word-limit></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="保存" @click="saveForm()" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect
  },
  mixins: [mixins],
  data() {
    return {
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      loading: false,
      disabled: false,
      dialogVisible: false,
      Query: {
        nameLike: ""
      },
      Form: {
        name: null,
        remark: null,
      }
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      this.$apis
        .pageUnits({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addData() {
      this.Form = {
        name: "",
        remark: "",
        no: 1
      };
      this.dialogVisible = true;
    },
    editData(item) {
      this.Form = {
        ...item,
      };
      this.dialogVisible = true;
    },
    saveForm() {
      if (this.typeList.includes(this.Form.name)) {
        this.$message.warning("请输入名称！");
        this.$refs.name.focus();
        return;
      }
      this.loading = true;
      if (this.Form.id) {
        // 编辑
        this.$apis
          .editUnits(this.Form, this.Form.id)
          .then(res => {
            if (res.body) {
              this.$message.success("编辑成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        //新增
        this.$apis
          .addUnits(this.Form)
          .then(res => {
            if (res.body) {
              this.$message.success("添加成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    deleData(item) {
      this.$confirm("您确定要删除" + item.name + "吗?", "删除提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning"
      }).then(() => {
        this.$apis.delUnits(item.id).then(res => {
          if (res.body) {
            this.$message.success("删除成功！");
            this.getData();
          }
        });
      }).catch(()=>{})
    },
    toggleEnabled(bool, item) {
      this.disabled = true;
      this.$apis
        .toggleUnits(bool, item.id)
        .then(res => {
          if (res.body) {
            this.$message.success("状态成功切换！");
          }
          this.disabled = false;
        })
        .catch(() => {
          this.tableData = this.tableData.map(ele => {
            if (ele.id == item.id) {
              return {
                ...ele,
                enabled: !bool
              };
            } else return ele;
          });
          this.disabled = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="less" scoped></style>