import request_artisan from '@/utils/request_artisan'

// 获取优惠券列表
export function getCouponList(data) {
  return request_artisan({
    url: '/v1/Coupon/Page',
    method: 'get',
    params: data
  })
}

// 获取优惠券领取列表
export function getCouponReceiveList(data) {
  return request_artisan({
    url: '/v1/Coupon/Receive/Page',
    method: 'get',
    params: data
  })
}

// 新增优惠券
export function addConpon(data) {
  return request_artisan({
    url: '/v1/Coupon/Add',
    method: 'post',
    data: data
  })
}

// 删除用户优惠券
export function deleteConponReceive(data) {
  return request_artisan({
    url: '/v1/Coupon/Receive/' + data.userCouponId,
    method: 'delete'
  })
}

// 停/启用
export function updateConponStatus(data) {
  return request_artisan({
    url: '/v1/Coupon/Enable/' + data.couponId,
    method: 'put'
  })
}

// 删除
export function deleteConpon(data) {
  return request_artisan({
    url: '/v1/Coupon/Delete/' + data.couponId,
    method: 'delete'
  })
}

// 派送优惠券
export function deliveryConpon(data) {
  return request_artisan({
    url: '/v1/Coupon/Delivery',
    method: 'post',
    data: data
  })
}

// 验证兑换码
export function valExchangeCode(data) {
  return request_artisan({
    url: '/v1/Coupon/VaildExchangeCode/' + data.exchangeCode,
    method: 'put',
    data: {}
  })
}
