<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="70%"
    @close="close"
  >
    <el-form :inline="true" class="demo-form-inline" size="small">
      <el-form-item>
        <el-input placeholder="用户" v-model="param.account" clearable>
          <template slot="prepend">用户账号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div
          class="el-input el-input--small el-input-group el-input-group--prepend"
        >
          <div class="el-input-group__prepend">优惠券类型</div>
          <el-select v-model="param.isUse" placeholder="请选择" clearable>
            <el-option label="未使用" value="false">未使用</el-option>
            <el-option label="已使用" value="true">已使用</el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item>   
        <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset('queryForm')" />     
        <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="onSelect"/>
      </el-form-item>
    </el-form>

    <div class="base-table">
      <el-table :data="receiveList" v-loading="loading">
        <el-table-column type="index" label="序号" width="100" align="center" />
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="260">
          <template #default="scope">
            <template v-if="!scope.row.isUse">
              <el-popconfirm
                title="是否确认核销？"
                @confirm="handleStatus(scope.row)"
              >
                <template #reference>
                  <el-button type="danger" size="small" icon="el-icon-delete"
                    >手动核销</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
            <template v-else> - </template>
          </template>
        </el-table-column>
      </el-table>
      <template>
        <el-pagination
          align="right"
        layout="total, prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="param.PageSize"
          :total="page.total"
        />
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/utils/day";
import {
  getCouponReceiveList,
  deleteConponReceive,
} from "@/api/Marketings/coupons";
import imsButton from "@/components/imsButton.vue";
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components:{
    imsButton
  },
  data() {
    return {
      visible: false,
      loading: false,
      param: {
        PageIndex: 1,
        PageSize: 5,
        couponId: null,
        account: null,
        isUse: null,
      },
      receiveList: [],
      page: {
        total: 0,
      },
      columns: [
        {
          label: "用户名",
          prop: "receiveUser.name",
        },
        {
          label: "手机",
          prop: "receiveUser.mobile",
        },
        {
          label: "领取时间",
          prop: "receiveTime",
          formatter: (row, column, value) => {
            return formatDate(value, "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          label: "领取途径",
          prop: "receiveChannelTypeName",
        },
        {
          label: "使用状态",
          prop: "isUse",
          formatter: (row, column, value) => {
            if (value) {
              return "已使用";
            } else {
              return "未使用";
            }
          },
        },
        {
          label: "优惠券系统标识ID",
          prop: "coupon.identification",
        },
      ],
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    async getCouponReceiveListApi() {
      this.loading = true;
      let data = await getCouponReceiveList(this.param);
      this.receiveList = data.records;
      this.page.total = data.recordCount;
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.param.PageIndex = val;
      this.onSelect();
    },
    // 获取领取列表
    getReceiveList(couponInfo) {
      this.visible = true;
      console.log(couponInfo);
      this.param.couponId = couponInfo.couponId;
      this.onSelect();
    },
    onSelect() {
      if (this.param.couponType == "") {
        this.param.couponType = null;
      }
      if (this.param.account == "") {
        this.param.account = null;
      }
      if (this.param.isUse == "") {
        this.param.isUse = null;
      }
      console.log(this.param);
      this.getCouponReceiveListApi();
    },
    // 核销
    handleStatus(row) {
      const data = {
        userCouponId: row.userCouponId,
      };
      deleteConponReceive(data);
      this.$message.success("操作成功");
      this.onSelect();
    },
  },
};
</script>

<style lang="less" scoped></style>
