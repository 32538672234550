<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">库存盘点</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">盘点单号：</div>
          <div class="item-value">
            <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
          </div>
          </div>
          <div class="search-item">
            <div class="item-label">盘点仓库：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageWarehouse" v-model="Query.warehouseId" />
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">计划盘点时间：</div>
            <div class="item-value">
              <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;margin-left: 10px;"
          @click="dialogVisible = true; Form = { warehouse: {} }">新增盘点计划</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="no" label="盘点单号" width="140" align="center"></el-table-column>
        <el-table-column prop="warehouse.name" label="盘点仓库" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="计划开始时间" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.planStartDate | dateformat('yyyy-MM-DD') }}</template>
        </el-table-column>
        <el-table-column label="计划结束时间" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.planEndDate | dateformat('yyyy-MM-DD') }}</template>
        </el-table-column>
        <el-table-column label="实际开始时间" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.realStartDate | dateformat('yyyy-MM-DD') }}</template>
        </el-table-column>
        <el-table-column label="实际结束时间" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.realEndDate | dateformat('yyyy-MM-DD') }}</template>
        </el-table-column>
        <el-table-column prop="status" label="盘点状态" width="100" align="center">
          <template slot-scope="scope">
            {{ statusOptions[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status != 0">
              <div class="link"
                @click="$router.push(`/Stock/Inventory/formPage?id=${scope.row.id}&status=${scope.row.status}`)">
                {{ scope.row.status == 1 ? '开始盘点' : scope.row.status == 2 ? '盘点' :
                  scope.row.status == 3 ? '复核' : '查看' }}
              </div>
              <div class="link star" v-show="(scope.row.status != 6)" @click="cancelData(scope.row)">作废</div>
            </div>
            <div v-else>
              <div class="link" @click="editData(scope.row)">编辑</div>
              <div class="link star" @click="delData(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>

    <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.id ? '编辑' : '新增' }}盘点计划</div>
      <div class="dialog-main">
        <div class="search-item" style="width: 50%;">
          <div class="item-label">
            <span class="star">*</span>盘点仓库：
          </div>
          <div class="item-value">
            <inputSelect apiMethod="pageWarehouse" v-model="Form.warehouseId" :label="Form.warehouseName"
              ref="warehouseId" />
          </div>
        </div>
        <div class="search-item" style="width: 50%;">
          <div class="item-label">
            仓库地址：
          </div>
          <div class="item-value">
            {{ Form.warehouse ? Form.warehouse.address : '选择仓库后自动加载' }}
          </div>
        </div>
        <div class="search-item" style="width: 50%;">
          <div class="item-label">
            <span class="star">*</span>计划开始时间：
          </div>
          <div class="item-value">
            <el-date-picker v-model="Form.planStartDate" type="date" value-format="yyyy-MM-dd"
              ref="planStartDate"></el-date-picker>
          </div>
        </div>
        <div class="search-item" style="width: 50%;">
          <div class="item-label">
            <span class="star">*</span>计划结束时间：
          </div>
          <div class="item-value">
            <el-date-picker v-model="Form.planEndDate" type="date" value-format="yyyy-MM-dd"
              ref="planEndDate"></el-date-picker>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="关闭" @click="dialogVisible = false" />
        <imsButton typeClass="secondary" text="保存" @click="tempSave()" :loading="loading" />
        <imsButton text="提交" @click="saveForm()" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect,
  },
  watch: {
    dateArr: {
      handler(val) {
        if (val && val.length > 0) {
          this.Query.planStartDate = val[0]
          this.Query.planEndDate = val[1]
        } else {
          this.Query.planStartDate = null
          this.Query.planEndDate = null
        }
      }
    },
    'Form.warehouseId'(val) {
      if (val) {
        this.$apis.getWarehouse(val).then(res => {
          this.Form = {
            ...this.Form,
            warehouse: {
              ...this.Form.warehouse,
              address: res.body.address
            }
          }
        })
      }
    }
  },
  data() {
    return {
      loading: false,
      Query: {
      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      dateArr: [],
      statusOptions: ['草稿', '未开始', '盘点中', '待复核', '通过', '不通过', '作废'],
      dialogVisible: false,
      Form: {}
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if (item) {
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      }

      this.$apis
        .pagePD({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    delData(item) {
      this.$confirm(
        `您确定要删除该条数据吗？删除后不可恢复！`,
        '删除提示',
        {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        }
      )
        .then(() => {
          this.$apis.delPD(item.id).then(res => {
            if (res.body) {
              this.$message.success('删除成功！')
              this.getData()
            }
          })
        })
        .catch(() => {

        })
    },
    cancelData(item) {
      this.$confirm(
        `您确定要作废该条数据吗？作废后不可恢复！`,
        '作废提示',
        {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        }
      )
        .then(() => {
          this.$apis.cancelPD(item.id).then(res => {
            if (res.body) {
              this.$message.success('作废成功！')
              this.getData()
            }
          })
        })
        .catch(() => {

        })
    },
    editData(item) {
      this.dialogVisible = true
      this.Form = {
        ...item,
        warehouseId: item.warehouse.id
      }
    },
    tempSave() {
      this.loading = true;
      this.$apis
        .savePD(this.Form)
        .then(res => {
          this.loading = false;
          if (res.body) {
            this.$message.success('保存成功！')
            this.Form = res.body
            this.getData()
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveForm() {
      // 表单校验
      if (!this.Form.warehouseId) {
        this.$message.warning('请选择“盘点仓库”')
        this.$refs.warehouseId.focus()
        return
      }
      if (!this.Form.planStartDate) {
        this.$message.warning('请选择“计划开始时间”')
        this.$refs.planStartDate.focus()
        return
      }
      if (!this.Form.planEndDate) {
        this.$message.warning('请选择“计划结束时间”')
        this.$refs.planEndDate.focus()
        return
      }
      this.loading = true;
      this.$apis
        .submitPD(this.Form)
        .then(res => {
          this.loading = false;
          if (res.body) {
            this.$message.success('提交成功！')
            this.dialogVisible = false
            this.getData()
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.dateArr = []
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>