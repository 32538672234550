<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">实收账款明细</div>
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-body">
        <div class="search-item">
          <div class="item-label bold">货单编号：</div>
          <div class="item-value">{{ Form.orderInfo.no }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">凭证号：</div>
          <div class="item-value">{{ Form.voucherNo }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">门店：</div>
          <div class="item-value">{{ Form.orderInfo.customer ? Form.orderInfo.customer.name : '' }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">订单总金额：</div>
          <div class="item-value">{{ Form.totalAmount }}元</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">实收款：</div>
          <div class="item-value">{{ Form.amount }}元</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">剩余应收款：</div>
          <div class="item-value">{{ Form.residualReceivableAmount }}元</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">收款银行：</div>
          <div class="item-value">{{ Form.blankAccount ? Form.blankAccount.name : '' }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">最后收款日期：</div>
          <div class="item-value">{{ Form.receiptDate | dateformat('yyyy-MM-DD') }}</div>
        </div>
        <div class="search-item" style="width: 100%;">
          <div class="item-label bold">收款凭证：</div>
          <div class="item-value" style="height: auto !important; max-height: auto !important;">
            <div v-for="(item, index) in Form.attachmentList" :key="index">
              {{ item.fileName }}
              <span class="link" style="vertical-align: middle;" @click="downloadFile(item)">下载</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-title" style="margin-top: 20px;">
        <span class="fa fa-border-style"></span>
        产品清单
      </div>
      <el-table :data="Form.orderInfo.listingCollection" border style="width: 100%;margin-top: 5px;"
        height="calc(100% - 280px)" ref="list" destroy-on-close>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="产品名称" prop="name" min-width="140" align="center">
        </el-table-column>
        <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
        </el-table-column>
        <el-table-column label="规格" prop="specification" width="120" align="center">
        </el-table-column>
        <el-table-column label="产品属性" prop="attributeTypeName" min-width="200" align="center">
        </el-table-column>
        <el-table-column label="单价（元）" prop="price" width="100" align="center">
        </el-table-column>
        <el-table-column label="批准文号" prop="drugApprovalNo" min-width="120" align="center"></el-table-column>
        <el-table-column label="生产批号/有效期" min-width="140" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.batchNo && scope.row.expiryDate">{{ scope.row.batchNo + '/' + scope.row.expiryDate }}</div>
          </template>
        </el-table-column>
      </el-table>

      <div class="form-title" style="margin-top: 20px;">
        <span class="fa fa-border-style"></span>
        冲账流水
        <div class="link" style="float:right" @click="(dialogVisible = true)">冲账</div>
      </div>
      <el-table :data="Form.clashReceiptsList" border style="width: 100%;margin-top: 5px;" height="calc(100% - 280px)"
        ref="list" destroy-on-close>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="凭证号" prop="voucherNo" align="center"></el-table-column>
        <el-table-column label="收款日期" prop="receiptDate" align="center">
          <template slot-scope="scope">{{ scope.row.receiptDate | dateformat('yyyy-MM-DD') }}</template>
        </el-table-column>
        <el-table-column label="收款银行" prop="blankAccount.name" align="center"></el-table-column>
        <el-table-column label="实收款（元）" prop="amount" align="center"></el-table-column>
        <el-table-column label="收款凭证" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.attachmentList" :key="index" class="link" @click="downloadFile(item)">
              {{ item.fileName }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push(`/Finance/RealIncome`)" />
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
      <div class="dialog-header">冲账</div>
      <div class="dialog-main" style="padding-left:100px">
        <div>
          <div class="search-item">
            <div class="item-label">货单编号：</div>
            <div class="item-value">
              <p>{{ Form.orderInfo.no }}</p>
            </div>
          </div>
          <div class="search-item" >
            <div class="item-label">门店：</div>
            <div class="item-value">
              <p>{{ Form.orderInfo.customer ? Form.orderInfo.customer.name : '' }}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="search-item">
            <div class="item-label">订单总金额：</div>
            <div class="item-value">
              <p>{{ Form.totalAmount }}元</p>
            </div>
          </div>
          <div class="search-item" >
            <div class="item-label">剩余应收款：</div>
            <div class="item-value">
              <p>{{ Form.unReceiveAmount }}元</p>
            </div>
          </div>
        </div>
        <div>
          <div class="search-item" data-required="blankAccountId">
            <div class="item-label">
              <span class="star">*</span>收款银行：
            </div>
            <div class="item-value">
              <el-select v-model="editForm.blankAccountId" placeholder="请选择" ref="blankAccountId">
                <el-option v-for="item in bankOptions" :key="item.id" :label="item.bankOfDeposit"
                  :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item" data-required="amount" >
            <div class="item-label">
              <span class="star">*</span>实收款（元）：
            </div>
            <div class="item-value">
              <el-input v-model="editForm.amount" :min="-99999999" :max="99999999" type="number" ref="amount"
                clearable></el-input>
            </div>
          </div>
        </div>
        <div>
          <div class="search-item" data-required="receiptDate">
            <div class="item-label">
              <span class="star">*</span>收款日期：
            </div>
            <div class="item-value">
              <el-date-picker v-model="editForm.receiptDate" type="date" placeholder="选择日期" ref="receiptDate"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </div>
          <div class="search-item" data-required="voucherNo" >
            <div class="item-label">
              <span class="star">*</span>凭证号：
            </div>
            <div class="item-value">
              <el-input v-model="editForm.voucherNo" clearable maxlength="11" ref="voucherNo"></el-input>
            </div>
          </div>
        </div>
        <div class="search-item" style="width: 100%;">
          <div class="item-label">收款凭证：</div>
          <div class="item-value">
            <el-upload class="left" ref="ref-upload" accept="" action="" :show-file-list="true" :http-request="uploadFile"
              :on-remove="removeFile">
              <imsButton text="上传文件" :loading="loading" />
            </el-upload>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="保存" @click="saveForm" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputNumber from "@/components/inputNumber.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputNumber,
    inputSelect
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      Form: {
        orderInfo: {
          listingCollection: [],
        },
        attachmentList: [],
        clashReceiptsList: []
      },
      bankOptions: [],
      editForm: {
        attachmentList: [],
      },
    };
  },
  methods: {
    getDetail() {
      this.$apis
        .getReceipts(this.Form.id)
        .then(res => {
          this.Form = {
            ...this.Form,
            ...res.body,
            orderInfo: res.body.salesOrderInfo ? res.body.salesOrderInfo : res.body.salesReturnsInfo
          };
        })
        .catch(() => { });
    },
    // 下载文件
    downloadFile(item) {
      var notify = this.$notify.info({
        title: "消息",
        duration: 0,
        message: `[${item.fileName}]正在下载，请耐心等待`,
      })
      this.$apis['downloadFile'](item.fileSummary)
        .then((res) => {
          if (!res.data) {
            return;
          }

        })
        .catch((res) => {
          var filename = item.fileName
          notify.close();
          this.$notify.success({
            title: "消息",
            duration: 3000,
            message: `[${filename}]已导出，请查收`,
          });
          const blob = new Blob([res]);
          let url = window.URL.createObjectURL(blob);
          if ("download" in document.createElement("a")) {
            try {
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
            } catch (e) {
              console.log(e);
            }
          } else {
            navigator.msSaveBlob(blob, filename);
          }
        });
    },
    // 移除附件
    removeFile(file) {
      this.editForm.attachmentList = this.editForm.attachmentList.filter(item => item.fileName != file.name)
    },
    // 上传附件
    uploadFile(file) {
      var params = new FormData(); // 创建form对象
      params.append("file", file.file); // 将文件存入file下面
      this.loading = true
      this.$apis.fileUpload(params).then(res => {
        this.loading = false
        this.editForm.attachmentList.push({
          fileName: file.file.name,
          fileSummary: res.body
        })
      }).catch(() => {
        this.loading = false
      })
    },
    saveForm() {
      var bool = this.checkForm(
        document.querySelectorAll("*[data-required]"),
        this.editForm
      );
      if (!bool) return;
      this.loading = true;
      this.$apis
        .postReceiptsClash({
          ...this.editForm,
          receivableId: this.Form.receivableId
        }, this.Form.id)
        .then(res => {
          if (res.body) {
            this.loading = false;
            this.dialogVisible = false;
            this.$message.success("冲账成功!");
            this.getDetail()
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.Form.id = this.$route.query.id;
        this.getDetail();
      }
      this.$apis.pageAccount({ pageSize: 999, enabled: true }).then(res => {
        if (res.body) {
          this.bankOptions = res.body.records
        }
      }).catch(()=>{})
    });
  }
};
</script>