<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          商品属性
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="base-table">
        <div class="table-btns">
          <span class="link" style="float: right;" @click="handleCreate()"
            >新增属性</span>
        </div>
        <el-table :data="attrList" row-key="attrId" stripe v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
          >
          </el-table-column>

          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  @click="handleShowValueList(scope.row)"
                  >查看属性值</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-popconfirm
                  title="是否确认删除？"
                  style="margin-left: 10px"
                  @confirm="handleDelete(scope.row)"
                >
                  <template #reference>
                    <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete-solid"
                      >删除</el-button
                    >
                  </template>
                </el-popconfirm>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <AttrDialog
        ref="attrDialog"
        :dialog-title="dialogTitle"
        @updateList="getAttrListApi"
      />
    </div>
  </div>
</template>

<script>
import { getAttrListV2, deleteAttr } from "@/api/Attr";
import AttrDialog from "./components/AttrDialog";
export default {
  name: "attrList",
  components: {
    AttrDialog,
  },
  data() {
    return {
      loading: false,
      queryForm: {
        createDate: [],
      },
      total: 0,
      attrList: [],
      categoryInfo: {},
      dialogTitle: "",
      columns: [
        {
          label: "属性ID",
          prop: "attrId",
        },
        {
          label: "属性名称",
          prop: "name",
        },
        {
          label: "备注",
          prop: "description",
        },
      ],
    };
  },
  mounted() {
    this.getAttrListApi();
  },
  methods: {
    async getAttrListApi() {
      this.loading = true;
      this.attrList = await getAttrListV2();
      this.loading = false;
    },
    handleShowValueList(row) {
      this.$router.push({
        path:`/Product/attr/attrValueList?id=${row.attrId}`,
        meta: {
                title: "“" + row.name + "”属性",
              },
      });
    },
    handleCreate() {
      this.dialogTitle = "添加属性";
      this.$refs.attrDialog.openDialog();
    },
    handleEdit(row) {
      this.dialogTitle = "编辑属性";
      this.$refs.attrDialog.openDialog(row);
    },
    async handleDelete(row) {
      await deleteAttr({ attrId: row.attrId });
      this.$message.success("删除成功");
      this.getAttrListApi();
    },
  },
};
</script>

<style lang="less" scoped></style>
