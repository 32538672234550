import axios from 'axios'
import { Message, MessageBox } from 'element-ui'

// 全局设置超时时间
axios.defaults.timeout = 15000
// 请求路由拦截
var token ='tubsoft ' + sessionStorage.getItem("ims_token");

axios.interceptors.request.use(function (config) {
    token ='tubsoft ' + sessionStorage.getItem("ims_token");
    if (config.data && config.data.type === 'file') {
        config.timeout = 60000 * 60 * 5
        config.headers = {
            'content-type': 'multipart/form-data',
            'Authorization': token
        }
        config.data = config.data.file
    } else {
        config.headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }
    if (config.method == 'get') {
        config.data = true
    }
    // delete请求body传参
    if (config.method == 'delete') {
        // config.data = config.body
        config.headers = {
            'Content-Type': 'application/json',
            'Authorization': token,
            abc: 'hh'
        }
    }
    config.headers = {
        ...config.headers,
        'Authorization': token
    }
    return config
}, function (error) {
    // Message({
    //     type: 'error',
    //     title: '错误提示',
    //     message: error,
    // })
    return Promise.reject(error)
})

// respone拦截器 响应路由拦截
axios.interceptors.response.use(
    response => {
        if (
            response.config.url.indexOf('/v1/js/config/web.js') > -1
            || response.config.url.indexOf('file-storage/download') > -1
            || response.config.url.indexOf('/v1/excel/import/stock-storage') > -1
            || response.config.url.indexOf('/common/template/expert-import') > -1
            || response.config.url.indexOf('/system/expert/export') > -1
            || response.config.url.indexOf('/common/export/salesStatistics') > -1
            || response.config.url.indexOf('/common/export/statementInventoryBalance') > -1
            || response.config.url.indexOf('/common/template/apply-report') > -1) { // 下载文件直接返回
            return response
        }
        if (response.data.statusCode == 30100) {
            window.location.href = "/#/error?code=404"
        }
        if (response.data.statusCode == 10025) {
            window.location.href = "/#/404"
        }
        // if (response.data.body.status == 404) {
        //     Message({
        //         type: 'error',
        //         title: '错误提示',
        //         message: response.data.body.error + ' ' + response.data.body.path,
        //     })
        //     return Promise.reject(404)
        // }
        var body
        if (response.data.statusCode == 2000) {
            body = response.data.body
        } else {
            if (response.data.notification == undefined) {
                return Promise.reject(response.data)
            } else {
                Message({
                    type: 'error',
                    title: '错误提示',
                    message: response.data.notification,
                })
                return Promise.reject(response.data)
            }
        }
        response = {
            ...response,
            data: {
                ...response.data,
                body: nullToStr(body)
            }
        }
        return response
    },
    error => {

        // var str = error + ''
        // if (str.indexOf('timeout') > -1) {
        //     Message({
        //         title: '超时提示',
        //         type: 'warning',
        //         message: '接口响应超时，已中断请求',
        //     })
        //     return Promise.reject('链接超时，上传文件已终止')
        // }
        if (error.response.status == 401) { // 没有权限
            // Message({
            //     title: '服务器异常提示',
            //     type: 'error',
            //     message: '登录已过期，请您重新登录',
            // })
            MessageBox.alert('', '登录失效', {
                confirmButtonText: '跳转登录页面',
                showClose: false,
                callback: async () => {
                  window.sessionStorage.setItem("ims_token", "");
                  window.location.href = "/#/Login"
                }
              })
           
        }
        else {
            Message({
                title: '服务器异常提示',
                type: 'error',
                message: error.message,
            })
        }
        return Promise.reject(error.response.status)
    }
)

function nullToStr(data) {
    for (var x in data) {
        if (data[x] === null) { // 如果是null 把直接内容转为 ''
            data[x] = '';
        } else {
            if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
                data[x] = data[x].map(z => {
                    return nullToStr(z);
                });
            }
            if (typeof (data[x]) === 'object') { // 是json 递归继续处理
                data[x] = nullToStr(data[x])
            }
        }
    }
    return data;
}

export default {
    Get({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.get(window.config.api + url, {
                data
            }).then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err.data)
            })
        })
    },
    Post({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.post(window.config.api + url, data)
                .then((res) => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    },
    Put({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.put(window.config.api + url, data)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    },
    Delete({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.delete(window.config.api + url, { data: data })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    },
    Download({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.get(window.config.api + url,
                {
                    responseType: "arraybuffer",
                    data
                }
            ).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    DownloadPost({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.post(window.config.api + url,
                data,
                {
                    responseType: "arraybuffer",
                }
            ).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    postFile({ url, data }) {
        return new Promise((resolve, reject) => {
            axios.post(window.config.api + url, {
                type: 'file',
                file: data
            }).then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        })
    },
}