<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          运营配置
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="base-table">
        <el-table :data="configList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column label="操作" align="center" width="400">
            <template #default="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getSystemConfigListApi"
        />
      </div>
      <FreightDialog
        ref="freightDialog"
        :dialog-title="dialogTitle"
        @updateList="getSystemConfigListApi"
      />
    </div>
  </div>
</template>

<script>
import { getSystemConfigList } from '@/api/System'
import FreightDialog from './components/FreightDialog'
export default {
  name: 'sysConfigList',
  components: {
    FreightDialog
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10
      },
      total: 0,
      configList: [],
      dialogTitle: '编辑',
      columns: [
        {
          label: '使用场景',
          prop: 'name'
        },
        {
          label: '内容',
          prop: 'value'
        }
      ]
    }
  },
  mounted() {
    this.getSystemConfigListApi()
  },
  methods: {
    async getSystemConfigListApi() {
      this.loading = true
      const params = JSON.parse(JSON.stringify(this.queryForm))
      const { records, recordCount } = await getSystemConfigList(params)
      this.configList = records
      this.total = recordCount
      this.loading = false
    },
    handleEdit(row) {
      this.dialogTitle = '编辑'
      this.$refs.freightDialog.getData(row)
    }
  }
}
</script>

<style lang="less" scoped></style>
