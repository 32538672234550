<template>
<div class="page">
  <div class="page-header">
    <div class="page-title-box">
      <div class="page-title">
        商品订单列表
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="page-search">
      <div class="page-search-left">
        <div class="search-item" style="width: 100%;">
          <div class="item-label">订单状态：</div>
          <div class="item-value">
            <el-radio-group
              v-model="queryForm.orderStatus"
              @change="handleQuery"
            >
              <el-radio-button
                v-for="item in orderStatusList"
                :key="item.prop"
                :label="item.id"
                >{{ item.remark }}</el-radio-button
              >
              </el-radio-group>
            </div>
            <!-- <el-select
              v-model="queryForm.orderStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in orderStatusList"
                :label="item.remark"
                :value="item.id"
                :key="item.prop"
              ></el-option>
            </el-select> -->
              <!-- <el-radio-group
                v-model="queryForm.orderStatus"
                @change="handleQuery"
              >
                <el-radio-button
                  v-for="item in orderStatusList"
                  :key="item.prop"
                  :label="item.id"
                  >{{ item.remark }}</el-radio-button
                >
              </el-radio-group> -->
        </div>
        <div class="search-item">
            <div class="item-label">订单号：</div>
            <div class="item-value">
            <el-input
              v-model="queryForm.orderNo"
              placeholder="请输入订单号"
            ></el-input>
            </div>
        </div>
        <div class="search-item">
            <div class="item-label">商品名称：</div>
            <div class="item-value">
              <el-input
              v-model="queryForm.produtName"
              placeholder="请输入商品名称"
              clearable
            ></el-input>
              <!-- <el-form-item label="用户名" prop="userName">
            <el-input
              v-model="queryForm.userName"
              placeholder="请输入用户名"
              clearable
            ></el-input>
          </el-form-item> -->
          </div>
        </div>
        <div class="search-item">
            <div class="item-label">手机号码：</div>
            <div class="item-value">
              <el-input
                v-model="queryForm.mobile"
                placeholder="请输入手机号码"
                clearable
              ></el-input>
            </div>

          <!-- <el-form-item label="下单类型" prop="placeOrderType">
            <el-select
              v-model="queryForm.placeOrderType"
              @change="getCategorysListApi"
              clearable
            >
              <el-option :value="1" label="销售"></el-option>
              <el-option :value="2" label="预订"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="商品类型" prop="productType">
            <el-select
              v-model="queryForm.productType"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in categorysList"
                :label="item.name"
                :value="item.categoryId"
                :key="item.prop"
              ></el-option>
            </el-select>
          </el-form-item> -->
        </div>
        <div class="search-item">
            <div class="item-label">创建时间：</div>
            <div class="item-value">
            <el-date-picker
              v-model="queryForm.createDate"
              size="small"
              style="width: 340px"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            </div>
        </div>
      </div>
      <div class="page-search-right">
          <!-- <imsButton typeClass="secondary"   @click="handlePrint" text="打印"/> -->
        <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="handleQuery"/>
        <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset('queryForm')" />
      </div>
          <!-- <el-button @click="handleReset('queryForm')">重置</el-button> -->
    </div>
    <div class="base-table">
      <el-table :data="orderList" v-loading="loading" border>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div v-if="item.prop === 'name'">
              <div
                v-for="(nameEle, nameIndex) in scope.row.name"
                :key="nameIndex"
              >
                {{ nameEle }}
              </div>
            </div>
            <div v-else-if="item.prop === 'category'">
              <div
                v-for="(cateEle, cateIndex) in scope.row.category"
                :key="cateIndex"
              >
                {{ cateEle }}
              </div>
            </div>
            <div v-else>
              {{ scope.row[item.prop] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="订单状态"
          prop="orderStatus"
          align="center"
          :formatter="
            (row, column, value) => {
              return {
                1: '待付款',
                2: '待发货',
                3: '待收货',
                5: '待评价',
                7: '已取消',
                9: '退款中',
                10: '已退款',
                11: '已完成',
                13: '申请退款中',
                14: '申请退款不通过',
                15: '拒收',
                16: '退款失败',
              }[value];
            }
          "
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <template>
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="handleView(scope.row.orderUid)"
                >查看</el-button
              >
              <el-button
                v-if="scope.row.orderStatus == 2"
                type="text"
                size="mini"
                icon="el-icon-printer"
                @click="handlePrint(scope.row.orderUid)"
                >打印</el-button
              >
              <el-popconfirm
                v-if="scope.row.orderStatus == 2"
                title="是否确认要发货？"
                style="margin-left: 10px"
                @confirm="handleConfirmAlert(scope.row.orderUid)"
              >
                <template #reference>
                  <el-button type="text" size="mini" icon="el-icon-receiving">
                    发货
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryForm.pageIndex"
        :limit.sync="queryForm.pageSize"
        @pagination="getTransactionListApi"
      />
    </div>

    <Detail
      ref="Detail"
      :dialog-title="dialogTitle"
      @handleRefresh="getTransactionListApi"
    />
  </div>
</div>
</template>

<script>
import {confirmDeliveryByOrderUid, getTransactionDetail, getTransactionList,} from "@/api/Order";
import {getCategorysList} from "@/api/Product";
import {BuyOrderStatus, getBuyOrderStatus} from "@/constant/status";
import {printOrder} from "@/utils";
import imsButton from "@/components/imsButton.vue";
import Detail from "./components/Detail.vue";

export default {
  name: "transactionList",
  components: {
    Detail,
    imsButton
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        productType: null,
        orderNo: "",
        produtName: "",
        orderType: 2,
        orderStatus: 2,
        createDate: [],
      },
      total: 0,
      orderList: [],
      categorysList: [],
      orderStatusList: [],
      dialogTitle: "订单明细",
      columns: [
        {
          label: "创建时间",
          prop: "createDate",
          width: "140",
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: "YYYY-MM-DD HH:mm:ss",
            });
          },
        },
        {
          label: "订单号",
          prop: "orderNo",
          width: "180",
        },
        {
          label: "商品类型",
          prop: "category",
          width: "170",
        },
        {
          label: "下单类型",
          prop: "placeOrderType",
          formatter: (row, column, value) => {
            if (value == 1) {
              return "销售";
            } else if (value == 2) {
              return "预订";
            } else {
              return "--";
            }
          },
        },
        {
          label: "收货方式",
          prop: "receivingType",
          formatter: (row, column, value) => {
            if (value == 11) {
              return "自提";
            } else if (value == 12) {
              return "自提";
            } else if (value == 21) {
              return "快递";
            } else {
              return "--";
            }
          },
        },
        {
          label: "商品名",
          prop: "name",
          width: "320",
        },
        // {
        //   label: '用户名',
        //   prop: 'userName'
        // },
        {
          label: "手机号",
          prop: "mobile",
        },
        {
          label: "打印次数",
          prop: "printNum",
        },
        // {
        //   label: '订单状态',
        //   prop: 'orderStatus',
        //   formatter: (row, column, value) => {
        //     return {
        //       1: '待付款',
        //       2: '待发货',
        //       3: '待收货',
        //       5: '待评价',
        //       7: '已取消',
        //       9: '退款中',
        //       10: '已退款',
        //       11: '已完成',
        //       13: '申请退款中'
        //     }[value]
        //   }
        // }
      ],
    };
  },
  filters: {
    filterFormatter: (value) => {
      return value;
    },
  },
  mounted() {
    this.getCategorysListApi();
    this.getTransactionListApi();
  },
  methods: {
    async getCategorysListApi() {
      this.orderStatusList = getBuyOrderStatus();
      let query = {};
      if (
        this.queryForm.placeOrderType != null &&
        this.queryForm.placeOrderType != undefined
      ) {
        query.categoryType = this.queryForm.placeOrderType;
      }
      this.queryForm.productType = null;
      this.categorysList = await getCategorysList(query);
    },
    async getTransactionListApi() {
      this.loading = true;
      if (this.queryForm.createDate != null) {
        this.queryForm.createDateStart = this.queryForm.createDate[0];
        this.queryForm.createDateEnd = this.queryForm.createDate[1];
      } else {
        this.queryForm.createDateStart = null;
        this.queryForm.createDateEnd = null;
      }
      const params = JSON.parse(JSON.stringify(this.queryForm));
      delete params.createDate;
      const { records, recordCount } = await getTransactionList(params);
      this.orderList = records;
      this.total = recordCount;
      this.orderList.forEach((item) => {
        item.orderStatusName = BuyOrderStatus[item.orderStatus];
        item.createDate = this.formatDay(item.createDate, {
          format: "YYYY-MM-DD HH:mm:ss",
        });
        item.placeOrderType =
          item.placeOrderType == 1
            ? "销售"
            : item.placeOrderType == 2
            ? "预订"
            : "--";
        item.receivingType =
          item.receivingType == 11
            ? "自提"
            : item.receivingType == 12
            ? "自提"
            : "配送";
        item.category = [];
        item.name = [];
        if (item.products && item.products.length) {
          item.products.forEach((it) => {
            item.category.push(`【${it.category}】`);
            item.name.push(`【${it.name}(${it.skuName})】`);
          });
          // item.category = item.products[0].category
          // item.name = item.products[0].name
        }
      });
      this.loading = false;
    },
    handleCreate() {
      this.dialogTitle = "添加分类";
      this.$refs.OperateDialog.visible = true;
      this.$refs.OperateDialog.reset();
    },
    async handleConfirmAlert(orderUid) {
      await confirmDeliveryByOrderUid({ orderUid });
      this.$message.success("已发货");
      this.getTransactionListApi();
    },
    handleQuery() {
      this.queryForm.pageIndex = 1;
      this.getTransactionListApi();
    },
    handleReset() {
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize' && key != 'orderType' && key != 'orderStatus'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
      this.$nextTick(() => {
      this.handleQuery();
        })     
      },
    // handleReset() {
    //   this.resetForm("queryForm");
    //   this.handleQuery();
    // },
    async handleView(orderUid) {
      this.$refs.Detail.setConfigInfo(orderUid);
      // this.$refs.Detail.visible = true
    },
    async handlePrint(orderUid) {
      const loading = this.$loading({
        text: "正在打印中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      try {
        const orderInfo = await getTransactionDetail({ orderUid });
        this.$store.dispatch("order/setOrderInfo", orderInfo);
        this.$nextTick(() => {
          printOrder(orderUid);
        });
        loading.close();
      } catch (error) {
        loading.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-search-left{
  min-width: 560px;
}
.page-search-right{
  width: 400px;
}
</style>
