<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    采购订单
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div class="page-search-left">
                    <div class="search-item">
                        <div class="item-label">采购订单号：</div>
                        <div class="item-value">
                            <el-input v-model="Query.no" clearable="" maxlength="99"></el-input>
                        </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">采购日期：</div>
                        <div class="item-value">
                            <el-date-picker v-model="Query.purchaseDateList" type="daterange" value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="page-search-right">
                    <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
                    <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
                </div>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column label="采购订单号" width="160" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{scope.row.no}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="计划名称" min-width="160" align="center"></el-table-column>
                <el-table-column label="合同签订状态" width="120" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.isSubmit ? '已签订' : '待签订' }}
                    </template>
                </el-table-column>
                <el-table-column label="采购日期" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.purchaseDate | dateformat() }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center">
                    <template slot-scope="scope">
                        <div class="link"
                            @click="$router.push(`/Purchase/Order/${!scope.row.isSubmit ? 'formPage' : 'detailPage'}?id=${scope.row.id}`)">
                            {{ !scope.row.isSubmit ? '编辑' : '查看' }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination
            v-show="pageQuery.total > 0"
            :total="pageQuery.total"
            :page.sync="pageQuery.pageIndex"
            :limit.sync="pageQuery.pageSize"
            @pagination="getData()"
          />
            <!-- <el-pagination  layout="total, prev, pager, next,jumper"
                :current-page="pageQuery.pageIndex" :total="pageQuery.total" :page-size="pageQuery.pageSize"
                @current-change="val => pageQuery.pageIndex = val">
            </el-pagination> -->
        </div>

    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import ImsNpmn from '@/components/imsNpmn.vue';
export default {
    components: {
        inputSelect,
        ImsNpmn
    },
    watch: {
        'Query.purchaseDateList': {
            handler(val) {
                if (val && val.length > 0) {
                    this.Query.startPurchaseDate = val[0]
                    this.Query.endPurchaseDate = val[1]
                } else {
                    this.Query.startPurchaseDate = null
                    this.Query.endPurchaseDate = null
                }
            },
            deep: true
        }
    },
    data() {
        return {
            loading: false,
            Query: {
                purchaseDateList: [],
                startPurchaseDate: null,
                endPurchaseDate: null
            },
            pageQuery:{
                pageIndex: 1,
                pageSize: 10,
            },
            tableData: [],
        }
    },
    methods: {
        getData(item) {
            this.loading = true
            if(item)
            this.pageQuery={
                ...this.pageQuery,
                pageIndex: 1,
            }
            this.$apis.pagePurchaseOrder({ ...this.Query, ...this.pageQuery, }).then(res => {
                this.tableData = res.body.records;
                this.pageQuery.total = Number(res.body.recordCount);
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData()
        })
    }
}
</script>