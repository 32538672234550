<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">银行账户</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>       
          <div class="search-item">
          <div class="item-label">银行卡号：</div>
          <div class="item-value">
            <el-input v-model="Query.accountNoLike" clearable maxlength="99"></el-input>
          </div>
          </div>
          <div class="search-item">
            <div class="item-label">开户行：</div>
            <div class="item-value">
              <el-input v-model="Query.bankOfDepositLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton
          style="float: right;margin-top: 4px;"
          text="查询"
          :loading="loading"
          @click="getData(1)"
          />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;margin-left: 10px;" @click="addData">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="accountNo" label="银行卡号" min-width="160" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="bankOfDeposit" label="开户行" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="openingBranch" label="开户网点" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="accountMobileNumber" label="开户手机号" min-width="160" align="center"></el-table-column>
        <el-table-column prop="enabled" label="使用状态" width="160" align="center">
          <template slot-scope="scope">
            {{ scope.row.enabled ? "启用":"禁用" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div class="link" @click="editData(scope.row)">编辑</div>
            <div
              class="link"
              style="color:rgba(217, 0, 17, 1)"
              @click=" disabled(scope.row)"
            >{{ !scope.row.enabled ? '启用' : '禁用' }}</div>
            <div class="link" @click="delData(scope.row)" v-if="!scope.row.enabled ">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.id ? '编辑' : '新增' }}银行账户</div>
      <div class="dialog-main">
        <div>
          <div class="search-item" data-required="accountNo">
            <div class="item-label">
              <span class="star">*</span>银行卡号：
            </div>
            <div class="item-value">
              <el-input v-model="Form.accountNo" clearable ref="accountNo"></el-input>
            </div>
          </div>
          <div class="search-item" data-required="bankOfDeposit">
            <div class="item-label">
              <span class="star">*</span>开户行：
            </div>
            <div class="item-value">
              <el-input v-model="Form.bankOfDeposit" clearable ref="bankOfDeposit"></el-input>
            </div>
          </div>
        </div>
        <div>
          <div class="search-item" data-required="openingBranch">
            <div class="item-label">
              <span class="star">*</span>开户网点：
            </div>
            <div class="item-value">
              <el-input v-model="Form.openingBranch" clearable ref="openingBranch"></el-input>
            </div>
          </div>
          <div class="search-item" data-required="accountMobileNumber">
            <div class="item-label">
              <span class="star">*</span>开户手机号：
            </div>
            <div class="item-value">
              <el-input v-model="Form.accountMobileNumber" clearable maxlength="11" ref="accountMobileNumber"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="确定" @click="saveForm(Form.id)" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import ImsNpmn from "@/components/imsNpmn.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    ImsNpmn
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      Query: {
        accountNoLike: null,
        createTime: null,
        status: null
      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      Form: {}
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageAccount({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 新增
    addData() {
      this.Form = {
        accountNo: "",
        bankOfDeposit: "",
        openingBranch: "",
        accountMobileNumber: ""
      };
      this.dialogVisible = true;
    },
    // 编辑
    editData(item) {
      this.Form = {
        ...item
      };
      this.dialogVisible = true;
    },
    disabled(scope) {
      this.$confirm(`您确定要${scope.enabled ? '禁用' : '启用'}银行卡号为【${scope.accountNo}】的数据吗？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
        showCancelButton: true,
        showConfirmButton: true
      })
        .then(() => {
          this.$apis.disabledAccount(scope.id).then(res => {
            this.getData();
          });
        })
        .catch(() => {});
    },
    delData(scope) {
      this.$confirm(
        `您确定要删除名称为【${scope.accountNo}】的数据吗？删除后不可恢复！`,
        "删除提示",
        {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
          showCancelButton: true,
          showConfirmButton: true
        }
      )
        .then(() => {
          let { uid } = scope
          this.$apis.deleAccount(uid).then(res => {
            this.getData();
          });
        })
        .catch(() => {});
    },
    saveForm(item) {
      console.log(item);
      var bool = this.checkForm(
        document.querySelectorAll("*[data-required]"),
        this.Form
      );
      if (!bool) return;
      this.loading = true;
      var apiMethod = item ? "editAccount" :  "addAccount"
      this.$apis
        [apiMethod](this.Form, this.Form.id)
        .then(res => {
          if (res.body) {
            this.loading = false;
            this.dialogVisible = false;
            this.getData()
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>