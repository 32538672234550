<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          充值订单列表
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">订单号：</div>
          <div class="item-value">
            <el-input
              v-model="queryForm.orderNo"
              placeholder="请输入订单号"
            ></el-input>       
          </div>
          </div>
          <div class="search-item">
            <div class="item-label">手机号码：</div>
            <div class="item-value">
              <el-input
                v-model="queryForm.mobile"
                placeholder="请输入手机号码"
                clearable
              ></el-input>       
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">订单状态：</div>
            <div class="item-value">
              <el-select
                v-model="queryForm.orderStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in orderStatusList"
                  :label="item.remark"
                  :value="item.id"
                  :key="item.prop"
                ></el-option>
              </el-select>           
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">创建时间：</div>
            <div class="item-value">
              <el-date-picker
                v-model="queryForm.createDate"
                size="small"
                style="width: 340px"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>           
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton
              style="float: right;"
              text="查询"
              :loading="loading"
              @click="handleQuery"
            />
            <imsButton typeClass="secondary" text="重置"  style="margin-right: 4px" @click="handleReset('queryForm')" />
        </div>
      </div>
      <div class="base-table">
        <el-table :data="orderList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="订单状态"
            prop="orderStatus"
            align="center"
            :formatter="
              (row, column, value) => {
                return {
                  1: '待付款',
                  11: '已完成',
                }[value];
              }
            "
          ></el-table-column>
          <!-- <el-table-column label="操作" align="center">
            <template #default="scope">
              <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleView(scope.row.orderUid)"
                  >查看</el-button
                >
              </template>
            </template>
          </el-table-column> -->
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getTransactionListApi"
        />
      </div>

      <Detail ref="Detail" :dialog-title="dialogTitle" />
    </div>
  </div>
</template>

<script>
import { getTransactionList } from "@/api/Order";
import { getCategorysList } from "@/api/Product";
import { getBuyOrderStatus, BuyOrderStatus } from "@/constant/status";
import imsButton from "@/components/imsButton.vue";
import Detail from "./components/Detail.vue";
export default {
  name: "rechargeList",
  components: {
    Detail,
    imsButton
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        mobile: "",
        orderType: 3,
        orderStatus: 11,
        createDate: [],
      },
      total: 0,
      orderStatusList: [
        {
          remark: "待付款",
          id: 1,
        },
        {
          remark: "已完成",
          id: 11,
        },
      ],
      orderList: [],
      dialogTitle: "订单明细",
      columns: [
        {
          label: "创建时间",
          prop: "createDate",
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: "YYYY-MM-DD HH:mm:ss",
            });
          },
        },
        {
          label: "订单号",
          prop: "orderNo",
        },
        {
          label: "用户名",
          prop: "userName",
        },
        {
          label: "手机号",
          prop: "mobile",
        },
        {
          label: "充值金额",
          prop: "salePrice",
        },
        {
          label: "赠送金额",
          prop: "givePrice",
        },
      ],
    };
  },
  filters: {
    filterFormatter: (value) => {
      console.log(value);
      return value;
    },
  },
  mounted() {
    this.getTransactionListApi();
  },
  methods: {
    async getTransactionListApi() {
      this.loading = true;
      if (this.queryForm.createDate != null) {
        this.queryForm.createDateStart = this.queryForm.createDate[0];
        this.queryForm.createDateEnd = this.queryForm.createDate[1];
      } else {
        this.queryForm.createDateStart = null;
        this.queryForm.createDateEnd = null;
      }
      const params = JSON.parse(JSON.stringify(this.queryForm));
      delete params.createDate;
      const { records, recordCount } = await getTransactionList(params);
      this.orderList = records;
      this.total = recordCount;
      this.orderList.forEach((item) => {
        if (item.rechargeCardInfos && item.rechargeCardInfos.length) {
          item.salePrice = item.rechargeCardInfos[0].salePrice;
          item.givePrice = item.rechargeCardInfos[0].givePrice;
        }
      });
      this.loading = false;
    },
    handleCreate() {
      this.dialogTitle = "添加分类";
      this.$refs.OperateDialog.visible = true;
      this.$refs.OperateDialog.reset();
    },
    handleQuery() {
      this.queryForm.pageIndex = 1;
      this.getTransactionListApi();
    },
    handleReset() {
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
      this.getTransactionListApi();
    },
    async handleView(orderUid) {
      this.$refs.Detail.setConfigInfo(orderUid);
      // this.$refs.Detail.visible = true
    },
  },
};
</script>

<style lang="less" scoped>
.page-search-right{
  width: 250px;
}
</style>
