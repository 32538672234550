<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">采购入库</div>
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-title">
        <span class="fa fa-border-style"></span>
        基本信息
      </div>
      <div class="form-body">
        <div class="search-item">
          <div class="item-label bold">采购入库单号：</div>
          <div class="item-value">
            {{ Form.no }}
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">
            <span class="star" v-if="$route.query.status!= 2">*</span>入库日期：
          </div>
          <div class="item-value">
            <el-date-picker v-model="Form.inboundDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
              ref="inboundDate" :disabled="$route.query.status== 2"></el-date-picker>
          </div>
        </div>
      </div>
      <div class="form-title" style="margin-top: 20px;">
        <span class="fa fa-border-style"></span>
        入库清单
      </div>
      <el-table :data="Form.stockInboundListingCollection" border style="width: 100%;margin-top: 15px;">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="产品名称" min-width="160" align="center"></el-table-column>
        <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
        <el-table-column prop="unitName" label="计量单位" width="80" align="center"></el-table-column>
        <el-table-column prop="attributeTypeName" label="产品属性" width="120" align="center"></el-table-column>
        <el-table-column prop="supplier.name" label="供应商" min-width="120" align="center">
        </el-table-column>
        <el-table-column prop="drugApprovalNo" label="批准文号" width="100" align="center"></el-table-column>
        <!-- <el-table-column prop="formulation" label="剂型" width="120" align="center"></el-table-column>
        <el-table-column prop="cryodose" label="冻量" width="120" align="center"></el-table-column> -->
        <el-table-column prop="stockStorageQuantity" label="库存数量" min-width="80" align="center"></el-table-column>
        <el-table-column min-width="200" align="center">
          <template slot="header">
            <span class="star" v-if="$route.query.status== 0">*</span>库位
          </template>
          <template slot-scope="scope">
            <el-select v-if="!readonly" v-model="scope.row.warehouseLocationId" filterable placeholder="请选择"
              :id="'warehouseLocationId' + scope.$index">
              <el-option v-for="item in optionsKW" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <div class="cell-div" v-else>{{ scope.row.warehouseLocation.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="入库数量" min-width="100" align="center"></el-table-column>
        <el-table-column prop="price" label="入库单价" min-width="100" align="center"></el-table-column>
        <el-table-column min-width="200" align="center">
          <template slot="header">
            <span class="star" v-if="$route.query.status== 0">*</span>有效期
          </template>
          <template slot-scope="scope">
            <el-date-picker v-if="!readonly" v-model="scope.row.expiryDate" :id="'expiryDate' + scope.$index" type="date"
              placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            <div class="cell-div" v-else>{{ scope.row.expiryDate | dateformat() }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="200" align="center">
          <template slot="header">
            <span class="star"  v-if="$route.query.status== 0">*</span>生产批号
          </template>
          <template slot-scope="scope">
            <el-input v-if="!readonly" v-model="scope.row.batchNo" maxlength="32" clearable
              :id="'batchNo' + scope.$index"></el-input>
            <div class="cell-div" v-else>{{
              scope.row.batchNo }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push('/Stock/Into')" style="margin-right:10px" />
        <imsButton typeClass="primary" text="保存" :loading="loading" @click="saveForm()" v-if="!readonly" />
        <imsButton typeClass="primary" text="确认记账" :loading="loading" @click="confirmForm(2)" v-if="Form.status == 1"
          style="margin-right:10px" />
        <imsButton typeClass="secondary" text="返回修改" :loading="loading" @click="confirmForm(0)" v-if="Form.status == 1" />
      </div>
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";
import npmnDialog from "@/components/npmnDialog.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber,
    npmnDialog
  },
  watch: {},
  data() {
    return {
      loading: false,
      readonly: false,
      Form: {
        stockInboundListingCollection: []
      },
      dialogVisible: false,
      Query: {
        nameLike: null,
        data: null
      },
      tableData: [],
      optionsKW: [],
    };
  },
  methods: {
    // 记账通过or不通过
    confirmForm(status) {
      this.loading = true;
      this.$apis
        .confirmPurchaseInbound({ status: status }, this.Form.id)
        .then(res => {
          if (res.body) {
            this.$message.success("操作成功！自动返回列表页");
          }
          this.loading = false;
          this.$router.push("/Stock/Into");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取详情数据
    getDetail() {
      this.loading = true;
      this.$apis
        .getPurchaseInbound(this.Form.id)
        .then(res => {
          if (res.body) {
            this.Form = {
              ...this.Form,
              ...res.body,
              stockInboundListingCollection: res.body.stockInboundListingCollection.map(item => {
                return {
                  ...item,
                  warehouseLocationId: item.warehouseLocation.id
                }
              })
            }
            if (this.Form.status == 1 || this.Form.status == 2) {
              this.readonly = true;
            }
            this.$router.push({
              name: this.$route.name,
              query: {
                ...this.$route.query,
                status: this.Form.status
              }
            })
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 保存
    saveForm() {
      var bool = true
      if (!this.Form.inboundDate) {
        this.$message.warning('请选择“入库日期”')
        this.$refs.inboundDate.focus()
        return
      }
      if (this.Form.stockInboundListingCollection.length == 0) {
        this.$message.warning('请选择“产品”')
        return
      } else {
        this.Form.stockInboundListingCollection.forEach((item, index) => {
          if (bool && !item.warehouseLocationId) {
            this.$message.warning(`第${index + 1}行未选择“库位”`)
            document.getElementById('warehouseLocationId' + index).focus()
            bool = false
          }
          if (bool && !item.expiryDate) {
            this.$message.warning(`第${index + 1}行未选择“有效期”`)
            document.getElementById('expiryDate' + index).focus()
            bool = false
          }
          if (bool && !item.batchNo) {
            this.$message.warning(`第${index + 1}行未填写“生产批号”`)
            document.getElementById('batchNo' + index).focus()
            bool = false
          }
        })
      }
      if (!bool) return
      this.loading = true;
      this.$apis
        .savePurchaseInbound({ 
          ...this.Form,
          stockInboundListingCollection: this.Form.stockInboundListingCollection.map(item => {
            return {
              ...item,
              stockKeepingUnit: `${item.productId}:${item.batchNo}:${item.warehouseLocationId}`
            }
          })
         }, this.Form.id)
        .then(res => {
          if (res.body) {
            this.$message.success("保存成功！自动返回列表页");
          }
          this.loading = false;
          this.$router.push("/Stock/Into");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.Form.id = this.$route.query.id;
      this.getDetail()
    }
    this.tableData = [];
    this.getOptionsKW();
  }
};
</script>