<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          日报表
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">年月日：</div>
            <div class="item-value">
              <el-date-picker
                v-model="queryForm.createDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="请选择"
                :clearable="false"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin: 4px;" text="查询" :loading="loading" @click="handleQuery" />
          <imsButton typeClass="secondary" text="重置"  style="float: right; margin: 4px" @click="handleReset('queryForm')" />
          <imsButton typeClass="secondary" text="导出" style="float: right; margin: 4px"  @click="handleExport"/>
        </div>

      </div>
      <el-row :gutter="12" style="margin-top:20px;">
        <el-col :span="5">
          <el-card shadow="hover">
            {{ otherData.day }}日累计充值金额：
            <br />
            {{ summaryAmount.recharge }} 元
          </el-card>
        </el-col>
        <el-col :span="5">
          <el-card shadow="hover">
            {{ otherData.day }}日微信收入金额：
            <br />
            {{ summaryAmount.wechatIncomeSale }} 元
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            {{ otherData.day }}日销售总额：
            <br />
            {{ summaryAmount.sale }} 元
          </el-card>
        </el-col>
        <el-col :span="5">
          <el-card shadow="hover">
            {{ otherData.day }}日销售微信收入额：
            <br />
            {{ summaryAmount.wechatSale }} 元
          </el-card>
        </el-col>
        <el-col :span="5">
          <el-card shadow="hover">
            {{ otherData.day }}日销售余额收入额：
            <br />
            {{ summaryAmount.walletSale }} 元
          </el-card>
        </el-col>
      </el-row>
      <div class="base-table">
        <el-table :data="flowList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
            <template v-if="item.prop === 'products'" #default="scope">
              <div>
                <div v-if="scope.row.orderType !== 3">
                  <div v-for="(it, idx) in scope.row.products" :key="idx">
                    <span>{{ `【${it.name}(${it.skuName})】` }}</span>
                  </div>
                </div>
                <div v-else>{{ scope.row.productName }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFinanceList,
  getFinanceSummaryMonth,
  getFinanceExportGenerateDownload
} from '@/api/Finance'
import { getCurrentMonthFirst, getCurrentMonthLast } from '@/utils/day'
export default {
  name: 'dayReport',
  data() {
    return {
      loading: false,
      queryForm: {
        createDate: ''
      },
      otherData: {},
      flowList: [],
      summaryAmount: {},
      columns: [
        {
          label: '订单时间',
          prop: 'createDate',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD hh:mm:ss'
            })
          }
        },
        {
          label: '订单号',
          prop: 'orderNo',
          width: '180'
        },
        {
          label: '商品名称',
          prop: 'products',
          width: '240'
        },
        {
          label: '手机号码',
          prop: 'mobile'
        },
        {
          label: '支付方式',
          prop: 'paymentType',
          formatter: (row, column, value) => {
            var str = ''
            switch (value) {
              case 1:
                str = '微信'
                break
              case 2:
                str = '支付宝'
                break
              case 3:
                str = '钱包'
                break
              case 4:
                str = '线下'
                break
              default:
                str = ''
            }
            return str
          }
        },
        {
          label: '金额',
          prop: 'amount',
          formatter: (row, column, value) => {
            return '￥' + value
          }
        }
      ]
    }
  },
  mounted() {
    this.initPage()
    this.getFinanceListApi()
  },
  methods: {
    initPage() {
      this.queryForm.createDate = this.formatDay(new Date(), {
        format: 'YYYY-MM-DD'
      })
    },
    async getFinanceListApi() {
      this.loading = true
      var date = new Date()
      if (this.queryForm.createDate != '') {
        date = new Date(this.queryForm.createDate)
      }
      this.otherData.day = date.getDate()
      this.queryForm.createDateStart = this.queryForm.createDate
      this.queryForm.createDateEnd = this.queryForm.createDate
      const params = JSON.parse(JSON.stringify(this.queryForm))
      this.flowList = await getFinanceList(params)
      this.loading = false

      this.summaryAmount = await getFinanceSummaryMonth(params)
    },
    handleQuery() {
      this.getFinanceListApi()
    },
    handleReset() {
      this.resetForm('queryForm')
      this.initPage()
      this.getFinanceListApi()
    },
    async handleExport() {
      var date = new Date()
      if (this.queryForm.createDate != '') {
        date = new Date(this.queryForm.createDate)
      }

      this.otherData.day = date.getDate()
      this.queryForm.createDateStart = getCurrentMonthFirst(date)
      this.queryForm.createDateEnd = getCurrentMonthLast(date)
      const params = JSON.parse(JSON.stringify(this.queryForm))
      const excelData = await getFinanceExportGenerateDownload(params)

      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(excelData) // 创建下载的链接
      downloadElement.href = href
      downloadElement.download = '月度报表.xlsx' // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
    }
  }
}
</script>

<style lang="less" scoped></style>
