<template>
  <div class="ims-button" :class="typeClass" @click="handleClick()">
    {{loading ? loadingText : text}}
  </div>
</template>

<script>
export default {
    props: {
        typeClass: { // 主按钮primary\次按钮secondary
            type: String,
            default: 'primary'
        },
        text: { // 按钮文字
            type: String,
            default: '按钮'
        },
        loading: { // 按钮防抖控制
            type: Boolean,
            default: false
        },
        loadingText: { // loading文字
            type: String,
            default: 'loading...'
        },
    },
    methods: {
        handleClick() {
            if (this.loading) return
            this.$emit('click')
        }
    }
}
</script>

<style lang="less">
.ims-button {
    height: 35px;
    line-height: 35px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    min-width: 80px;
    font-size: 14px;
    border-radius: 2px;
    &.primary {
        background-color: @basicColor;
        color: rgba(255, 255, 255, 1);
        &:hover {
            color: rgba(255, 255, 255, 1);
            font-weight: bold;
        }
    }
    &.secondary {
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, .8);
        border: 1px solid @color4;
        &:hover {
            color: rgba(0, 0, 0, 1);
            font-weight: bold;
        }
    }
    &.active {
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, .8);
        border: 1px solid @basicColor;
        &:hover {
            color: rgba(0, 0, 0, 1);
            font-weight: bold;
        }
    }
}
</style>