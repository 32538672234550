import request_artisan from '@/utils/request_artisan'

// 获取新人礼包
export function getMarketing() {
  return request_artisan({
    url: '/v1/MarketingEvent/NewGift',
    method: 'get'
  })
}

// 保存
export function saveMarketing(data) {
  return request_artisan({
    url: '/v1/MarketingEvent/NewGift/Save',
    method: 'post',
    data: data
  })
}

// 推荐列表
export function getRecommendList(data) {
  return request_artisan({
    url: '/v1/Recommend/List',
    method: 'get',
    data: data
  })
}

// 添加推荐
export function addRecommend(data) {
  return request_artisan({
    url: '/v1/Recommend',
    method: 'post',
    data: data
  })
}

// 删除推荐
export function deleteRecommendById(data) {
  return request_artisan({
    url: '/v1/Recommend/' + data.recommendId,
    method: 'delete'
  })
}

// 拖拽推荐的位置
export function dragRecommend(data) {
  return request_artisan({
    url: '/v1/Recommend/Sort',
    method: 'put',
    data
  })
}

// 事件配图
export function eventUpload(data) {
  return request_artisan({
    url: '/v1/File/public/other',
    method: 'post',
    data
  })
}
