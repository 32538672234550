<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="名称:" prop="name">
            <el-input v-model="form.name" placeholder="请输入间隙名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="显示权限:" prop="showType">
            <el-select v-model="form.showType">
              <el-option value="1" label="全部"></el-option>
              <el-option value="2" label="会员"></el-option>
              <el-option value="3" label="非会员"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="间隙选择:">
        <el-radio-group v-model="crevice">
          <el-radio :label="10" class="radio__item">
            <div class="box">
              <span class="crevice-routine"></span>
              <span>常规间隙 10px</span>
            </div>
          </el-radio>
          <el-radio :label="30" class="radio__item">
            <div class="box">
              <span class="middle-routine"></span>
              <span>中等间隙 30px</span>
            </div>
          </el-radio>
          <el-radio :label="60" class="radio__item">
            <div class="box">
              <span class="big-routine"></span>
              <span>大间隙 60px</span>
            </div>
          </el-radio>
          <el-radio :label="0" class="radio__item">
            <el-input v-model="custom">
              <template slot="append">px</template>
            </el-input>
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { getConfig, addConfig, updateConfig } from '@/api/Decorate'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    categoryInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    // Upload
  },
  data() {
    return {
      configId: null,
      crevice: '',
      custom: '',
      visible: false,
      loading: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.form = {
        name: '',
        typeConfig: '300',
        type: '3',
        showType: '1',
        contentList: [
          {
            height: 0
          }
        ]
      }
      this.crevice = ''
      this.custom = ''
      this.resetForm('dialogForm')
    },
    // 获取编辑信息
    async getData(id) {
      this.reset()
      this.configId = id
      if (id) {
        const { contentList, name, showType, typeConfig } = await getConfig({
          id
        })
        this.form.name = name
        this.form.showType = showType
        this.form.typeConfig = typeConfig
        this.form.contentList = contentList
        if ([10, 30, 60].includes(contentList[0] && contentList[0].height)) {
          this.crevice = contentList[0] && contentList[0].height
        } else {
          this.crevice = 0
          this.custom = contentList[0] && contentList[0].height
        }
      }
      this.visible = true
    },
    handleSubmit() {
      // console.log(this.form)
      // console.log(this.crevice)
      // console.log(this.custom)
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.form))
          const height = this.crevice ? this.crevice : Number(this.custom)
          params.contentList[0].height = height
          params.typeConfigNum = 1
          this.loading = true
          try {
            if (this.configId) {
              params.configId = this.configId
              await updateConfig(params)
              this.$message.success('更新成功')
            } else {
              await addConfig(params)
              this.$message.success('添加成功')
            }
            this.loading = false
            this.$emit('updateList')
            this.close()
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.radio__item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .box {
    display: flex;
    align-items: center;
  }
}
.crevice-routine,
.middle-routine,
.big-routine {
  display: inline-block;
  width: 90px;
  height: 10px;
  margin-right: 20px;
  background: #666;
}
.middle-routine {
  height: 30px;
}
.big-routine {
  height: 60px;
}
</style>
