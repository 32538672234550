import axios from 'axios'
import Cookies from 'js-cookie'
import store from '@/store'
import Router from 'vue-router'
import { getToken, removeToken } from '@/utils/auth'
import { Message, MessageBox } from 'element-ui'
import { showLoading, hideLoading } from '@/utils/loading'

const route = new Router()


function handleLogin() {
  MessageBox.alert('', '登录失效', {
    confirmButtonText: '跳转登录页面',
    showClose: false,
    callback: async () => {
      window.sessionStorage.setItem("ims_token", "");
      window.location.href = "/#/Login"
    }
  })
}

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
  timeout: 60000
})

var token = 'tubsoft '+ sessionStorage.getItem("ims_token");
service.interceptors.request.use(
  config => {
    config.needLoding && showLoading()
    token ='tubsoft '+ sessionStorage.getItem("ims_token");
    if (token) {
      config.headers = {
        Authorization:token
      }
    }
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json'
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.config.url === '/v1/Finance/Export/GenerateDownload') {
      return Promise.resolve(response.data)
    } else {
      const { body, notification, statusCode } = response.data
      if (statusCode === 2000) {
        return Promise.resolve(body)
      } else {
        if (notification.indexOf('需要用户验证') > -1) {
          handleLogin()
        } else {
          Message({
            message: notification,
            type: 'error'
          })
        }
        return Promise.reject(notification)
      }
    }
  },
  error => {
    console.log(error)
    if (error.response && error.response.status === 401) {
      handleLogin()
    }
    if (error.message && error.message.includes('timeout')) {
      Message({
        message: '请求超时，请检查网络是否连接',
        type: 'error',
        duration: 5000
      })
    }
    hideLoading()
    return Promise.reject(error)
  }
)

export default service
