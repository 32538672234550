<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          banner
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">广告名称：</div>
            <div class="item-value">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入广告名称"
              ></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">广告状态：</div>
            <div class="item-value">
              <el-select v-model="queryForm.status">
                <el-option :value="1" label="状态1"></el-option>
                <el-option :value="2" label="状态2"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">录入时间范围：</div>
            <div class="item-value">
              <el-date-picker
                v-model="queryForm.createDate"
                size="small"
                style="width: 340px;"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin: 4px;" text="查询" :loading="loading" @click="handleQuery" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="base-table">
      
        <div class="table-btns">
          <span class="link" style="float: right;" @click="handleCreate"
            >添加广告</span>
        </div>
        <el-table :data="userList" v-loading="loading" border>
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
            <template v-if="item.prop === 'imageUrl'" #default="scope">
              <img class="list-image m-6" :src="scope.row.imageUrl" alt="" width="100"/>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="260">
            <template #default="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row.bannerId)"
                >编辑</el-button
              >
              <el-popconfirm
                :title="
                  scope.row.status === 2
                    ? '是否确认停用该广告？'
                    : '是否确认发布该广告？'
                "
                style="margin-left: 10px;"
                @confirm="handleStatus(scope.row)"
              >
                <template #reference>
                  <el-button type="warning" size="mini" icon="el-icon-error">{{
                    scope.row.status === 2 ? '停用' : '发布'
                  }}</el-button>
                </template>
              </el-popconfirm>

              <el-popconfirm
                title="是否确认删除？"
                style="margin-left: 10px;"
                @confirm="handleDelete(scope.row.bannerId)"
              >
                <template #reference>
                  <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getBannerListApi"
        />
      </div>
      <OperateDialog
        ref="operateDialog"
        :dialog-title="dialogTitle"
        @updateList="getBannerListApi"
      />
    </div>
  </div>
</template>

<script>
import { getBannerList, updateBannerStatus, deleteBanner } from '@/api/Decorate'
import OperateDialog from './components/OperateDialog'
export default {
  name: 'bannerList',
  components: {
    OperateDialog
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        createDate: []
      },
      total: 0,
      userList: [],
      dialogTitle: '广告新增',
      columns: [
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '图片',
          prop: 'imageUrl',
          width: 120
        },
        {
          label: '位置',
          prop: 'type',
          formatter: (row, column, value) => {
            return {
              1: '顶部banner',
              2: '中部会员卡',
              3: '商品详情广告',
              4: '续租页广告',
              5: '我的足迹页广告'
            }[value]
          }
        },
        {
          label: '展示',
          prop: 'showType',
          formatter: (row, column, value) => {
            return {
              1: '全部可见',
              2: '仅会员可见',
              3: '仅非会员可见'
            }[value]
          }
        },
        {
          label: '排序',
          prop: 'sort'
        },
        {
          label: '有效期开始',
          prop: 'effectiveStartTime',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD'
            })
          }
        },
        {
          label: '有效期结束',
          prop: 'effectiveEndTime',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD'
            })
          }
        },
        {
          label: '状态',
          prop: 'status',
          formatter: (row, column, value) => {
            return {
              1: '未发布',
              2: '已发布',
              3: '已下架'
            }[value]
          }
        }
      ]
    }
  },
  mounted() {
    this.getBannerListApi()
  },
  methods: {
    async getBannerListApi() {
      this.loading = true
      this.queryForm.effectiveStartTime = this.queryForm.createDate[0]
      this.queryForm.effectiveEndTime = this.queryForm.createDate[1]
      const params = JSON.parse(JSON.stringify(this.queryForm))
      delete params.createDate
      const { records, recordCount } = await getBannerList(params)
      this.userList = records
      this.total = recordCount
      this.loading = false
    },
    async handleStatus(row) {
      await updateBannerStatus(row)
      this.getBannerListApi()
    },
    handleEdit(id) {
      this.dialogTitle = '广告编辑'
      this.$refs.operateDialog.getData(id)
    },
    async handleDelete(id) {
      await deleteBanner({ id })
      this.getBannerListApi()
    },
    handleCreate() {
      this.dialogTitle = '广告新增'
      this.$refs.operateDialog.getData()
      // this.$refs.OperateDialog.reset()
    },
    handleQuery() {
      this.queryForm.pageIndex = 1
      this.getBannerListApi()
    },
    handleReset() {
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
      this.$nextTick(()=>{
        this.getBannerListApi()
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
