<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="30%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form
      ref="dialogForm"
      :model="categoryForm"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="名称:" prop="name">
        <el-input v-model="categoryForm.name" placeholder="例如：护具" />
      </el-form-item>
      <el-form-item label="类型:" prop="categoryType">
        <el-select v-model="categoryForm.categoryType">
          <el-option :value="1" label="销售"></el-option>
          <el-option :value="2" label="预订"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input-number
          v-model="categoryForm.sort"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <!-- <el-form-item label="图标:" prop="fileList">
        <Upload :file-list.sync="categoryForm.fileList" />
      </el-form-item> -->
      <el-form-item label="备注:" prop="description">
        <el-input
          v-model="categoryForm.description"
          :rows="2"
          type="textarea"
          placeholder="说点什么..."
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import {saveCategory, updateCategory} from "@/api/Product";
import Upload from "@/components/Upload";

export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    categoryInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Upload,
  },
  data() {
    return {
      visible: false,
      loading: false,
      categoryForm: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        categoryType: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        // description: [
        //   {
        //     required: false,
        //     message: '请输入备注信息',
        //     trigger: 'blur'
        //   },
        //   {
        //     trigger: 'blur',
        //     validator: (rule, value, callback) => {
        //       console.log(value)
        //       if (value.length < 10) {
        //         callback(new Error('备注:最少输入10个字符'))
        //       } else {
        //         callback()
        //       }
        //     }
        //   }
        // ],
        fileList: [
          {
            required: true,
            message: "请至少上传一张图片",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.reset();
    },
    // 重置表单
    reset() {
      this.categoryForm = {
        name: "",
        categoryId: null,
        fileList: [],
        icon: "",
        description: "",
      };
      this.resetForm("dialogForm");
    },
    // 获取编辑信息
    getEditInfo(categoryInfo) {
      this.categoryForm = categoryInfo;
      this.visible = true;
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            if(this.categoryForm.fileList[0]){
              this.categoryForm.icon = this.categoryForm.fileList[0].url;
            }
            const params = JSON.parse(JSON.stringify(this.categoryForm));
            delete params.fileList;
            if (this.dialogTitle === "添加分类") {
              params.categoryId = null;
              await saveCategory(params);
            } else {
              await updateCategory(params);
            }
            this.loading = false;
            this.close();
            this.$emit("updateList");
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
