<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">姓名：</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="addData()">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="userAuthClassic.account" label="登录账户" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="email" label="Email" align="center"></el-table-column> -->
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="lastLoginTime" label="最后登录时间" align="center">
          <template slot-scope="scope">{{ scope.row.lastLoginTime | dateformat() }}</template>
        </el-table-column>
        <el-table-column prop="enabled" label="状态" width="80" align="center">
          <template slot-scope="scope"><el-tag effect="dark" :type="scope.row.isLock ? 'success' : 'warning'">{{
            scope.row.isLock | enabledText }}</el-tag></template>
        </el-table-column>
        <el-table-column prop="roleNames" label="角色" align="center">
          <template slot-scope="scope">
            <el-tag v-for="item in scope.row.roles" :key="item.id" :type="'primary'" class="tag-primary">{{ item.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button @click="editData(scope.row)" type="text" class="edit">编辑</el-button>
            <el-button type="text" @click="reset(scope.row)" class="edit">重置密码</el-button>
            <el-button type="text" @click="toggleEnabled(scope.row)" class="edit">{{ scope.row.isLock | operateEnableText
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.userUid ? '编辑' : '新增' }}用户</div>
      <div class="dialog-main">
        <div class="search-item" data-required="name">
          <div class="item-label">
            <span class="star">*</span>姓名：
          </div>
          <div class="item-value">
            <el-input v-model="Form.name" clearable maxlength="30" ref="name"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>
            手机号：
          </div>
          <div class="item-value">
            <el-input v-model="Form.mobile" clearable maxlength="11" ref="mobile"></el-input>
          </div>
        </div>
        <!-- <div class="search-item">
          <div class="item-label">
            Email：
          </div>
          <div class="item-value">
            <el-input v-model="Form.email" name="email" clearable ref="email" maxlength="64"></el-input>
          </div>
        </div> -->
        <div v-if="!Form.userUid" class="search-item" data-required="account">
          <div class="item-label">
            <span class="star">*</span>登录账户：
          </div>
          <div class="item-value">
            <el-input v-model="Form.userAuthClassic.account" clearable maxlength="30" ref="userAuthClassic.account"></el-input>
          </div>
        </div>
        <div v-if="!Form.userUid" class="search-item" data-required="password">
          <div class="item-label">
            <span class="star">*</span>密码：
          </div>
          <div class="item-value">
            <el-input v-model="Form.userAuthClassic.password" clearable maxlength="24" ref="userAuthClassic.password"
              show-password></el-input>
          </div>
        </div>
        <div v-if="!Form.userUid" class="search-item" data-required="confirmpPassword">
          <div class="item-label">
            <span class="star">*</span>确认密码：
          </div>
          <div class="item-value">
            <el-input v-model="Form.confirmpPassword" clearable maxlength="24" ref="confirmpPassword"
              show-password></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>角色：
          </div>
          <div class="item-value">
            <el-select v-model="Form.roleIds" multiple collapse-tags filterable placeholder="请选择" ref="roleIds">
              <el-option v-for="item in roleOptions" :key="item.id" :label="item.text" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="确定" @click="saveForm()" :loading="loading" />
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogReset" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">您正在重置[{{ resetForm.name }}]的登录密码</div>
      <div class="dialog-main">
        <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>新密码：
          </div>
          <div class="item-value">
            <el-input v-model="resetForm.password" clearable maxlength="24" ref="resetForm.password"
              show-password></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>确认新密码：
          </div>
          <div class="item-value">
            <el-input v-model="resetForm.confirmpPassword" clearable maxlength="24" ref="resetForm.confirmpPassword"
              show-password></el-input>
          </div>
        </div>
        <!-- <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>您的密码：
          </div>
          <div class="item-value">
            <el-input v-model="resetForm.myPassword" clearable maxlength="24" ref="resetForm.myPassword"
              show-password></el-input>
          </div>
        </div> -->
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogReset = false" />
        <imsButton text="确定" @click="saveResetForm()" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect
  },
  mixins: [mixins],
  data() {
    return {
      roleOptions: [],
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      loading: false,
      dialogVisible: false,
      Query: {

      },
      Form: {
        userAuthClassic: {
          account: "",
        },
        roleIds: []
      },
      dialogReset: false,
      resetForm: {}
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      this.$apis
        .pageUser({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 编辑
    editData(item) {
      this.Form = {
        ...item,
        roleIds: item.roles.map(ele => ele.id)
      };
      this.dialogVisible = true;
    },
    // 新增
    addData() {
      this.Form = {
        uid: null,
        userAuthClassic: {
        },
        roleIds: []
      };
      this.dialogVisible = true;
    },
    // 切换启/停用状态接口
    toggleUser(item) {
      this.$apis
        .toggleUser(item.userUid)
        .then(res => {
          if (res.body) {
            this.$message.success("状态成功切换！");
            this.getData();
          }
        })
        .catch(() => {
        });
    },
    // 切换启/停用状态
    toggleEnabled(item) {
      if (item.isLock) {
        this.$confirm("您确定要禁用" + item.userAuthClassic.account + "用户吗?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning"
        }).then(() => {
          this.toggleUser(item);
        }).catch(()=>{})
      } else {
        this.$confirm("您确定启用" + item.userAuthClassic.account + "用户吗?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning"
        }).then(() => {
          this.toggleUser(item);
        }).catch(()=>{})
      }
    },
    // 重置
    reset(item) {
      this.resetForm = {
        // id: item.id,
        userUid:item.userUid,
        uid: this.randomString(32),
        name: item.name,
        account:item.userAuthClassic.account
      }
      this.dialogReset = true
    },
    saveResetForm() {
      if (!this.resetForm.password) {
        this.$message.warning('请输入“新密码”')
        this.$refs['resetForm.password'].focus()
        return
      }
      if (!this.resetForm.confirmpPassword) {
        this.$message.warning('请输入“确认新密码”')
        this.$refs['resetForm.confirmpPassword'].focus()
        return
      }
      if (this.resetForm.password != this.resetForm.confirmpPassword) {
        this.$message.warning('“确认新密码”与“新密码”需保持一致')
        this.$refs['resetForm.confirmpPassword'].focus()
        return
      }
      // if (!this.resetForm.myPassword) {
      //   this.$message.warning('请输入“您的密码”')
      //   this.$refs['resetForm.myPassword'].focus()
      //   return
      // }
      this.loading = true
      this.$apis
        .resetPwd({
          uid: this.resetForm.uid,
          account: this.resetForm.account,
          password: this.encrypt(this.resetForm.password, this.resetForm.uid),
          // myPassword: this.encrypt(this.resetForm.myPassword, this.resetForm.userUid),
        }, this.resetForm.userUid)
        .then(res => {
          if (res.body) {
            this.$message.success("重置密码成功！");
            this.dialogReset = false
          }
        }).catch(() => {
          this.loading = false
        })
    },
    saveForm() {
      if (!this.Form.name) {
        this.$message.warning('请输入“姓名”')
        this.$refs['name'].focus()
        return
      }
      if (!this.Form.mobile) {
        this.$message.warning('请输入“手机号”')
        this.$refs['name'].focus()
        return
      }
      if (this.Form.mobile && !this.regPhone.test(this.Form.mobile)) {
        this.$message.warning('“手机号”格式不正确，正确格式应为13800138001！')
        this.$refs.mobile.focus()
        return
      }
      // if (this.Form.email && !this.regEmail.test(this.Form.email)) {
      //   this.$message.warning('“Email”格式不正确，正确格式应为1234@qq.com！')
      //   this.$refs.email.focus()
      //   return
      // }
      if (!this.Form.userUid) {
        if (!this.Form.userAuthClassic.account) {
          this.$message.warning('请输入“登录账户”')
          this.$refs['userAuthClassic.account'].focus()
          return
        }
        if (!this.Form.userAuthClassic.password) {
          this.$message.warning('请输入“密码”')
          this.$refs['userAuthClassic.password'].focus()
          return
        }
        if (!this.Form.confirmpPassword) {
          this.$message.warning('请输入“确认密码”')
          this.$refs['confirmpPassword'].focus()
          return
        }
        if (this.Form.userAuthClassic.password != this.Form.confirmpPassword) {
          this.$message.warning('“确认密码”与“密码”需保持一致')
          this.$refs['confirmpPassword'].focus()
          return
        }
      }
      if (this.Form.roleIds.length == 0) {
        this.$message.warning('请选择“角色”')
        this.$refs['roleIds'].focus()
        return
      }
      this.loading = true;
      if (this.Form.userUid) {
        // 编辑
        this.$apis
          .editUser({
            ...this.Form,
            roles: this.Form.roleIds.map(item => {
              return {
                id: item
              }
            })
          }, this.Form.userUid)
          .then(res => {
            if (res.body) {
              this.$message.success("编辑成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        var uid = this.randomString(32)
        this.$apis
          .addUser({
            ...this.Form,
            userAuthClassic: {
              ...this.Form.userAuthClassic,
              uid: uid,
              password: this.encrypt(this.Form.userAuthClassic.password, uid),
            },
            confirmpPassword: null,
            roles: this.Form.roleIds.map(item => {
              return {
                id: item
              }
            })
          })
          .then(res => {
            if (res.body) {
              this.$message.success("添加成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
    this.$apis
      .pageRole({ pageSize: 999, enabled: true })
      .then(res => {
        this.roleOptions = res.body.records;
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>