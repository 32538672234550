<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ Form.id ? '编辑仓库' : '新增仓库' }}
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div class="search-item">
                    <div class="item-label"><span class="star">*</span>仓库名称：</div>
                    <div class="item-value">
                        <el-input v-model="Form.name" clearable="" maxlength="99" ref="name"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label"><span class="star">*</span>仓库编码：</div>
                    <div class="item-value">
                        <el-input v-model="Form.no" clearable="" maxlength="99" ref="no"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label"><span class="star">*</span>地址：</div>
                    <div class="item-value">
                        <el-input v-model="Form.address" clearable="" maxlength="99" ref="address"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label">负责人：</div>
                    <div class="item-value">
                        <el-input v-model="Form.contact" clearable="" maxlength="99"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label">负责人电话：</div>
                    <div class="item-value">
                        <el-input v-model="Form.telephone" clearable="" maxlength="11" ref="telephone"></el-input>
                    </div>
                </div>
            </div>
            <div class="table-btns">
                <span class="link" style="float: right;" @click="addData">添加一行</span>
            </div>
            <el-table :data="Form. locations" border style="width: 100%" ref="table" max-height="480">
                <el-table-column type="index" label="序号" width="80" align="center">
                </el-table-column>
                <el-table-column label="库位名称" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name" clearable :ref="'name' + (scope.$index)" placeholder="限10字"
                            maxlength="10">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="容量" align="center">
                    <template slot-scope="scope">
                        <inputNumber v-model="scope.row.maxVolume" :id="'maxVolume' + (scope.$index)" :min="1"
                            :max="999999999" placeholder="1~999999999" />
                    </template>
                </el-table-column>
                <el-table-column label="启/禁用" width="80" align="center">
                    <template slot-scope="scope">
                        <el-switch active-color="#BB1BB5" v-model="scope.row.enabled">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="dele(scope.$index)" style="color:rgba(217, 0, 17, 1)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="bottom-btn">
            <imsButton typeClass="secondary" text="返回" @click="$router.push('/System/Warehouse')"
                style="margin-right: 10px;" />
            <imsButton typeClass="primary" text="保存" :loading="loading" @click="savForm()" />
        </div>
    </div>
</template>

<script>
import mixins from '@/mixins';
import { mapMutations } from 'vuex'
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
export default {
    components: {
        inputSelect,
        inputNumber
    },
    mixins: [mixins],
    computed: {
        ...mapMutations['getHeadline'],
    },
    data() {
        return {
            hoverKey: '',
            loading: false,
            disabled: false,
            Form: {
                name: '',
                code: '',
                address: '',
                principal: '',
                principalPhone: '',
                officePhone: '',
                 locations: []
            }
        }
    },
    methods: {
        addData() {
            this.Form. locations.push({enabled: true})
            // 滚动到最底部
            setTimeout(() => {
                this.$refs.table.bodyWrapper.scrollTop = this.$refs.table.bodyWrapper.scrollHeight
            }, 100);
        },
        dele(index) {
            this.Form. locations.splice(index, 1)
            this.$message.success('删除成功！')
        },
        getData() {
            if (this.$route.query.id) {
                this.$apis.getWarehouse(this.$route.query.id).then(res => {
                    this.Form = {
                        ...this.Form,
                        ...res.body,
                         locations: res.body.locations
                    }
                }).catch(()=>{})
            }
        },
        savForm() {
            if (this.typeList.includes(this.Form.name)) {
                this.$message.warning('请输入仓库名称！')
                this.$refs.name.focus()
                return
            }
            else if (this.typeList.includes(this.Form.no)) {
                this.$message.warning('请输入仓库编码！')
                this.$refs.no.focus()
                return
            }
            else if (this.typeList.includes(this.Form.address)) {
                this.$message.warning('请输入地址！')
                this.$refs.address.focus()
                return
            }
            if (this.Form.telephone && !this.regPhone.test(this.Form.telephone)) {
                this.$message.warning('联系人电话格式不正确，正确格式应为13800138001！')
                this.$refs.telephone.focus()
                return
            }
            else if (this.Form. locations.length > 0) {
                this.Form. locations.forEach((item, index) => {
                    // 表单非空校验
                    if (this.typeList.includes(item.name)) {
                        this.$refs['name' + index].focus()
                        this.$message.warning(`请填写序号为【${index + 1}】的库位名称！`)
                        throw Error();
                    }
                    else if (!item.maxVolume) {
                        document.getElementById('maxVolume' + index).focus()
                        this.$message.warning(`请填写序号为【${index + 1}】的容量！`)
                        throw Error();
                    }
                })
            }
            this.loading = true
            if (this.Form.id) { // 编辑
                this.$apis.editWarehouse(this.Form, this.Form.id).then(res => {
                    if (res.body) {
                        this.$message.success('编辑成功！')
                    }
                    this.getData()
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            } else {//新增
                this.$apis.addWarehouse(this.Form).then(res => {
                    if (res.body) {
                        this.$message.success('添加成功，即将跳转至列表页！')
                        setTimeout(() => {
                            this.$router.push('/System/Warehouse')
                        }, 1000);
                    }
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            }
        },
    },
    mounted() {
        this.getData()
    },
}
</script>
<style lang="less" scoped>
.page .page-body .page-search{
  flex-wrap: wrap;
}
</style>