<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    编辑采购订单
                </div>
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item">
                    <div class="item-label bold">采购计划单号：</div>
                    <div class="item-value">
                        {{ Form.no }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">采购计划名称：</div>
                    <div class="item-value ellipsis">
                        <el-tooltip v-if="Form.name?.length>20"  effect="dark" :content="Form.name" placement="bottom-start">
                            <p class="ellipsis"> {{ Form.name }}</p>
                        </el-tooltip>
                        <p v-else>{{ Form.name }}</p>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">
                        <span class="star">*</span>
                        采购日期：
                    </div>
                    <div class="item-value">
                        <el-date-picker v-model="Form.purchaseDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                            ref="purchaseDate2">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                产品信息
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 15px;"
                height="calc(100% - 200px)" ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center" ></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="140" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" width="120" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="规格" prop="specification" min-width="140" align="center">
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>合同编号
                    </template>
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.contractNo" maxlength="32" clearable
                            :id="'contractNo' + scope.$index"></el-input>
                    </template>
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>合同生效日期
                    </template>
                    <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.commencementDate" type="date" value-format="yyyy-MM-dd"
                            placeholder="选择日期" :id="'commencementDate' + scope.$index">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>物价ID
                    </template>
                    <template slot-scope="scope">
                        <inputNumber :max="99999999" v-model="scope.row.priceId" :id="'priceId' + scope.$index" />
                    </template>
                </el-table-column>
                <el-table-column label="计划采购数量" prop="purchaseQuantity" width="120" align="center">
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>实际采购数量
                    </template>
                    <template slot-scope="scope">
                        <inputNumber :max="99999999" :min="1" v-model="scope.row.contractQuantity"
                            :id="'contractQuantity' + scope.$index" />
                    </template>
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>采购总金额
                    </template>
                    <template slot-scope="scope">
                        <inputNumber :max="999999999" v-model="scope.row.contractAmount"
                            :id="'contractAmount' + scope.$index" />
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" width="200" align="center">
                    <template slot-scope="scope">
                        <div class="link"
                            @click="(eidtForm = { ...scope.row, keyIndex: scope.$index }, dialogVisibleEdit = true)">编辑
                        </div>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Purchase/Order')" />
                <imsButton style="margin-left: 10px;" text="提交并锁定" :loading="loading"
                    @click="saveForm('submitPurchaseOrder')" />
                <imsButton style="margin-left: 10px;" typeClass="secondary" text="保存" @click="saveForm('addPurchaseOrder')"
                    :loading="loading" />
            </div>
        </div>

        <!-- 编辑弹窗 -->
        <el-dialog :visible.sync="dialogVisibleEdit" width="90%" append-to-body :close-on-click-modal="false">
            <div class="dialog-header">{{ eidtForm.name }}</div>
            <div class="dialog-main">
                <div class="search-item" data-required="contractNo">
                    <div class="item-label bold"><span class="star">*</span>合同编号：</div>
                    <div class="item-value">
                        <el-input v-model="eidtForm.contractNo" clearable maxlength="32" ref="contractNo"></el-input>
                    </div>
                </div>
                <div class="search-item" data-required="commencementDate">
                    <div class="item-label bold"><span class="star">*</span>合同生效日期：</div>
                    <div class="item-value">
                        <el-date-picker v-model="eidtForm.commencementDate" type="date" value-format="yyyy-MM-dd"
                            placeholder="选择日期" ref="commencementDate">
                        </el-date-picker>
                    </div>
                </div>
                <div class="search-item" data-required="priceId">
                    <div class="item-label bold"><span class="star">*</span>物价ID：</div>
                    <div class="item-value">
                        <inputNumber :max="99999999" v-model="eidtForm.priceId" ref="priceId" />
                    </div>
                </div>
                <div class="search-item" data-required="contractQuantity">
                    <div class="item-label bold"><span class="star">*</span>合同数量：</div>
                    <div class="item-value">
                        <inputNumber :max="99999999" v-model="eidtForm.contractQuantity" ref="contractQuantity" />
                    </div>
                </div>
                <div class="search-item" data-required="contractAmount">
                    <div class="item-label bold"><span class="star">*</span>合同金额：</div>
                    <div class="item-value">
                        <inputNumber :max="99999999" v-model="eidtForm.contractAmount" ref="contractAmount" />
                    </div>
                </div>
            </div>
            <div class="dialog-footer">
                <imsButton typeClass="secondary" text="关闭" @click="dialogVisibleEdit = false" />
                <imsButton text="确定" @click="updateData()" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
import npmnDialog from '@/components/npmnDialog.vue';
export default {
    components: {
        inputSelect,
        inputNumber,
        npmnDialog
    },
    watch: {
        'Form.purchaseDate'(val) {
            this.Form.listingCollection = this.Form.listingCollection.map(item => {
                return {
                    ...item,
                    purchaseDate: val
                }
            })
        }
    },
    data() {
        return {
            loading: false,
            Form: {
                id: null,
                listingCollection: [],
            },
            Query: {
                nameLike: null,
                enabled: true
            },
            tableData: [],
            eidtForm: {},
            dialogVisibleEdit: false
        }
    },
    methods: {
        updateData() {
            var bool = this.checkForm(document.querySelectorAll('*[data-required]'), this.eidtForm)
            if (!bool) return
            this.$set(this.Form.listingCollection, this.eidtForm.keyIndex, this.eidtForm)
            this.dialogVisibleEdit = false
        },
        getDetail() {
            this.$apis.getPurchaseOrder(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                }
            }).catch(() => {

            })
        },
        saveForm(apiMethod) {
            if (apiMethod == "submitPurchaseOrder") {
                let bool = true
                if (!this.Form.purchaseDate) {
                    this.$message.warning('请选择“采购日期”')
                    this.$refs['purchaseDate2'].focus()
                    return
                }
                if (this.Form.listingCollection.length > 0) {
                    this.Form.listingCollection.forEach((item, index) => {
                        if (bool && !item.contractNo) {
                            this.$message.warning(`第${index + 1}行数据未填写“合同编号”`)
                            document.getElementById('contractNo' + index).focus()
                            bool = false
                        }
                        if (bool && !item.commencementDate) {
                            this.$message.warning(`第${index + 1}行数据未选择“合同生效日期”`)
                            document.getElementById('commencementDate' + index).focus()
                            bool = false
                        }
                        if (bool && !item.priceId) {
                            this.$message.warning(`第${index + 1}行数据未填写“物价ID”`)
                            document.getElementById('priceId' + index).focus()
                            bool = false
                        }
                        if (bool && !item.contractQuantity) {
                            this.$message.warning(`第${index + 1}行数据未填写“合同数量”`)
                            document.getElementById('contractQuantity' + index).focus()
                            bool = false
                        }
                        if (bool && !item.contractAmount) {
                            this.$message.warning(`第${index + 1}行数据未填写“合同金额”`)
                            document.getElementById('contractAmount' + index).focus()
                            bool = false
                        }
                    })
                }
                if (!bool) return
            }
            this.loading = true
            this.$apis[apiMethod]({
                ...this.Form
            }, this.Form.id).then(res => {
                if (res.body) {
                    if (apiMethod == "submitPurchaseOrder") {
                        this.$message.success('提交成功，即将返回列表页')
                        setTimeout(() => {
                            this.loading = false
                            this.$router.push('/Purchase/Order')
                        }, 1000);
                    } else {
                        this.$message.success("保存成功")
                        this.loading = false
                    }
                }

            }).catch(() => {
                this.loading = false
            })
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>