<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    采购计划
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div class="page-search-left">
                    <div class="search-item">
                    <div class="item-label">采购计划单号：</div>
                    <div class="item-value">
                        <el-input v-model="Query.noLike" clearable="" maxlength="99"></el-input>
                    </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">创建日期：</div>
                        <div class="item-value">
                            <el-date-picker v-model="Query.createTimeArr" type="daterange" value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">审批状态：</div>
                        <div class="item-value">
                            <el-select v-model="Query.approvalStatus" placeholder="请选择" clearable>
                                <el-option v-for="(item) in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="page-search-right">
                    <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
                    <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
                </div>
            </div>
            <div class="table-btns">
                <span class="link" style="float: right;margin-left: 10px;"
                    @click="$router.push('/Purchase/Plan/formPage')">创建采购计划</span>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="no" label="采购计划单号" width="160" align="center"></el-table-column>
                <el-table-column prop="name" label="计划名称" min-width="160" align="center"></el-table-column>
                <el-table-column label="采购单数量" width="120" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.listingCollection.length }}
                    </template>
                </el-table-column>
                <el-table-column label="创建日期" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | dateformat() }}
                    </template>
                </el-table-column>
                <el-table-column label="审核日期" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.auditDate | dateformat() }}
                    </template>
                </el-table-column>
                <el-table-column prop="approvalStatus" label="审核状态" width="160" align="center">
                    <template slot-scope="scope">
                        {{ dict1[scope.row.approvalStatus] }}
                    </template>
                </el-table-column>
                <el-table-column prop="printCount" label="打印次数" width="160" align="center"></el-table-column>
                <el-table-column label="操作" width="160" align="center">
                    <template slot-scope="scope">
                        <div class="link"
                            @click="$router.push(`/Purchase/Plan/${scope.row.approvalStatus == 0 || scope.row.approvalStatus == 4 ? 'formPage' : 'detailPage'}?approvalStatus=${scope.row.approvalStatus}&id=${scope.row.id}`)">
                            {{ scope.row.approvalStatus == 0 || scope.row.approvalStatus == 4 ? '编辑' :
                                    scope.row.approvalStatus == 1 ? '审核' : '查看'
                            }}
                        </div>
                        <div class="link star" v-if="(scope.row.approvalStatus == 0)" @click="delData(scope.row)">
                            删除
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination
            v-show="pageQuery.total > 0"
            :total="pageQuery.total"
            :page.sync="pageQuery.pageIndex"
            :limit.sync="pageQuery.pageSize"
            @pagination="getData()"
          />
        </div>

    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import ImsNpmn from '@/components/imsNpmn.vue';
export default {
    components: {
        inputSelect,
        ImsNpmn
    },
    data() {
        return {
            loading: false,
            Query: {
                noLike: null,
                createTimeArr: [],
                approvalStatus: null,
            },
            pageQuery:{
                pageIndex: 1,
                pageSize: 10,
            },
            tableData: [],
            options: [
            ]
        }
    },
    methods: {
        getData(item) {
            this.loading = true
            if(item)
            this.pageQuery={
                ...this.pageQuery,
                pageIndex:1
            }
            this.$apis.pagePurchasePlan({ ...this.Query, ...this.pageQuery, }).then(res => {
                this.tableData = res.body.records;
                this.pageQuery.total = Number(res.body.recordCount);
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        delData(scope) {
            this.$confirm(
                `您确定要永久删除该条数据吗？删除后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.$apis.delPurchasePlan(scope.id).then(res => {
                        if (res.body) {
                            this.$message.success('删除成功')
                            this.getData()
                        }
                    })
                })
                .catch(() => {

                })
        },
        handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
        for (var key in this.dict1) {
            this.options.push({
                value: key,
                label: this.dict1[key]
            })
        }
        this.$nextTick(() => {
            this.getData()
        })
    }
}
</script>
<style lang="less" scoped>
.page-search-right{
    width: 300px;
}
</style>