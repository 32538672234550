import request_artisan from '@/utils/request_artisan'

// 话题分页列表
export function getTopicPageList(data) {
  return request_artisan({
    url: '/v1/Community/Topic/Page',
    method: 'get',
    params: data
  })
}

// 发布话题
export function addTopic(data) {
  return request_artisan({
    url: '/v1/Community/Topic',
    method: 'post',
    data
  })
}

// 修改话题
export function updateTopic(data) {
  return request_artisan({
    url: '/v1/Community/Topic',
    method: 'put',
    data
  })
}

// 删除话题
export function deleteTopic(data) {
  return request_artisan({
    url: '/v1/Community/Topic/' + data.topicUId,
    method: 'delete'
  })
}
