<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">门店管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">门店名称：</div>
            <div class="item-value">
            <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
      <div>
        <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
        <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
      </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="addData()">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="门店名称" min-width="80" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" min-width="150" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="contact" label="联系人" min-width="60" align="center"></el-table-column>
        <el-table-column prop="telephone" label="联系电话" min-width="100" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="editData(scope.row)" type="text" class="edit">编辑</el-button>
            <el-button type="text" @click="deleData(scope.row)" style="color:rgba(217, 0, 17, 1)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.id ? '编辑' : '新增' }}门店</div>
      <div class="dialog-main">
        <div class="search-item" data-required="name">
          <div class="item-label">
            <span class="star">*</span>门店名称：
          </div>
          <div class="item-value">
            <el-input v-model="Form.name" clearable maxlength="30" ref="name"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            地址：
          </div>
          <div class="item-value">
            <el-input v-model="Form.address" clearable maxlength="100" ref="address"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            联系人：
          </div>
          <div class="item-value">
            <el-input v-model="Form.contact" clearable maxlength="10" ref="contact"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            联系电话：
          </div>
          <div class="item-value">
            <el-input v-model="Form.telephone" clearable maxlength="11" ref="telephone"></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="保存" @click="saveForm" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect
  },
  mixins: [mixins],
  data() {
    return {
      loading: false,
      dialogVisible: false,
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      Query: {
        name: ""
      },
      Form: {
        name: "",
        address: "",
        contact: "",
        contactPhone: "",
        telephone: ""
      }
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      this.$apis
        .pageBtype({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editData(item) {
      this.Form = {
        ...item
      };
      this.dialogVisible = true;
    },
    addData() {
      this.Form = {
        name: "",
        address: "",
        contact: "",
        contactPhone: "",
        telephone: ""
      };
      this.dialogVisible = true;
    },
    deleData(item) {
      // console.log(item.id);
      this.$confirm("您确定要删除" + item.name + "吗?", "删除提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning"
      }).then(() => {
        this.$apis.delCustomer(item.id).then(res => {
          if (res.body) {
            this.$message.success("删除成功！");
            this.getData();
          }
        });
      }).catch(()=>{})
    },
    saveForm() {
      var bool = this.checkForm(
        document.querySelectorAll("*[data-required]"),
        this.Form
      );
      if (!bool) return;
      if (this.Form.telephone && !this.regPhone.test(this.Form.telephone)) {
        this.$message.warning('联系电话格式不正确，正确格式应为13800138001！')
        this.$refs.telephone.focus()
        return
      }
      this.loading = true;
      if (this.Form.id) {
        // 编辑
        this.$apis
          .editCustomer(this.Form, this.Form.id)
          .then(res => {
            if (res.body) {
              this.$message.success("编辑成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$apis
          .addCustomer(this.Form)
          .then(res => {
            if (res.body) {
              this.$message.success("添加成功！");
            }
            this.dialogVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
  }
};
</script>