<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title" style="display:inline-block">门店财务月结统计</div>
        <imsButton style="float: right;margin-top: 8px;" text="导出" :loading="loading" />
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div class="search-item">
          <div class="item-label">查询月份：</div>
          <div class="item-value">
            <el-date-picker v-model="Query.month" type="month" placeholder="请选择选择月份"></el-date-picker>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">门店：</div>
          <div class="item-value">
            <inputSelect apiMethod="pageBtype" v-model="Query.btypeId" />
          </div>
        </div>
        <imsButton
          style="float: right;margin-top: 4px;"
          text="查询"
          :loading="loading"
          @click="getData(1)"
        />
        <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        show-summary
      >
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="name" label="门店" min-width="160" align="center"></el-table-column>
        <el-table-column prop="unpaidLastMonth" label="上月未收款累计" min-width="160" align="center"></el-table-column>
        <el-table-column prop="currentReceivables" label="本月应收款" width="120" align="center"></el-table-column>
        <el-table-column prop="receivedMonth" label="本月已收款" width="120" align="center"></el-table-column>
        <el-table-column prop="unpaidMonth" label="本月未收款" width="120" align="center"></el-table-column>
        <el-table-column prop="currentCollection" label="本月补收款" width="120" align="center"></el-table-column>
        <el-table-column prop="returnMonth" label="本月应退货" width="120" align="center"></el-table-column>
        <el-table-column prop="returnedMonth" label="本月已退货" width="120" align="center"></el-table-column>
        <el-table-column prop="fillMonthReturn" label="本月补退货" width="120" align="center"></el-table-column>
        <el-table-column prop="accumulatedUnpaidMoney" label="未交款累计" width="120" align="center"></el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next,jumper"
        :current-page="pageQuery.pageIndex"
        :total="pageQuery.total"
        @current-change="val => pageQuery.pageIndex = val"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import ImsNpmn from "@/components/imsNpmn.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    ImsNpmn
  },
  data() {
    return {
      loading: false,
      Query: {
        month: null,
        btypeId: null
      },
      tableData: []
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageFinancialCustomer({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>