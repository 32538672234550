<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">期初结存</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div class="search-item">
          <div class="item-label">产品名称：</div>
          <div class="item-value">
            <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">批次号：</div>
          <div class="item-value">
            <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">结存日期：</div>
          <div class="item-value">
            <el-date-picker
              v-model="Query.timeArr"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
        <imsButton
          style="float: right;margin-top: 4px;"
          text="查询"
          :loading="loading"
          @click="getData(1)"
        />
      </div>
      <div class="form-body">
        <div class="search-item" data-required="no">
          <div class="item-label bold">库存产品种类：</div>
          <div class="item-value">{{ x1 }}（种）</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">批次数量：</div>
          <div class="item-value">{{ x2 }}（个）</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">产品总数量：</div>
          <div class="item-value">{{ x3 }}</div>
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="no" label="产品名称" width="160" align="center"></el-table-column>
        <el-table-column prop="x2" label="生产批号" min-width="160" align="center"></el-table-column>
        <el-table-column prop="x3" label="供应商" min-width="160" align="center"></el-table-column>
        <el-table-column prop="x4" label="结存日期" min-width="160" align="center"></el-table-column>
        <el-table-column prop="x4" label="结存数量" min-width="160" align="center"></el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <div class="link" @click="$router.push(`/Stock/Balance/detail?id=${scope.row.id}`)">查看明细</div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
  </div>
</template>
      <script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";

export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber
  },
  data() {
    return {
      loading: false,
      Query: {
        noLike: null,
        Btype: null,
        timeArr: null
      },
       pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      x1: "",
      x2: "",
      x3: ""
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pagePurchasePlan({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>