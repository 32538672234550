<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">应付账款明细</div>
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-body">
        <div class="search-item">
          <div class="item-label bold">单据编号：</div>
          <div class="item-value">{{ Form.orderInfo.no }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">供应商：</div>
          <div class="item-value">{{ Form.orderInfo.supplier ? Form.orderInfo.supplier.name : '' }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">制单日期：</div>
          <div class="item-value">{{ Form.orderInfo.createTime | dateformat() }}</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">订单总金额：</div>
          <div class="item-value">{{ Form.totalAmount }}元</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">已付款：</div>
          <div class="item-value">{{ Form.receivedAmount }}元</div>
        </div>
        <div class="search-item">
          <div class="item-label bold">剩余应付款：</div>
          <div class="item-value">{{ Form.unReceiveAmount }}元</div>
        </div>
      </div>
      <div class="form-title" style="margin-top: 20px;">
        <span class="fa fa-border-style"></span>
        产品清单
      </div>
      <el-table :data="Form.orderInfo.listingCollection" border style="width: 100%;margin-top: 5px;" height="calc(100% - 280px)"
        ref="list" destroy-on-close>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="产品名称" prop="name" min-width="140" align="center">
        </el-table-column>
        <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
        </el-table-column>
        <el-table-column label="规格" prop="specification" width="120" align="center">
        </el-table-column>
        <el-table-column label="产品属性" prop="attributeTypeName" min-width="200" align="center">
        </el-table-column>
        <el-table-column label="单价（元）" prop="price" width="100" align="center">
        </el-table-column>
        <el-table-column label="批准文号" prop="drugApprovalNo" min-width="120" align="center"></el-table-column>
        <el-table-column label="生产批号" prop="batchNo" min-width="140" align="center">
        </el-table-column>
        <el-table-column label="有效期" prop="expiryDate" min-width="140" align="center">
        </el-table-column>
      </el-table>

      <div class="form-title" style="margin-top: 20px;">
        <span class="fa fa-border-style"></span>
        实收流水
      </div>
      <el-table :data="Form.financialPaymentList" border style="width: 100%;margin-top: 5px;" height="calc(100% - 280px)"
        ref="list" destroy-on-close>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="发票号" prop="voucherNo" align="center"></el-table-column>
        <el-table-column label="付款日期" prop="paymentDate" align="center">
          <template slot-scope="scope">{{ scope.row.paymentDate | dateformat('yyyy-MM-DD') }}</template>
        </el-table-column>
        <el-table-column label="付款银行" prop="blankAccount.name" align="center"></el-table-column>
        <el-table-column label="实付款（元）" prop="amount" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <div class="link" @click="$router.push(`/Finance/RealPay/detail?id=${scope.row.id}`)">查看明细</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push('/Finance/DuePay')" />
      </div>
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect
  },
  data() {
    return {
      loading: false,
      Form: {
        orderInfo: {
          listingCollection: []
        },
        financialPaymentList: []
      },
    };
  },
  methods: {
    getDetail() {
      this.$apis
        .getPayable(this.Form.id)
        .then(res => {
          this.Form = {
            ...this.Form,
            ...res.body,
            orderInfo: res.body.purchaseOrderInfo ? res.body.purchaseOrderInfo : res.body.purchaseReturnsInfo
          };
        })
        .catch(() => { });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.Form.id = this.$route.query.id;
        this.getDetail();
      }
    });
  }
};
</script>