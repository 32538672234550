<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form :inline="true" class="demo-form-inline" size="small">
      <el-form-item>
        <el-input placeholder="请输入优惠券名称" v-model="param.name" clearable>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div
          class="el-input el-input--small el-input-group el-input-group--prepend"
        >
          <el-select
            v-model="param.couponType"
            placeholder="请选择优惠券类型"
            clearable
          >
            <el-option label="满减券" value="1"></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item>
        <imsButton
            style="float: right;"
            text="查询"
            :loading="loading"
            @click="onSelect"
          />
      </el-form-item>
    </el-form>

    <div class="base-table">
      <el-table :data="couponList" v-loading="loading">
        <el-table-column type="index" label="序号" width="100" align="center" />
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="260">
          <template #default="scope">
            <template v-if="checkIsRelation(scope.row)">
              <el-button
                class="band"
                size="small"
                icon="el-icon-connection"
                @click="relation(scope.row)"
              >
                关联
              </el-button>
            </template>
            <template v-else> 已关联 </template>
          </template>
        </el-table-column>
      </el-table>
      <template>
        <el-pagination
          align="right"
        layout="total, prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="param.PageSize"
          :total="page.total"
        />
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { getCouponList } from "@/api/Marketings/coupons";
import imsButton from "@/components/imsButton.vue";
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    configInfo: {
      type: Object,
      default: () => ({}),
    },
  },  
  components:{
    imsButton
  },
  data() {
    return {
      visible: false,
      loading: false,
      param: {
        PageIndex: 1,
        PageSize: 5,
        name: null,
        couponType: null,
      },
      couponList: [],
      page: {
        total: 0,
      },
      columns: [
        {
          label: "券名称",
          prop: "name",
        },
        {
          label: "类型",
          prop: "couponTypeName",
        },
        {
          label: "标识",
          prop: "identification",
        },
      ],
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    async getCouponListApi() {
      this.loading = true;
      let data = await getCouponList(this.param);
      this.couponList = data.records;
      this.page.total = data.recordCount;
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.param.PageIndex = val;
      this.onSelect();
    },
    onSelect() {
      if (this.param.couponType == "") {
        this.param.couponType = null;
      }
      if (this.param.name == "") {
        this.param.name = null;
      }
      this.getCouponListApi();
    },
    checkIsRelation(row) {
      let result = true;
      this.configInfo.couponList.forEach((element) => {
        if (element.couponId === row.couponId) {
          result = false;
        }
      });
      return result;
    },
    // 绑定
    relation(row) {
      this.$parent.configInfo.couponList.push(row);
      this.$message.success("操作成功");
      this.onSelect();
    },
  },
};
</script>

<style lang="less" scoped>
.band:hover{
  color: #BB1BB5;
  border-color:#BB1BB5;
  background-color: #fcfafc;
}
</style>
