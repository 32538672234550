<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          首页配置
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="base-table">
        <div class="table-btns">
          <span class="link"   @click="handleImageDialog"
            >添加图片</span>
            <span class="link"  @click="handleVideoDialog"
            >添加视频</span>
          <span class="link"  @click="handleCreviceDialog"
            >添加间隙</span>
          <span class="link"  @click="handleCarouselDialog"
            >添加轮播</span>
          <span class="link"  @click="handleFullCarouselDialog"
            >全屏轮播</span>
          <span class="tips">（鼠标拖拽列表每一项可以排列顺序）</span>
        </div>
        <el-table :data="configList" v-loading="loading" border>
          <el-table-column label="排序" type="index" align="center" width="50">
          </el-table-column>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template #default="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                :title="
                  scope.row.isUsed
                    ? '是否确认停用该组件？'
                    : '是否确认启用该组件？'
                "
                style="margin-left: 10px;"
                @confirm="handleStatus(scope.row.configId)"
              >
                <template #reference>
                  <el-button
                    type="warning"
                    size="mini"
                    :icon="
                      scope.row.isUsed ? 'el-icon-error' : 'el-icon-circle-check'
                    "
                    >{{ scope.row.isUsed ? '停用' : '启用' }}</el-button
                  >
                </template>
              </el-popconfirm>

              <el-popconfirm
                title="是否确认删除？"
                style="margin-left: 10px;"
                @confirm="handleDelete(scope.row.configId)"
              >
                <template #reference>
                  <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <ImageDialog ref="imageDialog" @updateList="getConfigListApi" />
      <VideoDialog ref="videoDialog" @updateList="getConfigListApi" />
      <CreviceDialog ref="creviceDialog" @updateList="getConfigListApi" />
      <CarouselDialog ref="carouselDialog" @updateList="getConfigListApi" />
      <FullCarouselDialog
        ref="fullCarouselDialog"
        @updateList="getConfigListApi"
      />
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import {
  getConfigList,
  updateConfigStatus,
  deleteConfig,
  dragConfig
} from '@/api/Decorate'
import ImageDialog from './components/ImageDialog'
import VideoDialog from './components/VideoDialog'
import CreviceDialog from './components/CreviceDialog'
import CarouselDialog from './components/CarouselDialog'
import FullCarouselDialog from './components/FullCarouselDialog'

export default {
  name: 'configureList',
  components: {
    ImageDialog,
    VideoDialog,
    CreviceDialog,
    CarouselDialog,
    FullCarouselDialog
  },
  data() {
    return {
      loading: false,
      configList: [],
      categoryInfo: {},
      columns: [
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '组件类型',
          prop: 'type',
          formatter: (row, column, value) => {
            return {
              '1': '图片',
              '2': '视频',
              '3': '间隙',
              '4': '轮播',
              '5': '全屏轮播'
            }[value]
          }
        },
        {
          label: '显示权限',
          prop: 'showType',
          formatter: (row, column, value) => {
            return {
              '1': '全部可见',
              '2': '仅会员可见',
              '3': '仅非会员可见'
            }[value]
          }
        },
        {
          label: '状态',
          prop: 'isUsed',
          formatter: (row, column, value) => {
            return {
              true: '启用',
              false: '停用'
            }[value]
          }
        }
      ]
    }
  },
  mounted() {
    this.getConfigListApi()
    // 拖拽
    document.body.ondrop = event => {
      event.preventDefault()
      event.stopPropagation()
    }
    this.rowDrop()
  },
  methods: {
    async getConfigListApi() {
      this.loading = true
      this.configList = await getConfigList()
      this.loading = false
    },
    // 打开图片弹窗
    handleImageDialog() {
      this.$refs.imageDialog.getData()
    },
    // 打开视频弹窗
    handleVideoDialog() {
      this.$refs.videoDialog.getData()
    },
    // 打开间隙弹窗
    handleCreviceDialog() {
      this.$refs.creviceDialog.getData()
    },
    // 打开轮播弹窗
    handleCarouselDialog() {
      this.$refs.carouselDialog.getData()
    },
    // 打开全屏轮播弹窗
    handleFullCarouselDialog() {
      this.$refs.fullCarouselDialog.getData()
    },
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      Sortable.create(tbody, {
        onEnd: async ({ newIndex, oldIndex }) => {
          const list = JSON.parse(JSON.stringify(this.configList))
          const currRow = list.splice(oldIndex, 1)[0]
          list.splice(newIndex, 0, currRow)
          const oldList = []
          const newList = []
          this.configList.map(item => {
            oldList.push(item.configId)
          })
          list.map(item => {
            newList.push(item.configId)
          })
          if (oldList.toString() === newList.toString()) {
            return
          }
          await dragConfig(newList)
          this.$set(this.configList, list)
          this.$message.success('排序成功')
        }
      })
    },
    async handleEdit(row) {
      switch (row.type) {
        case '1':
          this.$refs.imageDialog.getData(row.configId)
          break
        case '2':
          this.$refs.videoDialog.getData(row.configId)
          break
        case '3':
          this.$refs.creviceDialog.getData(row.configId)
          break
        case '4':
          this.$refs.carouselDialog.getData(row.configId)
          break
        case '5':
          this.$refs.fullCarouselDialog.getData(row.configId)
          break
        default:
          break
      }
    },
    async handleStatus(configId) {
      await updateConfigStatus({
        id: configId
      })
      this.getConfigListApi()
    },
    async handleDelete(configId) {
      await deleteConfig({
        id: configId
      })
      this.getConfigListApi()
    }
  }
}
</script>

<style lang="less" scoped>
// .app-container {
//   /deep/ .el-table__body-wrapper {
//     cursor: move;
//   }

// }
  .tips {
    font-size: 22px;
    font-weight: bold;
    color: red;
  }
.svg-drag {
  color:  #a29898;
  width: 30px;
  vertical-align: middle;
  display: inline-block;
}
.svg-drag > .i {
  width: 30px;
  height: 40px;
}
</style>
