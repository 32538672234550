import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Images from '@/components/Image'

window.config = {
  api:'/api/erp',
  systemName: '嘉鼎门店管理系统'
}

Vue.config.productionTip = false

import '@/assets/css/reset.css'
import './assets/css/Font Awesome Pro 5.11.2/fontawesome-pro-5.11.2-web-Ulabs/css/all.min.css'
import './assets/css/Font Awesome Pro 5.11.2/fontawesome-pro-5.11.2-web-Ulabs/css/regular.min.css'
import './assets/css/Font Awesome Pro 5.11.2/fontawesome-pro-5.11.2-web-Ulabs/css/solid.min.css'
import './assets/css/Font Awesome Pro 5.11.2/fontawesome-pro-5.11.2-web-Ulabs/css/light.min.css'
import 'element-ui/lib/theme-chalk/index.css';

import api from '@/api/index.js'
import moment from 'moment'
import mixins from '@/mixins/index.js'
import customPagination from '@/components/Pagination'
import { addDateRange, resetForm, initListData } from '@/utils/common'
import imsButton from '@/components/imsButton.vue';
import { formatDay } from '@/utils/day'

// 全局组件挂载
Vue.component('Pagination', customPagination)
Vue.component('imsButton', imsButton)
Vue.component('Images', Images)

Vue.use(mixins)
Vue.prototype.formatDay = formatDay
Vue.prototype.resetForm = resetForm
Vue.prototype.initListData = initListData
Vue.prototype.$moment = moment;
Vue.prototype.$apis = api



// 引入 element-ui
import {
  Select,
  Option,
  Input,
  InputNumber,
  Pagination,
  Message,
  MessageBox,
  Loading,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Form,
  FormItem,
  Radio,
  Upload,
  Table,
  TableColumn,
  Dialog,
  Button,
  DatePicker,
  Switch,
  Divider,
  RadioGroup,
  Tooltip,
  Steps,
  Step,
  Tree,
  Collapse,
  Cascader,
  ColorPicker,
  Progress,
  Notification,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Checkbox,
  CheckboxGroup,
  CascaderPanel,
  Autocomplete,
  Tag,
  CollapseItem,
  Statistic,
  Col,
  Card,
  Row ,
  RadioButton,
  TimeSelect,
  Popconfirm,
  Image,
  Link,
  TabPane,
  Tabs
} from 'element-ui';
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(Upload);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(Divider);
Vue.use(RadioGroup);
Vue.use(Tooltip);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Tree);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Progress);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(CheckboxGroup)
Vue.use(CascaderPanel)
Vue.use(Autocomplete)
Vue.use(Tag)
Vue.use(Statistic)
Vue.use(Col)
Vue.use(Card)
Vue.use(Row)
Vue.use(RadioButton)
Vue.use(TimeSelect)
Vue.use(Popconfirm)
Vue.use(Image)
Vue.use(Link)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification
import directive from './directives'
Vue.use(directive)

// 全局时间过滤器
Vue.filter('dateformat', (dataStr, pattern = 'yyyy-MM-DD') => {
  if (dataStr == '' || dataStr == null) {
    return ''
  }
  return moment(dataStr).format(pattern)
})
Vue.filter('operateEnableText', value => {
  return value ? '禁用' : '启用';
})
Vue.filter('enabledText', value => {
  return value ? '已启用' : '已禁用';
})
Vue.filter('operateDisableText', value => {
  return value ? '启用' : '禁用';
})
Vue.filter('disabledText', value => {
  return value ? '已禁用' : '已启用';
})
Vue.filter('dateTimeFormat', (dataStr, pattern = 'yyyy-MM-DD hh:mm:ss') => {
  if (dataStr == '' || dataStr == null) {
    return ''
  }
  return moment(dataStr).format(pattern)
})
//main.js

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    next('/Login')
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
