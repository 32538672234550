<template>
 <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
           商品分类
        </div>
      </div>
    </div>
  <div class="page-body">
    <div class="base-table">
      <div class="table-btns">
        <span class="link" style="float: right;"  @click="handleCreate"
          >添加分类</span>
      </div>
      <el-table :data="categorysList" v-loading="loading" border>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
          align="center"
          show-overflow-tooltip
        >
          <!-- <template v-if="item.prop === 'icon'" #default="scope">
            <img class="list-image m-6" :src="scope.row.icon" alt="" width="100" />
          </template> -->
        </el-table-column>

        <el-table-column label="操作" align="center" width="260">
          <template #default="scope">
            <template v-if="scope.row.status === 2">
              <el-popconfirm
                title="是否确认停用？"
                @confirm="handleStatus(scope.row)"
              >
                <template #reference>
                  <el-button type="warning" size="mini" icon="el-icon-error"
                    >停用</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
            <template v-else>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="warning"
                size="mini"
                icon="el-icon-success"
                @click="handleStatus(scope.row)"
                >启用</el-button
              >

              <el-popconfirm
                title="是否确认删除？"
                style="margin-left: 10px"
                @confirm="handleDelete(scope.row.categoryId)"
              >
                <template #reference>
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <OperateCategoryDialog
      ref="OperateDialog"
      :dialog-title="dialogTitle"
      :category-info="categoryInfo"
      @updateList="getCategorysListApi"
    />
  </div>
</div>
</template>

<script>
import {deleteCategory, getCategorysList, updateCategoryStatus} from '@/api/Product'
import OperateCategoryDialog from './components/OperateCategoryDialog'

export default {
  name: "categoryList",
    components: {
      OperateCategoryDialog
  },
  data() {
    return {
      loading: false,
      categorysList: [],
      categoryInfo: {},
      dialogTitle: "添加分类",
      columns: [
        {
          label: "编号",
          prop: "categoryId",
          width: "60",
        },
        {
          label: "分类名称",
          prop: "name",
        },
        {
          label: "分类类型",
          prop: "categoryType",
          formatter: (row, column, value) => {
            return value === 1 ? "销售" : value === 2 ? "预订" : "";
          },
        },
        // {
        //   label: "图标",
        //   prop: "icon",
        //   width: 120
        // },
        {
          label: "创建日期",
          prop: "createDataTime",
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: "YYYY-MM-DD",
            });
          },
        },
        {
          label: "状态",
          prop: "status",
          formatter: (row, column, value) => {
            return {
              1: "未启用",
              2: "已启用",
              3: "已删除",
            }[value];
          },
        },
      ],
    };
  },
    mounted() {
      this.getCategorysListApi()
    },
  methods: {
        async getCategorysListApi() {
          this.loading = true
          this.categorysList = await getCategorysList()
          this.loading = false
        },
    handleCreate() {
            this.dialogTitle = '添加分类'
            this.$refs.OperateDialog.visible = true
            this.$refs.OperateDialog.reset()
    },
        handleEdit(row) {
          this.categoryInfo = {
            categoryType: row.categoryType,
            categoryId: row.categoryId,
            description: row.description,
            sort: row.sort,
            name: row.name,
            fileList: [
              {
                url: row.icon
              }
            ]
          }
          this.dialogTitle = '编辑分类'
          this.$refs.OperateDialog.getEditInfo(this.categoryInfo)
        },
        // 是否启用
        async handleStatus(row) {
          const status = { status: row.status === 1 ? 2 : 1 }
          await updateCategoryStatus({
            id: row.categoryId,
            status
          })
          this.$message.success('操作成功')
          this.getCategorysListApi()
        },
        async handleDelete(id) {
          await deleteCategory({
            id
          })
          this.$message.success('删除成功')
          this.getCategorysListApi()
        }
  },
};
</script>

<style lang="less" scoped></style>
