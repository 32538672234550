<template>
  <div id="app">
    <topBox v-show="$route.path != '/Login'" />
    <router-view />
  </div>
</template>

<script>
import topBox from './components/topBox.vue';
export default {
  components: {
    topBox
  },
  methods: {
    init() {
      if (window.config.api) {
        this.$store.dispatch("initAttributeType")
      } else {
        setTimeout(() => {
          this.init()
        }, 100);
      }
    }
  },
  mounted() {
    this.init()
    
  }
}
</script>

<style lang="less">
// element-ui样式
.page-search,.search-item,
.el-table__body{
  .el-input,
  .el-input__inner,
  .el-cascader,
  .el-autocomplete,
  .el-select,
  .el-textarea__inner {
  width: 100% !important;
  height: 100% !important;
  min-height: 35px !important;
  color: @color1 !important;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner ,
.el-radio__input.is-checked .el-radio__inner{
  background-color: @basicColor !important;
  border-color: @basicColor !important;
}
.el-radio__input.is-checked+.el-radio__label,
.el-select-dropdown__item.selected,
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: @basicColor !important;
}
.el-date-table td.start-date span,
.el-date-table td.end-date span,
.el-date-table td.current:not(.disabled) span {
  background-color:  @pinkColor !important;

}
.el-input.is-active .el-input__inner, .el-input__inner:focus ,
.el-textarea__inner:focus {
  border-color: @basicColor !important;
}
.el-input__icon {
  line-height: 28px !important;
}

.el-table .el-input,
.el-table .el-input__inner {
  min-height: 28px !important;
}

.el-input:hover,
.el-input__inner:hover,
.el-autocomplete:hover,
.el-select:hover,
.el-textarea__inner:hover {
  border-color: @basicColor !important;
}

.el-textarea.is-disabled .el-textarea__inner:hover,
.el-input.is-disabled .el-input__inner:hover {
  border-color: #E4E7ED !important;
}

// .el-cascader-menu__wrap.el-scrollbar__wrap {
//   height: 570px !important;
// }

// .el-cascader-node {
//   width: 244px !important;
// }

// .el-dialog__header,
// .el-dialog__footer {
//   display: none !important;
// }

.el-textarea .el-input__count {
  background: transparent !important;
}

.el-dialog__body {
  padding: 0 !important;
  overflow: hidden !important;
}

.el-popover.el-popper {
  padding: 0 !important;
}


.el-table .cell {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.el-table__header th {
  background-color: rgb(234, 246, 255) !important;
  line-height: 35px !important;
  height: 35px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: @color2 !important;
  font-weight: normal !important;
  border-color: @color4 !important;
}

.el-table__body td,
.el-table__body tr {
  line-height: 35px !important;
  height: 35px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-color: @color4 !important;
}

.cell .el-autocomplete {
  line-height: 35px !important;
}

.el-table {
  border-color: @color4 !important;
}

.el-table.el-table--border {
  border-right: 1px solid @color4 !important;
  border-bottom: 1px solid @color4 !important;
}

.el-dialog{
  padding:0 30px ;
}
.el-pagination{
  margin: 12px 0 20px 0 ;
  float: right;
  padding-right: 50px !important;
}
.el-pager li.active {
  color: @basicColor !important;
}
.el-pagination__jump{
  .el-input, .el-input__inner{
  min-height: 28px !important;
  border-radius: 0;
}
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner,
.el-button--primary ,
 .el-tabs__active-bar{
    background-color: #BB1BB5 !important;
    border-color: #BB1BB5 !important;
    box-shadow:none !important;
}
.el-radio-button__inner:hover,
.el-month-table td.current:not(.disabled) .cell ,
.el-year-table td .cell:hover,
 .el-year-table td.current:not(.disabled) .cell,
 .el-button--text ,
 .el-tabs__item.is-active,
 .el-tabs__item:hover{
  color: #BB1BB5 !important;
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner{
  color: #fff !important;
}
.el-date-table td.today span{
  color: #f0c7ef !important;
}

.el-upload--picture-card:hover, 
.el-upload:focus,
.el-range-editor.is-active, 
.el-range-editor.is-active:hover,
 .el-select .el-input.is-focus .el-input__inner
{    border-color:  #f0c7ef !important}
.el-icon-close:hover,
.el-date-picker__header-label.active,
 .el-date-picker__header-label:hover ,
 .el-picker-panel__icon-btn:hover ,
 .el-year-table td .cell:hover,
  .el-month-table td.today .cell,
  .el-month-table td .cell:hover,
  .el-year-table td.today .cell,
  .el-pagination button:hover{
  color:  #f0c7ef !important;
}
.el-dialog__headerbtn{
  right:10px !important;

}
.el-date-table td.available:hover{
  color: @pinkColor !important;
}
body {
  color: @color1;
}
.el-pager li:hover{
  color:  #f0c7ef !important;
}
.el-date-editor .el-range-separator{
  line-height: 26px !important;
}
.el-breadcrumb__inner.is-link{
  font-weight: 400 !important;
  color: #606266 !important;
}
.el-dialog__body{
  .el-input__inner{
    height: 35px !important;
  }
}
</style>
