import { getLodop } from "@/static/libs/lodop";
import { changePrintNum } from "@/api/Order";
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * 对象按字母升序排序
 * @param  {[object]} obj [需要升序的对象]
 * @return {[object]}     [需要升序的对象]
 */
export const objKeySort = (obj) => {
  const newkey = Object.keys(obj).sort();
  const newObj = {};
  for (let i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
};

// 深度克隆
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

// 路由还原
/**
 *
 * @param {arr} clientAsyncRoutes 前端保存动态路由
 * @param {arr} serverRouter 后端保存动态路由
 */
export function makePermissionRouters(serverRouter, clientAsyncRoutes) {
  if (!serverRouter.length) {
    return [];
  }
  clientAsyncRoutes.map((ele) => {
    if (!ele.name || (!ele.meta && !ele.meta.roles)) return;
    let roles_obj;
    for (let i = 0; i < serverRouter.length; i++) {
      const element = serverRouter[i];
      if (ele.name === element.name) {
        roles_obj = element;
        ele.meta.roles = roles_obj.meta.roles;
      }
    }

    if (ele.children && ele.children.length) {
      makePermissionRouters(serverRouter, ele.children);
    }
  });
  return clientAsyncRoutes;
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

export function environment(params) {
  const type = JSON.parse(process.env.VUE_APP_http);
  return type[params];
}

export function timestamp(params) {
  const date = new Date(params);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}

export async function printOrder(orderUid, option) {
  //这个是打印代码
  var self = this;
  let LODOP = getLodop(); //主要代码
  option = option || {
    intOrient:
      parseInt(window.localStorage.getItem("PrinterSettingOrient"), 10) || 3,
    intPageWidth: window.localStorage.getItem("PrinterSettingWidth") || 580,
    intPageHeight: window.localStorage.getItem("PrinterSettingHeight") || 200,
    pageName: window.localStorage.getItem("PrinterSettingPageName") || "A4",
    fontSize: 30,
  };
  LODOP.PRINT_INIT();
  if (
    (option.intOrient == 1 || option.intOrient == 2) &&
    option.intOrient != "自定义"
  ) {
    option.intPageWidth = 0;
    option.intPageHeight = 0;
  }
  LODOP.SET_PRINT_PAGESIZE(
    option.intOrient,
    option.intPageWidth,
    option.intPageHeight,
    option.pageName
  );
  LODOP.SET_PRINT_STYLE("FontSize", option.fontSize);
  LODOP.ADD_PRINT_HTM(
    0,
    0,
    "100%",
    "100%",
    document.getElementById("printMe").innerHTML //获取要打印部分html
  );
  //以上为声明打印参数，具体请参照官网示例
  //以下是成功加入打印队列之后的回调，如果不用刻意不看，直接执行LODOP.PRINT()
  LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

  if (LODOP.CVERSION) {
    //判断c_lodop是否存在，安装了c-lodop就会存在
    LODOP.On_Return = function (TaskID, Value) {
      // console.log('TaskID:' + TaskID)
      console.log("Value:" + Value); //job代码
      self.jobCode = Value;
      if (!!Value) {
        //如果成功，改变打印次数
        ChangePrintCount(self.newDataAuto).then((res) => {
          if (res.status !== 1) {
            self.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        }).catch(()=>{})
        return;
      }
    };
    LODOP.PRINT(); //这是执行打印（无预览）
    // LODOP.PREVIEW()   这是有预览打印
    // self.dialogVisible = false
    await changePrintNum({ orderUid });
    return;
  } else {
    console.log("c-lodop出错了");
  }
}
