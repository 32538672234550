<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="50%"
    @close="close"
  >
    <el-form
      ref="dialogForm"
      :model="couponInfo"
      :rules="rules"
      label-width="120px"
    >
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <div>
          <el-form-item label="名称:" prop="name">
            <el-input
              v-model="couponInfo.name"
              maxlength="10"
              placeholder="10字以内"
              :disabled="couponInfo.optionType === 'view'"
              clearable
            />
          </el-form-item>
          <el-form-item label="类型:" prop="couponType">
            <el-select
              v-model="couponInfo.couponType"
              placeholder="请选择"
              :disabled="couponInfo.optionType === 'view'"
              clearable
            >
              <el-option label="满减券" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用说明:" prop="explain">
            <el-input
              v-model="couponInfo.explain"
              maxlength="30"
              placeholder="30个字以内,请尽量说明启用金额"
              :disabled="couponInfo.optionType === 'view'"
              clearable
            />
          </el-form-item>
          <el-form-item label="有效期:" prop="validType">
            <el-radio-group
              v-model="couponInfo.validType"
              :disabled="couponInfo.optionType === 'view'"
            >
              <el-radio :label="1"> 时间段 </el-radio>
              <el-radio :label="2"> 永久 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="couponInfo.validType === 1" prop="validTime">
            <el-date-picker
              v-model="couponInfo.validTime"
              size="small"
              style="width: 340px"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="couponInfo.optionType === 'view'"
            ></el-date-picker>
          </el-form-item>
        </div>
      </el-card>
      <br />
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>面额信息</span>
        </div>
        <div>
          <el-form-item label="面额:" prop="denomination">
            <el-input
              type="number"
              v-model="couponInfo.denomination"
              maxlength="5"
              placeholder="1 ~ 99999"
              :disabled="couponInfo.optionType === 'view'"
            >
              <template slot="prepend">￥</template>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="启用金额:" prop="enableDenomination">
            <el-input
              type="number"
              v-model="couponInfo.enableDenomination"
              maxlength="5"
              placeholder="1 ~ 99999"
              :disabled="couponInfo.optionType === 'view'"
            >
              <template slot="prepend">￥</template>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
      </el-card>
      <br />
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>使用规则</span>
        </div>
        <div>
          <el-form-item label="领取/兑换用户:" prop="membershipLevelId">
            <el-checkbox-group
              v-model="couponInfo.membershipLevelId"
              :disabled="couponInfo.optionType === 'view'"
            >
              <el-checkbox :label="0" :key="0">所有注册/登录会员</el-checkbox>
              <el-checkbox
                v-for="item in memberList"
                :label="item.membershipLevelId"
                :key="item.membershipLevelId"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="限领/限兑:" prop="limitNum">
            <el-input
              type="number"
              v-model="couponInfo.limitNum"
              maxlength="5"
              placeholder="1 ~ 99999"
              :disabled="couponInfo.optionType === 'view'"
            >
              <template slot="append">张（仅支持正整数，0为无限制）</template>
            </el-input>
          </el-form-item>
          <el-form-item label="兑换码:" prop="exchangeCode">
            <el-input
              v-model="couponInfo.exchangeCode"
              maxlength="8"
              placeholder="8位，仅支持大写字母+数字"
              onKeyUp="value=value.replace(/[^0-9A-Z]/g,'');"
              :disabled="couponInfo.optionType === 'view'"
            >
              <el-button slot="append" @click="valExchangeCode">
                验证可用性
              </el-button>
            </el-input>
          </el-form-item>
          <p>（不填代表不能通过兑换系统进行兑换，仅支持大写字母+数字）</p>
          <el-form-item label="备注:" prop="remark">
            <el-input
              v-model="couponInfo.remark"
              maxlength="10"
              placeholder="10个字以内"
              :disabled="couponInfo.optionType === 'view'"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <imsButton
        text="确 定"
        v-if="couponInfo.optionType !== 'view'"
        :loading="loading"
        @click="handleSubmit"
      />
    </div>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/utils/day";
import { addConpon, valExchangeCode } from "@/api/Marketings/coupons";
import { getMemberList } from "@/api/Members";
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      memberList: [],
      couponInfo: {
        validTime: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        couponType: [
          {
            required: true,
            message: "请选择优惠券类型",
            trigger: "change",
          },
        ],
        validType: [
          {
            required: true,
            message: "请选择有效期",
            trigger: "change",
          },
        ],
        denomination: [
          {
            required: true,
            message: "请输入面额",
            trigger: "blur",
          },
        ],
        enableDenomination: [
          {
            required: true,
            message: "请输入启用面额",
            trigger: "blur",
          },
        ],
        membershipLevelId: [
          {
            required: true,
            message: "请选择用户类型",
            trigger: "change",
          },
        ],
        limitNum: [
          {
            required: true,
            message: "请输入限领/限兑数量",
            trigger: "blur",
          },
        ],
        exchangeCode: [
          {
            required: false,
            message: "请输入正确兑换码, 8位，仅支持大写字母+数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async getMemberListApi() {
      this.loading = true;
      let data = await getMemberList({ PageIndex: 1, PageSize: 999 });
      this.memberList = data.records;
      this.loading = false;
    },
    close() {
      this.visible = false;
    },
    setConfigInfo(couponInfo) {
      if (couponInfo.validType === 1) {
        couponInfo.validTime = [
          couponInfo.validStartTime,
          couponInfo.validEndTime,
        ];
      }
      this.couponInfo = couponInfo;
    },
    async handleSubmit() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let errMsg = "";
            if (this.couponInfo.validType == 1) {
              if (this.couponInfo.validTime == null) {
                errMsg = "选择时间段日期";
              } else {
                this.couponInfo.validTime.forEach((element) => {
                  if (
                    (element == "" || element == undefined) &&
                    errMsg === ""
                  ) {
                    errMsg = "选择时间段日期";
                  }
                });
                this.couponInfo.validStartTime = this.couponInfo.validTime[0];
                this.couponInfo.validEndTime = this.couponInfo.validTime[1];
              }
            } else {
              this.couponInfo.validStartTime = null;
              this.couponInfo.validEndTime = null;
              this.couponInfo.validTime = [];
            }
            if (errMsg != "") {
              this.$message.error(errMsg);
            } else {
              await addConpon(this.couponInfo);
              this.$message.success("新增成功");
              this.$emit("updateList");
              this.close();
            }
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
    async valExchangeCode() {
      if (
        this.couponInfo.exchangeCode == null ||
        this.couponInfo.exchangeCode == "" ||
        this.couponInfo.exchangeCode.length != 8
      ) {
        this.$message.error("请输入兑换码,长度 8");
      } else {
        let response = await valExchangeCode(this.couponInfo);
        if (response) {
          this.$message.success("验证成功");
        } else {
          this.$message.error("兑换码不可用");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
