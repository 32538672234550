<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">角色管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">角色名：</div>
            <div class="item-value">
              <el-input v-model="Query.textLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton
          style="float: right;margin-top: 4px;"
          text="查询"
          :loading="loading"
          @click="getData(1)"
          />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="gotoEdit()">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%" @sort-change="sortHeader">
        <el-table-column type="index" label="序号" width="80" align="center" ></el-table-column>
        <el-table-column prop="text" label="角色名" width="160" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="描述" min-width="180" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="enabled" label="状态" width="80" align="center">
          <template slot-scope="scope"><el-tag effect="dark" :type="scope.row.enabled? 'success':'warning'" >{{ scope.row.enabled | enabledText  }}</el-tag></template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人" width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable="custom" width="160" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createTime | dateTimeFormat  }}</template>
        </el-table-column>
        <el-table-column prop="modifyUserName" label="修改人"  width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="modifyTime" label="修改时间"  sortable="custom" width="160" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.modifyTime | dateTimeFormat }}</template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.enabled" @click="$router.push(`/System/Role/editPage?status=1&id=${scope.row.uid}`)" type="text" class="edit">编辑</el-button>
            <el-button @click="$router.push(`/System/Role/editPage?status=2&id=${scope.row.uid}`)" type="text" class="edit">查看</el-button>
            <el-button
              type="text"
              class="edit"
              @click="toggleEnabled( scope.row)"
            >{{ scope.row.enabled | operateEnableText  }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <!-- <el-dialog :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div class="dialog-header">{{ Form.id ? '编辑' : '新增' }}角色</div>
      <div class="dialog-main">
        <div class="search-item">
          <div class="item-label">
            <span class="star">*</span>角色名：
          </div>
          <div class="item-value">
            <el-input v-model="Form.text" clearable maxlength="30" ref="roleName"></el-input>
          </div>
        </div>
        <div class="search-item" style="width: 100%;">
          <div class="item-label">描述：</div>
          <div class="item-value">
            <el-input
              v-model="Form.remark"
              clearable
              maxlength="200"
              type="textarea"
              :rows="3"
              placeholder="限200字"
              show-word-limit
            ></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="确定" @click="saveForm()" :loading="loading" />
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
export default {
  components: {
    imsButton,
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      Query: {
        textLike: null,
        visibled:true,
        sorts:null
      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      Form: {
        text: null,
        enabled:true,
        remark: null
      }
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageRole({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveRole() {
      this.loading = true;
      this.$apis
        .pageRole({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sortHeader(data){
      if(data.order==='ascending'){
        this.Query.sorts=[{descending:false,propertyName:data.prop } ]
      }else if(data.order==='descending'){
        this.Query.sorts=[{descending:true,propertyName:data.prop }  ]
      }else{
        this.Query.sorts=null
      }
      this.getData();
    },
    addData() {
      this.Form = {
        name: null,
        enabled:true,
        remark: null
      };
      this.dialogVisible = true;
    },
    // editData(item) {
    //   this.Form = {
    //     ...item
    //   };
    //   this.dialogVisible = true;
    // },
    // 切换启/停用状态
    toggleEnabled(item) {
      if (item.enabled) {
        this.$confirm("您确定要禁用" + item.text + "角色吗?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning"
        }).then(() => {
          this.$apis
              .toggleRole(item.uid)
              .then(res => {
                this.getData();
              })
              .catch(() => {
              });
        });
      } else {
        this.$confirm("您确定启用" + item.text + "角色吗?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning"
        }).then(() => {
          this.$apis
              .toggleRole(item.uid)
              .then(res => {
                this.getData();
              })
              .catch(() => {
              });
        });
      }
    },
    gotoEdit(){
      this.$router.push(`/System/Role/editPage`)
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>