<template>
    <div class="page">
      <div class="page-header">
        <div class="page-title-box">
          <div class="page-title" style="display:inline-block">采购统计</div>
          <imsButton style="float: right;margin-top: 8px;" text="导出" :loading="loading" />
        </div>
      </div>
      <div class="page-body">
        <div class="page-search">
          <div class="search-item">
            <div class="item-label">时间单位：</div>
            <div class="item-value">
              <el-radio-group v-model="radio">
                <el-radio v-model="radio" label="1">年</el-radio>
                <el-radio v-model="radio" label="2">月</el-radio>
                <el-radio v-model="radio" label="3">日</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="search-item"  style="margin-right:200px">
            <div class="item-label" v-show="(radio=='1')">查询年份：</div>
            <div class="item-label" v-show="(radio=='2')">查询月份：</div>
            <div class="item-label" v-show="(radio=='3')">查询日份：</div>
            <div class="item-value">
              <el-date-picker
                v-model="Query.data"
                type="year"
                placeholder="请选择选择年份"
                v-show="(radio=='1')"
              ></el-date-picker>
              <el-date-picker
                v-model="Query.data"
                type="month"
                placeholder="请选择选择月份"
                v-show="(radio=='2')"
              ></el-date-picker>
              <el-date-picker
                v-model="Query.data"
                type="date"
                placeholder="选择日期"
                v-show="(radio=='3')"
              ></el-date-picker>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">供应商：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageSupplier" v-model="Query.Supplier" />
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">产品名称：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageVaccine" v-model="Query.Vaccine" />
            </div>
          </div>
          <imsButton
            style="float: right;margin-top: 4px;"
            text="查询"
            :loading="loading"
            @click="getData(1)"
          />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
        <el-table :data="tableData" border style="width: 100%" show-summary>
          <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
          <el-table-column prop="no" label="产品名称" width="140" align="center"></el-table-column>
          <el-table-column prop="name" label="门店" width="140" align="center"></el-table-column>
          <el-table-column prop="count" label="生产批号" width="120" align="center"></el-table-column>
          <el-table-column prop="count" label="单位" width="60" align="center"></el-table-column>
          <el-table-column prop="count" label="单价" width="60" align="center"></el-table-column>
          <!-- 年 -->
          <el-table-column prop="count" label="上年结存数量" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="上年结存金额" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="今年售出数量" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="今年退回金额" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="今年退回数量" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="今年退回金额" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="今年结存数量" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <el-table-column prop="count" label="今年结存金额" align="center" v-if="radio=='1'? true:false"></el-table-column>
          <!-- 月 -->
          <el-table-column prop="count" label="上月结存数量" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="上月结存金额" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="本月售出数量" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="本月退回金额" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="本月退回数量" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="本月退回金额" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="本月结存数量" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <el-table-column prop="count" label="本月结存金额" align="center" v-if="radio=='2'? true:false"></el-table-column>
          <!-- 日 -->
          <el-table-column prop="count" label="售出数量" align="center" v-if="radio=='3'? true:false"></el-table-column>
          <el-table-column prop="count" label="售出金额" align="center" v-if="radio=='3'? true:false"></el-table-column>
          <el-table-column prop="count" label="退回数量" align="center" v-if="radio=='3'? true:false"></el-table-column>
          <el-table-column prop="count" label="退回金额" align="center" v-if="radio=='3'? true:false"></el-table-column>
          <el-table-column prop="count" label="结存数量" align="center" v-if="radio=='3'? true:false"></el-table-column>
          <el-table-column prop="count" label="结存金额" align="center" v-if="radio=='3'? true:false"></el-table-column>
        </el-table>
        <el-pagination
          layout="total, prev, pager, next,jumper"
          :current-page="pageQuery.pageIndex"
          :total="pageQuery.total"
          @current-change="val => pageQuery.pageIndex = val"
        ></el-pagination>
      </div>
    </div>
  </template>
      <script>
  import imsButton from "@/components/imsButton.vue";
  import inputSelect from "@/components/inputSelect.vue";
  import ImsNpmn from "@/components/imsNpmn.vue";
  export default {
    components: {
      imsButton,
      inputSelect,
      ImsNpmn
    },
    data() {
      return {
        loading: false,
        radio: "1",
        Query: {
          data: null,
          Supplier: null,
          Vaccine: null
        },
        tableData: []
      };
    },
    methods: {
      getData(item) {
        var method = this.radio == "1" ? xxx : this.radio == "2" ? xxx : aaa;
        this.loading = true;
        if(item)
        this.pageQuery={
          ...this.pageQuery,
          pageIndex:1
        }
        this.$apis[method]({ ...this.Query, ...this.pageQuery })
          .then(res => {
            this.tableData = res.body.records;
            this.pageQuery.total = Number(res.body.recordCount);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } ,
      handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
      this.$nextTick(() => {
        this.getData();
      });
    }
  };
  </script>