<template>
  <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    商品列表
                </div>
            </div>
        </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">创建日期：</div>
          <div class="item-value">
            <el-date-picker
              v-model="param.createTime"
              size="small"
              style="width: 340px"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          </div>
          <div class="search-item">
            <div class="item-label">产品名称：</div>
            <div class="item-value"> 
              <el-input
                placeholder="查找产品名称中包含指定文字的产品"
                v-model="param.name"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">产品货号：</div>
            <div class="item-value">  
              <el-input
                placeholder="精准查询产品货号"
                v-model="param.productNo"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">分类类别：</div>
            <div class="item-value">  
              <el-select
                v-model="param.categoryType"
                @change="getCategorysListApi"
                clearable
              >
                <el-option :value="1" label="销售"></el-option>
                <el-option :value="2" label="预订"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">产品分类：</div>
            <div class="item-value">  
              <el-select v-model="param.categoryId" placeholder="请选择" clearable>
                <el-option
                  v-for="item in categorysList"
                  :label="item.name"
                  :value="item.categoryId"
                  :key="item.prop"
                  :prop="item.prop"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="onSelect"/>
        </div>
      </div>

      <div class="base-table">
        <div class="table-btns">
          <span class="link" style="float: right;"  @click="handleCreate"
            >添加商品</span>
        </div>
        <el-table :data="productList" v-loading="loading" border>
          <el-table-column type="index" label="序号" width="100" align="center" />
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            :show-overflow-tooltip="item.prop!='coverImage'"
          >
            <template v-if="item.prop === 'coverImage'" #default="scope">
              <img class="list-image m-6" :src="scope.row.coverImage" alt=""  width="100"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="280">
            <template #default="scope">
              <template v-if="scope.row.status === 2">
                <el-popconfirm
                  title="是否确认下架？"
                  @confirm="handleStatus(scope.row)"
                >
                  <template #reference>
                    <el-button type="warning" icon="el-icon-error"
                    size="small" 
                      >下架</el-button
                    >
                  </template>
                </el-popconfirm>
              </template>
              <template v-else>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="small" 
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="warning"
                  icon="el-icon-success"
                  size="small" 
                  @click="handleStatus(scope.row)"
                  >上架</el-button
                >
                <el-popconfirm
                  title="是否确认下架？"
                  style="margin-left: 10px"
                  @confirm="handleDelete(scope.row)"
                >
                  <template #reference>
                    <el-button type="danger" size="small" icon="el-icon-delete">
                      删除
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <template>
          <Pagination
            v-show="page.total > 0"
            :total="page.total"
            :page.sync="param.pageIndex"
            :limit.sync="param.pageSize"
            @pagination="onSelect"
          />
        </template>
      </div>
      <ProductDialog
        ref="productDialog"
        :dialog-title="dialogTitle"
        @updateList="onSelect"
      />
    </div>
</div>
</template>

<script>
import {deleteProduct, getCategorysList, getProductList, updateProductStatus,} from "@/api/Product";
import imsButton from "@/components/imsButton.vue";
import ProductDialog from "./components/ProductDialog";

export default {
  name: "productList",
  components: {
    ProductDialog,
    imsButton
  },
  data() {
    return {
      loading: false,
      categorysList: [],
      productList: [],
      attrList: [],
      param: {
        pageIndex: 1,
        pageSize: 10,
        name: null,
        productNo: null,
        categoryType: null,
        categoryId: null,
        createTime: null,
        createTimeStart: null,
        createTimeEnd: null,
      },
      page: {
        total: 0,
      },
      productInfo: {},
      dialogTitle: "添加商品",
      columns: [
        {
          label: "录入时间",
          prop: "createDateTime",
          width: "180",
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: "YYYY-MM-DD HH:mm:ss",
            });
          },
        },
        {
          label: "产品货号",
          prop: "productNo",
        },
        {
          label: "产品名称",
          prop: "name",
        },
        {
          label: "首图",
          prop: "coverImage",
          width:120
        },
        {
          label: "产品类型",
          prop: "category",
        },
        {
          label: "展示价格",
          prop: "markPrice",
        },
        {
          label: "发布状态",
          prop: "status",
          formatter: (row, column, value) => {
            return {
              1: "未启用",
              2: "已启用",
              3: "已删除",
            }[value];
          },
        },
      ],
    };
  },
  mounted() {
    this.getCategorysListApi();
    this.getProductListApi();
  },
  methods: {
    async getCategorysListApi() {
      let query = {};
      if (
        this.param.categoryType != null &&
        this.param.categoryType != undefined
      ) {
        query.categoryType = this.param.categoryType;
      }
      this.param.categoryId = null;
      this.categorysList = await getCategorysList(query);
    },
    async getProductListApi() {
      this.loading = true;
      let data = await getProductList(this.param);
      this.productList = data.records;
      this.page.total = data.recordCount;
      this.loading = false;
    },
    onSelect() {
      if (this.param.createTime != null && this.param.createTime.length > 1) {
        this.param.createTimeStart = this.param.createTime[0];
        this.param.createTimeEnd = this.param.createTime[1];
      } else {
        this.param.createTimeStart = null;
        this.param.createTimeEnd = null;
      }
      if (this.param.categoryId == "") {
        this.param.categoryId = null;
      }
      this.getProductListApi();
    },
    handleView(row) {
      this.dialogTitle = "查看商品";
      this.productInfo = row;
    },
    handleCreate() {
      this.dialogTitle = '添加商品'
      this.$refs.productDialog.getData()
      this.$router.push({
        path: "/Product/create",
      });
    },
    handleEdit(row) {
      this.productInfo = {
        categoryId: row.categoryId,
        description: row.description,
        name: row.name,
        fileList: [
          {
            url: row.icon
          }
        ]
      };
      this.dialogTitle = '编辑商品'
      this.$refs.productDialog.getData(row.productId)
      // this.$router.push({
      //   path: `/product/edit？id=${productId}`,
      // });
    },        
    handleReset() {
      let data = {}
      for(let key in this.param){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.param[key]
          }
      }
      this.initListData(data)
      this.param = Object.assign(this.param,data)
      this.$nextTick(() => {
      this.getProductListApi()
            })
      },
    // 上下架
    async handleStatus(row) {
      const data = {
        productId: row.productId,
        status: row.status === 2 ? 1 : 2,
      };
      await updateProductStatus(data);
      this.$message.success("操作成功");
      this.onSelect();
    },
    async handleDelete(row) {
      await deleteProduct({
        id: row.productId,
      });
      this.$message.success("删除成功");
      this.onSelect();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
