<template>
    <div class="ims-npmn" id="ims-npmn">
        <div v-for="(item) in nodeList" :key="item.nodeId" :class="getClass(item)" :style="{
            'border-color': item.borderColor, 'color': item.color, 'background-color': item.bgColor,
            'left': item.coordinate[0] + 'px', 'top': item.coordinate[1] + 'px'
        }" class="npmn-item" :title="item.text">
            <span
                style="word-break: break-all;word-wrap: break-word;display: inline-block;width: 100%;line-height: 22px;">
                {{ item.text }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: { // 流程图数据
            type: Object,
            default: null
        }
    },
    watch: {
        data: {
            handler(val) {
                if (val) {
                    this.activeNodes = val.activeNodes ? val.activeNodes : this.activeNodes
                    this.nodeList = val.nodeList ? val.nodeList : this.nodeList
                    this.arrowList = val.arrowList ? val.arrowList : this.arrowList
                    this.readOnly = val.readOnly ? val.readOnly : this.readOnly
                    this.$nextTick(() => {
                        this.isActive(this.activeNodes)
                        setTimeout(() => {
                            this.drawArrow()
                        }, 100);
                    })
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            activeNodes: [],
            nodeList: [
                {
                    nodeId: 1, // 节点id
                    type: 1, // 1：圆形 2：矩形 3：菱形
                    text: '开始', // 节点文字
                    borderColor: 'rgba(0, 0, 0, .35)', // 边框颜色
                    color: 'rgba(0, 0, 0, .85)', // 文本颜色
                    bgColor: 'rgba(255, 255, 255, 1)', // 填充颜色
                    coordinate: [0, 80], // 节点坐标(px)
                },
                {
                    nodeId: 2, // 节点id
                    type: 2, // 1：圆形 2：矩形 3：菱形
                    text: '示例节点', // 节点文字
                    borderColor: 'rgba(0, 0, 0, .35)', // 边框颜色
                    color: 'rgba(0, 0, 0, .85)', // 文本颜色
                    bgColor: 'rgba(255, 255, 255, 1)', // 填充颜色
                    coordinate: [160, 80], // 节点坐标(px)
                },
                {
                    nodeId: 3, // 节点id
                    type: 2, // 1：圆形 2：矩形 3：菱形
                    text: '示例节点', // 节点文字
                    borderColor: 'rgba(0, 0, 0, .35)', // 边框颜色
                    color: 'rgba(0, 0, 0, .85)', // 文本颜色
                    bgColor: 'rgba(255, 255, 255, 1)', // 填充颜色
                    coordinate: [440, 80], // 节点坐标(px)
                },
                {
                    nodeId: 4, // 节点id
                    type: 3, // 1：圆形 2：矩形 3：菱形
                    text: '是否通过', // 节点文字
                    borderColor: 'rgba(0, 0, 0, .35)', // 边框颜色
                    color: 'rgba(0, 0, 0, .85)', // 文本颜色
                    bgColor: 'rgba(255, 255, 255, 1)', // 填充颜色
                    coordinate: [720, 70], // 节点坐标(px)
                },
                {
                    nodeId: 5, // 节点id
                    type: 2, // 1：圆形 2：矩形 3：菱形
                    text: '示例判断', // 节点文字
                    borderColor: 'rgba(0, 0, 0, .35)', // 边框颜色
                    color: 'rgba(0, 0, 0, .85)', // 文本颜色
                    bgColor: 'rgba(255, 255, 255, 1)', // 填充颜色
                    coordinate: [960, 80], // 节点坐标(px)
                },
            ],
            arrowList: [ // 箭头连接关系
                {
                    startId: 1, // 起点节点id
                    linePosition: 'right-left', // 连接线起始位置
                    endId: 2, // 终点节点id
                    color: 'rgba(0, 0, 0, .15)', // 箭头及连接线颜色
                },
                {
                    startId: 2, // 起点节点id
                    linePosition: 'right-left', // 连接线起始位置
                    endId: 3, // 终点节点id
                    color: 'rgba(0, 0, 0, .15)', // 箭头及连接线颜色
                    text: '通过', // 箭头连接线文字
                },
                {
                    startId: 3, // 起点节点id
                    linePosition: 'right-left', // 连接线起始位置
                    endId: 4, // 终点节点id
                    color: 'rgba(0, 0, 0, .15)', // 箭头及连接线颜色
                },
                {
                    startId: 4, // 起点节点id
                    linePosition: 'top-top', // 连接线起始位置
                    endId: 2, // 终点节点id
                    color: 'rgba(0, 0, 0, .15)', // 箭头及连接线颜色
                    text: '不通过', // 箭头连接线文字
                },
                {
                    startId: 4, // 起点节点id
                    linePosition: 'bottom-bottom', // 连接线起始位置
                    endId: 3, // 终点节点id
                    color: 'rgba(0, 0, 0, .15)', // 箭头及连接线颜色
                    text: '不通过', // 箭头连接线文字
                    height: 100, // 向上或向下延申的高度，可不传，默认100
                },
                {
                    startId: 4, // 起点节点id
                    linePosition: 'right-left', // 连接线起始位置
                    endId: 5, // 终点节点id
                    color: 'rgba(0, 0, 0, .15)', // 箭头及连接线颜色
                    text: '通过', // 箭头连接线文字
                },
            ],
            readOnly: true
        }
    },
    methods: {
        getClass({ type, nodeId }) {
            var className = ''
            if (type == 1) {
                className = 'round'
            }
            if (type == 2) {
                className = 'rect'
            }
            if (type == 3) {
                className = 'diam'
            }
            if (type == 4) {
                className = 'none'
            }
            if (this.activeNodes.indexOf(nodeId) > -1) {
                className += ' active'
            }
            return className
        },
        // 绘制箭头连接线
        drawArrow() {
            this.arrowList.forEach(item => {
                // 获取起点、终点节点信息
                var startNode = this.nodeList.filter(node => node.nodeId == item.startId)
                var endNode = this.nodeList.filter(node => node.nodeId == item.endId)
                startNode = startNode[0]
                endNode = endNode[0]
                let arrowBox = window.document.createElement("div");
                arrowBox.className = "npmn-arrow"
                if (item.linePosition === 'right-left') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-top: 2px solid;
                    width: ${this.getArrowWidth(startNode, endNode)}px;
                    height: 80px;
                    border-color: ${item.color};
                    top: ${startNode.coordinate[1] + (startNode.type == 3 ? 86 : 64) / 2}px;
                    left: ${(startNode.coordinate[0] + (startNode.type == 1 ? 32 :
                            startNode.type == 2 ? 82 : 42))}px'>
                        <span class="fa fa-caret-right" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    right: ${(endNode.type == 1 ? 30 : endNode.type == 2 ? 78 : 56)}px;
                    top: -15px;"></span>
                    <span style="
                    position: absolute;
                    background-color: #fff;
                    font-size: 16px;
                    top: -12px;
                    padding: 0 6px;
                    z-index: 99;
                    left: ${this.getTextLeft(startNode, endNode)}px;">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'left-right') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-top: 2px solid;
                    width: ${this.getArrowWidth(startNode, endNode)}px;
                    height: 80px;
                    border-color: ${item.color};
                    top: ${startNode.coordinate[1] + (startNode.type == 3 ? 86 : 64) / 2}px;
                    left: ${(endNode.coordinate[0] + (endNode.type == 1 ? 32 :
                        endNode.type == 2 ? 82 : 42))}px'>
                        <span class="fa fa-caret-left" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: ${(endNode.type == 1 ? 30 : endNode.type == 2 ? 80 : 42.5)}px;
                    top: -15px;"></span>
                    <span style="
                    position: absolute;
                    background-color: #fff;
                    font-size: 16px;
                    top: -12px;
                    padding: 0 6px;
                    z-index: 99;
                    left: ${this.getTextLeft(startNode, endNode)}px;">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'top-top') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-top: 2px solid;
                    border-right: 2px solid;border-left: 2px solid;border-radius: 10px;
                    width: ${this.getArrowWidth(startNode, endNode)}px;
                    height: ${item.height ? item.height : 100}px;
                    border-color: ${item.color};
                    top: ${endNode.coordinate[1] - 124 / 2}px;
                    left: ${this.getArrowBoxLeft(startNode, endNode)}px';>
                        <span class="fa fa-caret-down" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: ${this.getArrowLeft(startNode, endNode)};
                    top: ${40}px;"></span>
                        <span style="
                    position: absolute;
                    background-color: #fff;
                    font-size: 16px;
                    top: -12px;
                    padding: 0 6px;
                    z-index: 99;
                    left: calc(50% - 20px);">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'bottom-bottom') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-bottom: 2px solid;
                    border-right: 2px solid;border-left: 2px solid;border-radius: 10px;
                    width: ${this.getArrowWidth(startNode, endNode)}px;
                    height: ${item.height ? item.height : 100}px;
                    border-color: ${item.color};
                    top: ${endNode.coordinate[1] + 24 / 2}px;
                    left: ${this.getArrowBoxLeft(startNode, endNode)}px';>
                        <span class="fa fa-caret-up" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: ${this.getArrowLeft(startNode, endNode)};
                    top: ${40}px;"></span>
                        <span style="
                    position: absolute;
                    background-color: #fff;
                    font-size: 16px;
                    bottom: -12px;
                    padding: 0 6px;
                    z-index: 99;
                    left: calc(50% - 20px);">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'top-bottom') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-left: 2px solid;
                    height: ${this.getArrowHeight(startNode, endNode)}px;
                    width: 80px;
                    border-color: ${item.color};
                    top: ${endNode.coordinate[1] + (endNode.type == 3 ? 86 : 64) / 2}px;
                    left: ${(startNode.coordinate[0] + (startNode.type == 1 ? 32 :
                            startNode.type == 2 ? 82 : 42))}px'>
                        <span class="fa fa-caret-up" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: -10px;
                    top: ${(endNode.type == 3 ? 24 : 20)}px;"></span>
                    <span style="
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    background-color: #fff;
                    font-size: 16px;
                    left: calc(-50% - 8px);
                    padding: 0 6px;
                    z-index: 99;
                    top: ${this.getTextTop(startNode, endNode)}px;">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'bottom-top') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-left: 2px solid;
                    height: ${this.getArrowHeight(startNode, endNode)}px;
                    width: 80px;
                    border-color: ${item.color};
                    top: ${startNode.coordinate[1] + (startNode.type == 3 ? 86 : 64) / 2}px;
                    left: ${(startNode.coordinate[0] + (startNode.type == 1 ? 32 :
                            startNode.type == 2 ? 82 : 42))}px'>
                        <span class="fa fa-caret-down" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: -10px;
                    bottom: ${(startNode.type == 3 ? 24 : 22)}px;"></span>
                    <span style="
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    background-color: #fff;
                    font-size: 16px;
                    left: calc(-50% - 8px);
                    padding: 0 6px;
                    z-index: 99;
                    top: ${this.getTextTop(startNode, endNode)}px;">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'bottom-right') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-bottom: 2px solid;
                    border-right: 2px solid;border-radius: 10px;
                    width: ${this.getArrowWidth(startNode, endNode)}px;
                    height: ${item.height ? item.height : 100}px;
                    border-color: ${item.color};
                    top: ${startNode.coordinate[1] + (startNode.type == 1 ? 30 : startNode.type == 2 ? 16 : 42.5)}px;
                    left: ${this.getArrowBoxLeft(startNode, endNode)}px';>
                        <span class="fa fa-caret-left" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: ${(endNode.type == 1 ? 30 : endNode.type == 2 ? 80 : 42.5)}px;
                    bottom: ${-16}px;"></span>
                        <span style="
                    position: absolute;
                    background-color: #fff;
                    font-size: 16px;
                    bottom: -12px;
                    padding: 0 6px;
                    z-index: 99;
                    left: calc(50% - 20px);">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                if (item.linePosition === 'top-right') {
                    arrowBox.innerHTML = `
                    <div class="npmn-arrow" style='display: inline-block;position: absolute;border-top: 2px solid;
                    border-right: 2px solid;border-radius: 10px;
                    width: ${this.getArrowWidth(startNode, endNode)}px;
                    height: ${item.height ? item.height : 100}px;
                    border-color: ${item.color};
                    top: ${endNode.coordinate[1] + (endNode.type == 1 ? 30 : endNode.type == 2 ? 30 : 42.5) + 20}px;
                    left: ${this.getArrowBoxLeft(startNode, endNode)}px';>
                        <span class="fa fa-caret-left" style="
                    color: ${item.color};
                    font-size: 30px;
                    position:absolute;
                    left: ${(endNode.type == 1 ? 30 : endNode.type == 2 ? 80 : 42.5)}px;
                    top: ${-16}px;"></span>
                        <span style="
                    position: absolute;
                    background-color: #fff;
                    font-size: 16px;
                    bottom: -12px;
                    padding: 0 6px;
                    z-index: 99;
                    left: calc(50% - 20px);">${item.text ? item.text : ''}</span>
                        </div>
                `
                }
                document.getElementById('ims-npmn').appendChild(arrowBox)
            })
        },
        // 获取箭头容器定位left值
        getArrowBoxLeft(startNode, endNode) {
            if (startNode.coordinate[0] > endNode.coordinate[0]) {
                return (endNode.coordinate[0] + (endNode.type == 1 ? 32 :
                    endNode.type == 2 ? 80 : 44))
            } else {
                return (startNode.coordinate[0] + (startNode.type == 1 ? 32 :
                    startNode.type == 2 ? 80 : 44))
            }
        },
        // 获取箭头定位left值
        getArrowLeft(startNode, endNode) {
            if (startNode.coordinate[0] > endNode.coordinate[0]) {
                return `-10px`
            } else {
                return `calc(100% - 8px)`
            }
        },
        // 获取箭头文字left值
        getTextLeft(startNode, endNode) {
            var left = this.getArrowWidth(startNode, endNode)
            left -= (startNode.type == 1 ? 60 : startNode.type == 2 ? 160 : 85) / 2
            left -= (endNode.type == 1 ? 60 : endNode.type == 2 ? 160 : 85) / 2
            left = left / 2
            left += (startNode.type == 1 ? 60 : startNode.type == 2 ? 160 : 85) / 2
            left -= 20
            return left
        },
        // 获取箭头文字top值
        getTextTop(startNode, endNode) {
            var top = this.getArrowHeight(startNode, endNode)
            top -= (startNode.type == 1 ? 60 : startNode.type == 2 ? 160 : 85) / 2
            top -= (endNode.type == 1 ? 60 : endNode.type == 2 ? 160 : 85) / 2
            top = top / 2
            top += (startNode.type == 1 ? 60 : startNode.type == 2 ? 160 : 85) / 2
            return top
        },
        // 获取箭头宽度
        getArrowWidth(startNode, endNode) {
            var width = 0
            if (startNode.coordinate[0] > endNode.coordinate[0]) {
                width = startNode.coordinate[0] - endNode.coordinate[0]
                width += (startNode.type == 1 ? 60 : startNode.type == 2 ? 160 : 85) / 2
                width -= (endNode.type == 1 ? 60 : endNode.type == 2 ? 160 : 85) / 2
            } else {
                width = endNode.coordinate[0] - startNode.coordinate[0]
                width -= (startNode.type == 1 ? 60 : startNode.type == 2 ? 160 : 85) / 2
                width += (endNode.type == 1 ? 60 : endNode.type == 2 ? 160 : 85) / 2
            }
            return width
        },
        // 获取箭头高度
        getArrowHeight(startNode, endNode) {
            var height = 0
            if (startNode.coordinate[1] > endNode.coordinate[1]) {
                height = startNode.coordinate[1] - endNode.coordinate[1]
                height += (startNode.type == 1 ? 60 : startNode.type == 2 ? 64 : 85) / 2
                height -= (endNode.type == 1 ? 60 : endNode.type == 2 ? 64 : 85) / 2
            } else {
                height = endNode.coordinate[1] - startNode.coordinate[1]
                height -= (startNode.type == 1 ? 60 : startNode.type == 2 ? 64 : 85) / 2
                height += (endNode.type == 1 ? 60 : endNode.type == 2 ? 64 : 85) / 2
            }
            return height
        },
        // ative处理
        isActive(nodeIds) {
            this.nodeList = this.nodeList.map(item => {
                if (nodeIds.indexOf(item.nodeId) > -1) {
                    return {
                        ...item,
                        borderColor: ' #BB1BB5'
                    }
                } else return item
            })
            // this.arrowList = this.arrowList.map(item => {
            //     if ((nodeIds.indexOf(item.startId) > -1) && (nodeIds.indexOf(item.endId) > -1)) {
            //         return {
            //             ...item,
            //             color: '@basicColor'
            //         }
            //     } else return item
            // })
        }
    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
.ims-npmn {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;

    .npmn-arrow {
        display: inline-block;
        position: absolute;
        border-top: 1px solid red;
    }

    .npmn-item {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        border: 2px solid;
        text-align: center;
        position: absolute;
        overflow: hidden;
        z-index: 99;
        border-radius: 10px;

        &.active {
            font-weight: bold;
        }

        &.round { // 圆形60x60
            border-radius: 50%;
            padding: 10px;
            width: 40px;
        }

        &.rect { // 矩形160x60
            padding: 10px 0px;
            width: 160px;
        }

        &.diam { // 菱形85x85
            width: 85px;
            height: 85px;
            transform: rotateZ(45deg);

            >span {
                transform: rotateZ(-45deg)translate(-16px, 16px);
                text-align: center;
            }
        }

        &.none { // 隐藏辅助盒子160x60
            padding: 10px 0px;
            width: 160px;
        }
    }
}
</style>