<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">{{ Form.id ? '编辑产品信息' : '新增产品信息' }}</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div class="search-item" data-required="name">
          <div class="item-label">
            <span class="star">*</span>产品名称：
          </div>
          <div class="item-value">
            <el-input v-model="Form.name" ref="name" clearable maxlength="99"></el-input>
          </div>
        </div>
        <div class="search-item" data-required="attributeType">
          <div class="item-label">
            <span class="star">*</span>属性：
          </div>
          <div class="item-value">
            <el-select v-model="Form.attributeType" placeholder="请选择" ref="attributeType">
              <el-option v-for="item in attributeTypeOptions" :key="item.code" :label="item.name"
                :value="item.code"></el-option>
            </el-select>
          </div>
        </div>
        <div class="search-item" data-required="supplierId">
          <div class="item-label">
            <span class="star">*</span>供应商：
          </div>
          <div class="item-value">
            <inputSelect apiMethod="pageSupplier" v-model="Form.supplierId" ref="supplierId"
              :label="Form.supplier.name" />
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            批准文号：
          </div>
          <div class="item-value">
            <el-input v-model="Form.drugApprovalNo" clearable maxlength="99" ref="drugApprovalNo"></el-input>
          </div>
        </div>
        <div class="search-item" data-required="specification">
          <div class="item-label">
            <span class="star">*</span>规格：
          </div>
          <div class="item-value">
            <el-input v-model="Form.specification" clearable maxlength="99" ref="specification"></el-input>
          </div>
        </div>
        <!-- <div class="search-item">
          <div class="item-label">剂型：</div>
          <div class="item-value">
            <el-input v-model="Form.formulation" clearable maxlength="99"></el-input>
          </div>
        </div> -->
        <!-- <div class="search-item">
          <div class="item-label">冻量：</div>
          <div class="item-value">
            <el-input v-model="Form.cryodose" clearable maxlength="99"></el-input>
          </div>
        </div> -->
        <div class="search-item">
          <div class="item-label">进口通关单编号：</div>
          <div class="item-value">
            <el-input v-model="Form.customsNo" clearable maxlength="99"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">合格证明编号：</div>
          <div class="item-value">
            <el-input v-model="Form.qualificationNo" clearable maxlength="99"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">条码：</div>
          <div class="item-value">
            <el-input v-model="Form.barCode" clearable maxlength="99"></el-input>
          </div>
        </div>
        <div class="search-item" data-required="price">
          <div class="item-label">
            <span class="star">*</span>销售单价：
          </div>
          <div class="item-value">
            <inputNumber v-model="Form.price" ref="price" :max="99999999" :min="0" :precision="2" />
          </div>
        </div>
        <div class="search-item" data-required="unitId">
          <div class="item-label">
            <span class="star">*</span>计量单位：
          </div>
          <div class="item-value">
            <inputSelect apiMethod="pageUnits" v-model="Form.unitId" ref="unitId" :label="Form.unit.name" />
          </div>
        </div>
        <div class="search-item" data-required="warehouseLocation">
          <div class="item-label">
            <span class="star">*</span>默认库位：
          </div>
          <div class="item-value">
            <el-cascader v-model="Form.warehouseLocation" :props="{ value: 'id' }" ref="warehouseLocation"
              :options="warehouseCascade" @change="warehouseHandleChange">
            </el-cascader>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">
            每件数量：
          </div>
          <div class="item-value">
            <inputNumber v-model="Form.eachQuantity" ref="eachQuantity" :max="99999999" :min="1" />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <imsButton typeClass="secondary" class="button" text="返回" @click="$router.push('/System/Vaccine')" />
      <imsButton typeClass="primary" class="button" text="保存" :loading="loading" @click="saveForm()" />
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import { mapMutations } from "vuex";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber
  },
  mixins: [mixins],
  computed: {
    ...mapMutations["getHeadline"]
  },
  data() {
    return {
      headline: "",
      hoverKey: "",
      loading: false,
      disabled: false,
      options: [],
      attributeTypeOptions: [],
      unitsOptions: [],
      warehouseCascade: [],
      Form: {
        validDateStart: "2020-12-12",
        supplier: {
          name: ''
        },
        unit: {
          name: ''
        },
        warehouseLocation: []
      }
    };
  },
  methods: {
    getData() {
      if (this.$route.query.id) {
        this.$apis.getVaccine(this.$route.query.id).then(res => {
          this.Form = res.body;
          this.Form.warehouseLocation = [res.body.warehouseId, res.body.warehouseLocationId]
        }).catch(()=>{})
      }
    },
    getAttributeType() {
      this.$apis.getAttributeType().then(res => {
        this.attributeTypeOptions = res.body.options;
      }).catch(()=>{})
    },
    getWarehouseCascade() {
      this.$apis.getWarehouseCascade().then(res => {
        this.warehouseCascade = res.body;
      }).catch(()=>{})
    },
    getUnitsOptions() {
      this.$apis.pageUnits({ pageSize: 999, enabled: true }).then(res => {
        this.unitsOptions = res.body.records;
      }).catch(()=>{})
    },
    saveForm() {
      var bool = this.checkForm(document.querySelectorAll('*[data-required]'), this.Form)
      if (!bool) return

      if (this.Form.warehouseLocation.length == 0) {
        this.$message.warning("请选择“默认库位”")
        return
      }

      this.loading = true;
      if (this.Form.id) {
        this.$apis
          .editVaccine(this.Form, this.Form.id)
          .then(res => {
            if (res.body) {
              this.$message.success("编辑成功！");
            }
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        //新增
        this.$apis
          .addVaccine(this.Form)
          .then(res => {
            if (res.body) {
              this.$message.success("添加成功，即将跳转至列表页！");
            }
            this.getData();
            setTimeout(() => {
              this.$router.push("/System/Vaccine");
            }, 1000);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    warehouseHandleChange(value) {
      this.Form.warehouseId = value[0]
      this.Form.warehouseLocationId = value[1]
    }
  },
  mounted() {
    this.getData();
    this.getAttributeType();
    this.getUnitsOptions();
    this.getWarehouseCascade();
  }
};
</script>

<style lang="less" scoped>
.page-body,.page-search{
  margin-bottom: 100px;
}
.item-value {
  width: calc(100% - 148px) !important;
}

.label {
  width: 140px !important;
}

.button {
  margin-right: 10px;
}

.title {
  font-size: 20px;
  color: #797979;
  margin-top: 10px;
}
.page .page-body .page-search{
  flex-wrap: wrap;
}
</style>