<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          营销主题
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">主题名称：</div>
            <div class="item-value">
              <el-input placeholder="请输入主题名称" v-model="param.name" clearable>
              </el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="onSelect"/>
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset('queryForm')" />
        </div>
            <!-- <el-button @click="handleReset('queryForm')">重置</el-button> -->
      </div>
      <div class="base-table">
        <div class="action">
          <span class="link" style="float: right; margin: 15px;" @click="handleCreate"
            >新增主题</span
          >
        </div>
        <el-table :data="productList" v-loading="loading" border>
          <el-table-column type="index" label="序号" width="100" align="center" />
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            :show-overflow-tooltip="item.prop!='topImg'"
          >
            <template v-if="item.prop === 'topImg'" #default="scope">
              <img class="list-image m-6" :src="scope.row.topImg" alt=""  width="100"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <template>
                <el-popconfirm
                  title="是否确认停用？"
                  style="margin-right: 10px"
                  @confirm="handleIsUse(scope.row)"
                  v-if="scope.row.isUsed"
                >
                  <template #reference>
                    <el-button type="warning" icon="el-icon-error"
                    size="mini"
                      >停用</el-button
                    >
                  </template>
                </el-popconfirm>
                <el-button
                  type="warning"
                  icon="el-icon-success"
                  size="mini"
                  @click="handleIsUse(scope.row)"
                  v-if="!scope.row.isUsed"
                  >启用</el-button
                >
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="handleEdit(scope.row.themeId)"
                  >编辑</el-button
                >
                <el-popconfirm
                  title="是否确认删除？"
                  style="margin-left: 10px"
                  size="mini"
                  @confirm="handleDelete(scope.row.categoryId)"
                >
                  <template #reference>
                    <el-button type="danger" icon="el-icon-delete-solid" size="mini">
                      删除
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <template>
          <Pagination
            v-show="page.total > 0"
            :total="page.total"
            :page.sync="param.pageIndex"
            :limit.sync="param.pageSize"
            @pagination="onSelect"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getThemeList,
  updateThemeIsUse,
  sortTheme,
  deleteTheme,
} from "@/api/Marketings/theme";
import imsButton from "@/components/imsButton.vue";

export default {
  name: "themeList",
  components:{
    imsButton
  },
  data() {
    return {
      loading: false,
      productList: [],
      param: {
        pageIndex: 1,
        pageSize: 10,
        name: null,
      },
      page: {
        total: 0,
      },
      productInfo: {},
      dialogTitle: "添加商品",
      columns: [
        {
          label: "主题名称",
          prop: "name",
        },
        {
          label: "主题顶图",
          prop: "topImg",
          width: 120
        },
        {
          label: "每行显示商品个数",
          prop: "rowShowNum",
        },
        {
          label: "主题商品数量",
          prop: "productCount",
        },
        {
          label: "是否启用",
          prop: "isUsed",
          formatter: (row, column, value) => {
            return value ? "启用" : "停用";
          },
        },
      ],
    };
  },
  mounted() {
    this.getThemeListApi();
  },
  methods: {
    async getThemeListApi() {
      this.loading = true;
      let data = await getThemeList(this.param);
      this.productList = data.records;
      this.page.total = data.recordCount;
      this.loading = false;
    },
    onSelect() {
      this.getThemeListApi();
    },
    handleCreate() {
      this.$router.push({
        path: "/Marketing/theme/create",
      });
    },
    handleEdit(themeId) {
      this.$router.push({
        path: `/Marketing/theme/edit?themeId=${themeId}`,
      });
    },
    handleReset() {
      let data = {}
      for(let key in this.param){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.param[key]
          }
      }
      this.initListData(data)
      this.param = Object.assign(this.param,data)
      this.getThemeListApi();
    },
    async handleIsUse(row) {
      await updateThemeIsUse(row);
      this.$message.success("操作成功");
      this.getThemeListApi();
    },
    async handleDelete(id) {
      await deleteTheme(row);
      this.$message.success("删除成功");
      this.getThemeListApi();
    },
  },
};
</script>

<style lang="less" scoped></style>
