import Cookies from 'js-cookie'

const TokenKey = 'accessToken'
const UserInfo = 'userinfo'

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setUserInfo(userinfo) {
  return Cookies.set(UserInfo, userinfo)
}

export function getUserInfo() {
  return Cookies.get(UserInfo) ? JSON.parse(Cookies.get(UserInfo)) : {}
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}
