import Vue from "vue";
import Vuex from "vuex";
import API from "../api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    headline: "",
    roleMenu: [
      {
        level: 1,
        route: "/Index",
        text: "首页",
        name: "menu-index",
        icon: "fas fa-laptop",
      },
      {
        level: 1,
        route: "/Purchase",
        text: "采购管理",
        name: "menu-purchase",
        icon: "fas fa-shopping-cart",
        children: [
          {
            level: 2,
            route: "/Purchase/Plan",
            text: "采购计划",
            hideenChildren: true,
            children: [
              {
                route: "/Purchase/Plan/formPage",
                text: "创建/编辑采购计划",
              },
              {
                route: "/Purchase/Plan/detailPage",
                text: "查看/审核采购计划",
              },
              {
                route: "/Purchase/Plan/historyPage",
                text: "查看采购计划历史",
              },
            ],
          },
          {
            level: 2,
            route: "/Purchase/Order",
            text: "采购订单",
            hideenChildren: true,
            children: [
              {
                route: "/Purchase/Order/formPage",
                text: "编辑采购订单",
              },
              {
                route: "/Purchase/Order/detailPage",
                text: "查看采购订单",
              },
            ],
          },
          {
            level: 2,
            route: "/Purchase/Reject",
            text: "采购退货",
            hideenChildren: true,
            children: [
              {
                route: "/Purchase/Reject/formPage",
                text: "创建/编辑退货单",
              },
              {
                route: "/Purchase/Reject/detailPage",
                text: "查看/审核退货单",
              },
            ],
          },
        ],
      },
      {
        level: 1,
        route: "/Sale",
        text: "销售管理",
        name: "menu-sale",
        icon: "fas fa-comments-dollar",
        children: [
          {
            level: 2,
            route: "/Sale/Order",
            text: "销售订单",
            hideenChildren: true,
            children: [
              {
                route: "/Sale/Order/formPage",
                text: "创建/编辑销售单",
              },
              {
                route: "/Sale/Order/detailPage",
                text: "查看/审核销售单",
              },
            ],
          },
          {
            level: 2,
            route: "/Sale/Reject",
            text: "销售退货",
            hideenChildren: true,
            children: [
              {
                route: "/Sale/Reject/formPage",
                text: "创建/编辑退货单",
              },
              {
                route: "/Sale/Reject/detailPage",
                text: "查看/审核退货单",
              },
            ],
          },
        ],
      },
      {
        level: 1,
        route: "/Stock",
        text: "库存管理",
        name: "menu-stock",
        icon: "fas fa-home",
        children: [
          {
            level: 2,
            route: "/Stock/Into",
            text: "采购入库",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/Into/formPage",
                text: "采购入库",
              },
              {
                route: "/Stock/Into/detail",
                text: "采购入库详情",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/Out",
            text: "销售出库",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/Out/detail",
                text: "销售出库详情",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/RejectInto",
            text: "销售退货入库",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/RejectInto/formPage",
                text: "销售退货入库/记账销售退货入库",
              },
              {
                route: "/Stock/RejectInto/detail",
                text: "销售退货入库详情",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/RejectOut",
            text: "采购退货出库",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/RejectOut/detail",
                text: "采购退货出库详情",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/Other",
            text: "其它入库",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/Other/formPage",
                text: "其它入库",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/Scrap",
            text: "报废出库",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/Scrap/formPage",
                text: "报废出库",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/RealTimeStock",
            text: "实时库存",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/RealTimeStock/detail",
                text: "库存流水",
              },
            ],
          },
          {
            level: 2,
            route: "/Stock/Inventory",
            text: "库存盘点",
            hideenChildren: true,
            children: [
              {
                route: "/Stock/Inventory/detail",
                text: "盘点产品库存",
              },
              {
                route: "/Stock/Inventory/formPage",
                text: "盘点产品库存",
              },
            ],
          },
          // {
          //   level: 2,
          //   route: '/Stock/Balance',
          //   text: '库存结存',
          //   hideenChildren: true,
          //   children: [
          //     {
          //       route: '/Stock/Balance/detail',
          //       text: '库存结存详情',
          //     },
          //   ]
          // },
        ],
      },
      // 商品管理
      {
        level: 1,
        route: "/Product",
        text: "商品管理",
        name: "menu-product",
        icon: "fas fa-wagon-covered",
        children: [
          {
            level: 2,
            route: "/Product/categoryList",
            text: "商品分类",
            hideenChildren: true,
            children: [
              {
                route: "/Products/categoryList",
                text: "商品分类",
              },
            ],
          },
          {
            level: 2,
            route: "/Product/list",
            text: "商品列表",
            hideenChildren: true,
            children: [
              {
                route: "/Product/create",
                text: "商品详情新增",
              },
              {
                route: "/Product/detail",
                text: "商品详情编辑",
              },
            ],
          },
          {
            level: 2,
            route: "/Product/attr",
            text: "商品属性",
            hideenChildren: true,
            children:[
              {
                route: "/Product/attr/attrValueList",
                text: "商品属性值",
              },
            ]
          },
          {
            level: 2,
            route: "/Product/rechargecards",
            text: "充值卡列表",
            hideenChildren: true,
          },
          {
            level: 2,
            route: "/Product/billOfMaterials",
            text: "物料清单",
            hideenChildren: true,
          },
        ],
      },
      // 订单管理
      {
        level: 1,
        route: "/Indent",
        text: "订单管理",
        name: "menu-orders",
        icon: "fas fa-list",
        children: [
          {
            level: 2,
            route: "/Indent/transactionList",
            text: "商品订单列表",
            hideenChildren: true,
          },
          {
            level: 2,
            route: "/Indent/rechargeList",
            text: "充值订单列表",
            hideenChildren: true,
          },
        ],
      },
      // 营销管理
      {
        level: 1,
        route: "/Marketing",
        text: "营销管理",
        name: "menu-marketing",
        icon: "fas fa-handshake",
        children: [
          {
            level: 2,
            route: "/Marketing/community/list",
            text: "营销文章",
          },
          {
            level: 2,
            route: "/Marketing/theme",
            text: "营销主题",
            hideenChildren: true,
            children:[
              {
                route: "/Marketing/theme/create",
                text: "主题新增",
              },
              {
                route: "/Marketing/theme/edit",
                text: "主题编辑",
              },
            ]
          },
          {
            level: 2,
            route: "/Marketing/giftbags/list",
            text: "新人礼包",
          },
          {
            level: 2,
            route: "/Marketing/coupons/list",
            text: "优惠券",
          },
          {
            level: 2,
            route: "/Marketing/recommend/productlist",
            text: "推荐促销",
          },
          {
            level: 2,
            route: "/Marketing/brand/introduction",
            text: "品牌介绍",
          },
        ],
      },
      // 用户管理
      {
        level: 1,
        route: "/User",
        text: "用户管理",
        name: "menu-user",
        icon: "fas fa-user-tie",
        children:[
          {
            level: 2,
            route: "/User/list",
            text: "客户列表",
            hideenChildren: true,
          },
          {
            level: 2,
            route: "/User/staff",
            text: "员工管理",
          },
        ]
      },
      {
        level: 1,
        route: "/Finance",
        text: "财务管理",
        name: "menu-finance",
        icon: "fas fa-funnel-dollar",
        children: [
          {
            level: 2,
            route: "/Finance/DueIncome",
            text: "应收账款",
            hideenChildren: true,
            children: [
              {
                route: "/Finance/DueIncome/detail",
                text: "应收账款明细",
              },
            ],
          },
          {
            level: 2,
            route: "/Finance/RealIncome",
            text: "实收账款",
            hideenChildren: true,
            children: [
              {
                route: "/Finance/RealIncome/detail",
                text: "实收账款明细",
              },
            ],
          },
          {
            level: 2,
            route: "/Finance/DuePay",
            text: "应付账款",
            hideenChildren: true,
            children: [
              {
                route: "/Finance/DuePay/detail",
                text: "应付账款明细",
              },
            ],
          },
          {
            level: 2,
            route: "/Finance/RealPay",
            text: "实付账款",
            hideenChildren: true,
            children: [
              {
                route: "/Finance/RealPay/detail",
                text: "实付账款明细",
              },
            ],
          },
          {
            level: 2,
            route: "/Finance/Account",
            text: "银行账户",
          },
          {
            level: 2,
            route: "/Finance/financeFlowList",
            text: "交易流水",
          },
          {
            level: 2,
            route: "/Finance/monthReport",
            text: "月度报表",
          },
          {
            level: 2,
            route: "/Finance/dayReport",
            text: "日报表",
          },
        ],
      },
      // 店铺管理
      {
        level: 1,
        route: "/Shop",
        text: "店铺管理",
        name: "menu-shop",
        icon: "fas fa-store",
        children: [
          {
            level: 2,
            route: "/Shop/list",
            text: "店铺列表",
            hideenChildren: true,
          },
          {
            level: 2,
            route: "/Shop/productList",
            text: "店铺商品列表",
          },
        ]
      },
      {
        level: 1,
        route: "/System",
        text: "系统管理",
        name: "menu-system",
        icon: "fas fa-cog",
        children: [
          {
            level: 2,
            route: "/System/Role",
            text: "角色管理",
            hideenChildren: true,
            children: [
              {
                route: "/System/Role/editPage",
                text: "角色管理",
              },
            ],
          },
          {
            level: 2,
            route: "/System/Organization",
            text: "门店管理",
          },
          {
            level: 2,
            route: "/System/Supplier",
            text: "供应商管理",
          },
          {
            level: 2,
            route: "/System/Warehouse",
            text: "仓库管理",
            hideenChildren: true,
            children: [
              {
                route: "/System/Warehouse/Detail",
                text: "新增/编辑仓库",
              },
            ],
          },
          {
            level: 2,
            route: "/System/Vaccine",
            text: "产品信息管理",
            hideenChildren: true,
            children: [
              {
                route: "/System/Vaccine/Detail",
                text: "新增产品信息",
              },
            ],
          },
          {
            level: 2,
            route: "/System/Units",
            text: "计量单位管理",
          },
          {
            level: 2,
            route: "/System/Operate",
            text: "运营配置",
          },
        ],
      },
      {
        level: 1,
        route: "/Statistics",
        text: "报表统计",
        name: "menu-statistics",
        icon: "fas fa-database",
        children: [
          // {
          //   level: 2,
          //   route: '/Statistics/Organization',
          //   text: '门店财务月结统计',
          // },
          // {
          //   level: 2,
          //   route: '/Statistics/Supplier',
          //   text: '供应商财务月结统计',
          // },
          // {
          //   level: 2,
          //   route: '/Statistics/Purchase',
          //   text: '采购统计',
          // },
          {
            level: 2,
            route: "/Statistics/RecordSale",
            text: "销售统计",
          },
          {
            level: 2,
            route: "/Statistics/Inventory",
            text: "库存盘点报表",
            hideenChildren: true,
            children: [
              {
                route: "/Statistics/Inventory/formPage",
                text: "库存盘点报表详情",
              },
            ],
          },
          {
            level: 2,
            route: "/Statistics/dayStatistics",
            text: "预订生成汇总",
            // hideenChildren: true,
          },
        ],
      },
      // 前端布局
      {
        level: 1,
        route: "/Decorate",
        text: "前端布局",
        name: "menu-decorate",
        icon: "fas fa-solid fa-table",
        children: [
          {
            level:2,
            route:'/Decorate/banner',
            text:'Banner'
          },
          {
            level:2,
            route:'/Decorate/configure',
            text:'首页配置'
          }
        ]
      }
    ],
    attributeTypes: [],
    permissions: [],
  },
  getters: {
    Headline(state) {
      return state.headline;
    },
  },
  mutations: {
    getHeadline(state, text) {
      this.state.headline = text;
    },
    saveMyInfo(state, data) {
      this.state.userInfo = data;
    },
    setAttributeType(state, data) {
      state.attributeTypes = data;
    },
    setFacadePermission(state, data) {
      state.permissions = data;
    },
  },
  actions: {
    initPermissions({ commit }) {
      API.facadePermission("web").then((res) => {
        commit("setFacadePermission", res.body);
      }).catch(()=>{})
    },
    initAttributeType({ commit }) {
      API.getAttributeType().then((res) => {
        commit("setAttributeType", res.body.options);
      }).catch(()=>{})
    },
  },
  modules: {},
});
