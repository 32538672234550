<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          {{ Form.id ? "编辑退货单" : "创建退货单" }}
        </div>
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-title">
        <span class="fa fa-border-style"></span>
        基本信息
      </div>
      <div class="form-body">
        <div class="search-item">
          <div class="item-label bold">退货单号：</div>
          <div class="item-value">
            <el-input v-model="Form.no" disabled></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold"><span class="star">*</span>退货供应商：</div>
          <div class="item-value">
            <inputSelect apiMethod="pageSupplier" v-model="Form.supplierId" ref="supplierId" :label="Form.supplier.name"
              :disabled="Form.listingCollection.length > 0" />
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">
            <span class="star">*</span>退货日期：
          </div>
          <div class="item-value">
            <el-date-picker v-model="Form.returnsDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
              ref="returnsDate">
            </el-date-picker>
          </div>
        </div>
        <div class="search-item" style="width: 100%">
          <div class="item-label bold">退货原因：</div>
          <div class="item-value">
            <el-input v-model="Form.reason" maxlength="100" clearable></el-input>
          </div>
        </div>
      </div>
      <div class="form-title" style="margin-top: 20px">
        <span class="fa fa-border-style"></span>
        退货产品信息
        <span class="link" style="float: right" @click="
          addData()
          ">添加退货产品</span>
      </div>
      <el-table :data="Form.listingCollection" border style="width: 100%; margin-top: 15px" height="calc(100% - 240px)"
        ref="list" destroy-on-close>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="产品名称" prop="name" min-width="140" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="供应商" prop="supplier.name" min-width="120" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="规格" prop="specification" min-width="120" align="center">
        </el-table-column>
        <el-table-column label="单位" prop="unit.name" width="80" align="center">
        </el-table-column>
        <el-table-column label="可退货数量" prop="refundableQuantity" width="100" align="center">
          </el-table-column>
        <el-table-column label="退货数量" width="120" align="center">
          <template slot-scope="scope">
            <inputNumber :max="scope.row.refundableQuantity" v-model="scope.row.quantity" :ref="'quantity' + scope.$index" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div class="link star" @click="delData(scope)">删除</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push('/Purchase/Reject')" />
        <imsButton style="margin-left: 10px" text="提交并锁定" :loading="loading" @click="saveForm('submitPurchaseReject')" />
        <imsButton style="margin-left: 10px" typeClass="secondary" text="保存" @click="saveForm('savePurchaseReject')"
          :loading="loading" />
      </div>
    </div>

    <!-- 选择弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="90%" append-to-body :close-on-click-modal="false">
      <div class="dialog-header">筛选添加退货产品</div>
      <div class="dialog-main">
        <div class="page-search">
          <div class="search-item">
            <div class="item-label">产品名称</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable="" maxlength="99"></el-input>
            </div>
          </div>
          <imsButton style="float: right; margin: 4px" text="查询" :loading="loading" @click="getData()" />
          <imsButton typeClass="secondary"  style="float: right; margin: 4px" text="重置" @click="handleReset()" />
        </div>
        <el-table :data="tableData" border style="width: 100%; margin-top: 20px" height="calc(100% - 140px)"
          @selection-change="(val) => (multipleSelection = val)" ref="multipleTable">
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column label="产品名称" prop="name" min-width="140" align="center">
          </el-table-column>
          <el-table-column label="采购订单id" prop="purchaseOrderId" width="120" align="center">
          </el-table-column>
          <el-table-column label="供应商" prop="supplier.name" min-width="120" align="center">
          </el-table-column>
          <el-table-column label="规格" prop="specification" min-width="120" align="center">
          </el-table-column>
          <el-table-column label="单位" prop="unit.name" width="80" align="center">
          </el-table-column>
          <el-table-column label="可退货数量" prop="refundableQuantity" width="100" align="center">
          </el-table-column>
          <el-table-column type="selection" width="50" align="center" :selectable="selectable">
          </el-table-column>
        </el-table>
        <el-pagination layout="total, prev, pager, next,jumper"
          :current-page="pageQuery.pageIndex" :total="pageQuery.total"
          @current-change="(val) => (pageQuery.pageIndex = val)">
        </el-pagination>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="关闭" @click="dialogVisible = false" />
        <imsButton text="确定" @click="updateList()" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";
import npmnDialog from "@/components/npmnDialog.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber,
    npmnDialog,
  },
  watch: {},
  data() {
    return {
      loading: false,
      Form: {
        id: null,
        name: null,
        no: "系统自动生成",
        supplier: {
          id: null,
          name: null
        },
        listingCollection: [],
      },
      dialogVisible: false,
      Query: {
        nameLike: null,
        enabled: true,
      },
      tableData: [],
      options: [],
      multipleSelection: [],
    };
  },
  methods: {
    //复选框禁用
    selectable(row, rowIndex) {
      var ids = this.Form.listingCollection.map(item => item.purchaseOrderListingId)
      if (ids.indexOf(row.purchaseOrderListingId) > -1) {
        return false
      } else return true
    },
    // 更新listingCollection数据
    updateList() {
      var keyId = "purchaseOrderListingId"
      var ids = this.Form.listingCollection.map((item) => item[keyId]);
      this.multipleSelection.forEach(item => {
        if (ids.indexOf(item[keyId]) < 0) {
          this.Form.listingCollection.push(item)
        }
      })
      this.dialogVisible = false;
      this.multipleSelection = [];
    },
    addData() {
      if (!this.Form.supplierId) {
        this.$message.warning("请先选择“退货供应商”");
        this.$refs["supplierId"].focus();
        return
      }
      this.dialogVisible = true;
      this.getData();
    },
    delData(scope) {
      this.$confirm(
        `您确定要删除名称为【${scope.row.name}】的数据吗？删除后不可恢复！`,
        "删除提示",
        {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
          showCancelButton: true,
          showConfirmButton: true,
        }
      )
        .then(() => {
          this.Form.listingCollection.splice(scope.$index, 1);
        })
        .catch(() => { });
    },
    // 查询产品列表
    getData() {
      this.loading = true;
      this.$apis
        .pageCanReject({ ...this.Query, ...this.pageQuery, supplierId: this.Form.supplierId })
        .then((res) => {
          this.tableData = res.body.records.map((item) => {
            return {
              ...item,
              id: null,
              purchaseOrderListingId: item.id
            };
          });
          var keyId = "purchaseOrderListingId"
          var ids = this.Form.listingCollection.map((item) => item[keyId]);
          this.multipleSelection = this.multipleSelection.filter(item => ids.indexOf(item[keyId]) > -1)
          this.tableData.forEach(item => {
            if (ids.indexOf(item[keyId]) > -1) {
              setTimeout(() => {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              }, 100); // 渲染bug，延迟切换
            } else {
              setTimeout(() => {
                this.$refs.multipleTable.toggleRowSelection(item, false);
              }, 100);
            }
          })
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveForm(apiMethod) {
      let bool = true;
      if (apiMethod == 'submitPurchaseReject') {
        if (!this.Form.supplierId) {
          this.$message.warning("请选择“退货供应商”");
          this.$refs["supplierId"].focus();
          return;
        }
        if (!this.Form.returnsDate) {
          this.$message.warning("请选择“退货日期”");
          this.$refs["returnsDate"].focus();
          return;
        }
        if (this.Form.listingCollection.length == 0) {
          this.$message.warning("请添加“退货产品信息”");
          return;
        } else {
          this.Form.listingCollection.forEach((item, index) => {
            if (bool && !item.quantity) {
              this.$message.warning(
                `第${index + 1}行数据请填写大于0的“退货数量”`
              );
              this.$refs["quantity" + index].focus();
              bool = false;
            }
          });
        }
      }

      if (!bool) return;
      this.loading = true;
      this.$apis[apiMethod](this.Form)
        .then((res) => {
          if (res.body) {
            this.loading = false
            this.$message.success(`${apiMethod == 'submitPurchaseReject' ? '提交' : '保存'}成功`);
            if (apiMethod == 'submitPurchaseReject') {
              this.$router.push('/Purchase/Reject')
            } else {
              this.Form = {
                ...this.Form,
                ...res.body,
                listingCollection: res.body.listingCollection.map((item) => {
                  return {
                    ...item,
                    // name: item.productName,
                  };
                }),
              };
              this.$router.push(`/Purchase/Reject/formPage?id=${res.body.id}`)
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetail() {
      this.$apis
        .getPurchaseReject(this.Form.id)
        .then((res) => {
          this.Form = {
            ...this.Form,
            ...res.body,
            listingCollection: res.body.listingCollection.map((item) => {
              return {
                ...item,
                // name: item.productName,
              };
            }),
          };
        })
        .catch(() => { });
    },
    handleReset(){
      let data = {}
      for(let key in this.Query){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.Query[key]
          }
      }
      this.initListData(data)
      this.Query = Object.assign(this.Query,data)
      this.$nextTick(()=>{
      this.getData()
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.Form.id = this.$route.query.id;
        this.getDetail();
      }
    });
  },
};
</script>
