import facade from './facade.js'

// const tubsoftDirectives = Vue => {
//     Vue.directive('authorization', authorization);
// };

// export default tubsoftDirectives



export default {
  install (Vue) {
    Vue.directive('facade', facade)
  }
}
 