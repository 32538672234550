<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title" style="display:inline-block">销售统计</div>
                <imsButton style="float: right;margin-top: 8px;" text="导出" :loading="loading" @click="exportData" />
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div>
                    <div class="search-item">
                        <div class="item-label">统计月份：</div>
                        <div class="item-value">
                            <el-date-picker v-model="Query.yearMonth" type="month" placeholder="请选择选择月份"></el-date-picker>
                        </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">门店：</div>
                        <div class="item-value">
                            <el-input v-model="Query.nameLike" maxlength="32" clearable></el-input>
                        </div>
                    </div>
                </div>
                <div>
                    <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
                    <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
                </div>
            </div>
            <el-table :data="tableData" border style="width: 100%" show-summary :summary-method="getSummaries">
                <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
                <el-table-column prop="name" label="门店" min-width="140" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="年/月" width="100" align="center"  show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.year + '/' + scope.row.month }}
                    </template>
                </el-table-column>
                <el-table-column prop="lastUnpaidTotal" label="上个月未收款累计" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="repairReceipts" label="本月补收款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="receivable" label="本月应收款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="receipts" label="本月已收款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outstandingReceipts" label="本月未收款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="receivableReturn" label="本月应退款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="refund" label="本月已退款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="repairRefund" label="本月补退款" width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="unpaidTotal" label="未交款累计" width="160" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
            <Pagination
            v-show="pageQuery.total > 0"
            :total="pageQuery.total"
            :page.sync="pageQuery.pageIndex"
            :limit.sync="pageQuery.pageSize"
            @pagination="getData()"
            /> 
        </div>
    </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
    components: {
        imsButton,
        inputSelect,
    },
    data() {
        return {
            loading: false,
            Query: {
                yearMonth: null,
                nameLike: null,
            },
            pageQuery:{
                pageIndex: 1,
                pageSize: 10,         
            },
            tableData: [],
            statistics: {}
        };
    },
    methods: {
        getData(item) {
            this.loading = true;
            if (item)
                this.pageQuery = {
                    ...this.pageQuery,
                    pageIndex: 1
                }
            this.$apis['pageStatisticsSale']({
                year: this.$options.filters['dateformat'](this.Query.yearMonth, 'yyyy'),
                month: this.$options.filters['dateformat'](this.Query.yearMonth, 'MM'),
                nameLike: this.Query.nameLike,
                ...this.pageQuery
            })
                .then(res => {
                    this.tableData = res.body.records;
                    this.statistics = res.body.statistics;
                    this.pageQuery.total = Number(res.body.recordCount);
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 表尾合计行
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '';
                    return;
                }
                if (index === 1) {
                    sums[index] = '合计';
                    return;
                }
                sums[index] = this.statistics[column.property + 'Sum']
            });
            return sums;
        },
        // 导出
        exportData() {
            var notify = this.$notify.info({
                title: "消息",
                duration: 0,
                message: `正在导出，请耐心等待`,
            })
            this.$apis['exportStatisticsSale']()
                .then((res) => {
                    var filename = res.headers['content-disposition']
                    filename = filename.split('filename=')
                    filename = decodeURIComponent(filename[1])
                    notify.close();
                    this.$notify.success({
                        title: "消息",
                        duration: 3000,
                        message: `[${filename}]已导出，请查收`,
                    });
                    const blob = new Blob([res.data]);
                    let url = window.URL.createObjectURL(blob);
                    if ("download" in document.createElement("a")) {
                        try {
                            let link = document.createElement("a");
                            link.style.display = "none";
                            link.href = url;
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        navigator.msSaveBlob(blob, filename);
                    }
                })
                .catch((res) => {

                });
        },
        handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
        });
    }
};
</script>