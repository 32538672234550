export default {
    inserted: function (el, binding, vnode) {
        // console.log(el);
        // console.log(binding);
        // console.log(vnode);
        // 判断传过来的值是为true 如果是false取反不显示
        if (binding.value.name !== undefined &&
            binding.value.permission !== undefined &&
            binding.value.permission instanceof Array) {
            const name = binding.value.name
            const permission = binding.value.permission
            if (!permission.includes(name)) {
                // 创建一个注释元素
                const comment = document.createComment(' ');
                // 设置value值
                Object.defineProperty(comment, 'setAttribute', {
                    value: () => undefined,
                });
                // 用注释节点替换 当前页面元素  
                vnode.elm = comment;
                // 下面作为初始化操作 赋值为空
                vnode.text = ' ';
                vnode.isComment = true;
                vnode.context = undefined;
                vnode.tag = undefined;
                vnode.data.directives = undefined;

                // 判断当前元素是否是组件  如果是组件的话也替换成 注释元素
                if (vnode.componentInstance) {
                    vnode.componentInstance.$el = comment;
                }

                // 判断当前元素是否是文档节点或者是文档碎片节点 
                if (el.parentNode) {
                    // 从 DOM 树中删除 node 节点，除非它已经被删除了。
                    el.parentNode.replaceChild(comment, el);
                }
            }
        }
    },
}