import request_artisan from '@/utils/request_artisan'

// 客户列表
export function getUsersList(data) {
  return request_artisan({
    url: '/v1/Users/Page',
    method: 'get',
    params: data
  })
}

// 用户详情
export function getUsersDetail(data) {
  return request_artisan({
    url: '/v1/Users/Detail',
    method: 'get',
    params: data
  })
}

// 充值
export function userRecharge(data) {
  return request_artisan({
    url: '/v1/Users/Recharge',
    method: 'post',
    data
  })
}

//编辑客户信息
export function userEditor(data) {
  return request_artisan({
    url: '/v1/Users/Editor',
    method: 'put',
    data
  })
}
