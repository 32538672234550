<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">应收账款</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">销售单号：</div>
            <div class="item-value">
              <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">门店：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageBtype" v-model="Query.customerId" />
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset('queryForm')" />
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="no" label="销售单号" width="160" align="center"></el-table-column>
        <el-table-column prop="customer.name" label="门店" min-width="160" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amount" label="应收金额（元）" min-width="120" align="center"  show-overflow-tooltip></el-table-column>
        <el-table-column prop="receivedAmount" label="已收金额（元）" min-width="120" align="center"  show-overflow-tooltip></el-table-column>
        <el-table-column prop="unReceiveAmount" label="余额（元）" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.settlementStatus == 0" class="link" @click="editData(scope.row)">结算</div>
            <div class="link" @click="$router.push(`/Finance/DueIncome/detail?id=${scope.row.id}`)">查看明细</div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
      <div class="dialog-header">结算</div>
      <div class="dialog-main" style="padding-left:100px">
        <div>
          <div class="search-item">
            <div class="item-label">销售单号：</div>
            <div class="item-value">
              <p>{{ Form.no }}</p>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">门店：</div>
            <div class="item-value">
              <p>{{ Form.customer ? Form.customer.name : '' }}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="search-item">
            <div class="item-label">订单总金额：</div>
            <div class="item-value">
              <p>{{ Form.totalAmount }}</p>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">应收款（元）：</div>
            <div class="item-value">
              <p>{{ Form.receivableAmount }}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="search-item" data-required="blankAccountId">
            <div class="item-label">
              <span class="star">*</span>收款银行：
            </div>
            <div class="item-value">
              <el-select v-model="Form.blankAccountId" placeholder="请选择" ref="blankAccountId">
                <el-option v-for="item in bankOptions" :key="item.id" :label="item.bankOfDeposit"
                  :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item" data-required="amount">
            <div class="item-label">
              <span class="star">*</span>实收款（元）：
            </div>
            <div class="item-value">
              <inputNumber v-model="Form.amount" :max="99999999" ref="amount" />
            </div>
          </div>
        </div>
        <div>
          <div class="search-item" data-required="receiptDate">
            <div class="item-label">
              <span class="star">*</span>收款日期：
            </div>
            <div class="item-value">
              <el-date-picker v-model="Form.receiptDate" type="date" placeholder="选择日期" ref="receiptDate"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </div>
          <div class="search-item" data-required="voucherNo">
            <div class="item-label">
              <span class="star">*</span>凭证号：
            </div>
            <div class="item-value">
              <el-input v-model="Form.voucherNo" clearable maxlength="11" ref="voucherNo"></el-input>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label">收款凭证：</div>
          <div class="item-value">
            <el-upload class="left" ref="ref-upload" accept="" action="" :show-file-list="true"
              :http-request="uploadFile" :on-remove="removeFile">
              <imsButton text="上传文件" :loading="loading" />
            </el-upload>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="取消" @click="dialogVisible = false" />
        <imsButton text="保存" @click="saveForm" :loading="loading" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import inputNumber from "@/components/inputNumber.vue";

export default {
  components: {
    imsButton,
    inputSelect,
    inputNumber
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      Query: {

      },
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      Form: {
        attachmentList: []
      },
      bankOptions: []
    };
  },
  methods: {
    // 移除附件
    removeFile(file) {
      this.Form.attachmentList = this.Form.attachmentList.filter(item => item.fileName != file.name)
    },
    // 上传附件
    uploadFile(file) {
      var params = new FormData(); // 创建form对象
      params.append("file", file.file); // 将文件存入file下面
      this.loading = true
      this.$apis.fileUpload(params).then(res => {
        this.loading = false
        this.Form.attachmentList.push({
          fileName: file.file.name,
          fileSummary: res.body
        })
      }).catch(() => {
        this.loading = false
      })
    },
    getData(item) {
      this.loading = true;
      if (item)
        this.pageQuery = {
          ...this.pageQuery,
          pageIndex: 1
        }
      this.$apis
        .pageReceivable({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editData(item) {
      this.Form = {
        ...item,
        receivableAmount: item.amount,
        amount: null,
        attachmentList: [],
      };
      this.dialogVisible = true;
    },
    saveForm() {
      var bool = this.checkForm(
        document.querySelectorAll("*[data-required]"),
        this.Form
      );
      if (!bool) return;
      this.loading = true;
      this.$apis
        .postReceipts({
          voucherNo: this.Form.voucherNo,
          totalAmount: this.Form.totalAmount,
          receivableAmount: this.Form.receivableAmount,
          amount: this.Form.amount,
          customerId: this.Form.customerId,
          receiptDate: this.Form.receiptDate,
          blankAccountId: this.Form.blankAccountId,
          attachmentList: this.Form.attachmentList,
          receivableId: this.Form.id
        })
        .then(res => {
          if (res.body) {
            this.$message.success("结算成功！");
          }
          this.loading = false;
          this.dialogVisible = false;
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
      this.$apis.pageAccount({ pageSize: 999, enabled: true }).then(res => {
        if (res.body) {
          this.bankOptions = res.body.records
        }
      }).catch(()=>{})
    });
  }
};
</script>