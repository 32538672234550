<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ Form.status ? '查看退货单' : '记账退货单' }}
                </div>
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item">
                    <div class="item-label bold">采购计划单号：</div>
                    <div class="item-value">
                        {{ Form.no }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">退货供应商：</div>
                    <div class="item-value">
                        {{ Form.supplier ? Form.supplier.name : '' }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">退货日期：</div>
                    <div class="item-value">
                        {{ Form.createTime | dateformat() }}
                    </div>
                </div>
                <div class="search-item" style="width: 100%;">
                    <div class="item-label bold">退货原因：</div>
                    <div class="item-value">
                        {{ Form.reason }}
                    </div>
                </div>
            </div>
            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                退货产品信息
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 15px;"
                height="calc(100% - 240px)" ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="200" align="center">
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" min-width="120" align="center">
                </el-table-column>
                <el-table-column label="规格" prop="specification" min-width="120" align="center">
                </el-table-column>
                <el-table-column label="单位" prop="unit.name" min-width="80" align="center">
                </el-table-column>
                <el-table-column label="可用库存" prop="availableStockStorageQuantity" width="100" align="center">
                </el-table-column>
                <el-table-column label="退货数量" prop="quantity" width="100" align="center">
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Purchase/Reject')" />
                <imsButton v-if="Form.status == 1" style="margin-left: 10px;" text="复核记账" :loading="loading"
                    @click="approval()" />
            </div>
        </div>

    </div>
</template>
<script>
export default {
    watch: {
    },
    data() {
        return {
            loading: false,
            Form: {
                id: null,
                name: null,
                no: '系统自动生成',
                createUserName: '系统自动生成',
                listingCollection: [],
            },
        }
    },
    methods: {
        getDetail() {
            this.$apis.getPurchaseReject(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                }
            }).catch(() => {

            })
        },
        // 审批
        approval() {
            this.loading = true
            this.$apis.approvalPurchaseReject(this.Form).then(res => {
                if (res.body) {
                    this.$message.success('审批成功!')
                    this.getDetail()
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            })
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>