var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.visible,"width":"80%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.close}},[_c('el-form',{ref:"dialogForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px"}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"店铺名称:","prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入店铺名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"省市区:","prop":"area"}},[_c('el-cascader',{ref:"cascaderArea",attrs:{"props":_vm.props},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}})],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"详细地址:","prop":"address"}},[_c('el-input',{attrs:{"placeholder":"请输入店铺名称"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"联系电话:","prop":"contactNumber"}},[_c('el-input',{attrs:{"placeholder":"请输入联系电话"},model:{value:(_vm.form.contactNumber),callback:function ($$v) {_vm.$set(_vm.form, "contactNumber", $$v)},expression:"form.contactNumber"}})],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"开始营业时间:","prop":"openBusinessHours"}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:30',
              step: '00:15',
              end: '24:00'
            },"placeholder":"选择开始营业时间"},model:{value:(_vm.form.openBusinessHours),callback:function ($$v) {_vm.$set(_vm.form, "openBusinessHours", $$v)},expression:"form.openBusinessHours"}})],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"结束营业时间:","prop":"closeBusinessHours"}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:30',
              step: '00:15',
              end: '24:00'
            },"placeholder":"选择结束营业时间"},model:{value:(_vm.form.closeBusinessHours),callback:function ($$v) {_vm.$set(_vm.form, "closeBusinessHours", $$v)},expression:"form.closeBusinessHours"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"经纬度:","prop":"longitudeLatitude"}},[_c('el-input',{attrs:{"placeholder":"请输入经纬度"},model:{value:(_vm.form.longitudeLatitude),callback:function ($$v) {_vm.$set(_vm.form, "longitudeLatitude", $$v)},expression:"form.longitudeLatitude"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('span',{staticClass:"map-link",on:{"click":_vm.handleMapLink}},[_vm._v("高德地图坐标拾取工具")])])],1),_c('el-form-item',{attrs:{"label":"上传封面:","prop":"fileList"}},[_c('Upload',{attrs:{"file-list":_vm.form.fileList,"limit":5,"multiple":"","upload-url":"product"},on:{"update:fileList":function($event){return _vm.$set(_vm.form, "fileList", $event)},"update:file-list":function($event){return _vm.$set(_vm.form, "fileList", $event)}}})],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"描述:","prop":"description"}},[_c('el-input',{attrs:{"rows":4,"type":"textarea","placeholder":"说点什么..."},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('imsButton',{attrs:{"text":"确定","loading":_vm.loading},on:{"click":_vm.handleSubmit}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }