<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          {{ !$route.query.status ? "新增" : $route.query.status == 1 ? "编辑" : "查看" }}角色
        </div>
      </div>
    </div>
    <div class="page-body" style="border-bottom: 10px">
      <div>
        <div class="search-item item-magin" data-required="text">
          <div class="item-label"><span class="star" v-show="$route.query.status != 2">*</span>角色名：</div>
          <div class="item-value">
            <el-input v-model="Form.text" clearable maxlength="30" ref="text"
              :disabled="$route.query.status == 2"></el-input>
          </div>
        </div>
        <div class="search-item item-magin" data-required="text">
          <div class="item-label"><span class="star" v-show="$route.query.status != 2">*</span>是否启用：</div>
          <div class="item-value">
            <el-radio-group v-model="Form.enabled" :disabled="$route.query.status == 2">
              <el-radio :label="true">启用</el-radio>
              <el-radio :label="false">禁用</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="search-item item-magin" style="padding-bottom: 10px">
          <div class="item-label">描述：</div>
          <div class="item-value">
            <el-input v-model="Form.remark" clearable maxlength="200" type="textarea" :rows="3" placeholder="限200字"
              show-word-limit :disabled="$route.query.status == 2"></el-input>
          </div>
        </div>
      </div>
      <div class="page-search" style="margin-top: 35px">
      </div>
    </div>
    <div class="bottom-btn">
      <imsButton typeClass="secondary" class="button" text="返回" @click="$router.push('/System/Role')" />
      <imsButton v-if="$route.query.status != 2" typeClass="primary" class="button" text="保存" :loading="loading" @click="saveForm(false)" />
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import { mapMutations } from "vuex";
import imsButton from "@/components/imsButton.vue";
export default {
  components: {
    imsButton,
  },
  mixins: [mixins],
  computed: {
    ...mapMutations["getHeadline"],
  },
  data() {
    return {
      headline: "",
      hoverKey: "",
      loading: false,
      disabled: false,
      facades: [],
      collapseActive: [],
      Form: {
        text: "",
        enabled: true,
        remark: "",
      },
    };
  },
  methods: {
    getData() {
      if (this.$route.query.id) {
        this.$apis.getRole(this.$route.query.id).then((res) => {
          this.Form = res.body;
        });
      }
    },
    // getFacade() {
    //   this.$apis.getFacadeTree('web').then(res => {
    //     this.facades = res.body;
    //     console.log(this.facades);
    //     this.facades.map(a => this.collapseActive.push(a.name))
    //     console.log(this.collapseActive);

    //   });
    // },
    saveForm(item) {
      var bool = this.checkForm(
        document.querySelectorAll("*[data-required]"),
        this.Form
      );
      if (!bool) return;

      this.loading = true;
      if (this.$route.query.id) {
        this.$apis
          .editRole(this.Form, this.$route.query.id)
          .then((res) => {
            this.$message.success("编辑成功！");
            this.Form = res.body;
            this.loading = false;
            if (item) this.$router.push("/System/Role");
          })
          .catch(() => {
            this.loading = false;
          });

      } else {
        //新增
        this.$apis
          .addRole(this.Form)
          .then((res) => {
            this.$message.success("新增成功！");
            this.Form = res.body;
            this.loading = false;
            if (item) this.$router.push("/System/Role");
          })
          .catch(() => {
            this.loading = false;
          });

      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
};
</script>

<style lang="less" scoped>
.item-magin {
  margin: 5px 110px;
}

.item-value {
  width: calc(100% - 148px) !important;
}

.label {
  width: 140px !important;
}

.button {
  margin-right: 10px;
}

.title {
  font-size: 20px;
  color: #797979;
  margin-top: 10px;
}
</style>
