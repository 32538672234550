<template>
  <el-dialog
    title="商品列表"
    :visible.sync="visible"
    width="70%"
    @close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="page-search">
      <el-form ref="param" :inline="true" :model="param">
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="param.name"
            placeholder="请输入商品名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="分类类别：" prop="categoryType">
          <el-select
            v-model="param.categoryType"
            @change="getCategorysListApi"
            clearable
          >
            <el-option :value="1" label="销售"></el-option>
            <el-option :value="2" label="预订"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品分类：" prop="categoryId">
          <el-select v-model="param.categoryId" placeholder="请选择" clearable>
            <el-option
              v-for="item in sonCategorysList"
              :label="item.name"
              :value="item.categoryId"
              :key="item.prop"
              :prop="item.prop"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="handleQuery"/>
          <imsButton typeClass="secondary" text="重置"  style="margin-right: 4px" @click="handleReset('param')" />
        </el-form-item>
      </el-form>
    </div>
    <div class="base-table">
      <el-table
        :data="productList"
        v-loading="loading"
        @current-change="clickChange"
        border
      >
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row" :disabled="scope.row?.disabled"><i></i></el-radio>
          </template>
        </el-table-column>

        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template v-if="item.prop === 'coverImage'" #default="scope">
            <img class="list-image m-6" :src="scope.row.coverImage" alt=""  width="100"/>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-show="page.total > 0"
      :total="page.total"
      :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
      :page.sync="param.pageIndex"
      :limit.sync="param.pageSize"
      @pagination="getProductListApi"
    />
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import {getCategorysList, getProductList} from "@/api/Product";

export default {
  props: {
    shopId: {
      type: [Number, String],
      default: 0,
    },
    categorysList:[],
    parentProductList:[]
  },
  components:{

  },
  data() {
    return {
      visible: false,
      loading: false,
      tableRadio: "",
      productList: [],
      productIds: [],
      sonCategorysList:[],
      page: {
        total: 0,
      },
      param: {
        status: 2,
        pageIndex: 1,
        pageSize: 5,
      },
      columns: [
        {
          label: "录入时间",
          prop: "createDateTime",
          width: "180",
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: "YYYY-MM-DD HH:mm:ss",
            });
          },
        },
        {
          label: "产品货号",
          prop: "productNo",
        },
        {
          label: "产品名称",
          prop: "name",
        },
        {
          label: "首图",
          prop: "coverImage",
        },
        {
          label: "产品类型",
          prop: "category",
        },
        {
          label: "展示价格",
          prop: "markPrice",
        },
        {
          label: "发布状态",
          prop: "status",
          formatter: (row, column, value) => {
            return {
              1: "未启用",
              2: "已启用",
              3: "已删除",
            }[value];
          },
        },
      ],
    };
  },
  mounted() {
    this.getCategorysListApi();
  },
  methods: {
    async getCategorysListApi() {
      this.loading = true;
      let query = {};
      if (
        this.param.categoryType != null &&
        this.param.categoryType != undefined
      ) {
        query.categoryType = this.param.categoryType;
      }
      delete this.param.categoryId;
      this.sonCategorysList = await getCategorysList(query);
      this.loading = false;
    },
    close() {
      this.visible = false;
      this.reset();
      this.tableRadio = ""
    },
    reset() {
      this.productIds = [];
    },
    async getData() {
      this.visible = true;
      await this.getProductListApi();
    },
    async getProductListApi() {
      this.loading = true;
      let { records, recordCount } = await getProductList(this.param);
      this.productList = records;
      let ids = this.parentProductList && this.parentProductList.map((item)=>item.productId||item.product.productId)
      if(ids){
        this.productList.forEach((item)=>{
        item.disabled = ids.includes(item.productId)
      })
      }

      this.page.total = recordCount;
      this.loading = false;
    },
    clickChange(item) {
      if(!item || item.disabled)return
      this.tableRadio = item;
    },
    handleSubmit() {
      if(!this.tableRadio){
        this.close();
      }else{
        try {
        this.$emit("addProduct", this.tableRadio);
        this.close();
      } catch (error) {
        this.loading = false;
      }
      }
    },
    handleQuery() {
      this.param.pageIndex = 1;
      this.getProductListApi();
    },
    handleReset() {
      this.resetForm("param");
      this.getProductListApi();
    },
  },
};
</script>

<style lang="less" scoped>
.map-link {
  line-height: 30px;
  color: #337ab7;
  margin-left: 30px;
  cursor: pointer;
}
</style>
