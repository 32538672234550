import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "loginPage",
    component: () => import("../views/loginPage.vue"),
  },
  {
    path: "/Index",
    name: "indexPage",
    component: () => import("../views/indexPage.vue"),
  },
  {
    path: "/Init",
    name: "initPage",
    component: () => import("../views/initPage.vue"),
  },
  {
    path: "/Purchase/Plan",
    name: "PurchasePlanlistPage",
    component: () => import("../views/Purchase/Plan/listPage.vue"),
  },
  {
    path: "/Purchase/Plan/formPage",
    name: "PurchasePlanformPage",
    component: () => import("../views/Purchase/Plan/formPage.vue"),
  },
  {
    path: "/Purchase/Plan/detailPage",
    name: "PurchasePlandetailPage",
    component: () => import("../views/Purchase/Plan/detailPage.vue"),
  },
  {
    path: "/Purchase/Plan/historyPage",
    name: "PurchasePlanhistoryPage",
    component: () => import("../views/Purchase/Plan/historyPage.vue"),
  },
  {
    path: "/Purchase/Order",
    name: "PurchaseOrderlistPage",
    component: () => import("../views/Purchase/Order/listPage.vue"),
  },
  {
    path: "/Purchase/Order/formPage",
    name: "PurchaseOrderformPage",
    component: () => import("../views/Purchase/Order/formPage.vue"),
  },
  {
    path: "/Purchase/Order/detailPage",
    name: "PurchaseOrderdetailPage",
    component: () => import("../views/Purchase/Order/detailPage.vue"),
  },
  {
    path: "/Purchase/Reject",
    name: "PurchaseRejectlistPage",
    component: () => import("../views/Purchase/Reject/listPage.vue"),
  },
  {
    path: "/Purchase/Reject/formPage",
    name: "PurchaseRejectformPage",
    component: () => import("../views/Purchase/Reject/formPage.vue"),
  },
  {
    path: "/Purchase/Reject/detailPage",
    name: "PurchaseRejectdetailPage",
    component: () => import("../views/Purchase/Reject/detailPage.vue"),
  },
  {
    path: "/Sale/Order",
    name: "SaleOrderlistPage",
    component: () => import("../views/Sale/Order/listPage.vue"),
  },
  {
    path: "/Sale/Order/formPage",
    name: "SaleOrderformPage",
    component: () => import("../views/Sale/Order/formPage.vue"),
  },
  {
    path: "/Sale/Order/detailPage",
    name: "SaleOrderdetailPage",
    component: () => import("../views/Sale/Order/detailPage.vue"),
  },
  {
    path: "/Sale/Reject",
    name: "SaleRejectlistPage",
    component: () => import("../views/Sale/Reject/listPage.vue"),
  },
  {
    path: "/Sale/Reject/formPage",
    name: "SaleRejectformPage",
    component: () => import("../views/Sale/Reject/formPage.vue"),
  },
  {
    path: "/Sale/Reject/detailPage",
    name: "SaleRejectdetailPage",
    component: () => import("../views/Sale/Reject/detailPage.vue"),
  },
  {
    path: "/System/Role",
    name: "SystemRolelistPage",
    component: () => import("../views/System/Role/listPage.vue"),
  },
  {
    path: "/System/Role/editPage",
    name: "SystemRoleEditPage",
    component: () => import("../views/System/Role/editPage.vue"),
  },
  {
    path: "/System/Organization",
    name: "SystemOrganizationPage",
    component: () => import("../views/System/Organization/listPage.vue"),
  },
  {
    path: "/System/Supplier",
    name: "SystemSupplierPage",
    component: () => import("../views/System/Supplier/listPage.vue"),
  },
  {
    path: "/System/Warehouse",
    name: "SystemWarehousePage",
    component: () => import("../views/System/Warehouse/listPage.vue"),
  },
  {
    path: "/System/Warehouse/Detail",
    name: "SystemWarehouseDetailPage",
    component: () => import("../views/System/Warehouse/detailPage.vue"),
  },
  {
    path: "/System/Vaccine",
    name: "SystemVaccinePage",
    component: () => import("../views/System/Vaccine/listPage.vue"),
  },
  {
    path: "/System/Vaccine/Detail",
    name: "SystemVaccineDetailPage",
    component: () => import("../views/System/Vaccine/detailPage.vue"),
  },
  {
    path: "/System/Units",
    name: "SystemUnitsPage",
    component: () => import("../views/System/Units/listPage.vue"),
  },
  {
    path: "/System/Operate",
    name: "SystemOperate",
    component: () => import("../views/System/Operate/list.vue"),
  },
  {
    path: "/Statistics/Inventory",
    name: "StatisticsInventoryPage",
    component: () => import("../views/Statistics/Inventory/listPage.vue"),
  },
  {
    path: "/Statistics/Inventory/formPage",
    name: "StatisticsInventoryformPage",
    component: () => import("../views/Statistics/Inventory/formPage.vue"),
  },
  {
    path: "/Statistics/Organization",
    name: "StatisticsOrganizationPage",
    component: () => import("../views/Statistics/OrganizationPage.vue"),
  },
  {
    path: "/Statistics/Supplier",
    name: "StatisticsSupplierPage",
    component: () => import("../views/Statistics/SupplierPage.vue"),
  },
  {
    path: "/Statistics/Purchase",
    name: "StatisticsPurchasePage",
    component: () => import("../views/Statistics/PurchasePage.vue"),
  },
  {
    path: "/Statistics/RecordSale",
    name: "StatisticsRecordSale",
    component: () => import("../views/Statistics/RecordSale/listPage.vue"),
  },
  {
    path: "/Statistics/dayStatistics",
    name: "StatisticsDayStatistics",
    component: () => import("../views/Statistics/dayStatistics.vue"),
  },
  {
    path: "/User/list",
    name: "UserList",
    component: () => import("../views/User/list.vue"),
  },
  {
    path: "/User/Staff",
    name: "UserStaff",
    component: () => import("../views/User/staff.vue"),
  },
  {
    path: "/Finance/DueIncome",
    name: "FinanceDueIncomePage",
    component: () => import("../views/Finance/DueIncome/listPage.vue"),
  },
  {
    path: "/Finance/DueIncome/detail",
    name: "FinanceDueIncomeDetailPage",
    component: () => import("../views/Finance/DueIncome/detailPage.vue"),
  },
  {
    path: "/Finance/RealIncome",
    name: "FinanceRealIncomePage",
    component: () => import("../views/Finance/RealIncome/listPage.vue"),
  },
  {
    path: "/Finance/RealIncome/detail",
    name: "FinanceRealIncomeDetailPage",
    component: () => import("../views/Finance/RealIncome/detailPage.vue"),
  },
  {
    path: "/Finance/DuePay",
    name: "FinanceDuePayPage",
    component: () => import("../views/Finance/DuePay/listPage.vue"),
  },
  {
    path: "/Finance/DuePay/detail",
    name: "FinanceDuePayDetailPage",
    component: () => import("../views/Finance/DuePay/detailPage.vue"),
  },
  {
    path: "/Finance/RealPay",
    name: "FinanceRealPayPage",
    component: () => import("../views/Finance/RealPay/listPage.vue"),
  },
  {
    path: "/Finance/RealPay/detail",
    name: "FinanceRealPayDetailPage",
    component: () => import("../views/Finance/RealPay/detailPage.vue"),
  },
  {
    path: "/Finance/Account",
    name: "FinanceAccountPage",
    component: () => import("../views/Finance/Account/listPage.vue"),
  },
  {
    path: "/Finance/financeFlowList",
    name: "FinanceFinanceFlowListPage",
    component: () => import("../views/Finance/financeFlowList/listPage.vue"),
  },
  {
    path: "/Finance/monthReport",
    name: "FinanceMonthReport",
    component: () => import("../views/Finance/monthReport/listPage.vue"),
  },
  {
    path: "/Finance/dayReport",
    name: "FinanceDayReport",
    component: () => import("../views/Finance/dayReport/listPage.vue"),
  },
  {
    path: "/Stock/RealTimeStock",
    name: "StockRealTimeStockPage",
    component: () => import("../views/Stock/RealTimeStock/listPage.vue"),
  },
  {
    path: "/Stock/RealTimeStock/detail",
    name: "StockRealTimeStockDetailPage",
    component: () => import("../views/Stock/RealTimeStock/detailPage.vue"),
  },
  {
    path: "/Stock/Balance",
    name: "StockBalancePage",
    component: () => import("../views/Stock/Balance/listPage.vue"),
  },
  {
    path: "/Stock/Balance/detail",
    name: "StockBalanceDetailPage",
    component: () => import("../views/Stock/Balance/detailPage.vue"),
  },
  {
    path: "/Stock/Into",
    name: "StockIntoPage",
    component: () => import("../views/Stock/Into/listPage.vue"),
  },
  {
    path: "/Stock/Into/formPage",
    name: "StockStockStockIntoformPage",
    component: () => import("../views/Stock/Into/formPage.vue"),
  },
  {
    path: "/Stock/RejectInto",
    name: "StockStockRejectIntoPage",
    component: () => import("../views/Stock/RejectInto/listPage.vue"),
  },
  {
    path: "/Stock/RejectInto/detail",
    name: "StockStockRejectIntoDetailPage",
    component: () => import("../views/Stock/RejectInto/detailPage.vue"),
  },
  {
    path: "/Stock/RejectOut",
    name: "StockRejectOutPage",
    component: () => import("../views/Stock/RejectOut/listPage.vue"),
  },
  {
    path: "/Stock/RejectOut/detail",
    name: "StockRejectOutDetailPage",
    component: () => import("../views/Stock/RejectOut/detailPage.vue"),
  },
  {
    path: "/Stock/Out/detail",
    name: "StockOutDetailPage",
    component: () => import("../views/Stock/Out/detailPage.vue"),
  },
  {
    path: "/Stock/Out",
    name: "StockOutPage",
    component: () => import("../views/Stock/Out/listPage.vue"),
  },
  {
    path: "/Stock/Inventory",
    name: "StockInventoryPage",
    component: () => import("../views/Stock/Inventory/listPage.vue"),
  },
  {
    path: "/Stock/Inventory/formPage",
    name: "StockInventoryFormPage",
    component: () => import("../views/Stock/Inventory/formPage.vue"),
  },
  {
    path: "/Stock/Other",
    name: "StockOtherPage",
    component: () => import("../views/Stock/Other/listPage.vue"),
  },
  {
    path: "/Stock/Other/formPage",
    name: "StockOtherFormPage",
    component: () => import("../views/Stock/Other/formPage.vue"),
  },
  {
    path: "/Stock/Scrap",
    name: "StockScrapPage",
    component: () => import("../views/Stock/Scrap/listPage.vue"),
  },
  {
    path: "/Stock/Scrap/formPage",
    name: "StockScrapFormPage",
    component: () => import("../views/Stock/Scrap/formPage.vue"),
  },
  // 商品管理
  {
    path: "/Product/categoryList",
    name: "ProductCategoryList",
    component: () => import("../views/Products/categoryList.vue"),
  },
  {
    path: "/Product/list",
    name: "ProductList",
    component: () => import("../views/Products/list.vue"),
  },
  {
    path: "/Product/create",
    name: "ProductCreate",
    component: () => import("../views/Products/detail.vue"),
  },
  {
    path: "/Product/attr",
    name: "ProductAttr",
    component: () => import("../views/Products/Attr/list.vue"),
  },
  {
    path: "/Product/attr/attrValueList",
    name: "ProductAttrAttrValueList",
    component: () => import("../views/Products/Attr/attrValueList.vue"),
  },
  {
    path: "/Product/rechargecards",
    name: "ProductRechargecards",
    component: () => import("../views/Products/rechargecards/list.vue"),
  },
  {
    path: "/Product/billOfMaterials",
    name: "ProductBillOfMaterials",
    component: () => import("../views/Products/billOfMaterials/list.vue"),
  },
  //订单管理
  {
    path: "/Indent/transactionList",
    name: "IndentTransactionList",
    component: () => import("../views/Indent/transactionList.vue"),
  },
  {
    path: "/Indent/rechargeList",
    name: "IndentRechargeList",
    component: () => import("../views/Indent/rechargeList.vue"),
  },

  // 营销管理
  {
    path: "/Marketing/community/list",
    name: "MarketingCommunityList",
    component: () => import("../views/Marketing/community/list.vue"),
  },
  {
    path: "/Marketing/theme",
    name: "MarketingTheme",
    component: () => import("../views/Marketing/theme/list.vue"),
  },
  {
    path: "/Marketing/theme/create",
    name: "MarketingThemeCreate",
    component: () => import("../views/Marketing/theme/detail.vue"),
  },
  {
    path: `/Marketing/theme/edit`,
    name: "MarketingThemeEdit",
    component: () => import("../views/Marketing/theme/detail.vue"),
  },
  {
    path: "/Marketing/giftbags/list",
    name: "MarketingGiftbagsList",
    component: () => import("../views/Marketing/giftbags/list.vue"),
  },
  {
    path: "/Marketing/coupons/list",
    name: "MarketingCouponsList",
    component: () => import("../views/Marketing/coupons/list.vue"),
  },
  {
    path: "/Marketing/recommend/productlist",
    name: "MarketingRecommendProductlist",
    component: () => import("../views/Marketing/recommend/productlist.vue"),
  },
  {
    path: "/Marketing/brand/introduction",
    name: "MarketingBrandIntroduction",
    component: () => import("../views/Marketing/brand/introduction.vue"),
  },
  // 前端配置
  {
    path: "/Decorate/banner",
    name: "DecorateBanner",
    component: () => import("../views/Decorate/banner.vue"),
  },
  {
    path: "/Decorate/configure",
    name: "DecorateConfigure",
    component: () => import("../views/Decorate/configure.vue"),
  },
  // 店铺管理
  {
    path: "/Shop/list",
    name: "ShopList",
    component: () => import("../views/Shop/list.vue"),
  },  
  {
    path: "/Shop/productList",
    name: "ShopProductList",
    component: () => import("../views/Shop/productList.vue"),
  },
];

const router = new VueRouter({
  routes,
});

// 防止连续点击多次路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
