<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          店铺列表
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">店铺名称：</div>
            <div class="item-value">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入店铺名称"
              ></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">店铺状态：</div>
            <div class="item-value">
              <el-select v-model="queryForm.status">
                <el-option :value="-1" label="关闭"></el-option>
                <el-option :value="0" label="待审核"></el-option>
                <el-option :value="1" label="通过"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin: 4px;" text="查询" :loading="loading" @click="handleQuery" />
          <imsButton style="float: right;margin: 4px" typeClass="secondary" text="重置"   @click="handleReset()" />
        </div>
      </div>
      <div class="base-table">
        <div class="action">
          <span class="link" style="float: right; margin: 15px;" @click="handleCreate"
            >添加店铺</span>
        </div>
        <el-table :data="userList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            :show-overflow-tooltip="item.prop!='firstIcon'"
          >
            <template v-if="item.prop === 'firstIcon'" #default="scope">
              <img class="list-image m-6" :src="scope.row.firstIcon" alt=""  width="100"/>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="400">
            <template #default="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row.shopId)"
                >编辑</el-button
              >
              <el-popconfirm
                :title="
                  scope.row.status === 1
                    ? '是否确认关闭该店铺？'
                    : '是否确认开启该店铺？'
                "
                style="margin-left: 10px;"
                @confirm="handleStatus(scope.row)"
              >
                <template #reference>
                  <el-button type="warning" size="mini" icon="el-icon-error">{{
                    scope.row.status === 1 ? '关闭' : '开启'
                  }}</el-button>
                </template>
              </el-popconfirm>

              <el-popconfirm
                title="是否确认删除？"
                style="margin: 0 10px;"
                @confirm="handleDelete(scope.row.shopId)"
              >
                <template #reference>
                  <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
              <el-button
                type="success"
                size="mini"
                icon="el-icon-view"
                @click="handleShopProduct(scope.row.shopId)"
                >查看店铺商品</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getShopListApi"
        />
      </div>
      <OperateDialog
        ref="operateDialog"
        :dialog-title="dialogTitle"
        @updateList="getShopListApi"
      />
    </div>
  </div>
</template>

<script>
import { getShopList, updateShopStatus, deleteShop } from '@/api/Shop'
import OperateDialog from './components/OperateDialog'
export default {
  name: 'shopList',
  components: {
    OperateDialog
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        name: '',
        status: ''
      },
      total: 0,
      userList: [],
      dialogTitle: '店铺新增',
      columns: [
        {
          label: '店铺ID',
          prop: 'shopId'
        },
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '图片',
          prop: 'firstIcon'
        },
        {
          label: '开始营业时间',
          prop: 'openBusinessHours'
        },
        {
          label: '结束营业时间',
          prop: 'closeBusinessHours'
        },
        {
          label: '联系电话',
          prop: 'contactNumber'
        },
        // {
        //   label: '省份',
        //   prop: 'province'
        // },
        // {
        //   label: '市区',
        //   prop: 'city'
        // },
        // {
        //   label: '区域',
        //   prop: 'county'
        // },
        {
          label: '状态',
          prop: 'status',
          formatter: (row, column, value) => {
            return {
              '-1': '关闭',
              0: '待审批',
              1: '通过'
            }[value]
          }
        }
      ]
    }
  },
  mounted() {
    this.getShopListApi()
  },
  methods: {
    async getShopListApi() {
      this.loading = true
      const params = JSON.parse(JSON.stringify(this.queryForm))
      const { records, recordCount } = await getShopList(params)
      this.userList = records
      this.total = recordCount
      this.loading = false
    },
    async handleStatus(row) {
      await updateShopStatus({
        shopId: row.shopId,
        status: row.status == -1 ? 1 : -1
      })
      this.getShopListApi()
    },
    handleEdit(shopId) {
      this.dialogTitle = '店铺编辑'
      this.$refs.operateDialog.getData(shopId)
    },
    async handleDelete(shopId) {
      await deleteShop({ shopId })
      this.getShopListApi()
    },
    handleCreate() {
      this.dialogTitle = '店铺新增'
      this.$refs.operateDialog.getData()
      // this.$refs.OperateDialog.reset()
    },
    handleQuery() {
      this.queryForm.pageIndex = 1
      this.getShopListApi()
    },
    handleShopProduct(id) {
        this.$router.push({
          name:'ShopProductList',
          query:{id}
        })
    },
    handleReset() {     
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
      this.$nextTick(() => {
      this.getShopListApi()
        })
      }
    // handleReset() {
    //   this.resetForm('queryForm')
    //   this.getShopListApi()
    // }
  }
}
</script>

<style lang="less" scoped></style>
