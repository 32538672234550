<template>
  <div class="demo-image__preview">
    <el-image
      fit="cover"
      :style="{ width: width + 'px', height: height + 'px' }"
      :src="src"
      :preview-src-list="imageList"
      :lazy="true"
    >
    </el-image>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    srcList: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 100
    },
    parameter: {
      type: String,
      default: 'm_pad,w_480,h_480,color_ffffff'
    }
  },
  data() {
    return {
      src: '',
      imageList: []
    }
  },
  watch: {
    url: {
      handler(val) {
        this.src = `${process.env.VUE_APP_OSS}${val}?x-oss-process=image/resize,${this.parameter}`
      },
      immediate: true
    },
    srcList(val) {
      this.imageList = val.map(item => process.env.VUE_APP_OSS + item)
    }
  }
}
</script>

<style lang="less" scoped></style>
