<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ Form.id ? '编辑采购计划' : '创建采购计划' }}
                </div>
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item" data-required="no">
                    <div class="item-label bold"><span class="star">*</span>采购计划名称：</div>
                    <div class="item-value">
                        <el-input v-model="Form.name" clearable maxlength="32" ref="name"></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">采购计划单号：</div>
                    <div class="item-value">
                        <el-input v-model="Form.no" disabled></el-input>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">制单人：</div>
                    <div class="item-value">
                        <el-input v-model="Form.createUserName" disabled></el-input>
                    </div>
                </div>
            </div>
            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                产品信息
                <span class="link" style="float: right;" @click="openDialogVisible">添加产品</span>
            </div>
            <el-table :data="Form.listingCollection" class="table-fixed-class" border style="width: 100%;margin-top: 15px;"
                height="calc(100% - 200px)" ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="140" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" width="120" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="规格" prop="specification" width="120" align="center">
                </el-table-column>
                <el-table-column label="单位" prop="unit.name" width="80" align="center">
                </el-table-column>
                <el-table-column label="库存数量" prop="storageQuantity" width="80" align="center">
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>收集计划数
                    </template>
                    <template slot-scope="scope">
                        <inputNumber :max="99999999" v-model="scope.row.planQuantity" :id="'planQuantity' + scope.$index" />
                    </template>
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        <span class="star">*</span>本次采购数量
                    </template>
                    <template slot-scope="scope">
                        <inputNumber :max="99999999" v-model="scope.row.quantity" :id="'quantity' + scope.$index" />
                    </template>
                </el-table-column>
                <el-table-column min-width="200" align="center">
                    <template slot="header">
                        备注
                    </template>
                    <template slot-scope="scope">
                        <el-input maxlength="200" v-model="scope.row.remark" clearable></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <div class="link star" @click="delData(scope)">删除
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Purchase/Plan')" />
                <imsButton style="margin-left: 10px;" text="提交并锁定" :loading="loading"
                    @click="saveForm('submitPurchasePlan')" />
                <imsButton v-if="Form.approvalStatus != 4" style="margin-left: 10px;" typeClass="secondary" text="保存"
                    @click="saveForm('addPurchasePlan')" :loading="loading" />
            </div>
        </div>
        <publishDialogVisible :dialogVisible="dialogVisible" :Form="Form" @change="change" :type="1"></publishDialogVisible>
    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
import npmnDialog from '@/components/npmnDialog.vue';
import publishDialogVisible from "@/components/publishDialogVisible.vue";

export default {
    components: {
        inputSelect,
        inputNumber,
        npmnDialog,
        publishDialogVisible
    },
    watch: {
    },
    data() {
        return {
            loading: false,
            Form: {
                id: null,
                name: null,
                no: '系统自动生成',
                createUserName: '提交后自动生成',
                listingCollection: [],
            },
            dialogVisible: false,
            Query: {
                nameLike: null,
                enabled: true
            },
            // vaccineList: [],
            options: [],
            eidtForm: {},
            dialogVisibleEdit: false,
        }
    },
    methods: {
        updateData() {
            this.$set(this.Form.listingCollection, this.eidtForm.keyIndex, this.eidtForm)
            this.dialogVisibleEdit = false
        },
        delData(scope) {
            this.$confirm(
                `您确定要删除名称为【${scope.row.name}】的数据吗？删除后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.Form.listingCollection.splice(scope.$index, 1)
                })
                .catch(() => {

                })
        },
        // 查询产品列表
        getData() {
            this.loading = true
            this.$apis.pageVaccine({ ...this.Query, ...this.pageQuery }).then(res => {
                this.tableData = res.body.records.map(item => {
                    return {
                        ...item,
                        productId: item.id,
                        id: null
                    }
                })
                this.pageQuery.total = Number(res.body.recordCount)
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        // 打开选择产品窗口
        openDialogVisible() {
            this.dialogVisible = true;
        },
        saveForm(apiMethod) {
            let bool = true
            if (!this.Form.name) {
                this.$message.warning('请输入“采购计划名称”')
                this.$refs['name'].focus()
                return
            }
            if (this.Form.listingCollection.length == 0) {
                this.$message.warning('请添加“产品信息”')
                return
            } else {
                this.Form.listingCollection.forEach((item, index) => {
                    if (bool && !item.planQuantity) {
                        this.$message.warning(`第${index + 1}行数据未填写“收集计划数”`)
                        document.getElementById('planQuantity' + index).focus()
                        bool = false
                    }
                    if (bool && !item.quantity) {
                        this.$message.warning(`第${index + 1}行数据未填写“本次采购数量”`)
                        document.getElementById('quantity' + index).focus()
                        bool = false
                    }
                })
            }
            if (!bool) return
            this.loading = true
            this.$apis[apiMethod]({
                name: this.Form.name,
                listingCollection: this.Form.listingCollection.map(item => {
                    return {
                        id: item.id,
                        productId: item.productId,
                        productNo: item.no ? item.no : item.productNo,
                        storageQuantity: item.storageQuantity,
                        planQuantity: item.planQuantity,
                        quantity: item.quantity,
                        remark: item.remark
                    }
                })
            }, this.Form.id).then(res => {
                if (res.body) {
                    this.loading = false
                    if (apiMethod == 'addPurchasePlan') {
                        this.$message.success('保存成功')
                        this.$router.push(`/Purchase/Plan/formPage?id=${res.body.id}`)
                        this.Form = {
                            ...this.Form,
                            ...res.body
                        }
                    } else {
                        this.$message.success('提交锁定成功，自动返回列表页')
                        setTimeout(() => {
                            this.$router.push('/Purchase/Plan')
                        }, 0);
                    }
                }
            }).catch(() => {
                this.loading = false
            })
        },
        getDetail() {
            this.$apis.getPurchasePlan(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                    listingCollection: res.body.listingCollection.map(item => {
                        return {
                            ...item,
                            name: item.name
                        }
                    })
                }
            }).catch(() => {

            })
        },
        change(val) {
            this.dialogVisible = false
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>
<style lang="less" scoped>
 .table-fixed-class /deep/ .el-table__fixed-right{
    height: 100% !important;
 }
 </style>