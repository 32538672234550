// import { mapState } from "vuex";
import CryptoJS from "crypto-js";

export default {
    install(Vue) {
        Vue.mixin({
            watch: {
                'pageQuery.pageIndex'() { // 分页通用监听熟悉，需保持名称一致
                    this.getData()
                },
                'Query.createTimeArr'(val) {
                    if (val && val.length > 0) {
                        this.pageQuery.startCreateTime = this.$options.filters['dateformat'](val[0], 'yyyy-MM-DDTHH:mm:ss')
                        this.pageQuery.endCreateTime = this.$options.filters['dateformat'](val[1], 'yyyy-MM-DDT23:59:59')
                    } else {
                        this.pageQuery.startCreateTime = null
                        this.pageQuery.endCreateTime = null
                    }
                },
            },
            computed: {
                // NAN,Infinity转换
                toggleNumber() {
                    return function (count) {
                        return count > 0 && count != Infinity ? count : 0
                    }
                },
            },
            data() {
                return {
                    typeList: ['', null, undefined],
                    regEmail: /[\w\.\-]+@([\w\-]+\.)+[\w\-]+/,
                    regPassword: /(?=.*\d+)(?=.*[A-Za-z]+)[A-Za-z\d]{6,20}/,
                    regAccount: /([A-z]|_){4,20}/,
                    regPhone: /^((13[0-9])|(14[0-9])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/,
                    regIDNumber: /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/,
                    regOfficePhone: /0\d{2,3}-\d{7,8}|\(?0\d{2,3}[)-]?\d{7,8}|\(?0\d{2,3}[)-]*\d{7,8}/,
                    pageQuery: {
                        pageIndex: 1,
                        pageSize: 100,
                        total: 0,
                        startCreateTime: null,
                        endCreateTime: null
                    },
                    dict1: {
                        0: '草稿', 1: '待审批', 2: '通过', 3: '未通过', 4: '解锁编辑', 5: '作废'
                    },
                    dict2: {
                        0: '草稿', 1: '待复核', 2: '已复核',3:'不通过',4:'已作废'
                    },
                    systemName: ''
                }
            },
            methods: {
                // 生成32位uid
                randomString(e) {
                    e = e || 32;
                    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                        a = t.length,
                        n = "";
                    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
                    return n;
                },
                // 加密
                encrypt(word, uid) {
                    var key = CryptoJS.enc.Utf8.parse(uid);
                    var srcs = CryptoJS.enc.Utf8.parse(word);
                    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
                        mode: CryptoJS.mode.ECB,
                        padding: CryptoJS.pad.Pkcs7,
                    });
                    return encrypted.toString();
                },
                // 通用表单校验
                checkForm(NodeList, Form, selectAttr = 'data-required') { // 表单节点集合，要校验的表单data
                    var bool = true
                    var checkNull = [null, '', undefined]
                    NodeList.forEach(node => {
                        if (bool) {
                            if (checkNull.includes(Form[node.attributes[selectAttr].nodeValue])
                                || (node.attributes[selectAttr].nodeValue.indexOf('Id') > -1 && node.attributes[selectAttr].nodeValue === 0)) {
                                this.$message.warning(`请输入“${node.children[0].innerText.replace('*', '').replace('：', '')}”`)
                                this.$refs[node.attributes[selectAttr].nodeValue].focus()
                                bool = false
                            }
                        }
                    })
                    return bool
                },
                // 显示输入框
                showInput(key) {
                    this.hoverKey = key
                    // 控件渲染需要时间，延迟聚焦
                    setTimeout(() => {
                        // 防止用户快速移动鼠标带来控制台报错，仅聚焦停留超过100ms的控件
                        if (this.hoverKey === key) {
                            this.$refs[key].focus()
                        }
                    }, 100);
                },
                // 新窗口打开链接
                openHref(name, query) {
                    const { href } = this.$router.resolve({
                        name: name,
                        query: query || {}
                    });
                    window.open(href, '_blank');
                },
                // 动态获取page-body高度
                getHeight() {
                    var footer = document.getElementsByClassName('page-footer')
                    var height = `calc(100% - 176px)`
                    if (footer.length) {
                        height = `calc(100% - ${footer[0].clientHeight + 86}px)`
                    }
                    return height
                },
                // 供应商选择
                getSupplierOptions() {
                    this.$apis.pageSupplier({ pageSize: 9999 }).then(res => {
                        this.supplierOptions = res.body.records
                    }).catch(()=>{})
                },
                // 库位下拉选择
                getOptionsKW() {
                    this.$apis.pageWarehouseLocation({
                        pageSize: 9999
                    }).then(res => {
                        console.log(res,'res');
                        this.optionsKW = res.body.records;
                    }).catch(()=>{})
                },
                getLabel(value, data) {
                    var text = '--'
                    data.forEach(item => {
                        if (item.value == value) {
                            text = item.label
                        }
                    })
                    return text
                },
                initName() {
                    if (window.config && window.config.systemName) {
                        // this.systemName = window.config.systemName
                        this.systemName = "嘉鼎门店管理系统"
                        document.title = this.systemName
                    } else {
                        setTimeout(() => {
                            this.initName()
                        }, 100);
                    }
                }
            },
            mounted() {
                this.initName()
            }
        })
    }
}