<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="名称:" prop="name">
            <el-input v-model="form.name" placeholder="请输入广告名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="显示权限:" prop="showType">
            <el-select v-model="form.showType">
              <el-option value="1" label="全部"></el-option>
              <el-option value="2" label="会员"></el-option>
              <el-option value="3" label="非会员"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="视频:" prop="contentList">
          <el-upload
            class="avatar-uploader"
            action=""
            :http-request="handleUpload"
          >
            <video
              v-if="videoUrl != '' && videoFlag == false"
              :src="videoUrl"
              class="avatar"
              controls="controls"
              width="480"
              height="200"
            >
              您的浏览器不支持视频播放
            </video>
            <i
              v-else-if="videoUrl == '' && videoFlag == false"
              class="el-icon-plus avatar-uploader-icon"
            ></i>
            <el-progress
              v-if="videoFlag == true"
              type="circle"
              :percentage="videoUploadPercent"
              style="margin-top:30px;"
            ></el-progress>
          </el-upload>
        </el-form-item>
      </el-row>
      <!-- <el-row>
        <el-col :span="12">
          <el-form-item label="跳转类型:">
            <el-select v-model="linkType">
              <el-option :value="1" label="App"></el-option>
              <el-option :value="2" label="Web"></el-option>
              <el-option :value="3" label="商品"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="跳转链接:">
            <el-input v-model="linkUrl" placeholder="请输入跳转链接" />
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { getConfig, addConfig, updateConfig } from '@/api/Decorate'
import { uploadVideo } from '@/api/common'
// import Upload from '@/components/Upload'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    categoryInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    // Upload
  },
  data() {
    return {
      configId: null,
      visible: false,
      loading: false,
      videoFlag: false,
      videoUploadPercent: 0,
      videoUrl: '',
      linkUrl: '',
      linkType: '',
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.form = {
        name: '',
        typeConfig: '200',
        type: '2',
        showType: '1',
        contentList: []
      }
      this.videoUrl = ''
      this.linkUrl = ''
      this.linkType = ''
      this.resetForm('dialogForm')
    },
    // 获取编辑信息
    async getData(id) {
      this.reset()
      this.configId = id
      if (id) {
        const { contentList, name, showType, typeConfig } = await getConfig({
          id
        })
        // this.form.contentList = contentList
        this.videoUrl = contentList[0].resourcesUrl
        this.linkUrl = ''
        this.linkType = '2'
        this.form.name = name
        this.form.showType = showType
        this.form.typeConfig = typeConfig
        // this.form.contentList.forEach(item => {
        //   this.$set(item, 'fileList', [
        //     {
        //       url: item.resourcesUrl
        //     }
        //   ])
        // })
      }
      this.visible = true
    },
    async handleUpload(file) {
      console.log(file)
      const formData = new FormData()
      formData.append('file', file['file'])
      this.videoFlag = true
      this.videoUploadPercent = 0
      const { url, path } = await uploadVideo(formData, event => {
        if (this.videoUploadPercent > 98) {
          console.log(this.videoUploadPercent)
          this.videoUploadPercent = 98
        } else {
          this.videoUploadPercent =
            ((event.loaded / event.total) * 100).toFixed(0) * 1
        }
      })
      this.videoUploadPercent = 100
      this.videoUrl = url
      this.videoFlag = false
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.form))
          if (!this.videoUrl) {
            this.$message.warning('请等待视频上传')
            return
          }
          params.contentList = [
            {
              linkType: this.linkType,
              linkUrl: this.linkUrl,
              resourcesUrl: this.videoUrl
            }
          ]
          params.typeConfigNum = params.contentList.length
          this.loading = true
          try {
            if (this.configId) {
              params.configId = this.configId
              await updateConfig(params)
              this.$message.success('更新成功')
            } else {
              await addConfig(params)
              this.$message.success('添加成功')
            }
            this.loading = false
            this.$emit('updateList')
            this.close()
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
