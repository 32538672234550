<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">其它入库</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">编号：</div>
          <div class="item-value">
            <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
          </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>

      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="$router.push('/Stock/Other/formPage')">添加</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="no" label="编号" min-width="100" align="center"></el-table-column>
        <el-table-column prop="inboundDate" label="入库日期" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="reviewDate" label="记账日期" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="reviewUser.name" label="记账人" min-width="80" align="center">
        </el-table-column>
        <el-table-column label="状态" min-width="80" align="center">
          <template slot-scope="scope">
            {{ statusOptions[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button @click="delData(scope.row)" type="text" v-if="scope.row.status == 0">删除</el-button>
            <el-button @click="$router.push('/Stock/Other/formPage?id=' + scope.row.id)" type="text">
              <span v-if="scope.row.status == 0">编辑</span>
              <span v-if="scope.row.status == 1">记账</span>
              <span v-if="scope.row.status == 2">查看</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
      v-show="pageQuery.total > 0"
      :total="pageQuery.total"
      :page.sync="pageQuery.pageIndex"
      :limit.sync="pageQuery.pageSize"
      @pagination="getData()"
      /> 
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect
  },
  mixins: [mixins],
  data() {
    return {
      warehouseOptions: [],
      loading: false,
      dialogVisible: false,
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      Query: {
        noLike: "",
      },
      statusOptions: ['待编辑', '待记账', '已记账']
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageInitialize({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    delData(item) {
      this.$confirm(
        `您确定要删除该条数据吗？删除后不可恢复！`,
        '删除提示',
        {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        }
      )
        .then(() => {
          this.$apis.delInitialize(item.id).then(res => {
            if (res.body) {
              this.$message.success('删除成功')
              this.getData()
            }
          })
        })
        .catch(() => {

        })
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
  }
};
</script>