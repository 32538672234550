<template>
  <div class="login">
    <div>
      <div class="title">
        <h1 class="title-group">
          <img src="../assets/group/Group.png" alt />
          <img src="../assets/group/group-1.png" alt />
          <img src="../assets/group/group-2.png" alt />
          <img src="../assets/group/group-3.png" alt />
          <img src="../assets/group/group-4.png" alt />
          <img src="../assets/group/group-5.png" alt />
          <img src="../assets/group/group-6.png" alt />
        </h1>
        <div class="title-cn">嘉鼎门店管理系统</div>
        <div class="title-en">Store management system</div>
        <!-- <img src="../assets/banner.png" alt /> -->
      </div>
      <div class="login-box">
        <div class="row">
          <i class="fa fa-user"></i>
          <div class="line"></div>
          <el-input
            v-model="Form.account"
            maxlength="1000"
            placeholder="请输入账户名"
            clearable
            @keydown.enter.native="login()"
          />
        </div>
        <div class="row">
          <i class="fa fa-lock"></i>
          <div class="line"></div>
          <el-input
            v-model="Form.password"
            maxlength="1000"
            type="password"
            @keydown.enter.native="login()"
            show-password
            placeholder="请输入密码"
          />
        </div>
        <div class="row">
          <i class="fa fa-code"></i>
          <div class="line"></div>
          <el-input
            v-model="Form.code"
            maxlength="1000"
            @keydown.enter.native="login()"
            placeholder="请输入验证码"
          />
          <img
            :src="Form.imgSrc"
            width="80"
            height="40"
            class="code-img"
            @click="refreshCode()"
          />
        </div>
        <div class="row login-button" @click="login()">
          {{ loading ? "登录中..." : "确定" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      Form: {
        account: "",
        password: "",
        code: "",
        uid: "",
        imgSrc: "",
      },
      loading: false,
    };
  },
  methods: {
    // 刷新验证码
    refreshCode() {
      this.Form.uid = this.randomString(32);
      this.Form.imgSrc =
        "/api/erp/v1/authentication/captcha?uid=" + this.Form.uid;
    },
    // 登录
    login() {
      if (this.loading === true) return;
      if (this.Form.account === "" || this.Form.account === null) {
        this.$message.warning("请输入用户名！");
        return;
      }
      if (this.Form.password === "" || this.Form.password === null) {
        this.$message.warning("请输入密码！");
        return;
      }
      if (this.Form.code === "" || this.Form.code === null) {
        this.$message.warning("请输入验证码！");
        return;
      }
      this.loading = true;
      this.$apis
        .login({
          ...this.Form,
          password: this.encrypt(this.Form.password, this.Form.uid),
        })
        .then((res) => {
          // 登录成功，保存token
          this.loading = false;
          // let token = res.body.type + " " + res.body.credentials;
          let token =  res.body.credentials;
          window.sessionStorage.setItem("ims_token", token);
          this.$store.dispatch("initAttributeType");
          // if (window.config.install == "true") {
            this.$router.push("/Index");
          // } else {
          //   this.$router.push("/Init");
          // }
          this.$message.success("登录成功！");
        })
        .catch(() => {
          this.loading = false;
          this.refreshCode();
        });
    },
    initCode() {
      if (window.config && window.config.api) {
        this.refreshCode();
      } else {
        setTimeout(() => {
          this.initCode();
        }, 100);
      }
    },
  },
  mounted() {
    this.initCode();
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 100vh;
  @media screen  and (max-height: 600px) {
    height: 100%;
  }
  background: linear-gradient(90deg, @basicColor 0%, hsl(52, 76%, 55%) 100%),
    linear-gradient(90deg, @basicColor 0%, #e3cb34 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    color: #fff;
    font-family: PingFang SC;
    font-style: normal;
    text-align: center;
    .title-group {
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    .title-cn {
      font-size: 64px;
      font-weight: 500;
      line-height: 96px; /* 150% */
    }
    .title-en {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px; /* 150% */
    }
    // padding-top: calc(25% - 200px);
    // color: rgba(255, 255, 255, .85);
    // font-weight: bold;
    // font-size: 32px;
  }

  .login-box {
    padding: 30px;
    .row {
      width: 240px;
      height: 50px;
      line-height: 50px;
      margin: 15px auto;
      position: relative;
      box-shadow: 2px 2px 1px @color4;

      .code-img {
        position: absolute;
        top: 3px;
        right: 2px;
        height: 48px;
        border-radius: 2px;
        cursor: pointer;
      }

      /deep/.el-input__inner {
        padding-left: 60px;
      }

      &.login-button {
        border-radius: 8px;
        background: @basicColor;
        color: #fff;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: @pinkColor;
          color: #fff;
        }
      }

      > i {
        display: inline-block;
        position: absolute;
        left: 16px;
        top: 12px;
        color: @color3;
        z-index: 9;
        font-size: 24px;
      }
    }
  }
  .login-box .row[data-v-18b4f4ee] {
    box-shadow: none !important;
  }
  /deep/.el-input__inner{
    height: 50px !important;
  }
}
</style>
