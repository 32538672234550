<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">产品信息管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">产品名称：</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">产品属性：</div>
            <div class="item-value">
              <el-select v-model="Query.attributeType" placeholder="请选择" clearable>
                <el-option v-for="item in $store.state.attributeTypes" :key="item.id" :label="item.name" :value="item.code"
                  ref="attributeTypes"></el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">供应商：</div>
            <div class="item-value">
              <inputSelect apiMethod="pageSupplier" v-model="Query.supplierId" />
            </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="$router.push('/System/Vaccine/Detail')">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="产品名称" min-width="80" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="specification" label="规格" min-width="120" align="center"></el-table-column>
        <el-table-column prop="unit.name" label="计量单位" width="80" align="center"></el-table-column>
        <el-table-column prop="attributeTypeName" label="产品属性" width="120" align="center">
        </el-table-column>
        <el-table-column prop="supplier.name" label="供应商" min-width="120" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="drugApprovalNo" label="批准文号" width="100" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="storageQuantity" label="库存数量" width="120" align="center"></el-table-column>
        <el-table-column prop="availableStorageQuantity" label="可用库存数量" width="120" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="$router.push('/System/Vaccine/Detail?id=' + scope.row.id)" type="text" class="edit">编辑</el-button>
            <el-button type="text" @click="deleData(scope.row)" style="color:rgba(217, 0, 17, 1)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import ImsNpmn from "@/components/imsNpmn.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    ImsNpmn
  },
  mixins: [mixins],
  computed: {
  },
  data() {
    return {
      Query: {
        nameLike: "",
        attributeType: "",
        supplierId: null
      },
      options: [],
      loading: false,
      Form: {},
       pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
    };
  },
  methods: {
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageVaccine({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleData(item) {
      this.$confirm("您确定要删除" + item.name + "吗?", "删除提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning"
      }).then(() => {
        this.$apis.delVaccine(item.id).then(res => {
          if (res.body) {
            this.$message.success("删除成功！");
            this.getData();
          }
        }).catch(()=>{})
      });
    },
    getAttributeTypes(value) {
      var arr = this.$store.state.attributeTypes.filter(item => item.code === value)
      return arr.length >= 0 ? arr[0].name : '-'
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="less" scoped>
.contact-box {
  position: relative;
  height: 160px;
  padding-top: 20px;
  padding-right: 40px;
  margin-top: 25px;
  margin-left: 40px;
  border: 1px @basicColor solid;

  .contact {
    position: absolute;
    top: -10px;
    left: 10px;
    width: 80px;
    text-align: center;
    align-content: center;
    background-color: #fff;
  }

  .close {
    position: absolute;
    top: -8px;
    right: -6px;
    width: 15px;
    height: 15px;
    line-height: 14px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    background-color: @redColor;
  }
}
</style>