<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          品牌介绍
        </div>
        <div style="margin-top: 25px">
          <tinymce v-model="form.content" ref="content" :height="560"/>
          <div class="dialog-footer" style="margin-top: 20px; text-align: center">
            <el-button
                type="primary"
                :loading="loading"
                @click="handleSubmit"
            >保存
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce/index.vue";
import Upload from "@/components/Upload/index.vue";
import {editOrAddIntroduction, getIntroduction} from "@/api/System/index";

export default {
  name: "MarketingBrandIntroduction",
  components: {
    Upload,
    Tinymce,
  },
  data() {
    return {
      loading: false,
      form: {}
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      var res = await getIntroduction();
      this.form = res
    },
    async handleSubmit() {
      var res = await editOrAddIntroduction(this.form);
      if(res){
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
          duration: 2000,
        });
        this.getData();
      }
    },
  }
}
</script>
<style scoped lang="less">

</style>