<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          交易流水
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
        <div class="search-item">
          <div class="item-label">订单时间：</div>
            <div class="item-value">
              <el-date-picker
              v-model="queryForm.createTime"
              size="small"
              style="width: 340px;"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            ></el-date-picker>
            </div>
        </div>
        <div class="search-item">
          <div class="item-label">订单号：</div>
            <div class="item-value">
              <el-input
              v-model="queryForm.orderNo"
              placeholder="请输入订单号"
              clearable
            ></el-input>
            </div>
        </div>
        <div class="search-item">
          <div class="item-label">商品名称：</div>
            <div class="item-value">
              <el-input
              v-model="queryForm.productName"
              placeholder="请输入商品名称"
              clearable
            ></el-input>
            </div>
        </div>
        <div class="search-item">
          <div class="item-label">订单类型：</div>
            <div class="item-value">
              <el-select
              v-model="queryForm.orderType"
              placeholder="请选择"
              clearable
            >
              <el-option label="商品" :value="2"></el-option>
              <el-option label="充值卡" :value="3"></el-option>
            </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">用户名：</div>
              <div class="item-value">
                <el-input
                v-model="queryForm.userName"
                placeholder="请输入用户名"
                clearable
              ></el-input>
              </div>
          </div>
          <div class="search-item">
            <div class="item-label">手机号码：</div>
              <div class="item-value">
                <el-input
                v-model="queryForm.mobile"
                placeholder="请输入手机号码"
                clearable
              ></el-input>
              </div>
          </div>
          <div class="search-item">
            <div class="item-label">支付状态：</div>
              <div class="item-value">
                <el-select v-model="queryForm.paymentStatus" clearable>
                <el-option :value="0" label="待支付"></el-option>
                <el-option :value="1" label="支付成功"></el-option>
                <el-option :value="2" label="支付失败"></el-option>
                <el-option :value="3" label="支付超时"></el-option>
              </el-select>
              </div>
          </div>
          <div class="search-item">
            <div class="item-label">支付方式：</div>
              <div class="item-value">
                <el-select
                v-model="queryForm.paymentType"
                placeholder="请选择"
                clearable
              >
                <el-option label="微信" value="1"></el-option>
                <el-option label="支付宝" value="2"></el-option>
                <el-option label="钱包" value="3"></el-option>
                <el-option label="线下" value="4"></el-option>
              </el-select>
              </div>
          </div>
        </div>
        <div class="page-search-right">
          <imsButton typeClass="secondary" text="重置"  style="float: right; margin:0 4px;" @click="handleReset()" />
          <imsButton style="float: right;" text="查询" :loading="loading" @click="handleQuery" />
        </div>
      </div>
      <el-row :gutter="12" style="margin-top:20px;">
        <el-col :span="6">
          <el-card shadow="hover">
            累计销售额：{{ summaryAmount.sale }} 元
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover">
            累计充值额：{{ summaryAmount.recharge }} 元
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover">
            退款金额：{{ summaryAmount.refund }} 元
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover">运费：{{ summaryAmount.express }} 元</el-card>
        </el-col>
      </el-row>
      <div class="base-table">
        <el-table :data="flowList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
            <template v-if="item.prop === 'products'" #default="scope">
              <div>
                <div v-if="scope.row.orderType !== 3">
                  <div v-for="(it, idx) in scope.row.products" :key="idx">
                    <span>{{ `【${it.name}(${it.skuName})】` }}</span>
                  </div>
                </div>
                <div v-else>{{ scope.row.productName }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getFinancePageListApi"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getFinancePageList, getFinanceSummary } from '@/api/Finance'
export default {
  name: 'financeFlowList',
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        createTime: [],
        orderNo: '',
        productName: '',
        orderType: null,
        userName: '',
        mobile: '',
        paymentStatus: null,
        paymentType: null
      },
      total: 0,
      flowList: [],
      summaryAmount: {},
      columns: [
        {
          label: '订单时间',
          prop: 'createDate',
          width: '180',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD hh:mm:ss'
            })
          }
        },
        {
          label: '流水号',
          prop: 'businessNo',
          width: '180'
        },
        {
          label: '订单号',
          prop: 'orderNo',
          width: '180'
        },
        {
          label: '商品名称',
          prop: 'products',
          width: '240'
        },
        {
          label: '用户名',
          prop: 'userName'
        },
        {
          label: '手机号码',
          prop: 'mobile'
        },
        {
          label: '订单类型',
          prop: 'orderType',
          formatter: (row, column, value) => {
            var str = ''
            switch (value) {
              case 1:
                str = '会员订单'
                break
              case 2:
                str = '交易订单'
                break
              case 3:
                str = '充值卡'
                break
              default:
                str = ''
            }
            return str
          }
        },
        {
          label: '支付方式',
          prop: 'paymentType',
          formatter: (row, column, value) => {
            var str = ''
            switch (value) {
              case 1:
                str = '微信'
                break
              case 2:
                str = '支付宝'
                break
              case 3:
                str = '钱包'
                break
              case 4:
                str = '线下'
                break
              default:
                str = ''
            }
            return str
          }
        },
        {
          label: '支付状态',
          prop: 'paymentStatus',
          formatter: (row, column, value) => {
            var str = ''
            switch (value) {
              case 0:
                str = '待支付'
                break
              case 1:
                str = '支付成功'
                break
              case 2:
                str = '支付失败'
                break
              case 3:
                str = '支付超时'
                break
              default:
                str = ''
            }
            return str
          }
        },
        {
          label: '流水账目',
          prop: 'financeType',
          formatter: (row, column, value) => {
            var str = ''
            switch (value) {
              case 1:
                str = '销售'
                break
              case 2:
                str = '退款'
                break
              case 3:
                str = '欠费'
                break
              case 4:
                str = '赔偿'
                break
              case 5:
                str = '押金'
                break
              case 6:
                str = '运费'
                break
              default:
                str = ''
            }
            return str
          }
        },
        {
          label: '金额',
          prop: 'amount',
          formatter: (row, column, value) => {
            return '￥' + value
          }
        }
      ]
    }
  },
  created() {
    this.getFinancePageListApi()
    this.getFinanceSummaryApi()
  },
  methods: {
    async getFinanceSummaryApi() {
      this.summaryAmount = await getFinanceSummary()
      console.log(this.summaryAmount)
    },
    async getFinancePageListApi() {
      this.loading = true
      if (this.queryForm.createTime != null) {
        this.queryForm.createDateStart = this.queryForm.createTime[0]
        this.queryForm.createDateEnd = this.queryForm.createTime[1]
      } else {
        this.queryForm.createDateStart = null
        this.queryForm.createDateEnd = null
      }
      const params = JSON.parse(JSON.stringify(this.queryForm))
      delete params.createTime
      const { records, recordCount } = await getFinancePageList(params)
      this.flowList = records
      this.total = recordCount
      this.loading = false
    },
    handleQuery() {
      this.queryForm.pageIndex = 1
      this.getFinancePageListApi()
    },
    handleReset() {
      let data = {}
      for(let key in this.queryForm){
          if(key!= 'pageIndex' && key != 'pageSize'){
            data[key] = this.queryForm[key]
          }
      }
      this.initListData(data)
      this.queryForm = Object.assign(this.queryForm,data)
        this.$nextTick(() => {
        this.getFinancePageListApi()
            })
        }
    // handleReset() {
    //   this.resetForm('queryForm')
    //   this.getFinancePageListApi()
    // }
  }
}
</script>

<style lang="less" scoped></style>
