import request from '../request.js'

export default {
    // 用户登录
  login(data) {
    return request.Put({
      url: '/v1/authentication/classic',
      data: data
    })
  },
}