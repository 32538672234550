<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">采购入库</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
          <div class="item-label">编号：</div>
          <div class="item-value">
            <el-input v-model="Query.noLike" clearable maxlength="99"></el-input>
          </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column label="采购入库单号" width="160" align="center">
          <template slot-scope="scope">
            <span class="link" @click="$router.push('/Purchase/Order/detailPage?id=' + scope.row.sourceId)">
                            {{scope.row.no}}
             </span>
          </template>
        </el-table-column>
        <el-table-column prop="inboundDate" label="入库日期" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="reviewDate" label="记账日期" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="reviewUser.name" label="记账人" min-width="80" align="center">
        </el-table-column>
        <el-table-column label="状态" min-width="80" align="center">
          <template slot-scope="scope">
            {{ statusOptions[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button @click="$router.push('/Stock/Into/formPage?id=' + scope.row.id)" type="text">
              <span v-if="scope.row.status == 0">编辑</span>
              <span v-if="scope.row.status == 1">记账</span>
              <span v-if="scope.row.status == 2">查看</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
    <!-- 选择采购单 -->
    <el-dialog :visible.sync="dialogVisible" width="90%" append-to-body :close-on-click-modal="false">
      <div class="dialog-header">选择采购单入库</div>
      <div class="dialog-main">
        <div class="page-search">
          <div class="search-item">
            <div class="item-label">采购单号</div>
            <div class="item-value">
              <el-input v-model="dpageQuery.requestNoLike" clearable maxlength="99"></el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="item-label">采购日期</div>
            <div class="item-value">
              <el-date-picker v-model="dpageQuery.applyDateArr" type="daterange" value-format="yyyy-MM-dd"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" ref="applyDate"></el-date-picker>
            </div>
          </div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getApplyData()" />
        </div>
        <el-table :data="dTableData" border style="width: 100%;margin-top: 20px;">
          <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
          <el-table-column prop="no" label="采购单号" min-width="180" align="center">
            <template slot-scope="scope">
              <span class="link" @click="$router.push(`/Purchase/Plan/detailPage?&id=${scope.row.id}`)">{{ scope.row.no
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="计划名称" min-width="160" align="center"></el-table-column>
          <el-table-column prop="purchaseOrderCount" label="采购单数量" width="120" align="center"></el-table-column>
          <el-table-column prop="signedContractCount" label="已签合同采购数量" width="140" align="center"></el-table-column>
          <el-table-column prop="arrivedCount" label="已到货采购单数量" width="140" align="center"></el-table-column>
          <el-table-column label="采购日期" width="160" align="center">
            <template slot-scope="scope">
              {{ scope.row.purchaseDate | dateformat() }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <div class="link" @click="$router.push(`/Stock/Into/formPage?orderId=${scope.row.id}&orderNo=${scope.row.no}`)">
                选择
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination  layout="total, prev, pager, next,jumper"
          :current-page="dpageQuery.pageIndex" :total="dpageQuery.total"
          @current-change="val => dpageQuery.pageIndex = val"></el-pagination>
      </div>
      <div class="dialog-footer">
        <imsButton typeClass="secondary" text="关闭" @click="dialogVisible = false" />
        <imsButton text="确定" @click="updateList()" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
import ImsNpmn from "@/components/imsNpmn.vue";
export default {
  components: {
    imsButton,
    inputSelect,
    ImsNpmn
  },
  watch: {
    "dpageQuery.applyDateArr"(val) {
      if (val) {
        if (val.length > 0) {
          this.pageQuery.startApplyDate = this.$options.filters["dateformat"](
            val[0],
            "yyyy-MM-DDTHH:mm:ss"
          );
          this.pageQuery.endApplyDate = this.$options.filters["dateformat"](
            val[1],
            "yyyy-MM-DDTHH:mm:ss"
          );
        } else {
          this.pageQuery.startApplyDate = null;
          this.pageQuery.endApplyDate = null;
        }
      }
    },
    "dpageQuery.pageIndex"(val) {
      if (val) {
        this.getApplyData();
      }
    },
    dialogVisible(val) {
      if (val) {
        this.getApplyData();
      }
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      Query: {
        noLike: null,
      },
       pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
      dpageQuery: {
        pageIndex: 1,
        total: 0,
        requestNoLike: null,
        createUserId: null,
        applyDateArr: [],
        isSubmit: true
      },
      dTableData: [],
      statusOptions: ['待编辑', '待记账', '已记账']
    };
  },
  methods: {
    getApplyData() {
      this.loading = true;
      this.$apis
        .pagePurchaseOrder({ ...this.dpageQuery })
        .then(res => {
          this.dTableData = res.body.records;
          this.dpageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pagePurchaseInbound({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    delData(item) {
      this.$confirm(
        `您确定要删除该条数据吗？删除后不可恢复！`,
        '删除提示',
        {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        }
      )
        .then(() => {
          this.$apis.delPurchaseInbound(item.id).then(res => {
            if (res.body) {
              this.$message.success('删除成功')
              this.getData()
            }
          })
        })
        .catch(() => {

        })
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  }
};
</script>