<template>
  <el-dialog
    title="退款申请"
    :visible.sync="visible"
    width="30%"
    :modal="false"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-card class="box-card">
      <div>
        <el-form ref="queryForm" :inline="true" :model="orderForm">
          <el-form-item label="退款详情" prop="reasonTypeName">
            {{ orderForm.reasonTypeName }}
          </el-form-item>
          <el-form-item label="退款金额" prop="refundAmount">
            <el-input
              type="number"
              v-model="orderForm.refundAmount"
              placeholder="请输入退款金额"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="添加备注" prop="remark">
            <el-input
              type="textarea"
              :rows="2"
              v-model="orderForm.remark"
              placeholder="请输入退款备注"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handSubmit(true)">通过</el-button>
            <el-button @click="handSubmit(false)">不通过</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
import { refundByOrderUid } from "@/api/Order";
import { RefundCause } from "@/constant/status";
export default {
  props: {
    fatherMethod: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      orderForm: {},
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.orderForm = {};
    },
    setConfigInfo(orderInfo) {
      this.orderForm = {
        orderUid: orderInfo.orderUid,
        reasonTypeName: RefundCause[orderInfo.refund.reasonType],
        refundAmount: orderInfo.refundAmount,
        remark: "",
      };
    },
    async handSubmit(isPass) {
      this.orderForm.isPass = isPass;
      await refundByOrderUid(this.orderForm);
      this.$message.success("退款操作成功：" + isPass ? "通过" : "不通过");
      this.fatherMethod(this.orderForm.orderUid);
      this.close();
    },
  },
};
</script>

<style lang="less" scoped></style>
