<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    查看采购订单
                </div>
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item">
                    <div class="item-label bold">采购单号：</div>
                    <div class="item-value">
                        {{ Form.no }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">采购计划单号：</div>
                    <div class="item-value">
                        {{ Form.planNo }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">采购计划名称：</div>
                    <div class="item-value ellipsis">
                        <el-tooltip v-if="Form.name?.length>20"  effect="dark" :content="Form.name" placement="bottom-start">
                            <p class="ellipsis"> {{ Form.name }}</p>
                        </el-tooltip>
                        <p v-else>{{ Form.name }}</p>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">
                        采购日期：
                    </div>
                    <div class="item-value">
                        {{Form.purchaseDate}}
                    </div>
                </div>
            </div>
            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                产品信息
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 15px;" height="calc(100% - 200px)"
                ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="140" align="center">
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
                </el-table-column>
                <el-table-column label="规格" prop="specification" min-width="140" align="center">
                </el-table-column>
                <el-table-column label="合同编号" prop="contractNo" width="120" align="center">
                </el-table-column>
                <el-table-column label="合同生效日期" prop="commencementDate" width="120" align="center">
                </el-table-column>
                <el-table-column label="采购日期" prop="purchaseDate" width="120" align="center">
                </el-table-column>
                <el-table-column label="物价ID" prop="priceId" width="80" align="center">
                </el-table-column>
                <el-table-column label="计划采购数量" prop="purchaseQuantity" width="120" align="center">
                </el-table-column>
                <el-table-column label="实际采购数量" prop="contractQuantity" width="120" align="center">
                </el-table-column>
                <el-table-column label="采购总金额" prop="contractAmount" width="120" align="center">
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Purchase/Order')" />
            </div>
        </div>
    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
import npmnDialog from '@/components/npmnDialog.vue';
export default {
    components: {
        inputSelect,
        inputNumber,
        npmnDialog
    },
    watch: {
    },
    data() {
        return {
            loading: false,
            Form: {
                id: null,
                listingCollection: [],
            },
            Query: {
                nameLike: null,
                enabled: true
            },
            tableData: [],
        }
    },
    methods: {
        getDetail() {
            this.$apis.getPurchaseOrder(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                }
            }).catch(() => {

            })
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>