<template>
  <el-dialog
    title="充值"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form
      ref="dialogForm"
      :model="rechargeForm"
      :rules="rules"
      label-width="100px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="充值金额:" prop="rechargePrice">
            <el-input
              type="number"
              v-model="rechargeForm.rechargePrice"
              placeholder="请输入充值金额"
              min="0"
              @keyup.native="price($event)"
              @blur="price($event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="赠送金额:" prop="givePrice">
            <el-input
              type="number"
              v-model="rechargeForm.givePrice"
              placeholder="请输入销售价格"
              min="0"
              @keyup.native="price($event)"
              @blur="price($event)"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { userRecharge } from '@/api/User'
export default {
  props: {},
  data() {
    return {
      visible: false,
      loading: false,
      userInfo: {},
      rechargeForm: {},
      rules: {
        rechargePrice: [
          {
            required: true,
            message: '请输入销售价格',
            trigger: 'blur'
          }
        ],
        givePrice: [
          {
            required: true,
            message: '请输入赠送金额',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.userInfo = {}
      this.rechargeForm = {
        rechargePrice: 100,
        givePrice: 10
      }
      this.resetForm('dialogForm')
    },
    number(e) {
      let boolean = new RegExp('^[1-9][0-9]*$').test(e.target.value)
      if (!boolean) {
        this.$message.error('请输入正整数')
        e.target.value = '0'
      }
    },
    price(e) {
      if (e.target.value < 0) {
        this.$message.error('请输入正整数')
        e.target.value = '0'
      }
    },
    getData(userInfo) {
      this.userInfo = userInfo
      this.visible = true
    },
    async handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            let params = {
              userId: this.userInfo.userId,
              rechargePrice: this.rechargeForm.rechargePrice,
              givePrice: this.rechargeForm.givePrice
            }
            console.log(params)
            await userRecharge(params)
            this.$parent.getUserListApi()
            this.loading = false
            this.close()
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.attr__name {
  font-size: 15px;
  font-weight: bold;
}
</style>
