/*!
 * 一些常用状态方法
 */
//订单状态
var OrderStatus = new Array()
OrderStatus[1] = ''
OrderStatus[2] = '已付款'
OrderStatus[3] = '待收货'
OrderStatus[4] = '待归还'
OrderStatus[5] = '待评价'
OrderStatus[6] = '归还中'
OrderStatus[7] = '已关闭'
OrderStatus[8] = '已归还'
OrderStatus[9] = '退款中'
OrderStatus[10] = '已退款'
OrderStatus[11] = '已完成'
OrderStatus[13] = '申请退款中'
OrderStatus[14] = '申请退款不通过'
OrderStatus[15] = '拒收'
OrderStatus[16] = '退款失败'
OrderStatus[17] = '已买断'

export var BuyOrderStatus = new Array()
BuyOrderStatus[1] = ''
BuyOrderStatus[2] = '待发货订单'
BuyOrderStatus[3] = '待收货订单'
BuyOrderStatus[4] = '待退款'
BuyOrderStatus[5] = '全部订单'
// BuyOrderStatus[4] = ''
// BuyOrderStatus[5] = ''
// BuyOrderStatus[6] = ''
// BuyOrderStatus[7] = ''
// BuyOrderStatus[8] = ''
// BuyOrderStatus[9] = ''
// BuyOrderStatus[10] = '已退款'
// BuyOrderStatus[11] = '已完成'

// BuyOrderStatus[14] = ''
// BuyOrderStatus[15] = ''
// BuyOrderStatus[16] = ''

//订单类型
var OrderTypes = new Array()
OrderTypes[1] = '会员订单'
OrderTypes[2] = '交易订单'
OrderTypes[3] = '租赁订单'

//欠费订单类型
var AbnormalTypes = new Array()
AbnormalTypes[1] = '欠费订单'
AbnormalTypes[2] = '赔偿订单'

//申请退款原因
export var RefundCause = new Array()
RefundCause[1] = '不想买了'
RefundCause[2] = '未收到商品'
RefundCause[3] = '空包裹'
RefundCause[4] = '其他'
RefundCause[5] = '商品归还退款'
RefundCause[6] = '拒收商品'

export function getBuyOrderStatus() {
  let result = []
  BuyOrderStatus.forEach(function(item, index) {
    if (item !== '') {
      if (index === 5) {
        result.push({ id: '', remark: item })
      } else if (index === 4) {
        result.push({ id: 13, remark: item })
      } else {
        result.push({ id: index, remark: item })
      }
    }
  })
  return result
}
