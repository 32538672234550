<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">销售出库详情</div>
      </div>
    </div>
    <div class="page-body" :style="{ height: getHeight() }">
      <div class="form-title">
        <span class="fa fa-border-style"></span>
        基本信息
      </div>
      <div class="form-body">
        <div class="search-item">
          <div class="item-label bold">单号：</div>
          <div class="item-value">
            <el-input v-model="Form.no" disabled></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">门店：</div>
          <div class="item-value">
            <el-input v-model="Form.customer.name" disabled></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="item-label bold">
            <span class="star"  v-if="$route.query.status == 1">*</span>出库日期：
          </div>
          <div class="item-value">
            <el-input v-model="Form.outboundDate" disabled></el-input>
          </div>
        </div>
      </div>
      <div class="form-title" style="margin-top: 20px">
        <span class="fa fa-border-style"></span>
        退货清单
      </div>
      <el-table :data="Form.stockOutboundListingCollection" border style="width: 100%; margin-top: 15px"
        height="calc(100% - 200px)" ref="list" destroy-on-close>
        <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="产品名称" prop="name" min-width="140" align="center">
        </el-table-column>
        <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
        </el-table-column>
        <el-table-column label="生产批号" prop="batchNo" width="120" align="center">
        </el-table-column>
        <el-table-column label="规格" prop="specification" width="120" align="center">
        </el-table-column>
        <el-table-column label="产品属性" prop="attributeTypeName" min-width="200" align="center">
        </el-table-column>
        <el-table-column label="单价" prop="price" width="80" align="center">
        </el-table-column>
        <el-table-column label="库存量" prop="stockStorageQuantity" width="100" align="center">
        </el-table-column>
        <el-table-column label="可用库存量" prop="availableStockStorageQuantity" width="100" align="center">
        </el-table-column>
        <el-table-column label="销售出库数量" prop="quantity" width="120" align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="page-footer">
      <div class="btn-box border">
        <imsButton typeClass="secondary" text="返回" @click="$router.push('/Stock/Out')" style="margin-right:10px" />
        <imsButton typeClass="primary" text="确认记账" :loading="loading" @click="confirmForm()" v-if="Form.status == 1" />
      </div>
    </div>
  </div>
</template>
<script>
import imsButton from "@/components/imsButton.vue";
export default {
  components: {
    imsButton,
  },
  watch: {},
  data() {
    return {
      loading: false,
      Form: {
        id: null,
        stockOutboundListingCollection: [],
      },
    };
  },
  methods: {
    // 记账
    confirmForm() {
      this.loading = true;
      this.$apis
        .confirmOrderFlowing({ status: 2 }, this.Form.id)
        .then(res => {
          if (res.body) {
            this.$message.success("操作成功！自动返回列表页");
          }
          this.loading = false;
          this.$router.push("/Stock/Out");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetail() {
      this.$apis
        .getOrderFlowing(this.Form.id)
        .then((res) => {
          this.Form = {
            ...this.Form,
            ...res.body,
            // customerName:res.body.records.customer.name,
          };
        })
        .catch(() => { });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.Form.id = this.$route.query.id;
        this.getDetail();
      }
    });
  },
};
</script>
