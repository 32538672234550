<template>
  <div class="page">
    <div class="page-body" style="width:100%;height: 100%;">
      <div class="index_left">
        <div class="todo_card">
          <div class="todo_title">销售订单</div>
          <el-table :data="tableData1" border style="width: 100%" height="calc(100% - 40px)">
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="no" label="销售单号" width="160" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="customer.name" label="门店" min-width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="制单日期" width="100" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.createTime | dateformat() }}
              </template>
            </el-table-column>
            <el-table-column prop="totalAmount" label="总金额" width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <div class="link"
                  @click="$router.push(`/Sale/Order/${scope.row.status == 0 ? 'formPage' : 'detailPage'}?id=${scope.row.id}&status=${scope.row.status}`)">
                  {{ scope.row.status == 0 ? '编辑' : scope.row.status == 1 ? '复核' : '查看' }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="todo_card">
          <div class="todo_title">采购计划</div>
          <el-table :data="tableData2" border style="width: 100%" height="calc(100% - 40px)">
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="no" label="采购计划单号" width="160" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="计划名称" min-width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="采购单数量" width="120" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.listingCollection.length }}
              </template>
            </el-table-column>
            <el-table-column label="创建日期" width="100" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.createTime | dateformat() }}
              </template>
            </el-table-column>
            <el-table-column label="审核日期" width="100" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.auditDate | dateformat() }}
              </template>
            </el-table-column>
            <el-table-column prop="printCount" label="打印次数" width="80" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <div class="link"
                  @click="$router.push(`/Purchase/Plan/${scope.row.approvalStatus == 0 || scope.row.approvalStatus == 4 ? 'formPage' : 'detailPage'}?approvalStatus=${scope.row.approvalStatus}&id=${scope.row.id}`)">
                  {{ scope.row.approvalStatus == 0 || scope.row.approvalStatus == 4 ? '编辑' :
                    scope.row.approvalStatus == 1 ? '审核' : '查看'
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="todo_card">
          <div class="todo_title">销售出库单</div>
          <el-table :data="tableData3" border style="width: 100%" height="calc(100% - 40px)">
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="no" label="销售单号" width="160" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="link"
                  @click="$router.push(`/Sale/Order/detailPage?status=${scope.row.status}&id=${scope.row.sourceId}`)">{{
                    scope.row.no }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="customer.name" label="门店" min-width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="出库日期" width="100" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.outboundDate }}</template>
            </el-table-column>
            <el-table-column prop="creator.name" label="操作人" width="80" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作时间" width="100" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.createTime | dateformat() }}</template>
            </el-table-column>
            <el-table-column label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <div class="link"
                  @click="$router.push(`/Stock/Out/detail?&id=${scope.row.id}&status=${scope.row.status}`)">
                  {{ scope.row.status == 1 ? '记账' : '查看' }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="todo_card">
          <div class="todo_title">采购入库单</div>
          <el-table :data="tableData4" border style="width: 100%" height="calc(100% - 40px)">
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column label="采购单号" min-width="160" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="link" @click="$router.push('/Purchase/Plan/detailPage?id=' + scope.row.sourceId)">
                  {{ scope.row.no }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="inboundDate" label="入库日期" width="100" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="reviewDate" label="记账日期" width="100" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="reviewUser.name" label="记账人" width="80" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button @click="$router.push('/Stock/Into/formPage?id=' + scope.row.id)" type="text">
                  <span v-if="scope.row.status == 0">编辑</span>
                  <span v-if="scope.row.status == 1">记账</span>
                  <span v-if="scope.row.status == 2">查看</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="index_right">
        <div class="right_bottom">
          <div class="nav_title">
            便捷导航
          </div>
          <div class="nav_item" v-for="i in menu" :key="i.index" @click="routePath(i.route)">
            <span>{{ i.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      name: '管理员',
      num: 56,
      Form: [],
      menu: [
        {
          title: '新建采购计划',
          route: '/Purchase/Plan/formPage'
        },
        {
          title: '新建采购退货单',
          route: '/Purchase/Reject/formPage'
        },
        {
          title: '新建销售单',
          route: '/Sale/Order/formPage'
        },
        {
          title: '新建销售退货单',
          route: '/Sale/Reject/formPage'
        },
      ],
      // 首页数据展示
      IndexForm: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: []
    }
  },
  methods: {
    routePath(val) {
      if (val) {
        this.$router.push(val)
      }
    },
    // 获取首页数据 
    getData() {
      this.$apis
        .homeStatistics()
        .then(res => {
          this.IndexForm = res.body
        })
        .catch(() => {
        });
    },
  },
  mounted() {
    this.getData()
    // 销售订单-待审批
    this.$apis.pageSaleOrder({ status: 1, pageSize: 20 }).then(res => {
      this.tableData1 = res.body.records;
    }).catch(() => {

    })
    // 采购计划-待审批
    this.$apis.pagePurchasePlan({ approvalStatus: 1, pageSize: 20 }).then(res => {
      this.tableData2 = res.body.records;
    }).catch(() => {

    })
    // 销售出库单-待审批
    this.$apis.pageOrderFlowing({ status: 1, pageSize: 20 }).then(res => {
      this.tableData3 = res.body.records;
    }).catch(() => {

    })
    // 采购入库单-待审批
    this.$apis.pagePurchaseInbound({ status: 1, pageSize: 20 }).then(res => {
      this.tableData4 = res.body.records;
    }).catch(() => {

    })
  }

}
</script>
  
<style lang="less" scoped>
.index_left {
  width: calc(100% - 400px);
  display: inline-block;
  vertical-align: top;

  .todo_card {
    width: 50%;
    padding: 10px;
    height: calc(50vh - 60px);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;

    .todo_title {
      font-size: 20px;
      padding-top: 15px;
      padding-bottom: 5px;
    }
  }
}

.index_right {
  width: 400px;
  display: inline-block;
  vertical-align: top;

  .right_top {
    width: 100%;
    height: 60px;
    margin-top: 20px;
  }

  .right_bottom {
    width: 100%;
    padding: 0 80px 0 40px;
    box-sizing: border-box;
    margin-top: 20px;

    .nav_title {
      font-size: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, .15);
      padding-bottom: 10px;
    }

    .nav_item {
      margin-top: 20px;
      cursor: pointer;
    }
  }
}</style>