<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    {{ Form.approvalStatus != 1 ? '查看采购计划' : '审核采购计划' }}
                </div>
                <!-- <imsButton style="float: right; margin-top: -50px;margin-right: 400px;" text="导出"
                    @click="exportData()" />
                <imsButton v-if="(Form.approvalStatus != 5)"
                    style="float: right; margin-top: -50px;margin-right: 300px;" text="作废" @click="delData()" />
                <imsButton v-if="(Form.approvalStatus == 1)"
                    style="float: right; margin-top: -50px;margin-right: 200px;" text="审批"
                    @click="(dialogVisibleEdit = true)" />
                <imsButton v-if="Form.hasChangeRecord" style="float: right; margin-top: -50px;margin-right: 100px;"
                    @click="$router.push('/Purchase/Plan/historyPage?id=' + Form.id)" text="查看历史" />
                <imsButton v-if="(Form.approvalStatus == 1)" style="float: right; margin-top: -50px;margin-right: 0px;"
                    text="解锁编辑" @click="toEdit()" /> -->
            </div>
        </div>
        <div class="page-body" :style="{ height: getHeight() }">
            <div class="form-title">
                <span class="fa fa-border-style"></span>
                基本信息
            </div>
            <div class="form-body">
                <div class="search-item" data-required="no">
                    <div class="item-label bold">采购计划名称：</div>
                    <div class="item-value ellipsis">
                        <el-tooltip v-if="Form.name?.length>20"  effect="dark" :content="Form.name" placement="bottom-start">
                            <p class="ellipsis"> {{ Form.name }}</p>
                        </el-tooltip>
                        <p v-else>{{ Form.name }}</p>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">采购计划单号</div>
                    <div class="item-value">
                        {{ Form.no }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">制单人：</div>
                    <div class="item-value">
                        {{ Form.creator.name }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">制单日期：</div>
                    <div class="item-value">
                        {{ Form.createDate | dateformat() }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">审批状态：</div>
                    <div class="item-value">
                        {{ this.dict1[Number(Form.approvalStatus)] }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">审核日期：</div>
                    <div class="item-value">
                        {{ Form.auditDate | dateformat() }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">打印次数：</div>
                    <div class="item-value">
                        {{ Form.printCount }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">最近打印日期：</div>
                    <div class="item-value">
                        {{ Form.printDate | dateformat() }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">采购单数量：</div>
                    <div class="item-value">
                        {{ Form.purchaseOrderCount }}
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">计划执行进度：</div>
                    <div class="item-value">
                        {{ Form.purchasePercent ? Form.purchasePercent : 0 }}%
                    </div>
                </div>
                <div >
                <div class="search-item" style="margin: 0px;">
                    <div class="item-label bold">审核内容：</div>
                    <div class="item-value">
                        {{ Form.approvalOpinion }}
                    </div>
                </div>
            </div>
            </div>

            <div class="form-title" style="margin-top: 20px;">
                <span class="fa fa-border-style"></span>
                产品信息
            </div>
            <el-table :data="Form.listingCollection" border style="width: 100%;margin-top: 5px;"
                height="calc(100% - 320px)" ref="list" destroy-on-close>
                <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
                <el-table-column label="产品名称" prop="name" min-width="140" align="center">
                </el-table-column>
                <el-table-column label="供应商" prop="supplier.name" width="120" align="center">
                </el-table-column>
                <el-table-column label="规格" prop="specification" width="120" align="center">
                </el-table-column>
                <el-table-column label="单位" prop="unit.name" width="80" align="center">
                </el-table-column>>
                <el-table-column label="库存数量" prop="storageQuantity" width="80" align="center">
                </el-table-column>
                <el-table-column label="收集计划数" prop="planQuantity" width="120" align="center">
                </el-table-column>
                <el-table-column label="本次采购数量" prop="quantity" width="120" align="center">
                </el-table-column>
                <el-table-column label="备注" prop="remark" min-width="140" align="center">
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" style="margin-right: 20px;" @click="$router.push('/Purchase/Plan')" />
                <imsButton  text="导出"
                style="margin-right: 20px;" @click="exportData()" />
                <imsButton v-if="(Form.approvalStatus == 1)"
                    style="margin-right: 20px;" text="作废" @click="delData()" />
                <imsButton v-if="(Form.approvalStatus == 1)"
                style="margin-right: 20px;" text="审批"
                    @click="(dialogVisibleEdit = true)" />
                <imsButton v-if="Form.hasChangeRecord" style="margin-right: 20px;"
                    @click="$router.push('/Purchase/Plan/historyPage?id=' + Form.id)" text="查看历史" />
                <imsButton v-if="(Form.approvalStatus == 1)"
                    text="解锁编辑" @click="toEdit()" />
            </div>
        </div>

        <!-- 审核弹窗 -->
        <el-dialog :visible.sync="dialogVisibleEdit" width="90%" append-to-body :close-on-click-modal="false">
            <div class="dialog-header">填写审批意见</div>
            <div class="dialog-main">
                <div class="search-item">
                    <div class="item-label bold">审核结论：</div>
                    <div class="item-value">
                        <el-radio v-model="editForm.conclusion" :label="true">通过</el-radio>
                        <el-radio v-model="editForm.conclusion" :label="false">不通过</el-radio>
                    </div>
                </div>
                <div class="search-item">
                    <div class="item-label bold">上传文件：</div>
                    <div class="item-value">
                        <el-upload style="margin: 0 10px;" class="left" ref="ref-upload" accept="" action=""
                            :show-file-list="false" :http-request="uploadFile">
                            <el-button color="rgb(43,164,253)" type="primary" v-if="!editForm.fileInfo">
                                选择文件
                            </el-button>
                            <span v-else>{{editForm.filename}}</span>
                        </el-upload>
                    </div>
                </div>
                <div class="search-item" style="width: 100%;">
                    <div class="item-label bold">审核意见：</div>
                    <div class="item-value">
                        <el-input v-model="editForm.approvalContext" clearable maxlength="200" type="textarea" :rows="3"
                            placeholder="限200字" show-word-limit></el-input>
                    </div>
                </div>
            </div>
            <div class="dialog-footer">
                <imsButton typeClass="secondary" text="关闭" @click="dialogVisibleEdit = false" />
                <imsButton text="确定" @click="approval()" :loading="loading" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import inputNumber from '@/components/inputNumber.vue';
import npmnDialog from '@/components/npmnDialog.vue';
export default {
    components: {
        inputSelect,
        inputNumber,
        npmnDialog
    },
    watch: {
    },
    data() {
        return {
            loading: false,
            Form: {
                id: null,
                name: null,
                no: '系统自动生成',
                creator: '',
                listingCollection: [],
            },
            dialogVisible: false,
            Query: {
                nameLike: null,
                enabled: true
            },
            tableData: [],
            options: [],
            multipleSelection: [],
            editForm: {
                conclusion: true,
                approvalContext: null,
                fileInfo: null,
                filename: ''
            },
            dialogVisibleEdit: false
        }
    },
    methods: {
        // 上传附件
        uploadFile(file) {
            var params = new FormData(); // 创建form对象
            params.append("file", file.file); // 将文件存入file下面
            this.$apis.fileUpload(params).then(res => {
                this.editForm.fileInfo = res.body
                this.editForm.filename = file.file.name
            }).catch(()=>{})
        },
        // 解锁编辑
        toEdit() {
            this.$apis.unlockPurchasePlan(this.Form.id).then(res => {
                if (res.body) {
                    this.$router.push(`/Purchase/Plan/formPage?id=` + this.Form.id)
                }
            }).catch(()=>{})
        },
        // 审批
        approval() {
            this.loading = true
            this.$apis.approvalPurchasePlan(this.editForm, this.Form.id).then(res => {
                this.$message.success('审批意见提交成功')
                this.getDetail()
                this.loading = false
                this.dialogVisibleEdit = false
            }).catch(() => {
                this.loading = false
                this.dialogVisibleEdit = false
            })
        },
        // 更新listingCollection数据
        updateList() {
            this.Form.listingCollection = this.Form.listingCollection.concat(this.multipleSelection)
            this.dialogVisible = false
            this.multipleSelection = []
        },
        delData(scope) {
            this.$confirm(
                `您确定要作废该条数据吗？作废后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.$apis.invalidPurchasePlan(this.Form.id).then(res => {
                        if (res.body) {
                            this.$message.success('作废成功')
                            this.getDetail()
                        }
                    })
                })
                .catch(() => {

                })
        },
        // 导出数据
        exportData() {
            this.$message.success('开始导出，请耐心等待')
            this.$apis.exportPurchasePlan(this.Form.id).then(res => {

            }).catch(res => {
                if (!res) {
                    return
                }
                const blob = new Blob([res]);
                let url = window.URL.createObjectURL(blob);
                if ("download" in document.createElement("a")) {
                    try {
                        let link = document.createElement("a");
                        link.style.display = "none";
                        link.href = url;
                        link.setAttribute("download", '采购计划.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.remove()
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    navigator.msSaveBlob(blob, '采购计划.xlsx')
                }
            })
        },
        // 查询产品列表
        getData() {
            this.loading = true
            this.$apis.pagePurchasePlan({ ...this.Query, ...this.pageQuery }).then(res => {
                this.tableData = res.body.records.map(item => {
                    return {
                        ...item,
                        materialId: item.id,
                        id: null
                    }
                })
                this.pageQuery.total = Number(res.body.recordCount)
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getDetail() {
            this.$apis.getPurchasePlan(this.Form.id).then(res => {
                this.Form = {
                    ...this.Form,
                    ...res.body,
                }
            }).catch(() => {

            })
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.Form.id = this.$route.query.id
                this.getDetail()
            }
        })
    }
}
</script>