
import Finance from './Finance'
import Purchase from './Purchase'
import Sale from './Sale'
import Stock from './Stock'
import Statistics from './Statistics'
import Home from './Home'
import System from './System'
import Auth from './Auth'
export default {
    ...Finance,
    ...Purchase,
    ...Sale,
    ...Statistics,
    ...Home,
    ...Auth,
    ...System,
    ...Stock
}