import request from '../request.js'

export default {

  // 公共-文件上传
  fileUpload(data) {
    return request.postFile({
      url: '/v1/system/file-storage/upload',
      data: data
    })
  },

  // 公共-文件下载
  downloadFile(fileSummaryStr) {
    return request.Download({
      url: `/v1/system/file-storage/summary/${fileSummaryStr}`,
      data: {}
    })
  },

  // 角色-查询分页
  pageRole(data) {
    return request.Post({
      url: '/v1/system/role/page',
      data: data
    })
  },
  // 角色-添加
  addRole(data) {
    return request.Post({
      url: '/v1/system/role',
      data: data
    })
  },
  // 角色-详情
  getRole(id) {
    return request.Get({
      url: `/v1/system/role/${id}`,
      data: {}
    })
  },
  // 获取当前角色菜单树
  getFacadeTree(domain) {
    return request.Get({
      url: `/v1/system/facade/tree/${domain}`,
      data: {}
    })
  },
  // 角色-修改
  editRole(data, id) {
    return request.Put({
      url: `/v1/system/role/${id}`,
      data: data
    })
  },
  // 角色-启/停用
  toggleRole(id) {
    return request.Put({
      url: `/v1/system/role/enabled/${id}`,
      data: {}
    })
  },
  // 角色-查看用户分页列表
  pageRoleList(data) {
    return request.Post({
      url: '/v1/system/role/userPage',
      data: data
    })
  },
  // 用户-查看用户分页列表
  pageUser(data) {
    return request.Post({
      url: '/v1/system/user/page',
      data: data
    })
  },
  // 用户-启/停用
  toggleUser(uid) {
    return request.Put({
      url: `/v1/system/user/${uid}/isLock`,
      data: {}
    })
  },
  // 用户-添加
  addUser(data) {
    return request.Post({
      url: '/v1/system/user',
      data: data
    })
  },
  // 用户-修改
  editUser(data, uid) {
    return request.Put({
      url: `/v1/system/user/${uid}`,
      data: data
    })
  },
  // 重置指定用户密码
	resetPwd(data, Uid) {
		return request.Put({
			url: `/v1/system/user/repwd/${Uid}`,
			data: data
		})
	},
  // 门店-查看门店分页列表
  pageBtype(data) {
    return request.Post({
      url: '/v1/system/customer/page',
      data: data
    })
  },
  // 门店-添加
  addCustomer(data) {
    return request.Put({
      url: '/v1/system/customer',
      data: data
    })
  },
  // 门店-修改
  editCustomer(data, Id) {
    return request.Put({
      url: `/v1/system/customer?id=${Id}`,
      data: data
    })
  },
  // 门店-删除
  delCustomer(Id) {
    return request.Delete({
      url: `/v1/system/customer/${Id}`,
      data: {}
    })
  },
  // 供应商-查看供应商分页列表
  pageSupplier(data) {
    return request.Post({
      url: '/v1/system/supplier/page',
      data: data
    })
  },
  // 供应商-添加
  addSupplier(data) {
    return request.Put({
      url: '/v1/system/supplier',
      data: data
    })
  },
  // 供应商-修改
  editSupplier(data, Id) {
    return request.Put({
      url: `/v1/system/supplier?id=${Id}`,
      data: data
    })
  },
  // 供应商-删除
  delSupplier(Id) {
    return request.Delete({
      url: `/v1/system/supplier/${Id}`,
      data: {}
    })
  },
  // 计量单位-查看分页列表
  pageUnits(data) {
    return request.Post({
      url: '/v1/system/units/page',
      data: data
    })
  },
  // 计量单位-添加
  addUnits(data) {
    return request.Put({
      url: '/v1/system/units',
      data: data
    })
  },
  // 计量单位-修改
  editUnits(data, Id) {
    return request.Put({
      url: `/v1/system/units?id=${Id}`,
      data: data
    })
  },
  // 计量单位-删除
  delUnits(Id) {
    return request.Delete({
      url: `/v1/system/units/${Id}`,
      data: {}
    })
  },
  // 计量单位-启/停用
  toggleUnits(enabled, Id) {
    return request.Put({
      url: `/v1/system/units/${Id}/${enabled}`,
      data: {}
    })
  },
  // 仓库-查看分页列表
  pageWarehouse(data) {
    return request.Post({
      url: '/v1/system/warehouse/page',
      data: data
    })
  },
  // 获取许可信息列表
  facadePermission(appName) {
    return request.Get({
      url: `/v1/system/facade/permission/${appName}`
    })
  },

  // 仓库库位-查看分页列表
  pageWarehouseLocation(data) {
    return request.Post({
      url: '/v1/system/warehouse/location/page',
      data: data
    })
  },
  // 仓库-启/停用
  toggleWarehouse(Id) {
    return request.Put({
      url: `/v1/system/warehouse/disabled/${Id}`,
      data: {}
    })
  },
  // 仓库-添加
  addWarehouse(data) {
    return request.Put({
      url: '/v1/system/warehouse',
      data: data
    })
  },
  // 仓库-修改
  editWarehouse(data, id) {
    return request.Put({
      url: `/v1/system/warehouse?id=${id ? id : ''}`,
      data: data
    })
  },
  // 仓库-删除
  delWarehouse(Id) {
    return request.Delete({
      url: `/v1/system/warehouse/${Id}`,
      data: {}
    })
  },
  // 仓库-查询仓库详情
  getWarehouse(Id) {
    return request.Get({
      url: `/v1/system/warehouse/${Id}`,
      data: {}
    })
  },
  // 仓库-层级
  getWarehouseCascade() {
    return request.Get({
      url: `/v1/system/warehouse/cascade`
    })
  },
  // 产品-查看分页列表
  pageVaccine(data) {
    return request.Post({
      url: '/v1/system/product/page',
      data: data
    })
  },
  // 产品-添加
  addVaccine(data) {
    return request.Put({
      url: '/v1/system/product',
      data: data
    })
  },
  // 产品-修改
  editVaccine(data, Id) {
    return request.Put({
      url: `/v1/system/product?id=${Id}`,
      data: data
    })
  },
  // 产品-删除
  delVaccine(id) {
    return request.Delete({
      url: `/v1/system/product/${id}`,
      data: {}
    })
  },
  // 产品-查询产品详情
  getVaccine(Id) {
    return request.Get({
      url: `/v1/system/product/${Id}`,
      data: {}
    })
  },
  // 产品-查询单位换算分页
  pageVaccineUnit() {
    return request.Post({
      url: `/v1/system/product/unit/page`,
      data: {}
    })
  },
  // 产品-修改单位换算
  editVaccineUnit(data, Id) {
    return request.Put({
      url: `/v1/system/product/unit?id=${Id}`,
      data: data
    })
  },
  // 产品-添加单位换算
  addVaccineUnit(data) {
    return request.Put({
      url: `/v1/system/product/unit`,
      data: data
    })
  },
  // 产品-属性枚举
  getAttributeType() {
    return request.Get({
      url: `/v1/system/options/type/PRODUCT_ATTRIBUTE_TYPE`,
      data: {}
    })
  },
  // 库存实时
  getSystemStorage(queryContext) {
    return request.Post({
      url: `/v1/system/storage/page`,
      data: queryContext
    })
  },
  // 库存流水-分页列表
  getSystemStorageFlowing(productId, queryContext) {
    return request.Post({
      url: `/v1/system/storage/flowing/page/${productId}`,
      data: queryContext
    })
  },

  // 其它入库-分页列表
  pageInitialize(data) {
    return request.Post({
      url: `/v1/stock/inbound/other/page`,
      data: data
    })
  },
  // 其它入库-提交
  saveInitialize(data) {
    return request.Post({
      url: `/v1/stock/inbound/other`,
      data: data
    })
  },
  // 其它入库-记账or退回
  confirmInitialize(data, id) {
    return request.Put({
      url: `/v1/stock/inbound/other/${id}`,
      data: data
    })
  },
  // 其它入库-详情
  getInitialize(id) {
    return request.Get({
      url: `/v1/stock/inbound/other/${id}`,
      data: {}
    })
  },
  // 其它入库-删除
  delInitialize(id) {
    return request.Delete({
      url: `/v1/stock/inbound/other/${id}`,
      data: {}
    })
  },

  // 报废出库-分页列表
  pageScrap(data) {
    return request.Post({
      url: `/v1/stock/outbound/other/page`,
      data: data
    })
  },
  // 报废出库-提交
  submitScrap(data) {
    return request.Post({
      url: `/v1/stock/outbound/other/commit`,
      data: data
    })
  },
  // 报废出库-暂存
  saveScrap(data) {
    return request.Post({
      url: `/v1/stock/outbound/other/temporary`,
      data: data
    })
  },
  // 报废出库-记账or退回
  confirmScrap(data, id) {
    return request.Put({
      url: `/v1/stock/outbound/other/${id}`,
      data: data
    })
  },
  // 报废出库-详情
  getScrap(id) {
    return request.Get({
      url: `/v1/stock/outbound/other/${id}`,
      data: {}
    })
  },
  // 报废出库-删除
  delScrap(id) {
    return request.Delete({
      url: `/v1/stock/outbound/other/${id}`,
      data: {}
    })
  },

  // 采购入库-分页列表
  pagePurchaseInbound(data) {
    return request.Post({
      url: `/v1/stock/inbound/purchase/page`,
      data: data
    })
  },
  // 采购入库-删除
  delPurchaseInbound(id) {
    return request.Delete({
      url: `/v1/stock/inbound/purchase/${id}`,
      data: {}
    })
  },
  // 采购入库-详情
  getPurchaseInbound(id) {
    return request.Get({
      url: `/v1/stock/inbound/purchase/${id}`,
      data: {}
    })
  },
  // 采购入库-提交
  savePurchaseInbound(data, orderId) {
    return request.Post({
      url: `/v1/stock/inbound/purchase/${orderId ? orderId : ''}`,
      data: data
    })
  },
  // 采购入库-记账or退回
  confirmPurchaseInbound(data, id) {
    return request.Put({
      url: `/v1/stock/inbound/purchase/${id}`,
      data: data
    })
  },

  // 实时库存-分页
  pageStockStorage(data) {
    return request.Post({
      url: `/v1/stock/storage/page`,
      data: data
    })
  },


  // 应收账款-分页列表
  pageYS(data) {
    return request.Post({
      url: `/v1/financial/receipts/financial/page`,
      data: data
    })
  },

  // 统计报表-库存盘点报表-分页列表
  pageStatisticsInventory(data) {
    return request.Post({
      url: `/v1/inventory/report/page`,
      data: data
    })
  },
  // 统计报表-库存盘点报表-详情
  getStatisticsInventory(id) {
    return request.Get({
      url: `/v1/inventory/report/${id}`,
      data: {}
    })
  },
  // 统计报表-库存盘点报表-导出
  exportStatisticsInventory(data) {
    return request.DownloadPost({
      url: `/v1/common/export/statementInventoryBalance`,
      data: data
    })
  },
  // 统计报表-销售统计-分页列表
  pageStatisticsSale(data) {
    return request.Post({
      url: `/v1/sales/statistics/page`,
      data: data
    })
  },
  // 统计报表-销售统计-导出
  exportStatisticsSale(data = {}) {
    return request.DownloadPost({
      url: `/v1/common/export/salesStatistics`,
      data: data
    })
  },

  // 下载初始化excel模板
  downloadTemplate() {
    return request.Download({
      url: `/v1/excel/import/stock-storage`,
      data: {}
    })
  },
  // 读取初始化Excel内容
  uploadTemplate(data) {
    return request.postFile({
      url: `/v1/excel/import/stock-storage`,
      data: data
    })
  },
  // 提交初始化数据
  subimitInitData(data) {
    return request.Post({
      url: `/v1/install/setup`,
      data: data
    })
  },
}

import request_artisan from '@/utils/request_artisan'
// 运营配置
export function getSystemConfigList(data) {
  return request_artisan({
    url: '/v1/keys',
    method: 'get',
    params: data
  })
}

// 编辑运营配置
export function editSystemConfig(data) {
  return request_artisan({
    url: '/v1/EditKey',
    method: 'post',
    data
  })
}

// 获取品牌介绍
export function getIntroduction(){
  return request_artisan({
    url: '/v1/protocols/protocols',
    method: 'get'
  })
}

// 编辑或添加品牌介绍
export function editOrAddIntroduction(data) {
  return request_artisan({
    url: '/v1/protocols/editOrAdd',
    method: 'post',
    data
  })
}