import request_artisan from '@/utils/request_artisan'

// 获取会员列表
export function getMemberList(data) {
  return request_artisan({
    url: '/v1/Membership/Page',
    method: 'get',
    params: data
  })
}
