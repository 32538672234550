<template>
    <div class="init_page" v-loading="loading" element-loading-text="请耐心等待数据解析" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="init_title">初始化库存</div>
        <el-table :data="tableData" border style="width: calc(100% - 20px);margin: 0 10px;" ref="table"
            height="calc(100vh - 190px)">
            <el-table-column type="index" label="序号" width="80" align="center">
            </el-table-column>
            <el-table-column label="产品名称" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.productName" clearable :id="'productName' + (scope.$index)"
                        placeholder="限32字" maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="供应商" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.supplier" clearable :id="'supplier' + (scope.$index)" placeholder="限32字"
                        maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="批号" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.batchNo" clearable :id="'batchNo' + (scope.$index)" placeholder="限32字"
                        maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="规格" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.specification" clearable :id="'specification' + (scope.$index)"
                        placeholder="限32字" maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="单位" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.unitName" clearable :id="'unitName' + (scope.$index)" placeholder="限32字"
                        maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="产品属性" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.attributeTypeName" clearable :id="'attributeTypeName' + (scope.$index)"
                        placeholder="限32字" maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="有效期" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.effectiveDate" clearable :id="'effectiveDate' + (scope.$index)"
                        placeholder="限32字" maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="价格" align="center">
                <template slot-scope="scope">
                    <inputNumber v-model="scope.row.price" :id="'price' + (scope.$index)" :max="99999999" :min="0" :precision="2" />
                </template>
            </el-table-column>
            <el-table-column label="数量" align="center">
                <template slot-scope="scope">
                    <inputNumber v-model="scope.row.quantity" :id="'quantity' + (scope.$index)" :max="99999999" :min="0" />
                </template>
            </el-table-column>
            <el-table-column label="存储仓库" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.warehouseName" clearable :id="'warehouseName' + (scope.$index)"
                        placeholder="限32字" maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="存储位置" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.warehouseLocationFullPath" clearable
                        :id="'warehouseLocationFullPath' + (scope.$index)" placeholder="限32字" maxlength="32">
                    </el-input>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-btn">
            <imsButton typeClass="secondary" text="导出模板" @click="downloadTemplate()" />
            <imsButton typeClass="primary" class="button" text="提交" :loading="loading" @click="saveForm()" />
            <el-upload ref="ref-upload" accept=".xlsx, .xls" action="" :show-file-list="false" :http-request="uploadFile">
                <imsButton class="button" text="导入文件" :loading="loading" />
            </el-upload>
        </div>
    </div>
</template>
<script>
import mixins from '@/mixins';
import imsButton from "@/components/imsButton.vue";
import inputNumber from "@/components/inputNumber.vue";
export default {
    components: {
        imsButton,
        inputNumber
    },
    mixins: [mixins],
    data() {
        return {
            loading: false,
            tableData: []
        }
    },
    methods: {
        // 上传附件
        uploadFile(file) {
            var params = new FormData(); // 创建form对象
            params.append("file", file.file); // 将文件存入file下面
            this.loading = true
            this.$apis.uploadTemplate(params).then(res => {
                this.loading = false
                this.tableData = res.body
            }).catch(() => {
                this.loading = false
            })
        },
        downloadTemplate() {
            var notify = this.$notify.info({
                title: "消息",
                duration: 0,
                message: `正在导出，请耐心等待`,
            })
            this.$apis['downloadTemplate'](this.Form)
                .then((res) => {
                    var filename = res.headers['content-disposition']
                    filename = filename.split('filename=')
                    filename = decodeURIComponent(filename[1])
                    notify.close();
                    this.$notify.success({
                        title: "消息",
                        duration: 3000,
                        message: `[${filename}]已导出，请查收`,
                    });
                    const blob = new Blob([res.data]);
                    let url = window.URL.createObjectURL(blob);
                    if ("download" in document.createElement("a")) {
                        try {
                            let link = document.createElement("a");
                            link.style.display = "none";
                            link.href = url;
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        navigator.msSaveBlob(blob, filename);
                    }
                })
                .catch((res) => {

                });
        },
        saveForm() {
            if (this.tableData.length == 0) {
                this.$message.warning('初始化数据不能为空，请先导入文件')
                return
            }
            var bool = true
            this.tableData.forEach((item, index) => {
                if (bool && !item.productName) {
                    this.$message.warning('请填写“产品名称”')
                    document.getElementById('productName' + index).focus()
                    bool = false
                }
                if (bool && !item.supplier) {
                    this.$message.warning('请填写“供应商”')
                    document.getElementById('supplier' + index).focus()
                    bool = false
                }
                if (bool && !item.batchNo) {
                    this.$message.warning('请填写“批号”')
                    document.getElementById('batchNo' + index).focus()
                    bool = false
                }
                if (bool && !item.specification) {
                    this.$message.warning('请填写“规格”')
                    document.getElementById('specification' + index).focus()
                    bool = false
                }
                if (bool && !item.unitName) {
                    this.$message.warning('请填写“单位”')
                    document.getElementById('unitName' + index).focus()
                    bool = false
                }
                if (bool && !item.attributeTypeName) {
                    this.$message.warning('请填写“产品属性”')
                    document.getElementById('attributeTypeName' + index).focus()
                    bool = false
                }
                if (bool && !item.effectiveDate) {
                    this.$message.warning('请填写“有效期”')
                    document.getElementById('effectiveDate' + index).focus()
                    bool = false
                }
                if (bool && (item.effectiveDate != this.$options.filters['dateformat'](item.effectiveDate, 'yyyy-MM-DD'))) {
                    this.$message.warning('“有效期”格式不正确，正确格式为2023-08-08')
                    document.getElementById('effectiveDate' + index).focus()
                    bool = false
                }
                if (bool && !item.price) {
                    this.$message.warning('请填写“价格”')
                    document.getElementById('price' + index).focus()
                    bool = false
                }
                if (bool && !item.quantity) {
                    this.$message.warning('请填写“数量”')
                    document.getElementById('quantity' + index).focus()
                    bool = false
                }
                if (bool && !item.warehouseName) {
                    this.$message.warning('请填写“存储仓库”')
                    document.getElementById('warehouseName' + index).focus()
                    bool = false
                }
                if (bool && !item.warehouseLocationFullPath) {
                    this.$message.warning('请填写“存储位置”')
                    document.getElementById('warehouseLocationFullPath' + index).focus()
                    bool = false
                }
            })
            if (!bool) return
            this.loading = true
            this.$apis.subimitInitData(this.tableData).then(res => {
                if (res.body) {
                    this.$message.success('成功初始化库存，自动进入系统首页')
                    window.config.install = "true"
                    this.$router.push('/Index')
                } else {
                    this.$message.error('已经初始化过库存了，请勿重复初始化！')
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>
<style lang="less" scoped>
.init_page {
    .init_title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: 10px 0;
    }

    .button {
        margin-left: 10px;
    }

    .bottom-btn>div {
        display: inline-block;
    }
}
</style>