import request from '../request.js'

export default {
	// 银行账户-分页列表
	pageAccount(data) {
		return request.Post({
			url: '/v1/financial/blank/account/page',
			data: data
		})
	},
	// 银行账户-添加
	addAccount(data) {
		return request.Post({
			url: `/v1/financial/blank/account`,
			data: data
		})
	},
	// 银行账户-编辑
	editAccount(data,id) {
		return request.Post({
			url: `/v1/financial/blank/account/${id}`,
			data: data
		})
	},
	// 银行账户-启/禁用
	disabledAccount(id) {
		return request.Put({
			url: `/v1/financial/blank/account/enable/${id}`,
			data: {}
		})
	},
	// 银行账户-删除
	deleAccount(id) {
		return request.Delete({
			url: `/v1/financial/blank/account/${id}`,
		})
	},
	// 财务应收-分页列表
	pageReceivable(data) {
		return request.Post({
			url: '/v1/financial/receivable/page',
			data: data
		})
	},
	// 财务应收-结算
	postReceipts(data) {
		return request.Post({
			url: '/v1/financial/receipts/review',
			data: data
		})
	},
	// 财务应收-详情
	getReceivable(id) {
		return request.Get({
			url: `/v1/financial/receivable/${id}`,
			data: {}
		})
	},
	// 财务实收-分页列表
	pageReceipts(data) {
		return request.Post({
			url: '/v1/financial/receipts/page',
			data: data
		})
	},
	// 财务实收-冲账
	postReceiptsClash(data, id) {
		return request.Post({
			url: `/v1/financial/receipts/clash/${id}`,
			data: data
		})
	},
	// 财务实收-详情
	getReceipts(id) {
		return request.Get({
			url: `/v1/financial/receipts/${id}`,
			data: {}
		})
	},
	// 财务应付-分页列表
	pagePayable(data) {
		return request.Post({
			url: '/v1/financial/payable/page',
			data: data
		})
	},
	// 财务应付-详情
	getPayable(id) {
		return request.Get({
			url: `/v1/financial/payable//${id}`,
			data: {}
		})
	},
	// 财务实付-记账（付款）
	addPayment(data) {
		return request.Post({
			url: '/v1/financial/payment/review',
			data: data
		})
	},
	// 财务实付-冲账（扣减）
	reducePayment(data, id) {
		return request.Post({
			url: `/v1/financial/payment/deduce/${id}`,
			data: data
		})
	},
	// 财务实付-分页列表
	pagePayment(data) {
		return request.Post({
			url: '/v1/financial/payment/page',
			data: data
		})
	},
	// 财务实付-详情
	getPayment(id) {
		return request.Get({
			url: `/v1/financial/payment/${id}`,
			data: {}
		})
	},
}

import request_artisan from '@/utils/request_artisan'

// 交易流水
export function getFinancePageList(data) {
  return request_artisan({
    url: '/v1/Finance/Page',
    method: 'get',
    params: data
  })
}

// 交易流水
export function getFinanceList(data) {
  return request_artisan({
    url: '/v1/Finance/List',
    method: 'get',
    params: data
  })
}

// 交易汇总
export function getFinanceSummary() {
  return request_artisan({
    url: '/v1/Finance/Summary',
    method: 'get'
  })
}

// 交易汇总
export function getFinanceSummaryMonth(data) {
  return request_artisan({
    url: '/v1/Finance/Summary/Month',
    method: 'get',
    params: data
  })
}

// 生成并下载文件
export function getFinanceExportGenerateDownload(data) {
  return request_artisan({
    url: '/v1/Finance/Export/GenerateDownload',
    responseType: 'blob',
    method: 'get',
    params: data
  })
}
