<template>
  <div class="app-container">
    <div class="base-table">
      <div class="action">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate()"
        style="margin-bottom:15px ;"
          >新增"{{ attr.name }}"属性值</el-button
        >
      </div>
      <el-table
        :data="attrValueList"
        row-key="valueId"
        stripe
        v-loading="loading"
        border
      >
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column label="操作" align="left">
          <template #default="scope">
            <template>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                title="是否确认删除？"
                style="margin-left: 10px"
                @confirm="handleDelete(scope.row)"
              >
                <template #reference>
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete-solid"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AttrValueDialog
      ref="attrValueDialog"
      :dialog-title="dialogTitle"
      @updateList="getAttrValueListApi"
    />
  </div>
</template>

<script>
import { getAttrValueList, deleteAttrValue } from "@/api/Attr";
import AttrValueDialog from "./components/AttrValueDialog";
export default {
  name: "attrValueList",
  components: {
    AttrValueDialog,
  },
  data() {
    return {
      attrId: "",
      loading: false,
      queryForm: {
        createDate: [],
      },
      total: 0,
      attr: {},
      attrValueList: [],
      categoryInfo: {},
      dialogTitle: "",
      columns: [
        {
          label: "属性ID",
          prop: "attrId",
        },
        {
          label: "属性值",
          prop: "name",
        },
        {
          label: "备注",
          prop: "description",
        },
      ],
    };
  },
  mounted() {
    this.getAttrValueListApi();
  },
  methods: {
    async getAttrValueListApi() {
      const { id } = this.$route.query;
      this.attrId = id;
      this.loading = true;
      this.attr = await getAttrValueList({ attrId: this.attrId });
      this.attrValueList = this.attr.valueList;
      this.loading = false;
    },
    handleCreate() {
      this.dialogTitle = "添加属性";
      this.$refs.attrValueDialog.showDialog(this.attr);
    },
    handleEdit(row) {
      this.dialogTitle = "编辑属性";
      this.$refs.attrValueDialog.showDialog(this.attr, row);
    },
    async handleDelete(row) {
      await deleteAttrValue({ valueId: row.valueId });
      this.$message.success("删除成功");
      this.getAttrValueListApi();
    },
  },
};
</script>

<style lang="less" scoped>
.base-table{
  padding: 16px;
}
</style>
