<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="120px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="配置名称:" prop="name">
            {{ form.name }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="费用:" prop="value">
            <el-select v-model="form.value">
              <el-option
                v-for="(item, index) in 31"
                :label="index + '元'"
                :value="index"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { editSystemConfig } from '@/api/System'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        value: [
          {
            required: true,
            message: '请选择费用',
            trigger: 'blur'
          }
        ]
      },
      props: {}
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.form = {}
      this.resetForm('dialogForm')
    },
    async getData(sysConfig) {
      this.reset()
      this.form = sysConfig
      this.visible = true
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const params = JSON.parse(JSON.stringify(this.form))
            params.value = params.value+''
            await editSystemConfig(params)
            this.loading = false
            this.close()
            this.reset()
            this.$emit('updateList')
          } catch (error) {
            console.log(error)
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.map-link {
  line-height: 30px;
  color: #337ab7;
  margin-left: 30px;
  cursor: pointer;
}
</style>
