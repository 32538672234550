import request from '../request.js'

export default {
    // 分页-销售订单
    pageSaleOrder(data) {
        return request.Post({
            url: `/v1/sales/order/page`,
            data: data
        })
    },
    // 详情-销售订单
    getSaleOrder(uid) {
        return request.Get({
            url: `/v1/sales/order/${uid}`,
            data: {}
        })
    },
    // 提交-销售订单
    submitSaleOrder(data, id) {
        return request.Put({
            url: `/v1/sales/order/commit`,
            data: data
        })
    },
    // 删除-销售订单
    delSaleOrder(id) {
        return request.Delete({
            url: `/v1/sales/order/${id}`,
            data: {}
        })
    },
    // 暂存-销售订单
    saveSaleOrder(data) {
        return request.Put({
            url: `/v1/sales/order/stage`,
            data: data
        })
    },
    // 复核-销售订单
    approvalSaleOrder(data) {
        return request.Put({
            url: `/v1/sales/order/approval`,
            data: data
        })
    },
    // 作废-销售订单
    invalidSaleOrder(id) {
        return request.Put({
            url: `/v1/sales/order/invalid/${id}`,
            data: {}
        })
    },
    // 打印-销售订单
    printSaleOrder(id) {
        return request.DownloadPost({
            url: `/v1/common/export/salesOrder/${id}`,
            data: {}
        })
    },

    // 分页-销售退货单
    pageSaleReject(data) {
        return request.Post({
            url: `/v1/sales/returns/page`,
            data: data
        })
    },
    // 删除-销售退货单
    delSaleReject(id) {
        return request.Delete({
            url: `/v1/sales/returns/${id}`,
            data: {}
        })
    },
    // 分页-销售退货-可选退货产品
    pageSaleGoods(data) {
        return request.Post({
            url: `/v1/sales/returns/listPage`,
            data: data
        })
    },
    // 详情-销售退货单
    getSaleReject(id) {
        return request.Get({
            url: `/v1/sales/returns/${id}`,
            data: {}
        })
    },
    // 提交-销售退货单
    submitSaleReject(data) {
        return request.Put({
            url: `/v1/sales/returns/commit`,
            data: data
        })
    },
    // 暂存-销售退货单
    saveSaleReject(data) {
        return request.Put({
            url: `/v1/sales/returns/stage`,
            data: data
        })
    },
    // 审核-销售退货单
    approvalSaleReject(data) {
        return request.Put({
            url: `/v1/sales/returns/approval`,
            data: data
        })
    },
    // 作废-销售退货单
    invalidSaleReject(id) {
        return request.Put({
            url: `/v1/sales/returns/invalid/${id}`,
            data: {}
        })
    },
    // 打印-销售退货单
    printSaleReject(id) {
        return request.DownloadPost({
            url: `/v1/common/export/salesReturn/${id}`,
            data: {}
        })
    },
}