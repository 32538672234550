<template>
  <div class="upload-container">
    <imsButton
      text="上传图片"
      @click="dialogVisible = true"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <!-- <el-upload
        :action="domain"
        list-type="picture-card"
        :file-list="fileList"
        :show-file-list="true"
        multiple
        :http-request="upqiniu"
        :on-remove="handleRemove"
        class="editor-slide-upload"
      >
        <el-button size="small" type="primary">
          Click upload
        </el-button>
      </el-upload> -->
      <Upload
        :file-list.sync="fileList"
        upload-url="product"
        multiple
        :limit="20"
      />
      <div style="margin-top: 12px">
        <el-button @click="dialogVisible = false"> 取消 </el-button>
        <imsButton text="确定" @click="handleSubmit"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Upload from "@/components/Upload";
export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
  },
  components: {
    Upload,
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      // 七牛云的上传地址，根据自己所在地区选择，我这里是华南区
      domain: "http://upload-z2.qiniup.com",
      // 这是七牛云空间的外链默认域名
      qiniuaddr: "http://q5ba7z9r4.bkt.clouddn.com/",
    };
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every(
        (item) => this.listObj[item].hasSuccess
      );
    },
    handleSubmit() {
      // const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      // if (!this.checkAllSuccess()) {
      //   this.$message(
      //     '请等待所有图像成功上载。如果有网络问题，请刷新页面并重新上传！'
      //   )
      //   return
      // }
      this.$emit("successCBK", this.fileList);
      // this.listObj = {}
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess(response, file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = response.files.file;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // async upqiniu(req) {
    //   console.log(req)
    //   const config = {
    //     headers: { 'Content-Type': 'multipart/form-data' }
    //   }
    //   let filetype = ''
    //   if (req.file.type === 'image/png') {
    //     filetype = 'png'
    //   } else {
    //     filetype = 'jpg'
    //   }
    //   // 重命名要上传的文件
    //   const keyname =
    //     Math.random()
    //       .toString(36)
    //       .substr(2) +
    //     '.' +
    //     filetype
    //   // 从后端获取上传凭证token
    //   const result = await getUploadToken()
    //   const formdata = new FormData()
    //   formdata.append('file', req.file)
    //   formdata.append('token', result.token)
    //   formdata.append('key', keyname)
    //   axios.post(this.domain, formdata, config).then(res => {
    //     // console.log(res.data.key)
    //     const image = {
    //       url: `${this.qiniuaddr}${res.data.key}`
    //     }
    //     this.fileList.push(image)
    //   })
    // }
  },
};
</script>

<style lang="less" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  /deep/ .el-upload--picture-card {
    width: 100%;
  }
}
</style>
