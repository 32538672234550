<template>
    <div class="page">
        <div class="page-body" style="height: calc(100% - 100px);">
            <div class="title_text">
                {{ `${Form.year}年${Form.month}月冷库非免疫规划产品盘点表` }}
            </div>
            <el-table :data="Form.statementInfo" border style="width: 100%" height="calc(100% - 60px)">
                <el-table-column type="index" label="序号" width="60" align="center" fixed="left"></el-table-column>
                <el-table-column prop="productName" label="名称" min-width="160" align="center" fixed="left">
                </el-table-column>
                <el-table-column prop="unitName" label="单位" width="80" align="center" fixed="left">
                </el-table-column>
                <el-table-column label="上月" min-width="160" align="center">
                    <el-table-column prop="lastQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="lastPrice" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="lastAmount" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="本月购进" min-width="160" align="center">
                    <el-table-column prop="purchaseQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="purchasePrice" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="purchaseAmount" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="本月退货" min-width="160" align="center">
                    <el-table-column prop="returnQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="returnPrice" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="returnQuantity" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="本月退回" min-width="160" align="center">
                    <el-table-column prop="backQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="backPrice" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="backAmount" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="本月报废" min-width="160" align="center">
                    <el-table-column prop="scrapQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="scrapPrice" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="scrapAmount" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="本月出库" min-width="160" align="center">
                    <el-table-column prop="outboundQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="outboundQuantity" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="outboundAmount" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="本月结存" min-width="160" align="center">
                    <el-table-column prop="balanceQuantity" label="数量" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="balancePrice" label="单价" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="balancePrice" label="金额" min-width="120" align="center"></el-table-column>
                </el-table-column>
            </el-table>
        </div>

        <div class="page-footer">
            <div class="btn-box border">
                <imsButton typeClass="secondary" text="返回" @click="$router.push('/Statistics/Inventory')" />
                <imsButton style="margin-left: 10px;" text="导出" :loading="loading" @click="exportData()" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            Form: {
                id: null,
                statementInfo: [],
            },
            loading: false
        }
    },
    methods: {
        getDetail() {
            this.loading = true
            this.$apis.getStatisticsInventory(this.Form.id).then(res => {
                if (res.body) {
                    this.Form = res.body
                }
                this.loading = false
            }).catch(() => { this.loading = false })
        },
        // 导出
        exportData() {
            var notify = this.$notify.info({
                title: "消息",
                duration: 0,
                message: `正在导出，请耐心等待`,
            })
            this.$apis['exportStatisticsInventory'](this.Form)
                .then((res) => {
                    var filename = res.headers['content-disposition']
                    filename = filename.split('filename=')
                    filename = decodeURIComponent(filename[1])
                    notify.close();
                    this.$notify.success({
                        title: "消息",
                        duration: 3000,
                        message: `[${filename}]已导出，请查收`,
                    });
                    const blob = new Blob([res.data]);
                    let url = window.URL.createObjectURL(blob);
                    if ("download" in document.createElement("a")) {
                        try {
                            let link = document.createElement("a");
                            link.style.display = "none";
                            link.href = url;
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        navigator.msSaveBlob(blob, filename);
                    }
                })
                .catch((res) => {
                    
                });
        }
    },
    mounted() {
        this.Form.id = this.$route.query.id
        if (this.Form.id) {
            this.getDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.title_text {
    font-size: 24px;
    font-weight: bold;
    margin: 10px auto;
    text-align: center;
}
</style>