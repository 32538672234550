<template>
  <div>
    <!-- <el-progress
      v-if="uploadFlag"
      type="circle"
      :percentage="videoUploadPercent"
      style="position: absolute;top: 10px;left: 10px;"
    ></el-progress> -->
    <el-progress
      v-if="uploadFlag"
      :text-inside="true"
      :stroke-width="16"
      :percentage="videoUploadPercent"
    ></el-progress>
    <el-upload
      list-type="picture-card"
      action=""
      :class="{
        'upload-end': fileList.length === limit,
      }"
      :file-list="fileList"
      :http-request="handleUpload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-error="handleError"
      :on-success="handleSuccess"
      :limit="limit"
      :multiple="multiple"
    >
      <i class="el-icon-plus"></i>
      <!-- <img v-if="limit === 1 && fileList[0]" :src="fileList[0].url" class="avatar"> -->
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";
import { uploadImage, uploadProductImage } from "@/api/common";
import uploadFile from "@/utils/file";
export default {
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 1,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // url: 上传获得一张图片地址   另外是base64
    uploadType: {
      type: String,
      default: "url",
    },
    uploadUrl: {
      type: String,
      default: "banner",
    },
  },
  data() {
    return {
      imageList: [],
      uploadFlag: false,
      videoUploadPercent: 0,
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    async handleUpload(fileInfo) {
      console.log(file);
      const file = fileInfo.file;
      imageConversion
        .compressAccurately(file, 150)
        .then(async (res) => {
          res = new File([res], file.name, {
            type: res.type,
            lastModified: Date.now(),
          });
          if (this.uploadType === "base64") {
            if (res) {
              uploadFile.getBase64(res).then((res) => {
                this.fileList.push({
                  url: res,
                });
              }).catch(()=>{})
            }
          }

          if (this.uploadType === "url") {
            const formData = new FormData();
            formData.append("file", res);
            let sendUrl = "";
            if (this.uploadUrl === "banner") {
              sendUrl = uploadImage;
            } else if (this.uploadUrl === "product") {
              sendUrl = uploadProductImage;
            }
            this.uploadFlag = true;
            this.videoUploadPercent = 0;
            const { url, path } = await sendUrl(formData, (event) => {
              if (this.videoUploadPercent > 98) {
                this.videoUploadPercent = 98;
              } else {
                this.videoUploadPercent =
                  ((event.loaded / event.total) * 100).toFixed(0) * 1;
              }
            });
            this.uploadFlag = false;
            this.videoUploadPercent = 100;
            this.fileList.push({
              path,
              url,
            });
          }
        })
        .catch((res) => {
          this.$message.error("上传图片出错");
        });
    },
    handleRemove(file, fileList) {
      this.$emit("update:fileList", fileList);
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess() {
      console.log(1);
      this.videoFlag = false;
      this.videoUploadPercent = 0;
    },
    handleError(err, file, fileList) {},
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.upload-end {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>
