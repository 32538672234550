<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="80%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form ref="dialogForm" :model="form" :rules="rules" label-width="120px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="店铺名称:" prop="name">
            <el-input v-model="form.name" placeholder="请输入店铺名称" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="省市区:" prop="area">
            <!-- <el-cascader
              ref="cascaderArea"
              v-model="form.area"
              :options="areaList"
              
              filterable
            ></el-cascader> -->
            <el-cascader
              ref="cascaderArea"
              v-model="form.area"
              :props="props"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="详细地址:" prop="address">
            <el-input v-model="form.address" placeholder="请输入店铺名称" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="联系电话:" prop="contactNumber">
            <el-input
              v-model="form.contactNumber"
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开始营业时间:" prop="openBusinessHours">
            <el-time-select
              v-model="form.openBusinessHours"
              :picker-options="{
                start: '00:30',
                step: '00:15',
                end: '24:00'
              }"
              placeholder="选择开始营业时间"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="结束营业时间:" prop="closeBusinessHours">
            <el-time-select
              v-model="form.closeBusinessHours"
              :picker-options="{
                start: '00:30',
                step: '00:15',
                end: '24:00'
              }"
              placeholder="选择结束营业时间"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="经纬度:" prop="longitudeLatitude">
            <el-input
              v-model="form.longitudeLatitude"
              placeholder="请输入经纬度"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <span class="map-link" @click="handleMapLink"
            >高德地图坐标拾取工具</span
          >
        </el-col>
      </el-row>

      <el-form-item label="上传封面:" prop="fileList">
        <Upload
          :file-list.sync="form.fileList"
          :limit="5"
          multiple
          upload-url="product"
        />
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="描述:" prop="description">
            <el-input
              v-model="form.description"
              :rows="4"
              type="textarea"
              placeholder="说点什么..."
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { addShop, getShop, updateShop, getArea } from '@/api/Shop'
import Upload from '@/components/Upload'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    categoryInfo: {
      type: Object,
      default: () => ({})
    },
    categorysList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Upload
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        fileList: [],
        openBusinessHours: '',
        closeBusinessHours: ''
      },
      shopId: '',
      rules: {
        area: [
          {
            required: true,
            message: '请选择省市区',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: '请输入店铺名称',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: 'blur'
          }
        ],
        openBusinessHours: [
          {
            required: true,
            message: '请输入开始营业时间',
            trigger: 'change'
          }
        ],
        closeBusinessHours: [
          {
            required: true,
            message: '请输入结束营业时间',
            trigger: 'change'
          }
        ],
        contactNumber: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'blur'
          }
        ],
        longitudeLatitude: [
          {
            required: true,
            message: '请输入经纬度',
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: '请输入描述信息',
            trigger: 'blur'
          }
        ],
        fileList: [
          {
            required: true,
            message: '请至少上传一张图片',
            trigger: 'change'
          }
        ]
      },
      props: {
        lazy: true,
        // value: 'areaId',
        // label: 'name',
        lazyLoad: async (node, resolve) => {
          const { level, value } = node
          const nodes = (
            await getArea({
              parentAreaId: value || null,
              areaLevel: level + 1
            })
          ).map(item => {
            // if (level === 1 && item.areaLevel > 2) {
            //   other = true
            // }
            // if (level === 1 && item.areaLevel === 2) {
            //   other = false
            // }
            return {
              value: item.areaId,
              label: item.name,
              areaLevel: item.areaLevel,
              leaf: level >= 3
            }
          })
          resolve(nodes)
        }
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.form = {
        fileList: [],
        area: []
      }
      this.resetForm('dialogForm')
    },
    // 获取编辑信息
    getEditInfo(categoryInfo) {
      this.form = categoryInfo
      this.visible = true
    },
    handleMapLink() {
      window.open(
        'https://lbs.amap.com/tools/picker',
        '_blank',
        'width=1400,height=800'
      )
    },
    async getData(shopId) {
      this.reset()
      this.shopId = shopId
      // 编辑
      if (shopId) {
        const res = await getShop({ shopId })
        this.form = res
        console.log(res)
        this.form.longitudeLatitude = `${res.longitude},${res.latitude}`
        this.form.openBusinessHours = res.openBusinessHours
        this.form.closeBusinessHours = res.closeBusinessHours
        this.form.area = [
          res.provinceId,
          res.cityId,
          res.countyId,
          res.streetId
        ]
        const fileList = res.imageList.map(item => ({
          url: item
        }))
        this.$set(this.form, 'fileList', fileList)
      }
      this.visible = true
    },
    handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          const areaLabels = this.$refs['cascaderArea'].getCheckedNodes()[0]
            .pathLabels
          try {
            this.loading = true
            this.form.province = areaLabels[0]
            this.form.provinceId = this.form.area[0]
            this.form.city = areaLabels[1]
            this.form.cityId = this.form.area[1]
            this.form.county = areaLabels[2]
            this.form.countyId = this.form.area[2]
            this.form.street = areaLabels[3]
            this.form.streetId = this.form.area[3]
            const params = JSON.parse(JSON.stringify(this.form))
            const newArr = params.fileList.map(item => {
              return item.url.split('.com/')[1]
            })
            params.icon = newArr.join(',')

            params.longitude = params.longitudeLatitude.split(',')[0]
            params.latitude = params.longitudeLatitude.split(',')[1]
            delete params.fileList
            delete params.imageList
            delete params.area
            delete params.longitudeLatitude
            if (this.shopId) {
              params.shopId = this.shopId
              await updateShop(params)
            } else {
              await addShop(params)
            }
            this.loading = false
            this.close()
            this.$emit('updateList')
          } catch (error) {
            console.log(error)
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.map-link {
  line-height: 30px;
  color: #337ab7;
  margin-left: 30px;
  cursor: pointer;
}
</style>
