<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="50%"
    @close="close"
  >
    <template>
      <el-tabs v-model="data.deliveryType" @tab-click="handleClick">
        <el-tab-pane label="指定派送" name="1">
          <p>请输入派送对象手机号码（多个用,隔开）</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="data.mobileStr"
            style="margin-bottom: 12px;"
          >
          </el-input>
        </el-tab-pane>
        <el-tab-pane label="按类型派送" name="2">
          请选择派送对象的类型:
          <el-select
            v-model="data.membershipLevelId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in memberList"
              :label="item.name"
              :value="item.membershipLevelId"
              :key="item.prop"
              :prop="item.prop"
            ></el-option>
          </el-select>
        </el-tab-pane>
      </el-tabs>
    </template>
    <br />
    <div style="width: 100%" align="right">
      <el-button type="primary" align="right" @click="save()" style="margin-bottom: 12px;">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deliveryConpon } from "@/api/Marketings/coupons";
import { getMemberList } from "@/api/Members";
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      memberList: [],
      data: {
        couponId: null,
        // 1:指定派送，2:用户类型派送， （默认下标为 0）
        deliveryType: "1",
        membershipLevelId: null,
        mobileStr: null,
      },
    };
  },
  methods: {
    async getMemberListApi() {
      this.loading = true;
      let data = await getMemberList({ PageIndex: 1, PageSize: 999 });
      this.memberList = data.records;
      this.memberList.push({ membershipLevelId: 0, name: "所有注册/登录会员" });
      this.loading = false;
    },
    close() {
      this.visible = false;
      this.data = {
        couponId: null,
        // 1:指定派送，2:用户类型派送， （默认下标为 0）
        deliveryType: "1",
        membershipLevelId: null,
        mobileStr: null,
      };
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async save() {
      if (
        this.data.deliveryType === "1" &&
        (this.data.mobileStr == "" || this.data.mobileStr == undefined)
      ) {
        this.$message.error("请输入手机号");
      } else {
        this.data.couponId = this.couponInfo.couponId;
        await deliveryConpon(this.data);
        this.$message.success("操作成功");
        this.close();
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
