import request_artisan from '@/utils/request_artisan'

// --------------- 商品列表相关 ---------------

// 获取产品列表
export function getProductList(data) {
  return request_artisan({
    url: '/v1/product',
    method: 'get',
    params: data
  })
}

// 获取产品详情
export function getProductInfo(data) {
  return request_artisan({
    url: '/v1/product/' + data.productId,
    method: 'get'
  })
}

// 新增产品
export function addProduct(data) {
  return request_artisan({
    url: '/v1/product',
    method: 'post',
    data: data
  })
}

// 修改产品
export function updateProduct(data) {
  return request_artisan({
    url: '/v1/product/' + data.productId,
    method: 'put',
    data: data
  })
}

// 产品上下架
export function updateProductStatus(data) {
  return request_artisan({
    url: '/v1/product/state/' + data.productId,
    method: 'put',
    data: data
  })
}

// 删除产品
export function deleteProduct(data) {
  return request_artisan({
    url: '/v1/product/' + data.id,
    method: 'delete'
  })
}

// --------------- 商品分类列表相关 ---------------
// 获取分类列表
export function getCategorysList(data) {
  return request_artisan({
    url: '/v1/category',
    method: 'get',
    params: data
  })
}

// 更新分类状态
export function updateCategoryStatus(data) {
  return request_artisan({
    url: '/v1/category/status/' + data.id,
    method: 'put',
    data: data.status
  })
}

// 删除分类
export function deleteCategory(data) {
  return request_artisan({
    url: '/v1/category/delete/' + data.id,
    method: 'delete'
  })
}

// 保存分类
export function saveCategory(data) {
  return request_artisan({
    url: '/v1/category',
    method: 'post',
    data
  })
}

// 更新分类
export function updateCategory(data) {
  return request_artisan({
    url: '/v1/category/' + data.categoryId,
    method: 'put',
    data
  })
}

// --------------- 物料清单相关 ---------------
// 获取物料清单列表
export function getBillOfMaterials(data) {
  return request_artisan({
    url: '/v1/product/billOfMaterials',
    method: 'get',
    params: data
  })
}
