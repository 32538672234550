<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          充值卡列表
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="base-table">
        <div class="table-btns">
          <span class="link" style="float: right;"  @click="handleCreate"
            >添加充值卡</span>
        </div>
        <el-table :data="cardList" v-loading="loading" border>
          <el-table-column
            v-for="item in columns"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :width="item.width"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column label="操作" align="center" width="370">
            <template #default="scope">
              <template>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="handleView(scope.row)"
                  >查看</el-button
                >
                <el-button
                  v-if="!scope.row.isUsed"
                  type="warning"
                  icon="el-icon-success"
                  size="mini"
                  @click="handleIsUsed(scope.row)"
                  >上架</el-button
                >
                <el-button
                  v-if="!scope.row.isUsed"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  class="m-l-r-10"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <template v-if="scope.row.isUsed">
                  <el-popconfirm
                    title="是否确认下架？"
                    @confirm="handleIsUsed(scope.row)"
                  >
                    <template #reference>
                      <el-button type="warning" size="mini" icon="el-icon-error"
                      class="m-l-r-10"
                        >下架</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
                <template v-if="!scope.row.isUsed">
                  <el-popconfirm
                    title="是否确认删除？"
                    @confirm="handleDelete(scope.row)"
                  >
                    <template #reference>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryForm.pageIndex"
          :limit.sync="queryForm.pageSize"
          @pagination="getRechargeCardPageListApi"
        />
      </div>
      <Detail
        ref="Detail"
        :dialog-title="dialogTitle"
        :option-type="optionType"
      />
    </div>
  </div>
</template>

<script>
import {
  getRechargeCardPageList,
  updateRechargeCardUse,
  deleteRechargeCard
} from '@/api/Product/rechargecards'
import Detail from './components/Detail.vue'
export default {
  name: 'rechargecardList',
  components: {
    Detail
  },
  data() {
    return {
      loading: false,
      queryForm: {
        pageIndex: 1,
        pageSize: 10
      },
      total: 0,
      cardList: [],
      dialogTitle: '充值卡明细',
      optionType: 'view',
      columns: [
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '展示价格',
          prop: 'markPrice'
        },
        {
          label: '销售价格',
          prop: 'salePrice'
        },
        {
          label: '赠送金额',
          prop: 'givePrice'
        },
        {
          label: '创建时间',
          prop: 'createDate',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD'
            })
          }
        },
        {
          label: '上下架',
          prop: 'isUsed',
          formatter: (row, column, value) => {
            return value ? '上架' : '下架'
          }
        }
      ]
    }
  },
  mounted() {
    this.getRechargeCardPageListApi()
  },
  methods: {
    async getRechargeCardPageListApi() {
      this.loading = true
      const params = JSON.parse(JSON.stringify(this.queryForm))
      const { records, recordCount } = await getRechargeCardPageList(params)
      this.cardList = records
      this.total = recordCount
      this.loading = false
    },

    // 修改使用状态
    async handleIsUsed(row) {
      row.isUsed = !row.isUsed
      await updateRechargeCardUse(row)
      this.$message.success('操作成功')
      this.getRechargeCardPageListApi()
    },
    // 删除
    async handleDelete(row) {
      await deleteRechargeCard(row)
      this.$message.success('操作成功')
      this.getRechargeCardPageListApi()
    },
    // 创建
    handleCreate() {
      this.$refs.Detail.reset()
      this.optionType = 'add'
      this.dialogTitle = '添加充值卡'
      this.$refs.Detail.getData({})
      this.$refs.Detail.visible = true
    },
    // 修改
    handleEdit(row) {
      this.$refs.Detail.reset()
      this.optionType = 'edit'
      this.dialogTitle = '编辑充值卡'
      this.$refs.Detail.getData(row)
      this.$refs.Detail.visible = true
    },
    // 查看
    handleView(row) {
      this.$refs.Detail.reset()
      this.optionType = 'view'
      this.dialogTitle = '查看充值卡明细'
      this.$refs.Detail.getData(row)
      this.$refs.Detail.visible = true
    }
  }
}
</script>

<style lang="less" scoped></style>
