<template>
    <el-autocomplete ref="ref-dom" v-model="data" :fetch-suggestions="querySearchAsync" @select="handleSelect" clearable
        :maxlength="100" :disabled="disabled">    
        <i
            class="el-icon-sort el-input__icon"
            slot="prefix">
        </i>                                                      
    </el-autocomplete>
</template>
<script>
export default {
    props: ['value', 'label', 'apiMethod','valueName', 'disabled'],
    model: {
        prop: 'value',        // 指定 v-model 要绑定的参数叫什么名字，来自于 props 中定义的参数
        event: 'change',    // 指定要触发的事件名字，将被用于 $emit
    },
    data() {
        return {
            data: null,
            bool: true
        }
    },
    watch: {
        data(val, oldVal) {
            // 变化后，中止循环回填
            this.bool = false
            if (oldVal != null) {
                this.$emit('change', '')
            }
        },
        value: {
            handler(val) {
                if (!val) {
                    this.data = null
                }
            },
            immediate: true
        }
    },
    methods: {
        querySearchAsync(queryString, cb) {
            if (queryString === 'null') {
                queryString = ''
            }
            this.$apis[this.apiMethod]({
                pageIndex: 1,
                pageSize: 999,
                nameLike: queryString,
                enabled: true
            }).then(res => {
                var restaurants = []
                var data = res.body.records ? res.body.records : res.body
                data.forEach(item => {
                    restaurants.push({
                        value: item[this.valueName ? this.valueName : 'name'],
                        label: item.id||item.userId
                    })
                })
                cb(restaurants);
            }).catch(()=>{})
        },
        handleSelect(val) {
            setTimeout(() => {
                this.$emit('change', val.label)
                this.$emit('label', val.value) // 把label文字返回，需要在父组件接收使用
            }, 10);
        },
        focus() {
            this.$refs['ref-dom'].focus()
        },
        // 循环回填数据
        backFill() {
            if (this.$props.label && this.$props.value) {
                this.data = this.$props.label
            }
            setTimeout(() => {
                if (this.bool) {
                    this.backFill()
                }
            }, 100);
        }
    },
    mounted() {
        this.backFill()
    }
}

</script>