<template>
    <div class="page">
        <div class="page-header">
            <div class="page-title-box">
                <div class="page-title">
                    销售订单
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="page-search">
                <div>
                    <div class="search-item">
                    <div class="item-label">销售单号：</div>
                    <div class="item-value">
                        <el-input v-model="Query.noLike" clearable="" maxlength="99"></el-input>
                    </div>
                    </div>
                    <div class="search-item">
                        <div class="item-label">门店：</div>
                        <div class="item-value">
                            <inputSelect apiMethod="pageBtype" v-model="Query.customerId" />
                        </div>
                    </div>
                </div>
                <div class="page-search-right">
                    <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
                    <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
                </div>
            </div>
            <div class="table-btns">
                <span class="link" style="float: right;margin-left: 10px;"
                    @click="$router.push('/Sale/Order/formPage')">新增</span>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="no" label="销售单号" width="160" align="center"></el-table-column>
                <el-table-column prop="customer.name" label="门店" min-width="160" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="制单日期" width="160" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | dateformat() }}
                    </template>
                </el-table-column>
                <el-table-column prop="totalAmount" label="总金额" width="120" align="center"></el-table-column>
                <!-- <el-table-column prop="totalQuantity" label="总数量" width="120" align="center"></el-table-column> -->
                <el-table-column label="复核状态" width="160" align="center">
                    <template slot-scope="scope">
                        {{ dict2[scope.row.status] }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200" align="center">
                    <template slot-scope="scope">
                        <div class="link"
                            @click="$router.push(`/Sale/Order/${scope.row.status == 0 ? 'formPage' : 'detailPage'}?id=${scope.row.id}&status=${scope.row.status}`)">
                            {{ scope.row.status == 0 ? '编辑' : scope.row.status == 1 ? '复核' : '查看' }}
                        </div>
                        <div class="link" v-show="scope.row.status != 0" @click="printReview(scope.row.id)">
                            打印单据
                        </div>
                        <div class="link star" v-if="scope.row.status == 0" @click="delData(scope.row)">
                            删除
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination
            v-show="pageQuery.total > 0"
            :total="pageQuery.total"
            :page.sync="pageQuery.pageIndex"
            :limit.sync="pageQuery.pageSize"
            @pagination="getData()"
          />
        </div>

    </div>
</template>
<script>
import inputSelect from '@/components/inputSelect.vue';
import ImsNpmn from '@/components/imsNpmn.vue';
export default {
    components: {
        inputSelect,
        ImsNpmn
    },
    data() {
        return {
            loading: false,
            Query: {
                noLike: null,
                createTime: null,
                customerId:null,
            },
            pageQuery:{
                pageIndex: 1,
                pageSize: 10,
            },
            tableData: [],
        }
    },
    methods: {
        getData(item) {
            this.loading = true
            if(item)
                this.pageQuery={
                ...this.pageQuery,
                pageIndex:1
            }
            this.$apis.pageSaleOrder({ ...this.Query, ...this.pageQuery, }).then(res => {
                this.tableData = res.body.records;
                this.pageQuery.total = Number(res.body.recordCount);
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        printReview(id) {
          this.loading = true
          this.$apis.printSaleOrder(id).then(res => {
            console.log(1,res);
          })
          .catch(res => {
                this.loading = false
                const content = res;
                this.pdfUrl = window.URL.createObjectURL(
                new Blob([content], { type: "application/pdf" })
                );
                // window.open(this.pdfUrl);
                // var date = new Date().getTime();
                var ifr = document.createElement("iframe");
                ifr.style.frameborder = "no";
                ifr.style.display = "none";
                ifr.style.pageBreakBefore = "always";
                ifr.setAttribute("id", "printPdf");
                ifr.setAttribute("name", "printPdf");
                ifr.src = this.pdfUrl;
                document.body.appendChild(ifr);
                this.doPrint("printPdf");
                window.URL.revokeObjectURL(ifr.src); // 释放URL 对象
            })
        },
        doPrint(val) {
          var ordonnance = document.getElementById(val).contentWindow;
          setTimeout(() => {
            ordonnance.print();
            this.pdfLoading = false;
          }, 100);
        },
        delData(item) {
            this.$confirm(
                `您确定要删除单号为【${item.no}】的数据吗？删除后不可恢复！`,
                '删除提示',
                {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true
                }
            )
                .then(() => {
                    this.$apis.delSaleOrder(item.id).then(res => {
                        if (res.body) {
                            this.$message.success('删除成功')
                            this.getData(true)
                        }
                    })
                })
                .catch(() => {

                })
        },        
        handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getData()
        })
    }
}
</script>