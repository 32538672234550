<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">仓库管理</div>
      </div>
    </div>
    <div class="page-body">
      <div class="page-search">
        <div>
          <div class="search-item">
            <div class="item-label">仓库名称：</div>
            <div class="item-value">
              <el-input v-model="Query.nameLike" clearable maxlength="99"></el-input>
            </div>
          </div>
        </div>
        <div>
          <imsButton style="float: right;margin-top: 4px;" text="查询" :loading="loading" @click="getData(1)" />
          <imsButton style="float: right;margin: 4px;" typeClass="secondary" text="重置"  @click="handleReset()" />
        </div>
      </div>
      <div class="table-btns">
        <span class="link" style="float: right;" @click="$router.push('/System/Warehouse/Detail')">新增</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="仓库名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="no" label="仓库编号" width="180" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="contact" label="负责人" align="center"></el-table-column>
        <el-table-column prop="telephone" label="负责人电话" align="center"></el-table-column>
        <el-table-column label="启/禁用" width="80" align="center">
          <template slot-scope="scope">
            <el-switch active-color="#BB1BB5" v-model="scope.row.enabled" :disabled="loading"
              @change="val => toggleEnabled(val, scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <div style="display: inline-block; margin-right: 10px;" v-if="!scope.row.state">
              <el-button @click="$router.push('/System/Warehouse/Detail?id=' + scope.row.id)" type="text" class="edit">编辑</el-button>
              <el-button type="text" @click="deleData(scope.row)" v-show="!scope.row.disabled"
                style="color:rgba(217, 0, 17, 1)">删除</el-button>
            </div>
            <el-button type="text" v-else>查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="pageQuery.total > 0"
        :total="pageQuery.total"
        :page.sync="pageQuery.pageIndex"
        :limit.sync="pageQuery.pageSize"
        @pagination="getData()"
        /> 
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";
import imsButton from "@/components/imsButton.vue";
import inputSelect from "@/components/inputSelect.vue";
export default {
  components: {
    imsButton,
    inputSelect,
  },
  mixins: [mixins],
  data() {
    return {
      Query: {
        nameLike: ""
      },
      loading: false,
      disabled: false,
      pageQuery:{
        pageIndex: 1,
        pageSize: 10,         
      },
      tableData: [],
    };
  },
  methods: {
    deleData(item) {
      this.$confirm("您确定要删除" + item.name + "吗?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning"
      }).then(() => {
        this.$apis.delWarehouse(item.id).then(res => {
          if (res.body) {
            this.$message.success("删除成功！");
            this.getData();
          }
        });
      }).catch(()=>{})
    },
    toggleEnabled(bool, item) {
      this.loading = true;
      this.$apis
        .toggleWarehouse(item.id)
        .then(res => {
          if (res.body) {
            this.$message.success("状态成功切换！");
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = this.tableData.map(ele => {
            if (ele.id == item.id) {
              return {
                ...ele,
                disabled: !bool
              };
            } else return ele;
          });
          this.loading = false;
        });
    },
    getData(item) {
      this.loading = true;
      if(item)
      this.pageQuery={
        ...this.pageQuery,
        pageIndex:1
      }
      this.$apis
        .pageWarehouse({ ...this.Query, ...this.pageQuery })
        .then(res => {
          this.tableData = res.body.records;
          this.pageQuery.total = Number(res.body.recordCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset() {
            this.initListData(this.Query)
            this.$nextTick(() => {
            this.getData(2)
            })
        }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="less" scoped>

</style>