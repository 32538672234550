<template>
    <el-input v-model="val" :disabled="disabled" ref="dom" :id="id" :max="max" :min="min" :placeholder="placeholder" clearable type="number">
    </el-input>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            default: ''
        },
        disabled: { // 是否停用
            default: false
        },
        precision: { // 限制几位小数
            default: 0
        },
        max: { // 最大值
            default: 99999999
        },
        min: { // 最小值
            default: 0
        },
        placeholder: {
            default: ''
        },
        id: {
            default: 'id'
        }
    },
    watch: {
        val(val) {
            val = val + '' // 强转字符串来处理
            if (this.precision == 0) {
                val = val.replace(/[^\d]/g, '') // 强制转换非数字为空字符串
            } else {
                val = val.replace(/[^\d\.]/g, '') // 强制转换除了数字和小数点外的为空字符串
            }
            // 只允许输入一个小数点
            var arr = val.split('.')
            if (arr.length > 2) {
                val = arr[0] + '.' + arr[1]
            }
            // 限制小数点后最大位数
            var arr2 = val.split('.')
            if (arr2.length == 2) {
                if (arr2[1].length > this.precision) {
                    val = arr2[0] + '.' + arr2[1].substring(0, this.precision)
                }
            }
            if (val != '') {
                if (Number(val) > this.max) {
                    val = this.max // 若超过最大值，强转为最大值
                }
                if (Number(val) < this.min) {
                    val = this.min // 若小于最小值，强转为最小值
                }
            }
            this.val = val == '' ? '' : val
                this.$emit('change', this.val)
        },
        value(val) {
            this.val = val
        }
    },
    data() {
        return {
            val: ''
        }
    },
    methods: {
        focus() {
            this.$refs.dom.focus()
            document.getElementById(this.$props.id).focus()
        }
    },
    mounted() {
        this.val = this.value
    }
}
</script>