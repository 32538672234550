import request_artisan from '@/utils/request_artisan'

// 商品属性列表
export function getAttrList(data) {
  return request_artisan({
    url: '/v1/Attr/List',
    method: 'get',
    params: data
  })
}

// 商品属性列表
export function getAttrListV2(data) {
  return request_artisan({
    url: '/v2/Attr/List',
    method: 'get',
    params: data
  })
}

// 添加属性
export function addAttr(data) {
  return request_artisan({
    url: '/v2/Attr/Add',
    method: 'post',
    data
  })
}

// 更新属性
export function updateAttr(data) {
  return request_artisan({
    url: '/v2/Attr/' + data.attrId,
    method: 'put',
    data
  })
}

// 删除属性
export function deleteAttr(data) {
  return request_artisan({
    url: '/v2/Attr/' + data.attrId,
    method: 'delete'
  })
}

// 商品属性值列表
export function getAttrValueList(data) {
  return request_artisan({
    url: '/v2/AttrValue/List/' + data.attrId,
    method: 'get',
    params: data
  })
}

// 添加属性值
export function addAttrValue(data) {
  return request_artisan({
    url: '/v2/AttrValue',
    method: 'post',
    data
  })
}

// 更新属性值
export function updateAttrValue(data) {
  return request_artisan({
    url: '/v2/AttrValue/' + data.valueId,
    method: 'put',
    data
  })
}

// 删除属性
export function deleteAttrValue(data) {
  return request_artisan({
    url: '/v2/AttrValue/' + data.valueId,
    method: 'delete'
  })
}
