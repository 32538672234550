<template>
  <el-dialog
    title="商品列表"
    :visible.sync="visible"
    width="70%"
    @close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-table
      :data="productList"
      v-loading="loading"
      @current-change="clickChange"
    >
      <el-table-column label="选择" width="55">
        <template slot-scope="scope">
          <el-radio v-model="tableRadio" :label="scope.row"><i></i></el-radio>
        </template>
      </el-table-column>

      <el-table-column
        v-for="item in columns"
        :key="item.prop"
        :prop="item.prop"
        :formatter="item.formatter"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template v-if="item.prop === 'coverImage'" #default="scope">
          <img class="list-image m-6" :src="scope.row.coverImage" alt="" width="100"/>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="param.pageIndex"
      :limit.sync="param.pageSize"
      @pagination="getProductListApi"
    />
    <div slot="footer" class="dialog-footer">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { getProductList } from '@/api/Product'
export default {
  props: {
    shopId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      tableRadio: '',
      productList: [],
      productIds: [],
      page: {
        total: 0
      },
      param: {
        status: 2,
        pageIndex: 1,
        pageSize: 10
      },
      columns: [
        {
          label: '录入时间',
          prop: 'createDateTime',
          width: '180',
          formatter: (row, column, value) => {
            return this.formatDay(value, {
              format: 'YYYY-MM-DD HH:mm:ss'
            })
          }
        },
        {
          label: '产品货号',
          prop: 'productNo'
        },
        {
          label: '产品名称',
          prop: 'name'
        },
        {
          label: '首图',
          prop: 'coverImage'
        },
        {
          label: '产品类型',
          prop: 'category'
        },
        {
          label: '展示价格',
          prop: 'markPrice'
        },
        {
          label: '发布状态',
          prop: 'status',
          formatter: (row, column, value) => {
            return {
              1: '未启用',
              2: '已启用',
              3: '已删除'
            }[value]
          }
        }
      ]
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    reset() {
      this.productIds = []
    },
    async getData() {
      this.visible = true
      await this.getProductListApi()
    },
    async getProductListApi() {
      this.loading = true
      let { records, recordCount } = await getProductList(this.param)
      this.productList = records
      this.page.total = recordCount
      this.loading = false
    },
    clickChange(item) {
      this.tableRadio = item
    },
    handleSubmit() {
      try {
        this.$emit('addProduct', this.tableRadio.productUid)
        this.close()
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.map-link {
  line-height: 30px;
  color: #337ab7;
  margin-left: 30px;
  cursor: pointer;
}
</style>
