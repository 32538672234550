import request_artisan from '@/utils/request_artisan'

// 店铺列表
export function getShopList(data) {
  return request_artisan({
    url: '/v1/Shop/page',
    method: 'get',
    params: data
  })
}

// 店铺列表
export function getShopList2(data) {
  return request_artisan({
    url: '/v1/Shop/List',
    method: 'get',
    params: data
  })
}

// 省市区
export function getArea(data) {
  return request_artisan({
    url: '/v1/Area',
    method: 'get',
    params: data
  })
}

// 新增店铺
export function addShop(data) {
  return request_artisan({
    url: '/v1/Shop/Add',
    method: 'post',
    data
  })
}

// 修改店铺
export function updateShop(data) {
  return request_artisan({
    url: '/v1/Shop/update/' + data.shopId,
    method: 'put',
    data
  })
}

// 获取店铺详情
export function getShop(data) {
  return request_artisan({
    url: '/v1/Shop/' + data.shopId,
    method: 'get'
  })
}

// 修改店铺状态
export function updateShopStatus(data) {
  return request_artisan({
    url: '/v1/Shop/update/status',
    method: 'put',
    data
  })
}

// 删除店铺
export function deleteShop(data) {
  return request_artisan({
    url: '/v1/Shop/' + data.shopId,
    method: 'delete'
  })
}

// -------------------- 店铺商品 -----------------

// 店铺商品列表
export function getShopProductList(data) {
  return request_artisan({
    url: '/v1/ShopProduct/page',
    method: 'get',
    params: data
  })
}

// 新增店铺商品
export function addShopProduct(data) {
  return request_artisan({
    url: '/v1/ShopProduct/add',
    method: 'post',
    data
  })
}

// 删除店铺商品
export function deleteShopProduct(data) {
  return request_artisan({
    url: '/v1/ShopProduct/' + data.shopProductId,
    method: 'delete'
  })
}

// 店铺商品详情
export function getShopProduct(data) {
  return request_artisan({
    url: '/v1/ShopProduct/' + data.shopProductId,
    method: 'get',
    params: {
      shopId: data.shopId
    }
  })
}

// 店铺商品增加库存
export function addShopProductStore(data) {
  return request_artisan({
    url: '/v1/ShopProduct/store/add',
    method: 'put',
    data: data
  })
}
