// 前端布局相关接口

import request_artisan from '@/utils/request_artisan'

// ----------------- banner ------------------

// banner列表
export function getBannerList(data) {
  return request_artisan({
    url: '/v1/banner',
    method: 'get',
    params: data
  })
}

// 更新广告状态
export function updateBannerStatus(data) {
  return request_artisan({
    url: '/v1/banner/status/' + data.bannerId,
    method: 'put',
    data: {
      Status: data.status === 2 ? 3 : 2
    }
  })
}

// 删除广告
export function deleteBanner(data) {
  return request_artisan({
    url: '/v1/banner/' + data.id,
    method: 'delete'
  })
}

// 创建banner
export function createBanner(data) {
  return request_artisan({
    url: '/v1/banner',
    method: 'post',
    data
  })
}

// 查看banner
export function getBanner(data) {
  return request_artisan({
    url: '/v1/banner/' + data.id,
    method: 'get'
  })
}

// 更新banner
export function updateBanner(data) {
  return request_artisan({
    url: '/v1/banner/' + data.id,
    method: 'put',
    data: data.info
  })
}

// ----------------- 首页配置------------------

// 首页配置列表
export function getConfigList(data) {
  return request_artisan({
    url: '/v1/Home/Config',
    method: 'get',
    params: data
  })
}

// 删除首页配置项
export function deleteConfig(data) {
  return request_artisan({
    url: '/v1/Home/Config/' + data.id,
    method: 'delete'
  })
}

// 修改首页配置是否启用
export function updateConfigStatus(data) {
  return request_artisan({
    url: '/v1/Home/Config/IsUsed/' + data.id,
    method: 'put'
  })
}

// 拖拽编辑首页配置的位置
export function dragConfig(data) {
  return request_artisan({
    url: '/v1/Home/Config/Sort',
    method: 'put',
    data
  })
}

// 获取配置组件内容
export function getConfig(data) {
  return request_artisan({
    url: '/v1/Home/Config/' + data.id,
    method: 'get'
  })
}

// 新增首页配置
export function addConfig(data) {
  return request_artisan({
    url: '/v1/Home/Config',
    method: 'post',
    data
  })
}

// 更新首页配置
export function updateConfig(data) {
  return request_artisan({
    url: '/v1/Home/Config/' + data.configId,
    method: 'put',
    data
  })
}
