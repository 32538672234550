<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title-box">
        <div class="page-title">
          新人礼包
        </div>
      </div>
    </div>
    <div class="page-body">
      <el-card class="box-card" style="padding-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>新人大礼包配置</span>
        </div>
        <el-form ref="form" :model="configInfo" label-width="80px">
          <el-col :span="12">
            <el-card shadow="never">
              <el-form-item label="事件名称">
                <el-input v-model="configInfo.name"></el-input>
              </el-form-item>
              <el-form-item label="事件配图(领取弹窗)">
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  action="#"
                  :http-request="httpRequest"
                  :on-success="handleAvatarShowImageSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="configInfo.showImageUrl"
                    :src="configInfo.showImageUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="never">
              <el-form-item label="标识码">
                <el-input v-model="configInfo.identification" disabled></el-input>
              </el-form-item>
              <el-form-item label="事件配图(成功弹窗)">
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  action="#"
                  :http-request="finishedHttpRequest"
                  :on-success="handleAvatarFinishedImageSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="configInfo.finishedImageUrl"
                    :src="configInfo.finishedImageUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-card>
          </el-col>
        </el-form>
      </el-card>
      <br />
      <el-card class="box-card" style="padding-bottom: 20px">
        <div slot="header" class="clearfix">
          <span>已关联且启用中的优惠券</span>
          <span class="link" style="float: right;" 
            @click="openCouponList()"
            >添加优惠券</span>
        </div>
        <el-col :span="24">
          <el-table :data="configInfo.couponList" v-loading="loading" border>
            <el-table-column
              type="index"
              label="序号"
              width="120"
              align="center"
            />
            <el-table-column
              v-for="item in columns"
              :key="item.prop"
              :prop="item.prop"
              :formatter="item.formatter"
              :label="item.label"
              :width="item.width"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template #default="scope">
                <template>
                  <el-button
                    class="delte"
                    type="error"
                    size="small"
                    icon="el-icon-error"
                    @click="handleDelete(scope.row)"
                    >删除</el-button
                  >
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-card>
      <br />
      <div style="width: 100% ;height: 50px; text-align: center;">
        <imsButton text="保存" @click="save" style="display: inline-block;"/>
      </div>
      <CouponList
        ref="CouponList"
        :dialog-title="dialogTitle"
        :config-info="configInfo"
      />
    </div>
  </div>
</template>

<script>
import { getMarketing, saveMarketing, eventUpload} from "@/api/Marketings";
import CouponList from "./components/CouponList.vue";
export default {
  name: "giftBagList",
  components: {
    CouponList,
  },
  data() {
    return {
      loading: false,
      configInfo: {
        eventId: null,
        name: null,
        identification: null,
        showImageUrl: null,
        finishedImageUrl: null,
        couponList: [],
        couponIds: null,
      },
      dialogTitle: "添加优惠券",
      columns: [
        {
          label: "优惠券名称",
          prop: "name",
        },
        {
          label: "优惠券类型",
          prop: "couponTypeName",
        },
        {
          label: "面额",
          prop: "denomination",
        },
        {
          label: "是否启用",
          prop: "enable",
          formatter: function (row, column, value) {
            return value ? "是" : "否";
          },
        },
        {
          label: "是否过期",
          prop: "isOverdue",
          formatter: function (value, row, index) {
            return value ? "是" : "否";
          },
        },
        {
          label: "优惠券系统标识ID",
          prop: "identification",
        },
      ],
    };
  },
  mounted() {
    this.getMarketingApi();
  },
  methods: {
    async getMarketingApi() {
      this.loading = true;
      let data = await getMarketing();
      this.configInfo = {
        eventId: data.eventId,
        name: data.name,
        identification: data.identification,
        showImageUrl: data.showImageUrl,
        finishedImageUrl: data.finishedImageUrl,
        couponList: data.coupons,
      };
      this.loading = false;
    },
   async httpRequest(data){
      var formData = new FormData();
      formData.append("file", data.file);
      let res = await eventUpload(formData)
      this.configInfo.showImageUrl = res.url;
    },
    async finishedHttpRequest(data){
      var formData = new FormData();
      formData.append("file", data.file);
      let res = await eventUpload(formData)
      this.configInfo.finishedImageUrl = res.url;
    },
    handleAvatarShowImageSuccess(res, file) {
      this.configInfo.showImageUrl = res.body.url;
    },
    handleAvatarFinishedImageSuccess(res, file) {
      this.configInfo.finishedImageUrl = res.body.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleDelete(row) {
      //表示先获取这个元素的下标，然后从这个下标开始计算，删除长度为1的元素
      this.configInfo.couponList.splice(
        this.configInfo.couponList.indexOf(row),
        1
      );
      this.$message.success("删除成功");
    },
    // 打开派送
    openCouponList() {
      this.$refs.CouponList.visible = true;
      this.$refs.CouponList.onSelect();
    },
    // 保存
    async save() {
      if (
        this.configInfo.name == undefined ||
        this.configInfo.name == null ||
        this.configInfo.name == ""
      ) {
        this.$message.error("请填写名称");
        return;
      }
      if (
        this.configInfo.showImageUrl == undefined ||
        this.configInfo.showImageUrl == null ||
        this.configInfo.showImageUrl == ""
      ) {
        this.$message.error("请上传事件配图");
        return;
      }
      if (
        this.configInfo.finishedImageUrl == undefined ||
        this.configInfo.finishedImageUrl == null ||
        this.configInfo.finishedImageUrl == ""
      ) {
        this.$message.error("请上传事件配图");
        return;
      }
      if (this.configInfo.couponList.length == 0) {
        this.$message.error("请补充优惠券");
        return;
      }
      let couponIds = "";
      this.configInfo.couponList.forEach(function (item, index) {
        couponIds += item.couponId + ",";
      });
      couponIds = couponIds.substring(0, couponIds.length - 1);
      this.configInfo.couponIds = couponIds;
      await saveMarketing(this.configInfo);
      this.$message.success("操作成功");
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #BB1BB5;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.delte:hover{
  color: #BB1BB5;
  border-color:#BB1BB5;
  background-color: #fcfafc;
}
</style>
