<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="visible"
    width="70%"
    :close-on-click-modal="false"
    @close="close"
  >
    <el-form
      ref="dialogForm"
      :model="cardForm"
      :rules="rules"
      label-width="100px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="名称:" prop="name">
            <el-input
              v-model="cardForm.name"
              placeholder="请输入充值卡名称"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排序:" prop="sort">
            <el-input
              type="number"
              v-model="cardForm.sort"
              placeholder="请输入排序"
              min="0"
              @keyup.native="number($event)"
              @blur="number($event)"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="展示价格:" prop="markPrice">
            <el-input
              type="number"
              v-model="cardForm.markPrice"
              placeholder="请输入展示价格"
              min="0"
              @keyup.native="price($event)"
              @blur="price($event)"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="销售价格:" prop="salePrice">
            <el-input
              type="number"
              v-model="cardForm.salePrice"
              placeholder="请输入销售价格"
              min="0"
              @keyup.native="price($event)"
              @blur="price($event)"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="赠送金额:" prop="givePrice">
            <el-input
              type="number"
              v-model="cardForm.givePrice"
              placeholder="请输入销售价格"
              min="0"
              @keyup.native="price($event)"
              @blur="price($event)"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="摘要:" prop="summary">
            <el-input
              v-model="cardForm.description"
              maxlength="30"
              placeholder="请输入摘要"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="描述:" prop="description">
            <el-input
              type="textarea"
              v-model="cardForm.description"
              placeholder="请输入描述"
              :disabled="optionType == 'view'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" v-show="optionType != 'view'">
       <imsButton text="确定" @click="handleSubmit" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import { addRechargeCard, updateRechargeCard } from '@/api/Product/rechargecards.js'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    optionType: {
      type: String,
      default: 'view'
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      cardForm: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          }
        ],
        markPrice: [
          {
            required: true,
            message: '请输入展示价格',
            trigger: 'blur'
          }
        ],
        salePrice: [
          {
            required: true,
            message: '请输入销售价格',
            trigger: 'blur'
          }
        ],
        givePrice: [
          {
            required: true,
            message: '请输入赠送金额',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.reset()
    },
    // 重置表单
    reset() {
      this.cardForm = {}
      this.resetForm('dialogForm')
    },
    number(e) {
      // this.cardForm.sort = this.cardForm.sort.replace(/[^\.\d]/g, '')
      // this.cardForm.sort = this.cardForm.sort.replace('.', '')
      let boolean = new RegExp('^[1-9][0-9]*$').test(e.target.value)
      if (!boolean) {
        this.$message.error('请输入正整数')
        e.target.value = '0'
      }
    },
    price(e) {
      if (e.target.value < 0) {
        this.$message.error('请输入正整数')
        e.target.value = '0'
      }
    },
    getData(cardInfo) {
      this.cardForm = cardInfo
      this.visible = true
    },
    async handleSubmit() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          try {
            true
            this.loading = true
            const params = JSON.parse(JSON.stringify(this.cardForm))
            if (this.optionType == 'edit') {
              await updateRechargeCard(params)
            }
            if (this.optionType == 'add') {
              await addRechargeCard(params)
            }
            this.$parent.getRechargeCardPageListApi()
            this.loading = false
            this.close()
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.attr__name {
  font-size: 15px;
  font-weight: bold;
}
</style>
